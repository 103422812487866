import { Box, Text } from "@chakra-ui/react";
import Post from "./index"; // Ensure this path is correct

export default function PostsList({ posts }) {
  return (
    <Box
      w="100%"  // Ensure the width is set to 100% to take full horizontal space
      px={0}    // Remove horizontal padding
      mx={0}    // Remove horizontal margins
      border="none" // Confirm no border is applied that could affect width
      mt={0} // Confirm top margin is zero
      mb={0} // Confirm bottom margin is zero
    >
      {posts?.length === 0 ? (
        <Text textAlign="center" fontSize="xl">
          No posts yet... Feeling a little lonely here.
        </Text>
      ) : (
        posts?.map((post) => <Post key={post.id} post={post} />)
      )}
    </Box>
  );
}
