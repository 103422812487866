import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Stack,
  useBreakpointValue,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import Chats from "./chats";
import UserMessage from "./userMessage";
import Sidebar from "./sidebar";
import Contact from "./contact";
import { dispatch, useSelector } from "./redux/store";
import SharedMessages from "./sharedMessages";
import StarredMessages from "./starredMessages";
import Settings from "./settings";
import Groups from "./group";
import Call from "./call";
import Requests from "./requests";
import { ToggleSidebar, UpdateSidebarType } from "./redux/slices/app";
import { useDispatch } from "react-redux";
import UserGroup from "./userGroups";
import GroupInfo from "./groupInfo";
import CreateGroup from "./createGroup";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "lib/firebase";
import { useLocation } from "react-router-dom";

const MessageMu = () => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { sidebar } = useSelector((store) => store.app);
  const [settings, showSettings] = useState(false);
  const [groups, showGroups] = useState(false);
  const [calls, showCalls] = useState(false);
  const [requests, showRequests] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const { user, isLoading } = useAuth();
  const [userPressed, setUserPressed] = useState(
    window.location.pathname.split("/").pop() !== "messages"
      ? window.location.pathname.split("/").pop()
      : null
  );
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.app.sidebar.open);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [showGroupInfo, setShowGroupInfo] = useState(false);
  const [history, setHistory] = useState([]);
  const [messages, showMessages] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("groups")) {
      showGroups(true);
      showRequests(false);
      showMessages(false);
    } else if (location.pathname.includes("requests")) {
      showGroups(false);
      showRequests(true);
      showMessages(false);
    } else if (location.pathname.includes("messages")) {
      showGroups(false);
      showRequests(false);
      showMessages(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!user) return;
    const handleToggleSidebar = () => {
      dispatch(ToggleSidebar());
      dispatch(UpdateSidebarType("CONTACT"));
    };
    if (isSidebarOpen) {
      handleToggleSidebar();
    }
  }, [userPressed, user]);

  if (isLoading || !user) {
    return <Center>Loading...</Center>;
  }

  const refreshChat = () => {
    if (!user || !userPressed) return;
    const userRef = doc(db, user?.fullName ? "users" : "businesses", user?.id);
    const chatsCollectionRef = collection(userRef, "chats");
    const docRef = doc(chatsCollectionRef, userPressed);
    const timestampCollectionRef = collection(docRef, "timestamp");

    const unsubscribe = onSnapshot(timestampCollectionRef, (querySnapshot) => {
      querySnapshot.forEach((chatDoc) => {
        const chatId = chatDoc.id;

        onSnapshot(timestampCollectionRef, (timestampSnapshot) => {
          const chatDataList = [];
          timestampSnapshot.forEach((timestampDoc) => {
            const timestamp = timestampDoc.data();
            chatDataList.push({ ...timestamp, chatId });
          });

          setHistory(chatDataList);
        });
      });
    });

    return () => {
      unsubscribe();
    };
  };

  const renderChatsOrUserMessage = () => {
    if (userPressed) {
      return (
        <UserMessage
          setUserPressed={setUserPressed}
          userPressed={userPressed}
        />
      );
    } else {
      return (
        <>
          <Chats setUserPressed={setUserPressed} userPressed={userPressed} />
          {userPressed && (
            <UserMessage
              setUserPressed={setUserPressed}
              userPressed={userPressed}
            />
          )}
        </>
      );
    }
  };

  const renderContactsOrUserMessage = () => {
    if (sidebar.open) {
      return <></>;
    } else {
      return renderChatsOrUserMessage();
    }
  };

  const handleGroupSelect = (groupId) => {
    setSelectedGroupId(groupId);
    setShowGroupInfo(false);
    setCreateGroup(false);
  };

  const handleOpenCreateGroup = () => {
    setCreateGroup(true);
    setSelectedGroupId(null);
  };

  const renderGroupsOrUserGroups = () => {
    if (selectedGroupId) {
      if (isMobile) {
        if (showGroupInfo) {
          return (
            <GroupInfo
              groupId={selectedGroupId}
              setShowGroupInfo={setShowGroupInfo}
            />
          );
        } else if (createGroup) {
          return (
            <Box width="100%">
              <CreateGroup handleClose={() => setCreateGroup(false)} />
            </Box>
          );
        } else {
          return (
            <UserGroup
              groupId={selectedGroupId}
              onBack={() => setSelectedGroupId(null)}
              setShowGroupInfo={setShowGroupInfo}
            />
          );
        }
      } else {
        return (
          <>
            <Box width="30%">
              <Groups
                onGroupSelect={handleGroupSelect}
                onOpenCreateGroup={handleOpenCreateGroup}
              />
            </Box>
            <UserGroup
              groupId={selectedGroupId}
              onBack={() => setSelectedGroupId(null)}
              setShowGroupInfo={setShowGroupInfo}
            />
            {showGroupInfo && (
              <Box width="30%" borderLeft="1px solid" borderColor="gray.200">
                <GroupInfo
                  groupId={selectedGroupId}
                  setShowGroupInfo={setShowGroupInfo}
                />
              </Box>
            )}
          </>
        );
      }
    } else {
      return (
        <>
          <Box width={isMobile ? "100%" : "30%"}>
            {createGroup && isMobile ? (
              <CreateGroup handleClose={() => setCreateGroup(false)} />
            ) : (
              <Groups
                onGroupSelect={handleGroupSelect}
                onOpenCreateGroup={handleOpenCreateGroup}
              />
            )}
          </Box>
          {!isMobile && !createGroup && (
            <Box
              p={4}
              height="60%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="70%"
            >
              <Text fontSize="30px" color="#838080">
                No Group selected
              </Text>
            </Box>
          )}
        </>
      );
    }
  };

  return (
    <Center
      pt={isMobile ? 12 : 20}
      ml={!isMobile ? "-30" : "auto"}
      width={isMobile ? "100%" : "100%"}
      maxHeight={isMobile ? "auto" : "calc(100vh - 64px)"}
    >
      <Stack
        height={isMobile ? "87vh" : "100%"}
        width={isMobile ? "100vw" : "75%"}
        direction={"column"}
      >
        {!isMobile && (
          <Sidebar
            settings={settings}
            showSettings={showSettings}
            groups={groups}
            showGroups={showGroups}
            calls={calls}
            showCalls={showCalls}
            setUserPressed={setUserPressed}
            requests={requests}
            showRequests={showRequests}
          />
        )}
        {isMobile && !userPressed && (
          <Sidebar
            settings={settings}
            showSettings={showSettings}
            groups={groups}
            showGroups={showGroups}
            calls={calls}
            showCalls={showCalls}
            setUserPressed={setUserPressed}
            requests={requests}
            showRequests={showRequests}
          />
        )}
        <Box>
          <Stack
            height={isMobile ? "87vh" : "85vh"}
            width={isMobile ? "100vw" : "100%"}
            direction={"row"}
          >
            {!settings &&
              !groups &&
              !calls &&
              !requests &&
              isMobile &&
              renderContactsOrUserMessage()}
            {!settings && !groups && !calls && !requests && !isMobile && (
              <>
                <Box width="30%">
                  <Chats
                    setUserPressed={setUserPressed}
                    userPressed={userPressed}
                  />
                </Box>
                {userPressed && (
                  <UserMessage
                    setUserPressed={setUserPressed}
                    userPressed={userPressed}
                  />
                )}
                {!userPressed && !createGroup && (
                  <Box
                    p={4}
                    height="60%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="70%"
                  >
                    <Text fontSize="30px" color="#838080">
                      No Message selected
                    </Text>
                  </Box>
                )}
              </>
            )}
            {sidebar.open && userPressed && (
              <Box width="30%" borderLeft="1px solid" borderColor="gray.200">
                <Contact userPressed={userPressed} refreshChat={refreshChat} />
              </Box>
            )}
            {groups && renderGroupsOrUserGroups()}
            {createGroup && !requests && !isMobile && (
              <Box width="70%">
                <CreateGroup handleClose={() => setCreateGroup(false)} />
              </Box>
            )}
            {settings && <Settings />}
            {calls && <Call />}
            {requests && <Requests />}
          </Stack>
        </Box>
      </Stack>
    </Center>
  );
};

export default MessageMu;
