import {
  Box, Button, IconButton, Flex, Stack, Text, useColorMode, Menu, MenuButton,
  MenuList, MenuItem, useToast, useDisclosure, Modal, ModalBody, ModalContent,
  ModalOverlay, ModalHeader, Textarea, Input, ModalFooter, RadioGroup, Radio,
  VStack
} from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FiFile } from 'react-icons/fi';
import Avatar from 'components/profile/Avatar';
import { useUser } from 'hooks/users';
import { useDeletePost } from 'hooks/posts';
import { formatDistanceToNow } from 'date-fns';
import UsernameButton from 'components/profile/UsernameButton';
import { useAuth } from 'hooks/auth';
import { useState } from 'react';
import { db, storage } from 'lib/firebase'; // Import your Firebase setup
import { addDoc, collection, Timestamp, doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export async function submitReport({
  id,                     // ID could refer to postId, chatId, forumId, etc.
  reporterId,              // ID of the person reporting
  reportedId,        // This could be the authorId, chatUserId, forumPosterId, etc.
  reason,                  // Reason for the report
  additionalComments,      // Optional additional comments
  evidenceFiles,           // Any evidence provided by the user
  reportType,              // Type of report (post, chat, forum, groupChat, user)
}) {
  try {
    console.log('Submitting report...', { id, reporterId, reportedId, reason, reportType });

    // Step 1: Implement rate limiting (same for all report types)
    const reporterRef = doc(db, 'users', reporterId);
    const reporterDoc = await getDoc(reporterRef);

    if (!reporterDoc.exists()) {
      console.error('Reporter does not exist');
      return { success: false, message: 'Reporter does not exist.' };
    }

    const lastReportTimestamp = reporterDoc.data().lastReportTimestamp || 0;
    const currentTime = Date.now();

    if (currentTime - lastReportTimestamp < 20000) {
      return { success: false, message: 'You are submitting reports too quickly. Please wait before filing another report.' };
    }

    const evidenceFiles = [];

    // Step 2: Upload evidence files to Firebase Storage (if provided)
    if (evidenceFiles && evidenceFiles.length > 0) {
      for (const file of evidenceFiles) {
        if (file) {
          console.log(`Uploading file: ${file.name}`);
          const evidenceRef = ref(storage, `reports/${uuidv4()}-${file.name}`);
          const snapshot = await uploadBytes(evidenceRef, file);
          const evidenceUrl = await getDownloadURL(snapshot.ref);
          evidenceFiles.push(evidenceUrl);
          console.log(`Uploaded file URL: ${evidenceUrl}`);
        }
      }
    }

    // Step 3: Add the report details to Firestore
    const reportData = {
      id,                   // This ID could represent a postId, chatId, forumId, etc.
      reporterId,           // ID of the reporter
      reportedId,     // The ID of the reported user (based on reportType)
      reason,               // Reason for the report
      additionalComments,   // Additional comments (optional)
      timestamp: Timestamp.now(),
      evidenceFiles,         // Evidence URLs (optional)
      reportType,           // Type of report (e.g., 'post', 'chat', 'user', etc.)
    };

    console.log('Adding report to Firestore', reportData);

    // Add the report to the Firestore "reports" collection
    await addDoc(collection(db, 'reports'), reportData);

    // Step 4: Update reporter's `reportsFiled` and `lastReportTimestamp`
    await updateDoc(reporterRef, {
      reportsFiled: increment(1),
      lastReportTimestamp: currentTime,
    });

    // Step 5: Update the reported entity's `reportsReceived`
    if (['post', 'chat', 'forum', 'groupChat'].includes(reportType)) {
      const reportedEntityRef = doc(db, 'users', reportedId);
      await updateDoc(reportedEntityRef, {
        reportsReceived: increment(1),
      });
    } else if (reportType === 'user') {
      const reportedUserRef = doc(db, 'users', reportedId);
      await updateDoc(reportedUserRef, {
        reportsReceived: increment(1),
      });
    }

    console.log('Report submitted successfully');
    return { success: true, message: `Report for ${reportType} submitted successfully.` };

  } catch (error) {
    console.error('Error submitting report:', error);
    return { success: false, message: 'Failed to submit report. Please try again.' };
  }
}


export default function Header({ post, onEdit }) {
  const { id, uid } = post;
  const { user, isLoading } = useUser(uid);
  const { colorMode } = useColorMode();
  const { deletePost, isLoading: deletePostLoading } = useDeletePost(id);
  const toast = useToast();
  const authUser = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const [reportReason, setReportReason] = useState(''); 
  const [fileUpload, setFileUpload] = useState([]);
  const [comments, setComments] = useState('');

  if (isLoading || !user) return 'Loading...';

  const bgColor = colorMode === 'light' ? '#EDF7FE' : 'gray.900';
  const borderColor = colorMode === 'light' ? 'gray.100' : 'gray.700';
  const textColor = colorMode === 'light' ? 'gray.500' : 'gray.400';

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
  
    if (selectedFiles.length > 3) {
      toast({
        title: "Too many files.",
        description: "You can only attach up to 3 files.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 3000,
      });
      return;
    }
  
    setFileUpload(selectedFiles); // Store the files in the state
  };

  const handleSubmitReport = async () => {
    try {
      const result = await submitReport({
        id: post.id,  
        reporterId: authUser.user.id,
        reportedId: post.uid,
        reason: reportReason,  
        additionalComments: comments,
        evidenceFiles: fileUpload,
        reportType: "post",
      });
  
      toast({
        title: result.success ? 'Report submitted successfully.' : 'Failed to submit report.',
        description: result.message,
        status: result.success ? 'success' : 'error',
        isClosable: true,
        position: 'top',
        duration: 5000,
      });
  
      if (result.success) onClose();
    } catch (error) {
      console.error('Error handling report submission:', error);
      toast({
        title: 'Failed to submit report.',
        description: 'An unexpected error occurred. Please try again.',
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 5000,
      });
    }
  };

  const handleDeletePost = async () => {
    try {
      await deletePost();
    } catch (error) {
      toast({
        title: 'An error occurred while deleting the post.',
        description: error.toString(),
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 5000,
      });
    }
  };

  return (
    <>
      <Flex
        justifyContent={'space-between'}
        borderBottom={`2px solid ${borderColor}`}
        bg={bgColor}
        ml={0}
      >
        <Flex alignItems="center">
          <Box mr={2}></Box>
          <Avatar user={user} size="sm" post={true} />
          <Box ml="3" mt={1}>
            <Text>{user.username}</Text>
            <Stack p={1} />
          </Box>
        </Flex>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisH />}
            bg={bgColor}
            color={'gray'}
            mt={1}
            mr={1}
            _hover={{ backgroundColor: 'transparent' }}
            _active={{ backgroundColor: 'transparent' }}
            _after={{ backgroundColor: 'transparent' }}
          />
          <MenuList
            minWidth="5px"
            width="100px"
            p={0}
            border={'1.5px solid #EDF7FE'}
            borderRadius={'3%'}
          >
            {authUser?.user?.id === uid && (
              <MenuItem
                isLoading={deletePostLoading}
                onClick={handleDeletePost}
                borderBottom={'0.5px solid #EDF7FE'}
                fontSize={'xs'}
                pl={'25%'}
              >
                Delete
                <Box as="span" ml="1">
                  <RiDeleteBin6Line />
                </Box>
              </MenuItem>
            )}
            {authUser?.user?.id === uid && (
              <MenuItem
                pl={'35%'}
                borderBottom={'0.5px solid #EDF7FE'}
                fontSize={'xs'}
                onClick={onEdit}
              >
                Edit
                <Box as="span" ml="1">
                  <CiEdit />
                </Box>
              </MenuItem>
            )}
            {authUser?.user?.id !== uid && (
              <MenuItem pl="25%" fontSize={'xs'} onClick={onOpen}>
                Report
                <Box as="span" ml="1">
                  <HiOutlineDocumentReport />
                </Box>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Flex>

      {/* Report Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Report Post</ModalHeader>
          <ModalBody>
            <RadioGroup onChange={setReportReason} value={reportReason}>
              <VStack align="start">
                <Radio value="Spam">Spam</Radio>
                <Radio value="Harassment">Harassment</Radio>
                <Radio value="Inappropriate Content">Inappropriate Content</Radio>
                <Radio value="Other">Other</Radio>
              </VStack>
            </RadioGroup>
            {reportReason === 'Other' && (
              <>
              <Textarea
                placeholder="Describe the issue with this post..."
                value={comments}
                onChange={(e) => {
                  if (e.target.value.length <= 500) {
                    setComments(e.target.value);
                  }
                }}
                mt={3}
                maxLength={500}
              />
              <Text fontSize="sm" color={comments.length === 500 ? 'red.500' : 'gray.500'}>
              {500 - comments.length} characters remaining
            </Text>
            </>
            )}
            
            <Button
              variant="ghost"
              size="sm"
              leftIcon={<FiFile />}
              onClick={() => document.getElementById('fileInput').click()}
              bg="#6899fe"
              color="white"
              _hover={{ background: "#9bbafa" }}
              mt={3}
            >
              Attach Files
            </Button>
            <Input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              multiple
              display="none"
            />

            {/* Display the attached file names */}
            {fileUpload.length > 0 && (
              <Box mt={3}>
                <Text fontSize="sm" fontWeight="bold">
                  Attached Files:
                </Text>
                <VStack align="start">
                  {fileUpload.map((file, index) => (
                    <Text key={index} fontSize="sm">
                    {file.name}
                    </Text>
                  ))}
                </VStack>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              border={"2px #6899fe solid"}
              variant="ghost"
              onClick={onClose}
              bg="white"
              _hover={{ background: "lightgrey" }}
              rounded="md"
              size="xs"
              textColor="#6899fe"
              fontWeight="bold"
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitReport}
              bg="#6899fe"
              _hover={{ background: "#9bbafa" }}
              rounded="md"
              size="xs"
              textColor="white"
              fontWeight="bold"
            >
              Submit Report
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
