import { useToast } from "@chakra-ui/react";
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { db } from "lib/firebase";

export function useToggleReplyLike({ commentId, replyId, userId }) {
  const [isLoading, setLoading] = useState(false);

  async function toggleReplyLike(onLikeUpdate) {
    setLoading(true);
    const replyRef = doc(db, `comments/${commentId}/replies`, replyId);

    try {
      // Fetch the current reply data
      const replySnapshot = await getDoc(replyRef);
      const replyData = replySnapshot.data();

      if (!replyData) {
        throw new Error('Reply data is missing or invalid');
      }

      // Check if the user has already liked the reply
      const likes = Array.isArray(replyData.likes) ? replyData.likes : [];
      const existingLike = likes.find(like => like.userId === userId);

      if (existingLike) {
        // Remove the like
        await updateDoc(replyRef, {
          likes: arrayRemove(existingLike),
        });
      } else {
        // Add a new like with a timestamp
        const likeData = {
          userId: userId,
          timestamp: Timestamp.now(),
        };
        await updateDoc(replyRef, {
          likes: arrayUnion(likeData),
        });
      }

      // Fetch the updated reply data
      const updatedReplySnapshot = await getDoc(replyRef);
      const updatedReplyData = updatedReplySnapshot.data();

      // Update the likes in the UI
      onLikeUpdate(updatedReplyData.likes);
    } catch (error) {
      console.error('Error toggling reply like: ', error);
    } finally {
      setLoading(false);
    }
  }

  return { toggleReplyLike, isLoading };
}
