import {
  Heading,
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  useColorMode,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import { PROTECTED } from 'lib/routes';
import { FollowButton } from 'components/profile/index';
import { MessageRequestButton } from 'components/profile/index';
import { useAuth } from 'hooks/auth';

// Define the handleGenres function
const handleGenres = (genres) => {
  if (typeof genres === 'object' && genres !== null) {
    return Object.values(genres); // Convert the genres object to an array of values
  }
  return []; // Return an empty array if it's not an object
};

export default function UserCard({
  user,
  isNetwork,
  extraInfo,
  followAndRequest,
  inStudio,
}) {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { user: authUser } = useAuth();

  // Convert genres object to array if needed
  const genresArray = handleGenres(user.genres);

  return (
    <Flex
      width={'100%'}
      minHeight="100%"
      height={'auto'}
      bg={useColorModeValue(
        inStudio ? 'transparent' : 'white',
        inStudio ? 'transparent' : 'gray.900'
      )}
      boxShadow={inStudio ? '' : 'xl'}
      rounded={'lg'}
      borderWidth={inStudio ? '' : '0.2px'}
      borderColor={inStudio ? '' : 'rgba (72, 50,133, 0)'}
      direction="row"
      align="center"
      p={2}
      style={{
        boxShadow: inStudio ? '' : '0 4px 4px rgba(0, 0, 0, 0.25)',
      }}
      radius={5}
    >
      {/* Avatar on the left */}
      <Box
        p={3}
        borderRightWidth="0.2px"
        paddingRight={5}
        borderColor="rgba (72, 50,133, 0)"
      >
        <Avatar
          size={isMobile ? 'md' : 'lg'}
          user={user}
          alt={'Avatar Alt'}
          mb={0}
          pos={'relative'}
          post={true}
        />
      </Box>

      {/* Divider */}
      <Box
        borderRightWidth={1}
        ml={1}
        mr={-4}
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      />

      {/* User information on the right */}
      <Box pl={6} mt={2} flex="1">
        <Flex flexDirection={'row'} mb={3} alignItems="center">
          <Wrap spacing={1} mt={1} flex="1">
            <WrapItem>
              <Heading
                fontSize={{ base: '2xs', md: 'xs' }}
                fontFamily={'body'}
                as={Link}
                href={`${PROTECTED}/profile/${user.username}`}
              >
                {user?.fullName || user?.businessName || ''}
              </Heading>
            </WrapItem>
            <WrapItem>
              <Text
                fontWeight={200}
                fontSize={{ base: '2xs', md: 'xs' }}
                as={Link}
                href={`${PROTECTED}/profile/${user.username}`}
                mt={-0.5}
              >
                @{user.username}
              </Text>
            </WrapItem>
          </Wrap>

          {/* Conditional display of Follow and Request buttons */}
          {followAndRequest && (
            <Flex ml="auto" gap={2}>
              <FollowButton
                userId={user.id}
                authUserId={authUser?.id}
                isMobile={isMobile}
                updateFollowersCount={() => {}}
              />
              <MessageRequestButton
                userId={user.id}
                authUserId={authUser?.id}
                isMobile={isMobile}
                updateFollowersCount={() => {}}
              />
            </Flex>
          )}
        </Flex>

        {extraInfo && (
          <Box>
            <Text color="red.500" fontWeight="bold">
              {extraInfo}
            </Text>
          </Box>
        )}

        {/* Role, Instruments, Gender, Signed Status, and Genres */}
        {isNetwork && (
          <Wrap>
            <WrapItem
              borderRightWidth={1}
              paddingRight={inStudio ? (isMobile ? 2 : 3) : 1}
              borderColor="gray.200"
            >
              <Text
                fontWeight={400}
                fontSize={{ base: '2xs', md: 'xs' }}
                color={'red.700'}
              >
                {user.role}
              </Text>
            </WrapItem>
            {Array.isArray(user?.instrument) &&
              user.instrument.map((instrument, index) => (
                <WrapItem
                  key={index}
                  borderRightWidth={1}
                  paddingRight={inStudio ? (isMobile ? 2 : 3) : 1}
                  borderColor="gray.200"
                >
                  <Text
                    fontWeight={400}
                    fontSize={{ base: '2xs', md: 'xs' }}
                    color={'green.700'}
                  >
                    {instrument}
                  </Text>
                </WrapItem>
              ))}
            <WrapItem
              borderRightWidth={1}
              paddingRight={inStudio ? (isMobile ? 2 : 3) : 1}
              borderColor="gray.200"
            >
              <Text
                fontWeight={400}
                fontSize={{ base: '2xs', md: 'xs' }}
                color={'blue.700'}
              >
                {user.gender}
              </Text>
            </WrapItem>
            <WrapItem
              borderRightWidth={1}
              paddingRight={inStudio ? (isMobile ? 2 : 3) : 1}
              borderColor="gray.200"
            >
              <Text
                fontWeight={400}
                fontSize={{ base: '2xs', md: 'xs' }}
                color={'orange.400'}
              >
                {user.signed ? 'Signed' : 'Not Signed'}
              </Text>
            </WrapItem>
            {genresArray.map((genre, index) => (
              <WrapItem
                key={index}
                borderRightWidth={1}
                paddingRight={inStudio ? (isMobile ? 2 : 3) : 1}
                borderColor="gray.200"
              >
                <Text
                  fontWeight={400}
                  fontSize={{ base: '2xs', md: 'xs' }}
                  color={'purple.700'}
                >
                  {genre}
                </Text>
              </WrapItem>
            ))}
            {Array.isArray(user?.languages) &&
              user.languages.map((language, index) => (
                <WrapItem key={index}>
                  <Text
                    fontWeight={400}
                    fontSize={{ base: '2xs', md: 'xs' }}
                    color={'black'}
                    ml={0.5}
                  >
                    {language}
                  </Text>
                </WrapItem>
              ))}
          </Wrap>
        )}
      </Box>
    </Flex>
  );
}
