import React from "react";
import {
  Box,
  HStack,
  VStack,
  Avatar,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { FaEllipsisH } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineDocumentReport } from "react-icons/hi";

const RenderReplies = ({
  replies,
  commentId,
  authUser,
  isMobile,
  logoM,
  PROTECTED,
  handleEdit,
  handleDelete,
  handleUpvoteReply,
  handleDownvoteReply,
  showReplyToReplyInput,
  setShowReplyToReplyInput,
  replyToReplyText,
  setReplyToReplyText,
  handleReplyToReplySubmit,
  UpvoteImg,
  DownvoteImg,
  CommentImg,
  ShareImg,
  OptionsImg,
}) => {
  return replies.map((reply) => (
    <Box
      key={reply.id}
      p="2"
      borderWidth="1px"
      borderRadius="md"
      width="100%"
      border={"1px solid #6899FE"}
      boxShadow={"0px 2px 5px #6899FE"}
      mb={"5px"}
      // ml={4}
    >
      <HStack>
        <Avatar size="sm" src={reply.avatar || logoM} />
        <VStack alignItems="start" spacing="0">
          <Button
            color="black"
            fontSize={isMobile ? "11px" : "13px"}
            fontWeight="600"
            variant="link"
            as={RouterLink}
            to={`${PROTECTED}/profile/${reply.user}`}
          >
            {reply.user}
          </Button>
          <Text fontSize="8px" color="gray.500" whiteSpace="nowrap">
            {reply.createdAt instanceof Date &&
            !isNaN(reply.createdAt.getTime())
              ? formatDistanceToNow(reply.createdAt) + " ago"
              : reply.createdAt && !isNaN(new Date(reply.createdAt).getTime())
              ? formatDistanceToNow(new Date(reply.createdAt)) + " ago"
              : "Invalid date"}
          </Text>
        </VStack>
        <Flex justifyContent="flex-end" width="100%">
          <Menu>
            <MenuButton
              as={IconButton}
              background="white"
              icon={<FaEllipsisH />}
              variant="ghost"
              size="sm"
            />
            <MenuList
              border="1px"
              borderColor="#6899FE87"
              width="100px"
              sx={{ minWidth: "100px !important" }}
              padding="1px"
            >
              {authUser?.username === reply.user && (
                <MenuItem
                  pl="35%"
                  borderBottom="0.5px solid #EDF7FE"
                  fontSize="xs"
                  onClick={() =>
                    handleEdit(reply.text, `${commentId}-${reply.id}`, "reply")
                  }
                >
                  Edit
                  <Box as="span" ml="1">
                    <CiEdit />
                  </Box>
                </MenuItem>
              )}
              {authUser?.username === reply.user && (
                <MenuItem
                  borderBottom="0.5px solid #EDF7FE"
                  fontSize="xs"
                  pl="25%"
                  onClick={() =>
                    handleDelete(`${commentId}-${reply.id}`, "reply")
                  }
                >
                  Delete
                  <Box as="span" ml="1">
                    <RiDeleteBin6Line />
                  </Box>
                </MenuItem>
              )}
              {authUser?.username !== reply.user && (
                <MenuItem pl="25%" fontSize="xs">
                  Report
                  <Box as="span" ml="1">
                    <HiOutlineDocumentReport />
                  </Box>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <HStack
        mt={isMobile ? "2" : "2"}
        ml={isMobile ? "5px" : "5px"}
        gap={"5px"}
      >
        <Text
          color={"#6899FE"}
          fontSize={isMobile ? "12px" : "12px"}
          textAlign={"justify"}
          variant="link"
          as={RouterLink}
          to={`${PROTECTED}/profile/${reply.user}`}
          _hover={{ textDecor: "underline" }}
        >
          <strong>@{reply.user}</strong>
        </Text>
        <Text fontSize={isMobile ? "12px" : "12px"} textAlign={"justify"}>
          {reply.text}
        </Text>
      </HStack>
      <HStack spacing="1" ml="5px" mt="0">
        <IconButton
          background="white"
          backgroundImage={UpvoteImg}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize={isMobile ? "12px" : "15px"}
          onClick={() => handleUpvoteReply(commentId, reply.id)}
          _hover={{
            backgroundImage: `${UpvoteImg}`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <Text fontSize="sm" fontWeight="bold" textAlign="center">
          {reply.upvotes || 0}
        </Text>
        <IconButton
          background="white"
          backgroundImage={DownvoteImg}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize={isMobile ? "12px" : "15px"}
          onClick={() => handleDownvoteReply(commentId, reply.id)}
          _hover={{
            backgroundImage: `${DownvoteImg}`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <VStack>
          <Button
            ml={"10px"}
            background={"white"}
            backgroundImage={CommentImg}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundSize={isMobile ? "12px" : "15px"}
            onClick={() =>
              setShowReplyToReplyInput((prev) => ({
                ...prev,
                [`${commentId}-${reply.id}`]: !prev[`${commentId}-${reply.id}`],
              }))
            }
            _hover={{
              backgroundImage: `${CommentImg}`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </VStack>
        <Text fontSize={"sm"} cursor="pointer">
          {reply.replies?.length || 0}
        </Text>
        <Flex justifyContent="flex-end" alignItems="center" width="100%">
          <IconButton
            background="white"
            backgroundImage={ShareImg}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize={isMobile ? "12px" : "16px"}
            _hover={{
              backgroundImage: `${ShareImg}`,
            }}
          />
        </Flex>
      </HStack>

      <Box>
        {/* Reply to reply input */}
        {showReplyToReplyInput[`${commentId}-${reply.id}`] && (
          <InputGroup
            mt="2"
            mb="2"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Textarea
              variant="flushed"
              placeholder={`Reply to @${reply.user}...`}
              autoComplete="off"
              resize="vertical"
              value={replyToReplyText[`${commentId}-${reply.id}`] || ""}
              onChange={(e) =>
                setReplyToReplyText((prev) => ({
                  ...prev,
                  [`${commentId}-${reply.id}`]: e.target.value,
                }))
              }
              size={isMobile ? "xs" : "sm"}
              minH="30px"
              width={isMobile ? "65%" : "84%"}
              overflow="hidden"
              transition="height 0.5s"
            />
            <Button
              mt="1"
              ml="3"
              colorScheme="blue"
              size={isMobile ? "xs" : "sm"}
              fontSize={"10px"}
              onClick={() => handleReplyToReplySubmit(commentId, reply.id)}
            >
              Reply
            </Button>
          </InputGroup>
        )}
      </Box>

      {/* Render nested replies recursively */}
      {reply.replies && reply.replies.length > 0 && (
        <RenderReplies
          replies={reply.replies}
          commentId={commentId}
          authUser={authUser}
          isMobile={isMobile}
          logoM={logoM}
          PROTECTED={PROTECTED}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleUpvoteReply={handleUpvoteReply}
          handleDownvoteReply={handleDownvoteReply}
          showReplyToReplyInput={showReplyToReplyInput}
          setShowReplyToReplyInput={setShowReplyToReplyInput}
          replyToReplyText={replyToReplyText}
          setReplyToReplyText={setReplyToReplyText}
          handleReplyToReplySubmit={handleReplyToReplySubmit}
          UpvoteImg={UpvoteImg}
          DownvoteImg={DownvoteImg}
          CommentImg={CommentImg}
          ShareImg={ShareImg}
          OptionsImg={OptionsImg}
        />
      )}
    </Box>
  ));
};

export default RenderReplies;
