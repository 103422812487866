// Messages.js
import { Box, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  Timeline,
  TextMsg,
  MediaMsg,
  ReplyMsg,
  LinkMsg,
  DocMsg,
} from './msgTypes';
import { format } from 'date-fns';

const colorPalette = [
  'red.600',
  'green.600',
  'yellow.600',
  'orange.600',
  'purple.600',
  'pink.600',
  'cyan.600',
  'teal.600',
];

const Message = ({ menu, history, user, searchQuery }) => {
  const chatContainerRef = useRef(null);
  const [userColors, setUserColors] = useState({});

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }

    const usersInChat = Array.from(new Set(history.map((msg) => msg.sentBy)));
    const assignedColors = {};
    usersInChat.forEach((userId, index) => {
      assignedColors[userId] = colorPalette[index % colorPalette.length];
    });
    setUserColors(assignedColors);
  }, [history, searchQuery]);

  const getUserColor = (userId) => {
    return userColors[userId] || 'gray.100';
  };

  return (
    <Box
      p={3}
      height={'100%'}
      overflowY={'scroll'}
      ref={chatContainerRef}
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#6899fe',
          borderRadius: '24px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#6899fe',
        },
      }}
    >
      <Stack spacing={3}>
        {history?.map((el) => {
          const backgroundColor =
            el.sentBy === user.id ? 'blue.500' : getUserColor(el.sentBy);

          switch (el.type) {
            case 'divider':
              return <Timeline el={el} />;
            case 'msg':
              switch (el.subtype) {
                case 'img':
                  return (
                    <MediaMsg
                      el={el}
                      menu={menu}
                      backgroundColor={backgroundColor}
                      user={user}
                    />
                  );
                case 'doc':
                  return (
                    <DocMsg
                      el={el}
                      menu={menu}
                      user={user}
                      backgroundColor={backgroundColor}
                    />
                  );
                case 'link':
                  return (
                    <LinkMsg
                      el={el}
                      menu={menu}
                      user={user}
                      backgroundColor={backgroundColor}
                    />
                  );
                case 'reply':
                  return (
                    <ReplyMsg
                      el={el}
                      menu={menu}
                      user={user}
                      backgroundColor={backgroundColor}
                    />
                  );
                default:
                  return (
                    <TextMsg
                      el={el}
                      menu={menu}
                      user={user}
                      searchQuery={searchQuery}
                      backgroundColor={backgroundColor}
                    />
                  );
              }
            case 'text':
              return (
                <>
                  <TextMsg
                    el={el}
                    menu={menu}
                    user={user}
                    searchQuery={searchQuery}
                    backgroundColor={backgroundColor}
                  />
                  <Text fontSize="xs" color="gray.500">
                    {format(new Date(el.createdAt.seconds * 1000), 'PPpp')}
                  </Text>
                </>
              );
            default:
              return <></>;
          }
        })}
      </Stack>
    </Box>
  );
};

export default Message;
