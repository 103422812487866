import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  Button,
  UnorderedList,
} from '@chakra-ui/react';
import { collection, onSnapshot } from 'firebase/firestore';
import { useAuth } from 'hooks/auth';
import { db } from 'lib/firebase';
import DealItem from './dealItem';
import RequestDetails from './requestDetails';

const Requests = () => {
  const { user } = useAuth();
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [requestCollection, setRequestCollection] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);

  useEffect(() => {
    const fetchRequests = () => {
      if (user) {
        try {
          const requestsRef = collection(
            db,
            user.businessName ? 'businesses' : 'users',
            user.id,
            'requests'
          );

          const unsubscribe = onSnapshot(requestsRef, (snapshot) => {
            const requestsData = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            setRequestCollection(requestsData);
          });

          return () => unsubscribe();
        } catch (error) {
          console.error('Error setting up snapshot listener:', error);
        }
      }
    };

    fetchRequests();
  }, [user]);

  const handleSelectDeal = (deal) => {
    setSelectedDeal(deal);
  };

  const handleBack = () => {
    setSelectedDeal(null);
  };

  return (
    <Stack direction="row" height={isMobile ? '87vh' : '85vh'} width="100%">
      {(isMobile && !selectedDeal) || !isMobile ? (
        <Box
          position="relative"
          backgroundColor={colorMode === 'light' ? '#fff' : 'blackAlpha.300'}
          boxShadow="sm"
          overflowY="auto"
          width={isMobile ? '100%' : '30%'}
          borderRight="1px solid #E2E8F0"
          p={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text mb={3} fontSize="14px" color="#6899FE">
              All Requests
            </Text>
          </Stack>
          <UnorderedList styleType="none" p={0} m={0} spacing={4}>
            {requestCollection.length > 0 ? (
              requestCollection.map((deal) => (
                <DealItem
                  key={deal.id}
                  deal={deal}
                  onSelect={handleSelectDeal}
                />
              ))
            ) : (
              <Text>No Requests yet</Text>
            )}
          </UnorderedList>
        </Box>
      ) : null}
      {(isMobile && selectedDeal) || !isMobile ? (
        <Box width={isMobile ? '100%' : '70%'} p={4}>
          {isMobile && selectedDeal ? (
            <Button onClick={handleBack} mb={4}>
              Back
            </Button>
          ) : null}
          <RequestDetails deal={selectedDeal} onBack={handleBack} />
        </Box>
      ) : null}
    </Stack>
  );
};

export default Requests;
