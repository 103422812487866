import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Textarea,
  VStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import { useAuth } from 'hooks/auth';
import useAddComment from 'hooks/comments';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Comment from './Comment';
import { useNotifications } from 'hooks/notifications';
import { collection, query, where, onSnapshot } from 'firebase/firestore'; // Import Firestore functions
import { db } from 'lib/firebase'; // Your Firestore instance

export default function NewComment({ post }) {
  const postID = post?.id;  // Ensure post is defined
  const { user, isLoading: authLoading } = useAuth();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { addComment, isLoading: commentLoading } = useAddComment({
    postID,
    uid: user?.id,
  });
  const [comments, setComments] = useState([]);  // Use empty array initially
  const [commentText, setCommentText] = useState('');
  const navigate = useNavigate();
  const characterLimit = 400;
  const { sendNotification } = useNotifications();

  useEffect(() => {
    if (!postID) return;
  
    // Firestore query to get live comments for the post
    const commentsRef = collection(db, 'comments');
    const q = query(commentsRef, where('postID', '==', postID));
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        text: String(doc.data().text), // Ensure `text` is always a string
      }));
      setComments(fetchedComments);
    });
  
    return () => unsubscribe();
  }, [postID]);

  const onSubmit = async ({ commentText }) => {
    try {
      if (!user || !post) {
        throw new Error("User or Post is undefined.");
      }
  
      const newComment = {
        postID,
        text: String(commentText), // Ensure it's stored as a string
        user: {
          id: user.id,
          username: user.username,
        },
        replies: [],
        likes: [],
        date: new Date().toISOString(),
      };
  
      await addComment(newComment);
  
      if (user?.id !== post?.uid) {
        await sendNotification({
          title: 'New Comment',
          content: `commented on your `,
          uid: post?.uid,
          from: user?.id,
          type: 'postComment',
          time: Date.now(),
          postId: post?.id,
        });
      }
  
      reset();
      setCommentText(''); // Clear comment text state
  
    } catch (error) {
      console.error('Error adding comment:', error.message);
    }
  };

  function handleBack() {
    navigate(-1);
  }

  function addReply(commentId, replyText) {
    const newReply = {
      id: Date.now(),
      text: replyText,
      user: user,
      replies: [],
    };

    const updateComments = (comments) =>
      comments.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, replies: [...comment.replies, newReply] };
        } else if (comment.replies.length > 0) {
          return { ...comment, replies: updateComments(comment.replies) };
        }
        return comment;
      });

    setComments(updateComments(comments));
  }

  // Display error if post or user is not properly loaded
  if (!user || !post) {
    return (
      <Alert status="error">
        <AlertIcon />
        Unable to load user or post data.
      </Alert>
    );
  }

  // Ensure post.uid and post.id are present before proceeding
  if (!post?.uid || !post?.id) {
    return (
      <Alert status="error">
        <AlertIcon />
        Post data is incomplete.
      </Alert>
    );
  }

  return (
    <Box
      maxW="600px"
      mx="auto"
      border={'1px solid #E2E8F0'}
      borderRadius={'lg'}
    >
      <Flex padding={'15px 16px'} paddingBottom={'5px'} alignItems="flex-start">
        <Avatar user={user} size="sm" post={true} />
        <Box flex={1} ml="4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Textarea
                variant="flushed"
                placeholder="Write comment..."
                autoComplete="off"
                resize="vertical"
                {...register('commentText', { required: 'Comment text is required' })}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                maxLength={characterLimit}
                minH="30px"
                overflow="hidden"
                transition="height 0.2s"
              />
              <Text fontSize="xs" color="gray.500">
                {commentText.length}/{characterLimit} characters
              </Text>
              {errors.commentText && (
                <Alert status="error" mt="2">
                  <AlertIcon />
                  {errors.commentText.message}
                </Alert>
              )}
            </Box>
            <Flex pt="2">
              <Button
                isLoading={commentLoading || authLoading}
                type="submit"
                colorScheme="blue"
                isDisabled={!commentText}
                size="xs"
                ml="auto"
              >
                Add Comment
              </Button>
              <Button colorScheme="gray" size="xs" ml="2" onClick={handleBack}>
                Back
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}