import { useToast } from "@chakra-ui/react";
import { doc, getDoc, updateDoc, arrayRemove, arrayUnion, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { db } from "lib/firebase";

export function useToggleCommentLike({ commentId, userId }) {
  const [isLoading, setLoading] = useState(false);

  async function toggleCommentLike(onLikeUpdate) {
    setLoading(true);
    const commentRef = doc(db, 'comments', commentId);

    try {
      const commentSnapshot = await getDoc(commentRef);
      const commentData = commentSnapshot.data();

      if (!commentData) {
        throw new Error('Comment data is missing or invalid');
      }

      // Check if the user has already liked the comment
      const existingLike = commentData.likes.find((like) => like.userId === userId);

      if (existingLike) {
        // Remove the like
        await updateDoc(commentRef, {
          likes: arrayRemove(existingLike),
        });
      } else {
        // Add the like with timestamp
        const likeData = {
          userId: userId,
          timestamp: Timestamp.now(),
        };
        await updateDoc(commentRef, {
          likes: arrayUnion(likeData),
        });
      }

      // Update the likes in the UI
      const updatedCommentSnapshot = await getDoc(commentRef);
      const updatedCommentData = updatedCommentSnapshot.data();
      onLikeUpdate(updatedCommentData.likes);

    } catch (error) {
      console.error('Error toggling like:', error);
    } finally {
      setLoading(false);
    }
  }

  return { toggleCommentLike, isLoading };
}

