import {
  ChakraProvider,
  CSSReset,
  ColorModeScript,
  useColorMode,
} from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'lib/routes';
import { useEffect } from 'react';
// import { ThirdwebProvider } from '@thirdweb-dev/react';
// import {
//   metamaskWallet,
//   coinbaseWallet,
//   walletConnect,
//   embeddedWallet,
//   localWallet,
//   trustWallet,
// } from '@thirdweb-dev/react';
import {
  ThirdwebProvider,
  // metamaskWallet,
  // coinbaseWallet,
  // walletConnect,
  // trustWallet,
} from 'thirdweb/react';
import { createThirdwebClient } from 'thirdweb';

function App() {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode); // Store the color mode preference in local storage
  }, [colorMode]);

  {
    /*
     activeChain={'polygon'}
      desiredChainId={Polygon}
      // clientId="d294cd30551655013dfff9988795e8dd"
      // autoConnect={true}
      supportedChains={[Polygon]}
      // supportedWallets={[
      //   metamaskWallet(),
      //   coinbaseWallet(),
      //   walletConnect(),
      //   trustWallet(),
      //   // embeddedWallet({
      //   //   auth: {
      //   //     options: ['email', 'google', 'apple', 'facebook'],
      //   //   },
      //   // }),
      //   // localWallet(),
      // ]}
    */
  }

  const client = createThirdwebClient({
    secretKey: 'd294cd30551655013dfff9988795e8dd',
  });

  // List of routes where we want to hide the navbar
  const hideNavbarRoutes = ['/login', '/register', '/reset'];

  return (
    <ThirdwebProvider>
      <ChakraProvider>
        <CSSReset />
        <ColorModeScript
          initialColorMode={localStorage.getItem('colorMode') || 'light'}
        />
        {/* Set the initial color mode from local storage */}

        {/* Conditionally render the Navbar here based on the route and user authentication */}
        {/* Assuming you handle the layout or Navbar logic in the RouterProvider or routes */}
        {/* Render Router with router configuration */}
        <RouterProvider
          router={router}
          colorMode={colorMode}
          toggleColorMode={toggleColorMode}
        />
      </ChakraProvider>
    </ThirdwebProvider>
  );
}

export default App;
