import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  Text,
  useDisclosure,
  Image,
  HStack,
  Menu,
  MenuButton,
  Avatar,
  MenuItem,
  MenuList,
  MenuDivider,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useBreakpointValue,
  Spacer,
  Badge,
  useColorMode,
  Textarea,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Kbd,
  Spinner,
} from '@chakra-ui/react';
import Avatarr from 'components/profile/Avatar';
import {
  FiHome,
  FiStar,
  FiSettings,
  FiBell,
  FiUsers,
  FiAward,
  FiChevronRight,
  FiChevronLeft,
  FiMessageCircle,
  FiX,
  FiMoon,
  FiSun,
  FiSearch,
  FiMap,
  FiPlus,
  FiSend,
  FiCamera,
  FiVideo,
  FiFile,
  FiTrash,
} from 'react-icons/fi';
import { LuFileInput } from 'react-icons/lu';
import { GoHome } from 'react-icons/go';
import { LuMessagesSquare } from 'react-icons/lu';
import { MdOutlineCreditScore } from 'react-icons/md';
import { BsMusicNote, BsSearch } from 'react-icons/bs';
import playVideo from './play.png';
import { RiSecurePaymentFill } from 'react-icons/ri';
import logo from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Text Logo copy@0.75x.png';
import logoM from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png';
import filePdf from './pdf-icon.png';
import fileImage from './image-icon.png';
import fileAudio from './play.png';
import { useAuth, useLogout } from 'hooks/auth';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import {
  DASHBOARD,
  FINDR,
  MESSAGEMU,
  NETWORK,
  PAYMU,
  PROTECTED,
  SEARCH,
  FORUM,
  USER,
  FORUMS,
  BILLING,
  ADMIN,
  SETTINGS,
  INSTUDIO,
} from 'lib/routes';
import { Link as goToLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  startAt,
  endAt,
  addDoc,
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import NotificationItem from './NotificationItem';
import { orderBy } from 'lodash';
import { BiGroup } from 'react-icons/bi';
import { BsCash, BsFileMusic } from 'react-icons/bs';
import { FaForumbee, FaReact } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useAddPost } from 'hooks/posts';
import { getAnalytics, logEvent } from 'firebase/analytics';
import reactTextareaAutosize from 'react-textarea-autosize';
import UsernameButton from 'components/profile/UsernameButton';
import { CgLivePhoto } from 'react-icons/cg';
import { MentionsInput, Mention } from 'react-mentions';
import { getIDfromUsername } from 'hooks/users';
import { useNotifications } from 'hooks/notifications';

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showText, setShowText] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <SidebarContent
        onClose={onClose}
        showText={showText}
        setShowText={setShowText}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      ></Drawer>
      <MobileNav
        onOpen={onOpen}
        showText={showText}
        setShowText={setShowText}
        colorMode={colorMode}
        toggleColorMode={toggleColorMode}
      />
    </>
  );
}

function NewPost({ isOpen, onCloseNewPost }) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { register, handleSubmit, reset } = useForm();
  const { user, isLoading: authLoading } = useAuth();
  const { addPost, isLoading: addingPost } = useAddPost();
  const inputWidth = useBreakpointValue({
    base: '84vw',
    md: '65vw',
    lg: '30vw',
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [text, setText] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [filePressed, setFilePressed] = useState(false);
  const [users, setUsers] = useState([]);
  const { sendNotification } = useNotifications();

  const fetchUsers = async (queryText) => {
    if (!queryText) return;
    const usersRef = collection(db, 'users'); // Firestore collection
    const q = query(
      usersRef,
      where('username', '>=', queryText),
      where('username', '<=', queryText + '\uf8ff')
    );
    const querySnapshot = await getDocs(q);
    const fetchedUsers = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        display: doc.data().username,
        avatar: doc.data().avatar, // Assuming users have avatars
      }))
      // Exclude the authenticated user from suggestions
      .filter((userItem) => userItem.display !== user.username);

    setUsers(fetchedUsers);
  };

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setText(newValue);
  };

  const postCharacterLimit = 2000;

  async function handleAddPost(data) {
    try {
      const mentionRegex = /@\[(\w+)\]/g;
      const mentionedUsers = [...text.matchAll(mentionRegex)].map(
        (match) => match[1]
      );

      // Step 1: Create the post and get the post ID
      const postId = await addPost({
        uid: user.id,
        text: text.slice(0, postCharacterLimit),
        photos: selectedPhotos,
        videos: selectedVideos,
        files: selectedFiles,
      });

      console.log('Post created with ID: ', postId);

      if (!postId) {
        throw new Error(
          'Post ID not found. The post might not have been created.'
        );
      }

      console.log('Post ID: ', postId); // Log the post ID

      // Step 3: Send notifications to mentioned users
      await Promise.all(
        mentionedUsers.map(async (username) => {
          const userId = await getIDfromUsername(username);

          console.log(
            `Sending notification to user: ${username}, ID: ${userId}`
          ); // Log user info

          if (userId) {
            await sendNotification({
              title: "You've been tagged!",
              content: `tagged you in a `,
              uid: userId,
              from: user.id,
              type: 'postTagging',
              time: Date.now(),
              postId: postId, // Add the postId to the notification
            });

            console.log(`Notification sent to ${username}`);
          }
        })
      );

      // Reset form after post and notifications are handled
      reset(); // Reset the form
      setSelectedFiles([]);
      setSelectedPhotos([]);
      setSelectedVideos([]);
      setText('');
      onCloseNewPost();

      console.log('Post created and notifications sent successfully');
    } catch (error) {
      console.error('Error adding post or sending notifications:', error);
    }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value.length <= postCharacterLimit) {
      setIsExpanded(value !== '');
      setText(value);
    }
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handlePhotoInputChange = (event) => {
    const photos = Array.from(event.target.files);
    setSelectedPhotos((prevPhotos) => [...prevPhotos, ...photos]);
  };

  const handleVideoInputChange = (event) => {
    const videos = Array.from(event.target.files);
    setSelectedVideos((prevVideos) => [...prevVideos, ...videos]);
  };

  const handleFileDelete = (file) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.name !== file.name)
    );
  };

  const handlePhotoDelete = (photo) => {
    setSelectedPhotos((prevPhotos) =>
      prevPhotos.filter((prevPhoto) => prevPhoto.name !== photo.name)
    );
  };

  const handleVideoDelete = (video) => {
    setSelectedVideos((prevVideos) =>
      prevVideos.filter((prevVideo) => prevVideo.name !== video.name)
    );
  };

  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, 'new post');
  }, []);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Modal isOpen={isOpen} onClose={onCloseNewPost} size="xl">
      {' '}
      {/* Change the size to "xl" */}
      <ModalOverlay />
      <ModalContent maxW="600px">
        {' '}
        {/* Increase max width of modal content */}
        <ModalHeader
          mt={isMobile ? 1.5 : 3.5}
          p={isMobile ? '5px' : '12px 18px'}
          ml={isMobile ? '15px' : ''}
        >
          {' '}
          Create a New Post
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleAddPost)}>
          <ModalBody>
            <Box maxW="auto" mx="auto" pt="0">
              <Box
                maxW="auto"
                mx={{ base: 'auto', md: 'auto' }}
                p={2}
                pb="0"
                rounded="md"
                bg={colorMode === 'light' ? 'white' : 'gray.900'}
                border={'0.1px solid #e2e8f0'}
              >
                <VStack align="start" ml="3" mt={1} mb={2}>
                  <HStack>
                    <Avatarr size="sm" user={user} post={true} mr="10" />
                    <Text color={'#9f9f9f'}>{user?.username}</Text>
                  </HStack>
                </VStack>

                {/* MentionsInput Area */}
                <MentionsInput
                  value={text}
                  onChange={handleChange}
                  placeholder="Share your notes ♪"
                  style={{
                    control: {
                      backgroundColor: '#fff', // White background
                      fontSize: '14px', // Font size for text
                      padding: '12px', // Padding around the input
                      borderRadius: '6px', // Rounded corners
                      border: '1px solid #ddd', // Light gray border
                      height: '150px', // Fixed height to avoid auto-resize
                      width: '100%', // Full width of the container
                      overflowY: 'auto', // Enable scrolling when content exceeds height
                      resize: 'none', // Disable resizing
                      position: 'relative', // Make control the reference point for absolute positioning
                    },
                    highlighter: {
                      overflow: 'hidden', // Prevent overflow from the mentions highlighter
                    },
                    input: {
                      height: '100%', // Input area fills the height
                      padding: '8px', // Add padding inside the input for better text spacing
                      boxSizing: 'border-box', // Ensure padding doesn't affect overall width/height
                    },
                    suggestions: {
                      list: {
                        maxHeight: '150px', // Increase max height of mention suggestions
                        overflowY: 'auto', // Enable scrolling for suggestions
                        backgroundColor: '#f1f1f1', // Slightly different background for suggestions
                        border: '1px solid #ddd', // Add a border around the mention box
                        borderRadius: '6px', // Match the border-radius of the input
                        padding: '5px', // Add padding inside the suggestion box
                        position: 'absolute', // Position the suggestion box absolutely
                        top: '100%', // Make sure it's below the input box
                        left: '0', // Align to the left edge of the input box
                        marginTop: '10px', // Add margin to further separate it from the input
                        zIndex: 999, // Ensure it appears on top of other elements
                      },
                      item: {
                        padding: '10px 12px', // Increase padding for the suggestion item
                        fontSize: '16px', // Larger font size for the suggestion text
                        cursor: 'pointer', // Change cursor to pointer for better UX
                        display: 'flex', // Flex layout for avatar and username
                        alignItems: 'center', // Vertically center the avatar and text
                        '&focused': {
                          backgroundColor: '#ddd', // Highlight focused mention suggestion
                        },
                        borderRadius: '6px',
                      },
                    },
                  }}
                >
                  <Mention
                    trigger="@"
                    data={async (search, callback) => {
                      await fetchUsers(search);
                      callback(users);
                    }}
                    displayTransform={(id, display) => `@${display}`} // Display as @username
                    markup="@[__display__]" // Store mentions in the form @[username]
                    renderSuggestion={(
                      suggestion,
                      search,
                      highlightedDisplay
                    ) => (
                      <Box display="flex" alignItems="center">
                        <Avatarr
                          size="sm" // Larger size for the avatar
                          user={suggestion}
                          post={true}
                        />
                        <Text fontSize="10px" ml="5">
                          {highlightedDisplay}
                        </Text>{' '}
                        {/* Larger text */}
                      </Box>
                    )}
                  />
                </MentionsInput>

                {/* Character Limit and Other Elements */}
                <Text
                  fontSize="sm"
                  color={
                    text.length === postCharacterLimit ? 'red.500' : 'gray.500'
                  }
                  ml="2.5"
                >
                  {text.length}/{postCharacterLimit}
                </Text>

                <Flex
                  mt={2}
                  mb={0}
                  pb={0}
                  align="center"
                  justify="space-between"
                  ml="1"
                >
                  <Box rounded="md" p={0}>
                    <HStack>
                      <label htmlFor="file-input">
                        <IconButton
                          as="span"
                          background="none"
                          _hover={{ background: 'gray.200' }}
                          icon={<LuFileInput size={18} color="#6899FE" />}
                        />
                        <input
                          id="file-input"
                          type="file"
                          style={{ display: 'none' }}
                          multiple
                          accept="*"
                          onChange={handleFileInputChange}
                        />
                      </label>
                    </HStack>
                  </Box>
                  {addingPost && (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="md"
                    />
                  )}
                  {/* Submit Button */}
                  <IconButton
                    mr={2}
                    bg="#fff"
                    rounded="md"
                    size="sm"
                    type="submit"
                    icon={<FiSend fontSize={'18px'} color="#6899FE" />}
                    fontWeight="bold"
                    isLoading={authLoading || addingPost}
                    isDisabled={
                      text === '' &&
                      selectedPhotos.length === 0 &&
                      selectedFiles.length === 0
                    }
                    loadingText="Posting..."
                  />
                </Flex>
                {selectedFiles.length > 0 && (
                  <Box mt={2}>
                    <Text fontWeight="bold">Added Files:</Text>
                    {selectedFiles.map((file, index) => (
                      <Flex
                        key={index}
                        align="center"
                        paddingBottom={2}
                        justify="space-between"
                      >
                        <Text>{file.name}</Text>
                        <IconButton
                          icon={<FiTrash />}
                          size="sm"
                          onClick={() => handleFileDelete(file)}
                        />
                      </Flex>
                    ))}
                  </Box>
                )}
                {(authLoading || addingPost) && (
                  <Flex
                    position="fixed"
                    top="0"
                    left="0"
                    width="100vw"
                    height="100vh"
                    background="rgba(0, 0, 0, 0.3)"
                    align="center"
                    justify="center"
                    zIndex="9999"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Flex>
                )}
              </Box>
            </Box>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}

function SidebarContent({ onClose, showText, setShowText, ...rest }) {
  // Receive the showText and setShowText props
  const [iconWidth, setIconWidth] = useState(0);
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    isOpen: isOpenNewPost,
    onOpen,
    onClose: onCloseNewPost,
  } = useDisclosure();

  const handleToggleForm = () => {
    if (isOpenNewPost) {
      onCloseNewPost(); // Close the modal
    } else {
      onOpen(); // Open the modal
    }
  };

  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode);
  }, [colorMode]);

  useEffect(() => {
    if (sidebarRef.current) {
      const icons = sidebarRef.current.querySelectorAll('.sidebar-icon');
      if (icons.length > 0) {
        const firstIconWidth = icons[0].getBoundingClientRect().width;
        setIconWidth(firstIconWidth);
      }
    }
  }, []);

  useEffect(() => {
    if (user && user.id) {
      const userDocRef = collection(db, 'users');
      const userQuery = query(userDocRef, where('id', '==', user.id));

      // Set up the Firestore snapshot listener
      const unsubscribe = onSnapshot(userQuery, (snapshot) => {
        if (!snapshot.empty) {
          const userData = snapshot.docs[0].data();
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const toggleText = () => {
    setShowText(!showText); // Toggle the showText state using setShowText function
  };

  const sidebarItems = [
    { name: 'Home', icon: GoHome, active: false, goTo: DASHBOARD },
    { name: 'Network', icon: FiUsers, active: false, goTo: NETWORK },
    { name: 'Forums', icon: LuMessagesSquare, active: false, goTo: FORUM },
    { name: 'Finder', icon: FiMap, active: false, goTo: FINDR },
    { name: 'PayMu', icon: MdOutlineCreditScore, active: false, goTo: PAYMU },
    { name: 'InStudio', icon: CgLivePhoto, active: false, goTo: INSTUDIO },
    { name: 'New Post', icon: BsMusicNote, active: false },
    // { name: "NFT", icon: FaReact, active: false, goTo: "" },
    // { name: "Platinum", icon: FiAward, active: false, goTo: "" },
  ];

  return (
    <Box
      ref={sidebarRef}
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w="auto"
      pos="fixed"
      h="full"
      {...rest}
      zIndex={999}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box textAlign="center" mt="auto" mb="10">
        <Link
          fontSize="sm"
          fontWeight="bold"
          color={useColorModeValue('gray.600', 'gray.400')}
          onClick={toggleText}
          style={{ textDecoration: 'none' }}
          _focus={{ boxShadow: 'none' }}
        >
          {showText ? (
            <IconButton
              icon={<FiChevronLeft />}
              mt="2"
              color={colorMode === 'light' ? '#6899fe' : 'white'}
              background="none"
              fontSize="20"
            />
          ) : (
            <IconButton
              icon={<FiChevronRight />}
              mt="2"
              color={colorMode === 'light' ? '#6899fe' : 'white'}
              background="none"
              fontSize="20"
            />
          )}
        </Link>
      </Box>
      {sidebarItems.map((item) =>
        item.name === 'New Post' ? (
          <VStack spacing={4} mb={4} align="center">
            {/* <Button
              onClick={() => navigate(INSTUDIO)}
              ml={showText ? 5 : 5}
              w={{ md: !showText && 5 }}
              mt={4}
              leftIcon={<CgLivePhoto />}
              iconSpacing={showText ? 2 : 0}
              backgroundColor={'#EDF7FE'}
              textColor={'#6899fe'}
              cursor="pointer"
              border={
                isInStudio ? '3px solid lightgreen' : '2px solid transparent'
              }
              _hover={{ backgroundColor: 'lightgrey' }}
            >
              {showText && 'InStudio'}
            </Button> */}
            <Button
              onClick={handleToggleForm}
              ml={showText ? 0 : 0}
              w={{ md: !showText && 5 }}
              mt={4}
              leftIcon={<BsMusicNote />}
              iconSpacing={showText ? 2 : 0}
              backgroundColor={'#6899fe'}
              textColor={'white'}
              cursor="pointer"
              // _hover={{ backgroundColor: "#1041B2" }}
            >
              {showText && 'New Post'}
            </Button>
          </VStack>
        ) : (
          <NavItem
            key={item.name}
            icon={item.icon}
            as={goToLink}
            to={item.goTo}
            showText={showText}
            active={location.pathname === item.goTo} // Sets the item as active if the current location matches the goTo attribute
            colorMode={colorMode}
            toggleColorMode={toggleColorMode}
          >
            {item.name}
          </NavItem>
        )
      )}
      {isOpenNewPost && (
        <NewPost isOpen={isOpenNewPost} onCloseNewPost={onCloseNewPost} />
      )}
    </Box>
  );
}

function NavItem({
  icon,
  children,
  showText,
  active,
  colorMode,
  toggleColorMode,
  ...rest
}) {
  const [hovered, setHovered] = useState(false);

  const [isInStudio, setIsInStudio] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.id) {
      const userDocRef = collection(db, 'users');
      const userQuery = query(userDocRef, where('id', '==', user.id));

      // Set up the Firestore snapshot listener
      const unsubscribe = onSnapshot(userQuery, (snapshot) => {
        if (!snapshot.empty) {
          const userData = snapshot.docs[0].data();
          setIsInStudio(userData?.inStudio || false);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <Link
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      cursor="pointer"
    >
      <Flex
        align="center"
        p={'4'}
        py={6}
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={colorMode === 'light' ? 'gray' : 'white'}
        {...rest}
        position="relative"
      >
        <Flex
          px={2}
          rounded={'md'}
          backgroundColor={children === 'InStudio' && '#EDF7FE'}
          textColor={children === 'InStudio' && '#6899fe'}
          width={children === 'InStudio' && 'fit-content'}
          alignItems={'center'}
          height={children === 'InStudio' && 10}
          border={
            children === 'InStudio'
              ? isInStudio
                ? '3px solid lightgreen'
                : '3px solid #6899fe'
              : 'none'
          }
          _hover={
            showText
              ? { backgroundColor: '#D9D9D9' }
              : {
                  backgroundColor: children !== 'InStudio' && 'transparent',
                }
          }
        >
          <Icon
            mr={showText && '4'}
            fontSize={icon === FiMap ? '19' : '21'}
            color="#6899fe"
            as={icon}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
          {showText && (
            <Text
              fontSize="md"
              fontWeight="medium"
              color={colorMode === 'light' ? '#6899fe' : 'white'}
            >
              {children}
            </Text>
          )}
        </Flex>
        {hovered && !showText && (
          <Box
            position="absolute"
            left="65%"
            ml={5}
            px={4}
            py={2}
            bg={colorMode === 'light' ? 'gray.200' : 'gray.600'}
            borderRadius="md"
            whiteSpace="nowrap"
            _after={{
              content: `''`,
              position: 'absolute',
              top: '50%',
              left: '-8px', // Adjust this to position the arrow properly
              transform: 'translateY(-50%) rotate(45deg)',
              width: '20px',
              height: '20px',
              bg: colorMode === 'light' ? 'gray.200' : 'gray.600',
              clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)', // Sharp rocket-like shape
            }}
          >
            <Text color={colorMode === 'light' ? '#6899fe' : 'white'}>
              {children}
            </Text>
          </Box>
        )}
      </Flex>
    </Link>
  );
}

function MobileNav({ onOpen, showText, setShowText }) {
  const { logout, isLoading } = useLogout();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isInStudio, setIsInStudio] = useState(false);
  const [visibleCount, setVisibleCount] = useState(4); // Initially show 4 notifications

  const {
    isOpen: isOpenNewPost,
    onOpen: onOpenNewPost,
    onClose: onCloseNewPost,
  } = useDisclosure();
  const [isInputVisible, setIsInputVisible] = useState(false);

  const handleToggleForm = () => {
    if (isOpenNewPost) {
      onCloseNewPost(); // Close the modal
    } else {
      onOpenNewPost(); // Open the modal
    }
  };

  useEffect(() => {
    if (user && user.id) {
      const userDocRef = collection(db, 'users');
      const userQuery = query(userDocRef, where('id', '==', user.id));

      const unsubscribe = onSnapshot(userQuery, (snapshot) => {
        if (!snapshot.empty) {
          const userData = snapshot.docs[0].data();
          setIsInStudio(userData?.inStudio || false);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode);
  }, [colorMode]);

  const handleSearchClick = () => {
    setIsSearchActive(true); // Show the search input
  };

  const handleCloseSearch = () => {
    setSearchQuery('');
    setIsSearchActive(false);
  };

  useEffect(() => {
    if (user && user.id) {
      const notificationsQuery = query(
        collection(db, 'notifications'),
        where('uid', '==', user.id)
      );

      // Fetch and sort notifications by time
      const unsubscribe = onSnapshot(notificationsQuery, (querySnapshot) => {
        const fetchedNotifications = querySnapshot.docs.map((doc) =>
          doc.data()
        );

        // Sort notifications by time in descending order (most recent first)
        const sortedNotifications = fetchedNotifications.sort(
          (a, b) => b.time - a.time
        );

        setNotifications(sortedNotifications); // Update state with sorted notifications
      });

      return () => unsubscribe(); // Cleanup on unmount
    }
  }, [user]);

  async function deleteNotification(
    notificationId,
    userFollowedId,
    notificationType,
    notificationTime
  ) {
    try {
      const notificationSnapshot = await getDocs(
        query(
          collection(db, 'notifications'),
          where('uid', '==', notificationId),
          where('from', '==', userFollowedId),
          where('type', '==', notificationType),
          where('time', '==', notificationTime)
        )
      );

      if (!notificationSnapshot.empty) {
        notificationSnapshot.docs.forEach((docSnapshot) => {
          deleteDoc(doc(db, 'notifications', docSnapshot.id));
        });
        console.log('Notification deleted successfully.');
      } else {
        console.log('Notification not found or does not match criteria.');
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  }

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [userResults, setUserResults] = useState([]);

  const fetchUsers = async (searchQuery) => {
    const search = searchQuery.toLowerCase();
    try {
      const q = query(
        collection(db, 'users'),
        where('username', '>=', search),
        where('username', '<=', search + '\uf8ff')
      );
      const businessQ = query(
        collection(db, 'businesses'),
        where('username', '>=', search),
        where('username', '<=', search + '\uf8ff')
      );
      const querySnapshot = await getDocs(q);
      const businessQuerySnapshot = await getDocs(businessQ);
      const users = [
        ...querySnapshot.docs.map((doc) => doc.data()),
        ...businessQuerySnapshot.docs.map((doc) => doc.data()),
      ];
      setUserResults(users);
      setSearchResults(users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    // Assuming fetchUsers is already implemented and it fetches search results based on searchQuery
    if (e.target.value) {
      fetchUsers(e.target.value);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchResultClick = (result, index) => {
    setSearchQuery('');
    setSearchResults([]);
    setSelectedResultIndex(index);
    window.location.href = `${PROTECTED}/profile/${result.username}`;
  };

  const [selectedResultIndex, setSelectedResultIndex] = useState(0);

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/protected/search?query=${searchQuery.trim()}`);
      setSearchQuery('');
      setSearchResults([]);
    }
    setIsSearchActive(false);
  };

  const [subscribed, setSubscribed] = useState(null);

  const getActiveSubscription = async (user) => {
    try {
      if (!user) {
        console.log('User is undefined.');
        return null;
      }

      const userDocRef = collection(
        db,
        user.businessName ? 'businesses' : 'users',
        user.id,
        'subscriptions'
      );

      const snapshot = await getDocs(
        query(userDocRef, where('status', 'in', ['trialing', 'active']))
      );

      if (snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];
        const data = doc.data();
        return data.status;
      } else {
        console.log('No active or trialing subscription found.');
        return null;
      }
    } catch (error) {
      console.error('Error getting active subscription:', error);
      throw error;
    }
  };

  const handleShowMore = (e) => {
    e.stopPropagation(); // Prevent the menu from closing
    setVisibleCount(visibleCount + 4); // Load 4 more notifications
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getActiveSubscription(user);
        setSubscribed(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user?.id]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch(); // Call the search function on Enter key press
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      const change = event.key === 'ArrowUp' ? -1 : 1;
      setSelectedResultIndex((prevIndex) =>
        prevIndex + change < 0 || prevIndex + change >= searchResults.length
          ? prevIndex
          : prevIndex + change
      );
    }
  };

  const toggleInputVisibility = () => {
    setIsInputVisible((prevState) => !prevState); // Toggle the input visibility
  };

  return (
    <Flex
      ml={{
        base: !isMobile && -9,
        md: !isMobile && -9,
        sm: !isMobile && '-5px',
      }}
      px={{ base: !isMobile && 0, md: !isMobile && 0 }}
      height={{ base: 16, md: 16 }}
      alignItems="center"
      direction={isMobile ? 'column' : undefined}
      bg={colorMode === 'light' ? 'white' : 'gray.900'}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'space-between' }}
      position="fixed"
      right={0}
      left={0}
      zIndex={999} // Add zIndex to ensure the navbar is displayed above other elements
    >
      {!isMobile && (
        <Link as={goToLink} to={DASHBOARD}>
          <Image
            src={logo}
            alt="Musicom Logo"
            display={{ base: 'flex' }}
            h={{ base: '20', md: '20' }}
            align="center"
            ml={{ base: 'auto', md: 0 }} // Set the ml to 20 if showText is true
          />
        </Link>
      )}
      {isMobile && (
        <>
          {isSearchActive ? (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              p={2}
            >
              {/* Input Field */}
              <InputGroup w="100%">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  h="40px"
                  autoFocus
                />
              </InputGroup>

              {/* Search and Close Button */}
              <IconButton
                aria-label="Close search"
                icon={<SearchIcon color="blue.300" />}
                onClick={() => {
                  handleSearch();
                  handleCloseSearch();
                }}
                ml={2}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
              ></IconButton>
              <IconButton
                aria-label="Close search"
                icon={<CloseIcon />}
                onClick={handleCloseSearch} // Close search on click
                ml={2}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
              />
            </Flex>
          ) : (
            <HStack
              w="100%"
              px={{ base: 1, md: 4 }}
              py={0.5}
              justifyContent="space-between"
              alignItems="center"
              spacing={'0.5%'}
            >
              <Link as={goToLink} to={DASHBOARD}>
                <Image
                  src={logoM}
                  alt="Musicom Logo"
                  height="70px"
                  width="auto"
                  maxW="100%"
                />
              </Link>
              <Button
                backgroundColor="#6899fe"
                color="white"
                onClick={handleToggleForm}
                height="30px" // Adjust height to increase button size
                minWidth="30px" // Adjust width to increase button size
                p="7px"
                mr="10px"
              >
                <Icon as={BsMusicNote} boxSize={2.5} />
              </Button>
              <Button
                onClick={() => navigate(INSTUDIO)}
                height="30px" // Adjust height to increase button size
                minWidth="30px" // Adjust width to increase button size
                p="7px"
                mr="10px"
                backgroundColor={'#EDF7FE'}
                textColor={'#6899fe'}
                border={
                  isInStudio ? '3px solid lightgreen' : '3px solid #6899fe'
                }
              >
                <Icon as={CgLivePhoto} boxSize={2.5} />
              </Button>
              {isOpenNewPost && (
                <NewPost
                  isOpen={isOpenNewPost}
                  onCloseNewPost={onCloseNewPost}
                />
              )}

              {/* Search Icon Button */}
              <Box>
                <IconButton
                  aria-label="Search database"
                  icon={<SearchIcon color="blue.300" />}
                  onClick={handleSearchClick}
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                />
              </Box>

              <Menu>
                <MenuButton position="relative">
                  <IconButton
                    fontSize="18px"
                    variant="ghost"
                    aria-label="Notifications"
                    color="#6899fe"
                    icon={<FiBell />}
                  />
                  {notifications.length > 0 && (
                    <Badge
                      bg="#E21919"
                      color="white"
                      borderRadius="full"
                      position="absolute"
                      top="2px"
                      right="6px"
                      fontSize="9px"
                    >
                      {notifications.length}
                    </Badge>
                  )}
                </MenuButton>
                <MenuList
                  border="1px #6899FE solid"
                  rounded="lg"
                  maxHeight="300px"
                  maxWidth="200px"
                  overflowY="auto"
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  zIndex="999"
                >
                  {notifications.length > 0 ? (
                    notifications
                      .slice(0, visibleCount)
                      .sort((a, b) => b.time - a.time)
                      .map((notification) => (
                        <NotificationItem
                          key={notification.uid}
                          notification={notification}
                          deleteNotification={deleteNotification} // Only delete a single notification
                          colorMode={colorMode}
                        />
                      ))
                  ) : (
                    <MenuItem bg={colorMode === 'light' ? 'white' : 'gray.900'}>
                      No notifications
                    </MenuItem>
                  )}

                  {visibleCount < notifications.length && (
                    <Box textAlign="center" p={2}>
                      <Button
                        size="sm"
                        onClick={handleShowMore}
                        variant="ghost"
                        color={colorMode === 'light' ? 'blue.500' : 'gray.500'}
                      >
                        Show more notifications
                      </Button>
                    </Box>
                  )}
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  size="md"
                  variant="ghost"
                  aria-label="Profile"
                  _hover={{ color: 'blue.500' }}
                >
                  <Avatar
                    size={isMobile ? 'sm' : 'md'}
                    mr={5}
                    src={user?.avatar !== '' ? user?.avatar : logoM}
                    ring="1px"
                    ringColor="#6899fe"
                  />
                </MenuButton>
                <MenuList bg={colorMode === 'light' ? 'white' : 'gray.900'}>
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    as={goToLink}
                    to={`${PROTECTED}/profile/${user?.username}`}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    as={goToLink}
                    to={SETTINGS}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    as={goToLink}
                    to={BILLING}
                  >
                    {subscribed ? 'Billing' : 'Upgrade to Premium'}
                  </MenuItem>
                  {user?.isAdmin && (
                    <MenuItem
                      bg={colorMode === 'light' ? 'white' : 'gray.900'}
                      as={goToLink}
                      to={ADMIN}
                    >
                      Admin Panel
                    </MenuItem>
                  )}
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    onClick={toggleColorMode}
                  >
                    <IconButton
                      icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
                      onClick={toggleColorMode}
                      aria-label="Toggle Color Mode"
                    />
                    <Text ml="2">
                      {colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
                    </Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    onClick={logout}
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          )}
        </>
      )}
      {!isMobile && (
        <Box mr="auto" ml="auto" display={{ base: 'none', md: 'flex' }}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              h={{ base: 'auto', md: '10' }}
              w="605px"
            />
            <InputRightElement pointerEvents="none" mr={searchQuery && 7}>
              {searchQuery && <Kbd mr={3}>Enter</Kbd>}
              <SearchIcon color="gray.300" onClick={handleSearch} />
            </InputRightElement>
          </InputGroup>
        </Box>
      )}
      {!isMobile && (
        <HStack spacing={{ base: '0', md: '10' }}>
          <Link as={goToLink} to={MESSAGEMU}>
            <IconButton
              ml={'5'}
              size="md"
              fontSize="2xl"
              color={'#6899fe'}
              variant="ghost"
              aria-label="Messages"
              icon={<FiMessageCircle />}
            />
          </Link>
          <Menu defaultIsOpen={false} autoSelect={false}>
            <MenuButton py={2} transition="all 0.3s" pr="0">
              <Box position="relative">
                <IconButton
                  size="md"
                  fontSize="2xl"
                  variant="ghost"
                  color={'#6899fe'}
                  aria-label="Notifications"
                  icon={<FiBell />}
                />
                {notifications.length > 0 && (
                  <Badge
                    bg="#E21919"
                    color="white"
                    borderRadius="full"
                    position="absolute"
                    top="2px"
                    right="6px"
                    fontSize="10px"
                  >
                    {notifications.length}
                  </Badge>
                )}
              </Box>
            </MenuButton>
            <MenuList
              border="1px #6899FE solid"
              rounded="lg"
              maxHeight="300px"
              maxWidth="400px"
              overflowY="auto"
              bg={colorMode === 'light' ? 'white' : 'gray.900'}
              zIndex="999"
            >
              {notifications.length > 0 ? (
                notifications
                  .slice(0, visibleCount)
                  .sort((a, b) => b.time - a.time)
                  .map((notification) => (
                    <NotificationItem
                      key={notification.uid}
                      notification={notification}
                      deleteNotification={deleteNotification} // Only delete a single notification
                      colorMode={colorMode}
                    />
                  ))
              ) : (
                <MenuItem bg={colorMode === 'light' ? 'white' : 'gray.900'}>
                  No notifications
                </MenuItem>
              )}

              {visibleCount < notifications.length && (
                <Box textAlign="center" p={2}>
                  <Button
                    size="sm"
                    onClick={handleShowMore}
                    variant="ghost"
                    color={colorMode === 'light' ? 'blue.500' : 'gray.500'}
                  >
                    Show more notifications
                  </Button>
                </Box>
              )}
            </MenuList>
          </Menu>
          <Flex alignItems="center">
            <Menu defaultIsOpen={false} autoSelect={false}>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
                pr="5"
              >
                <HStack>
                  <Avatar
                    size="sm"
                    src={user?.avatar !== '' ? user?.avatar : logoM}
                  />
                </HStack>
              </MenuButton>
              <MenuList
                bg={colorMode === 'light' ? 'white' : 'gray.900'}
                borderColor="gray.200"
              >
                <MenuItem
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  as={goToLink}
                  to={`${PROTECTED}/profile/${user?.username}`}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  as={goToLink}
                  to={`${SETTINGS}`}
                >
                  Settings
                </MenuItem>
                <MenuItem
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  as={goToLink}
                  to={`${BILLING}`}
                >
                  {subscribed ? 'Billing' : 'Upgrade to Premium'}
                </MenuItem>
                {user?.isAdmin && (
                  <MenuItem
                    bg={colorMode === 'light' ? 'white' : 'gray.900'}
                    as={goToLink}
                    to={`${ADMIN}`}
                  >
                    Admin Panel
                  </MenuItem>
                )}
                <MenuItem
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  onClick={toggleColorMode}
                >
                  <IconButton
                    icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
                    onClick={toggleColorMode}
                    aria-label="Toggle Color Mode"
                  />
                  {colorMode !== 'light' ? (
                    <Text ml="2">light mode</Text>
                  ) : (
                    <Text ml="2">dark mode</Text>
                  )}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  bg={colorMode === 'light' ? 'white' : 'gray.900'}
                  onClick={logout}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      )}
    </Flex>
  );
}

export function FooterIcons() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { logout, isLoading } = useLogout();
  const { user } = useAuth();

  const mobileSidebarItems = [
    { name: 'Home', icon: GoHome, active: false, goTo: DASHBOARD },
    { name: 'Message', icon: FiMessageCircle, active: false, goTo: MESSAGEMU },
    { name: 'Network', icon: FiUsers, active: false, goTo: NETWORK },
    { name: 'Forums', icon: LuMessagesSquare, active: false, goTo: FORUM },
    { name: 'Finder', icon: FiMap, active: false, goTo: FINDR },
    { name: 'PayMu', icon: MdOutlineCreditScore, active: false, goTo: PAYMU },
  ];

  const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    localStorage.setItem('colorMode', colorMode);
    return; // Save the color mode to local storage
  }, [colorMode]);

  return (
    <Flex
      justify={isMobile ? 'center' : 'flex-end'}
      py={isMobile ? 2 : 4}
      pt={isMobile ? 0 : 4}
      bg={useColorModeValue('white', 'gray.900')}
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      zIndex="10"
      display={isMobile ? 'flex' : 'none'}
      flexDirection="column"
    >
      <Box borderTopWidth="1px" borderTopColor="gray.200" />
      <Flex justify="space-evenly" py={isMobile ? 0 : 1}>
        {mobileSidebarItems.map((items) =>
          items.name === 'Message' ? (
            <Link as={goToLink} to={MESSAGEMU}>
              <IconButton
                key={items.name}
                size="sm"
                variant="ghost"
                aria-label={items.name}
                icon={<items.icon size={'24'} />}
                color={
                  location.pathname === items.goTo ? 'blue.500' : 'gray.400'
                }
                _hover={{ color: 'blue.500' }}
                padding-top="40px"
              />
            </Link>
          ) : (
            <IconButton
              key={items.name}
              size="sm"
              variant="ghost"
              aria-label={items.name}
              as={goToLink}
              to={items.goTo}
              icon={<items.icon size={'24'} />}
              color={location.pathname === items.goTo ? 'blue.500' : 'gray.400'}
              _hover={{ color: 'blue.500' }}
              padding-top="40px"
            />
          )
        )}
      </Flex>
    </Flex>
  );
}
