import { SearchIcon } from "@chakra-ui/icons";
import {
  AvatarBadge,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorMode,
  Avatar,
  Badge,
  useBreakpointValue,
  List,
  ListItem,
  useToast,
  Flex,
  useColorModeValue,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  ModalCloseButton,
  RadioGroup,
  VStack,
  Radio,
} from "@chakra-ui/react";
import React, { Component, useEffect, useRef, useState } from "react";
import { FiArchive, FiFilter, FiMoreVertical, FiFile } from "react-icons/fi";
import { TbCircleDashed } from "react-icons/tb";
import { IoFilterCircleOutline, IoFilterCircle } from "react-icons/io5";
import { ChatList } from "./buttons";
import { dispatch } from "./redux/store";
import { ToggleSidebar } from "./redux/slices/app";
import { useDispatch } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  writeBatch,
  where,
} from "firebase/firestore";
import { db } from "lib/firebase";
import { useAuth } from "hooks/auth";
import { useFollowUser, useUser } from "hooks/users";
import { submitReport } from "components/post/Header";

const createChatAndSendEmptyMessage = async (senderRef, receiverRef) => {
  try {
    const timestamp = Date.now().toString();

    // Create references to sender's and receiver's chats
    const senderChatsRef = collection(senderRef, "chats");
    const receiverChatsRef = collection(receiverRef, "chats");

    // Create references to sender's and receiver's chat documents
    const senderChatRef = doc(senderChatsRef, receiverRef.id);
    const receiverChatRef = doc(receiverChatsRef, senderRef.id);

    // Check if the chat documents exist
    const senderChatDoc = await getDoc(senderChatRef);
    const receiverChatDoc = await getDoc(receiverChatRef);

    // Create chat documents if they don't exist
    if (!senderChatDoc.exists()) {
      await setDoc(senderChatRef, {});
    }

    if (!receiverChatDoc.exists()) {
      await setDoc(receiverChatRef, {});
    }

    // Create references to sender's and receiver's timestamp collections
    const senderTimestampRef = collection(senderChatRef, "timestamp");
    const receiverTimestampRef = collection(receiverChatRef, "timestamp");

    // Create references to sender's and receiver's timestamp documents
    const senderTimestampDocRef = doc(senderTimestampRef, timestamp);
    const receiverTimestampDocRef = doc(receiverTimestampRef, timestamp);

    // Send an empty message from the sender to the receiver
    const welcomeMessageData = {
      message: "Hello!",
      type: "msg",
      incoming: false,
      outgoing: true,
      unread: false,
      date: serverTimestamp(),
    };

    await setDoc(senderTimestampDocRef, welcomeMessageData);
    await setDoc(receiverTimestampDocRef, welcomeMessageData);
  } catch (error) {
    console.error("Error creating chat and sending empty message: ", error);
    throw error;
  }
};

export function FollowButton({
  userId,
  authUserId,
  isMobile,
  updateFollowersCount,
}) {
  const { isFollowing, isLoading, followUser, unfollowUser } = useFollowUser(
    userId,
    authUserId
  );
  const { isFollowing: isFollowedBack } = useFollowUser(authUserId, userId); // Check if the target user follows the authenticated user
  const toast = useToast();

  const handleFollowUser = async () => {
    try {
      await followUser();
      // Show a toast notification when follow is successful
      toast({
        title: `You followed ${userId}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error following user:", error);
      toast({
        title: "Error following user",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUnfollowUser = async () => {
    await unfollowUser();
  };

  return (
    <>
      {isMobile ? (
        <Button
          pos="flex"
          mb="-10"
          ml="auto"
          colorScheme={isFollowing ? "gray" : "blue"}
          onClick={isFollowing ? handleUnfollowUser : handleFollowUser}
          isLoading={isLoading}
          rounded="full"
          size="sm"
          display="flex"
        >
          {isFollowing ? "Unfollow" : isFollowedBack ? "Follow Back" : "Follow"}
        </Button>
      ) : (
        <Button
          colorScheme={isFollowing ? "gray" : "blue"}
          isLoading={isLoading}
          size={"xs"}
          onClick={isFollowing ? handleUnfollowUser : handleFollowUser}
        >
          {isFollowing ? "Unfollow" : isFollowedBack ? "Follow Back" : "Follow"}
        </Button>
      )}
    </>
  );
}

const markMessagesAsRead = async (userId, chatId) => {
  const userRef = doc(db, "users", userId);
  const chatRef = doc(collection(userRef, "chats"), chatId);
  const messagesRef = collection(chatRef, "timestamp");

  const unreadQuery = query(messagesRef, where("unread", "==", true));
  const unreadSnapshot = await getDocs(unreadQuery);

  const batch = writeBatch(db);
  unreadSnapshot.forEach((doc) => {
    batch.update(doc.ref, { unread: false });
  });

  await batch.commit();
};

const Search = ({ chatData }) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { user } = useAuth();

  const handleUserClick = async (userPressedId) => {
    console.log(userPressedId);
    try {
      const senderRef = doc(
        db,
        user.businessName ? "businesses" : "users",
        user.id
      );

      const userPressedRef = doc(
        db,
        userPressedId?.businessName ? "businesses" : "users",
        userPressedId?.id
      );

      await createChatAndSendEmptyMessage(senderRef, userPressedRef);

      // Redirect to the chat or do any necessary actions after creating the chat.
    } catch (error) {
      console.error("Error handling user click: ", error);
    }
  };

  useEffect(() => {
    const fetchAndFilterUsers = async () => {
      try {
        if (!searchValue) {
          setFilteredUsers([]);
          return;
        }

        const usersCollectionRef = collection(db, "users");
        const businessesCollectionRef = collection(db, "businesses");

        // Fetch data from both collections using getDocs
        const [usersSnapshot, businessesSnapshot] = await Promise.all([
          getDocs(usersCollectionRef),
          getDocs(businessesCollectionRef),
        ]);

        const usersData = usersSnapshot.docs.map((doc) => doc.data());
        const businessesData = businessesSnapshot.docs.map((doc) => doc.data());

        // Combine both sets of users
        const allUsers = [...usersData, ...businessesData];

        // Filter users based on the search value
        const matchingUsers = allUsers.filter((user) =>
          user.username.includes(searchValue)
        );

        // Fetch current user's following list
        const currentUserRef = doc(
          db,
          user?.fullName ? "users" : "businesses",
          user?.id
        );

        const currentUserDoc = await getDoc(currentUserRef);
        const { following } = currentUserDoc.data() || {}; // Ensure following exists

        // Exclude the current user from the list of matching users
        const filteredUsers = matchingUsers
          .filter((matchingUser) => matchingUser.id !== user?.id) // Exclude current user
          .map((matchingUser) => ({
            ...matchingUser,
            isFollowing: following && following.includes(matchingUser.id),
          }))
          .filter((matchingUser) => {
            // Check if the user is not already in chatData
            return !chatData.some((chat) => chat.id === matchingUser.id);
          });

        setFilteredUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching and filtering users:", error);
      }
    };

    fetchAndFilterUsers();
  }, [searchValue, user, chatData]);

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div>
      <InputGroup borderRadius="xl" backgroundColor="transparent">
        <Input
          type="text"
          placeholder="Search for New Chat"
          color="black"
          fontSize={12}
          ml={-1}
          mb={2}
          height={"6"}
          value={searchValue}
          onChange={handleSearchInputChange}
        />
        <InputRightElement pointerEvents="none">
          <SearchIcon color="gray.300" mb={4} />
        </InputRightElement>
      </InputGroup>

      {filteredUsers.map((userr) => (
        <Stack
          direction={"row"}
          mt={2}
          mb={1}
          key={userr.id}
          onClick={() => {
            userr.isFollowing && handleUserClick(userr);
          }}
        >
          <Box
            p={1.5}
            backgroundColor={"gray.100"}
            borderRadius={"md"}
            width={"100%"}
          >
            {!userr.isFollowing ? (
              <Stack alignContent={"center"} direction={"row"}>
                <Text fontSize={"xs"}>{userr.username}</Text>
                <Flex flex={1} />
                <FollowButton
                  userId={userr.id}
                  authUserId={user.id} // Replace with the actual authenticated user's ID
                  isMobile={false} // Replace with your condition for mobile
                />
              </Stack>
            ) : (
              <Text>{userr.username}</Text>
            )}
          </Box>
        </Stack>
      ))}
    </div>
  );
};

const ReportChatModal = ({
  isReportModalOpen,
  setReportModalOpen,
  handleSubmitReport,
}) => {
  const [reportReason, setReportReason] = useState(""); // Ensure reportReason state is initialized
  const [comments, setComments] = useState(""); // Handle additional comments
  const [fileUpload, setFileUpload] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track if the form is submitting
  const toast = useToast();

  // Handle file selection
  const handleFileChange = (event) => {
    setFileUpload(Array.from(event.target.files)); // Save selected files
  };

  const onSubmit = async () => {
    setIsSubmitting(true); // Set loading state to true before submission
    await handleSubmitReport(
      reportReason,
      comments,
      fileUpload,
      setIsSubmitting
    ); // Pass the setIsSubmitting function
  };

  return (
    <Modal isOpen={isReportModalOpen} onClose={() => setReportModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report Chat</ModalHeader>
        <ModalBody>
          <RadioGroup onChange={setReportReason} value={reportReason}>
            <VStack align="start">
              <Radio value="Spam">Spam</Radio>
              <Radio value="Harassment">Harassment</Radio>
              <Radio value="Inappropriate Content">Inappropriate Content</Radio>
              <Radio value="Other">Other</Radio>
            </VStack>
          </RadioGroup>

          {reportReason === "Other" && (
            <>
              <Textarea
                placeholder="Describe the issue with this chat..."
                value={comments}
                onChange={(e) => {
                  if (e.target.value.length <= 500) {
                    setComments(e.target.value);
                  }
                }}
                mt={3}
                maxLength={500}
              />
              <Text
                fontSize="sm"
                color={comments.length === 500 ? "red.500" : "gray.500"}
              >
                {comments.length}/{500 - comments.length}
              </Text>
            </>
          )}

          <Button
            variant="ghost"
            size="sm"
            leftIcon={<FiFile />}
            onClick={() => document.getElementById("fileInput").click()}
            bg="#6899fe"
            color="white"
            _hover={{ background: "#9bbafa" }}
            mt={3}
          >
            Attach Files
          </Button>

          <Input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            multiple
            display="none"
          />
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onSubmit} // Use the updated onSubmit function
            bg="#6899fe"
            _hover={{ background: "#9bbafa" }}
            rounded="md"
            size="xs"
            textColor="white"
            fontWeight="bold"
            isLoading={isSubmitting} // Show loading spinner while submitting
            loadingText="Submitting"
          >
            Submit Report
          </Button>
          <Button
            border={"2px #6899fe solid"}
            variant="ghost"
            onClick={() => setReportModalOpen(false)}
            bg="white"
            _hover={{ background: "lightgrey" }}
            rounded="md"
            size="xs"
            textColor="#6899fe"
            fontWeight="bold"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ChatElement = ({
  chatId,
  name,
  img,
  message: msg,
  date,
  unread,
  online,
  userPressed,
  handleChatClick, // Now passed directly from parent
}) => {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { user: userr, isLoading } = useUser(chatId);
  const { user } = useAuth();
  const toast = useToast();
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [comments, setComments] = useState("");

  const handleFileChange = (event) => {
    setFileUpload(Array.from(event.target.files));
  };

  const handleSubmitReport = async (
    reason,
    additionalComments,
    evidenceFiles,
    setIsSubmitting
  ) => {
    try {
      console.log({
        id: chatId,
        reporterId: user.id,
        reportedId: userPressed,
        reason: reason,
        additionalComments: additionalComments,
        evidenceFiles: evidenceFiles,
        reportType: "chat",
      });

      const result = await submitReport({
        id: chatId,
        reporterId: user.id,
        reportedId: userPressed,
        reason: reason,
        additionalComments: additionalComments,
        evidenceFiles: evidenceFiles,
        reportType: "chat",
      });

      console.log("Report submission result:", result);

      toast({
        title: "Report submitted successfully..",
        description: result.message,
        status: "success",
        isClosable: true,
        duration: 5000,
      });

      if (result.success) {
        setReportModalOpen(false);
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      toast({
        title: "Failed to submit report.",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false); // Set loading state back to false
    }
  };

  const timestampMilliseconds = date
    ? date.seconds * 1000 + date.nanoseconds / 1e6
    : Date();

  const dateObject = new Date(timestampMilliseconds);
  const currentDate = new Date();
  const timeDifference = dateObject.getDate() - currentDate.getDate();
  let displayText;
  if (timeDifference === 0) {
    displayText = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    displayText = dateObject.toLocaleDateString([], {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  }

  return (
    <Box
      width={"100%"}
      borderRadius={isMobile ? "0" : "lg"}
      boxShadow={isMobile ? "none" : "xl"}
      rounded={isMobile ? "none" : "lg"}
      borderWidth="0.2px"
      borderColor="rgba(72, 50, 133, 0.1)"
      backgroundColor={
        colorMode === "light"
          ? userPressed === chatId
            ? "#6899FE"
            : "white"
          : "white"
      }
      p={2}
      _hover={{ bg: "#6899FE", cursor: "pointer" }}
      position="relative"
      onClick={() => handleChatClick(chatId)} // Trigger chat opening here
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Stack direction={"row"} spacing={2} align={"center"}>
            <Avatar size={"sm"} src={userr?.avatar != "" ? userr?.avatar : ""}>
              {online && <AvatarBadge boxSize="1.75em" bg="green.500" />}
            </Avatar>
            <Stack spacing={0.3} textAlign={"left"}>
              <Text
                variant={"subtitle2"}
                as="b"
                fontWeight={"600"}
                fontSize={isMobile ? "sm" : "xs"}
              >
                {userr?.username}
              </Text>
              <Text
                variant={"caption"}
                as="p"
                fontWeight={"400"}
                fontSize={isMobile ? "sm" : "12px"}
                opacity={0.8}
              >
                {msg.length > 20 ? `${msg.substring(0, 20)}...` : msg}
              </Text>
            </Stack>
          </Stack>
        </Box>
        <Stack spacing={1} alignItems={"center"}>
          {unread ? (
            <Badge
              backgroundColor={"#447CF0"}
              color="#447CF0"
              px={0.5}
              py={0.1}
              fontSize="0.4em"
              rounded={"xl"}
            >
              1
            </Badge>
          ) : (
            <Box p={2}></Box>
          )}
          <Flex justifyContent="flex-end" mt={2}>
            <Text fontSize="2xs" color="gray.500">
              {displayText}
            </Text>
          </Flex>
        </Stack>
      </Stack>

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FiMoreVertical />}
          variant="ghost"
          size="xs"
          position="absolute"
          right="10px"
          top="10px"
          _hover={{ bg: "transparent" }}
          _focus={{ boxShadow: "none" }}
          onClick={(e) => e.stopPropagation()} // Prevent click event from bubbling up
        />
        <MenuList>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setReportModalOpen(true);
            }}
          >
            Report
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Report modal */}
      <ReportChatModal
        isReportModalOpen={isReportModalOpen}
        setReportModalOpen={setReportModalOpen}
        handleSubmitReport={handleSubmitReport}
      />
    </Box>
  );
};

function Chats({ setUserPressed, userPressed }) {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [chatData, setChatData] = useState([]);
  const { user, isLoading } = useAuth();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false); // State for filter

  useEffect(() => {
    if (!isLoading && user) {
      const userRef = doc(
        db,
        user?.fullName ? "users" : "businesses",
        user?.id
      );
      const chatsCollectionRef = collection(userRef, "chats");

      const unsubscribe = onSnapshot(
        chatsCollectionRef,
        async (querySnapshot) => {
          const updatedChatDataList = [...chatData];

          for (const chatDoc of querySnapshot.docs) {
            const chatId = chatDoc.id;
            const timestampCollectionRef = collection(chatDoc.ref, "timestamp");

            const timestampUnsubscribe = onSnapshot(
              timestampCollectionRef,
              (timestampQuerySnapshot) => {
                if (!timestampQuerySnapshot.empty) {
                  const lastTimestampDoc =
                    timestampQuerySnapshot.docs[
                      timestampQuerySnapshot.size - 1
                    ];
                  const timestamp = lastTimestampDoc.data();

                  if (timestamp) {
                    const existingChatIndex = updatedChatDataList.findIndex(
                      (chatData) => chatData.id === chatId
                    );

                    if (existingChatIndex !== -1) {
                      updatedChatDataList[existingChatIndex] = {
                        id: chatId,
                        data: {
                          ...timestamp,
                          chatId,
                        },
                      };
                    } else {
                      updatedChatDataList.push({
                        id: chatId,
                        data: {
                          ...timestamp,
                          chatId,
                        },
                      });
                    }

                    setChatData(updatedChatDataList);
                  }
                }
              }
            );
          }
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [user, isLoading, chatData]);

  chatData.sort((a, b) => {
    const dateA = new Date(a.data.date ? a.data.date.seconds * 1000 : 0);
    const dateB = new Date(b.data.date ? b.data.date.seconds * 1000 : 0);
    return dateB - dateA;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChatClick = async (chatId) => {
    setUserPressed(chatId);
    await markMessagesAsRead(user.id, chatId);
  };

  // Filter chat data based on the filter state
  const filteredChatData = showUnreadOnly
    ? chatData.filter((chat) => chat.data.unread)
    : chatData;

  return (
    <Box
      position={"relative"}
      height={isMobile ? "87vh" : "85vh"}
      width={isMobile ? "100%" : "100%"}
      backgroundColor={colorMode === "light" ? "#fff" : "blackAlpha.300"}
      boxShadow={"sm"}
      zIndex={2}
      borderRight="1px #E2E8F0 solid"
    >
      <Stack spacing={2} p={3} height={"100%"}>
        <Stack width={"100%"}>
          <Search chatData={chatData} />
        </Stack>
        <Stack
          direction={"column"}
          flexGrow={1}
          overflow={isMobile ? "" : "auto"}
          height={"100%"}
          spacing={2}
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#6899fe",
              borderRadius: "24px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#6899fe",
            },
          }}
        >
          {filteredChatData.map((el) => (
            <ChatElement
              key={el.id}
              {...el.data}
              userPressed={userPressed}
              handleChatClick={handleChatClick} // Pass chat click handler directly here
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

export default Chats;
