import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  VStack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Center,
  Spinner,
  useBreakpointValue,
} from '@chakra-ui/react';
import PostsList from "components/post/PostsList"; // Ensure this path is correct
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from 'lib/firebase';
import UserCard from '../network/UserCard';

const Search = () => {
  const [searchParams] = useSearchParams();
  const queryText = searchParams.get('query');
  const [userResults, setUserResults] = useState([]);
  const [postResults, setPostResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchResults = async () => {
      if (queryText) {
        setLoading(true);
        const search = queryText.toLowerCase();
        const searchWords = search.split(' ');

        try {
          // Fetch users based on the search query
          const userQuery = query(
            collection(db, 'users'),
            where('username', '>=', search),
            where('username', '<=', search + '\uf8ff')
          );
          const userSnapshot = await getDocs(userQuery);
          const users = userSnapshot.docs.map((doc) => doc.data());
          setUserResults(users);

          // Fetch posts based on the search query in the text content
          const postSnapshot = await getDocs(collection(db, 'posts'));
          const posts = postSnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter(post => searchWords.every(word => post.text.toLowerCase().includes(word)));
          setPostResults(posts);

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchResults();
  }, [queryText]);

  return (
    <Center>
      <VStack
        width={isMobile ? "100%" : "630px"}
        minHeight="100vh"
        p={0}
        m={0}
        spacing={4}
        borderRightWidth={isMobile ? "0" : "1px"}
        borderLeftWidth={isMobile ? "0" : "1px"}
        borderColor="gray.300"
        rounded={isMobile ? "0" : "md"}
      >
        <Text
          mt={isMobile ? 10 : 16}
          fontWeight={'bold'}
          fontSize={'22px'}
          px={'10px'}
          color={'#6899FE'}
          backgroundColor={'#D9D9D9AB'}
          rounded={isMobile ? "lg" : "none"}
        >
          Search
        </Text>
        <Text fontSize="xl" textAlign="center" mb={6}>
          Search Results for "{queryText}"
        </Text>

        {loading ? (
          <Spinner size="xl" color="blue.500" />
        ) : (
          <Tabs size="md" width="100%">
            <TabList borderBottom={'1px solid #6899FE'} gap={'1px'}>
              <Tab
                border={'1px solid #6899FE'}
                borderBottom={'none'}
                borderTopRadius={'8px'}
                fontSize={'16px'}
                fontWeight={'400'}
                padding={'0px 20px'}
                color={'#6899FE'}
                _selected={{
                  color: 'white',
                  bg: '#6899FE',
                }}
                _hover={{ color: 'white', bg: '#acc6fa' }}>
                People
              </Tab>
              <Tab
                border={'1px solid #6899FE'}
                borderTopRadius={'8px'}
                borderBottom={'none'}
                fontSize={'16px'}
                fontWeight={'400'}
                padding={'0px 20px'}
                color={'#6899FE'}
                _selected={{
                  color: 'white',
                  bg: '#6899FE',
                }}
                _hover={{ color: 'white', bg: '#acc6fa' }}>
                Posts
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4} width="100%">
                  {userResults.length > 0 ? (
                    userResults.map((user) => (
                      <UserCard key={user.username} user={user} isNetwork={true} followAndRequest={true}/>
                    ))
                  ) : (
                    <Text>No users found.</Text>
                  )}
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack width="100%" spacing={4}>
                  {postResults.length > 0 ? (
                    <PostsList posts={postResults} />
                  ) : (
                    <Text>No posts found.</Text>
                  )}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </VStack>
    </Center>
  );
};

export default Search;
