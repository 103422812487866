import React, { useEffect, useState } from 'react';
import { Box, Text, Button, Avatar, Spinner, Flex, Link, useBreakpointValue, HStack, Center } from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import { PROTECTED } from 'lib/routes';
import { doc, deleteDoc, getDoc, collection, serverTimestamp, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { formatDistanceToNow } from 'date-fns';


const RequestDetails = ({ deal, onBack }) => {
  const { user } = useAuth();
  const [dealSender, setDealSender] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDeny = async (deal) => {
    const dealIdToDelete = deal?.id;
    try {
      const requestsRef = collection(
        db,
        user?.businessName ? 'businesses' : 'users',
        user?.id,
        'requests'
      );
      const dealDocRef = doc(requestsRef, dealIdToDelete);
      const dealDocSnapshot = await getDoc(dealDocRef);

      if (dealDocSnapshot.exists()) {
        await deleteDoc(dealDocRef);
        onBack(); // Call the onBack function to go back to the previous screen
      } else {
        console.log(`No matching document found for deal ${dealIdToDelete}`);
      }
    } catch (error) {
      console.error(`Error deleting deal ${deal?.id}:`, error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (deal && deal.from) {
        const userRef = doc(db, 'users', deal.from);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setDealSender(userSnap.data());
        } else {
          console.error(`No user found with ID: ${deal.from}`);
        }
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [deal]);

  if (!deal) {
    return (
      <Box
        p={4}
        height="60%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="70%"
      >
        <Text fontSize="30px" color="#838080">No request selected</Text>
      </Box>
    );
  }

  const formattedTime = deal.timestamp
    ? formatDistanceToNow(deal.timestamp.toDate(), { addSuffix: true })
    : 'Timestamp not available';

  return (
    <Box
      overflow="hidden"
      height="100%"
      width="100%"
      mx="auto"
      display="flex"
      flexDirection="column"
    >
      {loading ? (
        <Center height={"30%"}><Spinner /></Center>
      ) : dealSender ? (
        <>
          <Box
            borderBottom="1px solid #E2E8F0"
            mb={3}
            p={1}
          >
            <Flex alignItems="center" direction="row" justifyContent="space-between">
              <Flex alignItems="center" spacing={4}>
                <Avatar size="md" src={dealSender?.avatar || ''} />
                <Text fontSize="lg" fontWeight="bold" ml={2} as={Link} href={`${PROTECTED}/profile/${dealSender.username}`}>
                  @{dealSender.username}
                </Text>
              </Flex>
            </Flex>
          </Box>

          <HStack mb={"15%"}>
            <Text p={isMobile ? 1 : 0} fontSize={'7px'} color={'gray.500'}>
              {formattedTime}
            </Text>
            <Box
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              bg="#D9D9D9"
              width={{ base: "90%", md: "50%" }}
              minWidth="300px"
              maxWidth="100%"
              height="auto"
              maxHeight={isMobile ? "50vh" : "300px"}
              overflowY="auto"
              textAlign="left"
            >
              <Text fontSize={"10px"}>{deal.message || "No message provided"}</Text>
            </Box>
          </HStack>
          <Flex width="100%" justifyContent="space-around">
            <Text width="450px" mt={4} mb={4} textAlign="center">
              User <Text fontWeight="bold" as={Link} href={`${PROTECTED}/profile/${dealSender.username}`} display="inline">@{dealSender.username}</Text> has sent you a request to direct message you. They are not part of your followers or friends. Accept or decline if you would like to reply to them and continue the conversation.
            </Text>
          </Flex>
          <Flex justify="center" gap={4}>
            <Button
              colorScheme="green"
              onClick={() => {
                window.location.href = `${PROTECTED}/messages/${deal.from}`;
              }}
              width="70px"
              p={1}
            >
              Accept
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleDeny(deal)}
              width="70px"
              p={1}
            >
              Decline
            </Button>
          </Flex>
        </>
      ) : (
        <Text fontSize="30px" color="#838080">User not found</Text>
      )}
    </Box>
  );
};

export default RequestDetails;
