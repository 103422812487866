import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Image,
  Flex,
  Center,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Collapse,
  List,
  ListItem,
  Icon,
  useColorMode,
  SimpleGrid,
  Button,
  Input,
  Tooltip,
  Textarea,
  useBreakpointValue,
  Avatar,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AudioPlayer from "components/audioPlayer/audioPlayer";
import {
  FiChevronDown,
  FiChevronUp,
  FiFile,
  FiRepeat,
  FiMusic,
  FiVideo,
  FiPlay,
} from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import Actions from "./Actions";
import Header from "./Header";
import picBack from "./assets/pic-back.svg";
import picFwd from "./assets/pic-forward.svg";
import { useEditPost } from "hooks/posts";
import { PROTECTED } from "lib/routes";
import {
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { MentionsInput, Mention } from 'react-mentions';
import { getIDfromUsername } from 'hooks/users';
import { useNotifications } from 'hooks/notifications';
import { useAuth } from "hooks/auth"; 

function renderTextWithMentions(text) {
  // Regex to detect mentions in the format @[username]
  const mentionRegex = /@\[(\w+)\]/g;

  // Split the text and replace mentions with anchor tags
  const parts = text.split(mentionRegex).map((part, index) => {
    // If it's an odd index, it means it's a username
    if (index % 2 === 1) {
      const username = part;
      return (
        <RouterLink
          key={index}
          to={`${PROTECTED}/profile/${username}`}
          style={{ color: "#6899fe", fontWeight: "bold" }}
        >
          @{username}
        </RouterLink>
      );
    }
    // Otherwise, it's just regular text
    return part;
  });

  return <>{parts}</>;
}


const isImage = (file) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const extension = file.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};

const isAudio = (file) => {
  const audioExtensions = ["mp3", "wav", "ogg", "m4a"];
  const extension = file.split(".").pop().toLowerCase();
  return audioExtensions.includes(extension);
};

const isVideo = (file) => {
  const videoExtensions = ["mp4", "webm", "ogg"];
  const extension = file.split(".").pop().toLowerCase();
  return videoExtensions.includes(extension);
};

const extractFileName = (url) => {
  if (!url) return null;
  const regex = /\/([^/]+)\?/;
  const matches = url.match(regex);
  if (matches && matches.length > 1) {
    const filePath = matches[1];
    const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
    return decodeURIComponent(fileName.split("%2F").pop());
  }
  return null;
};

const parseArtistAndSong = (fileName) => {
  if (!fileName) return fileName;
  const withoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const parts = withoutExtension.split(" - ");
  if (parts.length === 2) {
    return parts.join(" - ");
  }
  return fileName;
};

export function RepostHeader({ originalPost, reposts }) {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === "light" ? "gray.100" : "gray.700";
  const textColor = colorMode === "light" ? "black" : "gray.400";
  const linkColor = colorMode === "light" ? "blue.500" : "blue.300";

  return (
    <Flex
      width={"fit-content"}
      rounded={15}
      px={2}
      py={0.5}
      mt={1}
      ml={1}
      bg={"#EDF7FE"}
      border={"0.5px solid #C4C9CF"}
      alignItems={"center"}
    >
      <Icon as={FiRepeat} boxSize={3} color={textColor} mr={1} />
      <Text fontSize="xs" color={textColor}>
        from{" "}
        {reposts.length > 0 ? (
          <>
            {reposts.map((repost, index) => (
              <RouterLink
                key={index}
                to={`/protected/profile/${repost.username}`}
                color={linkColor}
                fontWeight="bold"
              >
                @{repost.username}
              </RouterLink>
            ))}
          </>
        ) : (
          <RouterLink
            to={`/protected/profile/${originalPost.username}`}
            color={linkColor}
            fontWeight="bold"
          >
            @{originalPost.username}
          </RouterLink>
        )}
      </Text>
    </Flex>
  );
}

export default function Post({ post, reposts }) {
  const { text, files, originalPost, edited } = post;
  const [expanded, setExpanded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAudioOpen,
    onOpen: onAudioOpen,
    onClose: onAudioClose,
  } = useDisclosure();
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const mediaRef = useRef(null);
  const [mediaSize, setMediaSize] = useState({ width: 0, height: 0 });
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const seeMoreCharacterLimit = 150;
  const postCharacterLimit = 2000;
  const [users, setUsers] = useState([]);
  const { user: authUser } = useAuth(); 
  const { sendNotification } = useNotifications();
  const { user } = useAuth();
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const lineHeight = 1.2;
  const maxLines = 3; // Limit to 3 lines

  const handleMediaLoad = () => {
    setMediaLoaded(true);
  };

  const fetchUsers = async (search) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('username', '>=', search),
        where('username', '<=', search + '\uf8ff')
      );
      const querySnapshot = await getDocs(q);
      const fetchedUsers = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          display: doc.data().username,
          avatar: doc.data().avatar,
        }))
        .filter((user) => user.id !== authUser.id); // Exclude the authenticated user
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    // Check if text exceeds character limit
    if (text.length > seeMoreCharacterLimit) {
      setIsTruncated(true);
    }

    // Check if text exceeds line limit (only if the element exists in the DOM)
    if (textRef.current) {
      const lineHeightPx = parseFloat(window.getComputedStyle(textRef.current).lineHeight);
      const maxHeight = lineHeightPx * maxLines; // Height for 3 lines
      if (textRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true); // Also set truncated if line height exceeds
      }
    }
  }, [text]);

  const isRepost = originalPost && originalPost.id;

  const imageFiles = files.filter(
    (file) => file && isImage(extractFileName(file))
  );
  const audioFiles = files.filter(
    (file) => file && isAudio(extractFileName(file))
  );
  const videoFiles = files.filter(
    (file) => file && isVideo(extractFileName(file))
  );
  const otherFiles = files.filter(
    (file) =>
      file &&
      !isImage(extractFileName(file)) &&
      !isAudio(extractFileName(file)) &&
      !isVideo(extractFileName(file))
  );

  const allMediaFiles = [...imageFiles, ...videoFiles];

  const handleMediaClick = (index) => {
    setCurrentMediaIndex(index);
    onOpen();
  };

  const handleAudioClick = (url) => {
    setCurrentMediaIndex(audioFiles.indexOf(url));
    onAudioOpen();
  };

  const handleNextMedia = () => {
    setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % allMediaFiles.length);
  };

  const handlePreviousMedia = () => {
    setCurrentMediaIndex(
      (prevIndex) =>
        (prevIndex - 1 + allMediaFiles.length) % allMediaFiles.length
    );
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (isOpen && allMediaFiles.length > 1) {
        if (event.key === "ArrowRight") {
          handleNextMedia();
        } else if (event.key === "ArrowLeft") {
          handlePreviousMedia();
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isOpen]);

  useEffect(() => {
    const updateMediaSize = () => {
      if (mediaRef.current) {
        const { offsetWidth, offsetHeight } = mediaRef.current;
        setMediaSize({ width: offsetWidth, height: offsetHeight });
      }
    };

    updateMediaSize();
    window.addEventListener("resize", updateMediaSize);

    return () => window.removeEventListener("resize", updateMediaSize);
  }, [currentMediaIndex]);

  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(
    text.slice(0, postCharacterLimit)
  );
  const { editPost, isLoading: editLoading } = useEditPost(post.id);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(text.slice(0, postCharacterLimit));
  };

  const handleSaveEdit = async () => {
    try {
      const mentionRegex = /@\[(\w+)\]/g;
      const mentionedUsers = [...editedText.matchAll(mentionRegex)].map(match => match[1]);
      
      // Save the edited post
      await editPost(editedText);
      
      // Send notifications to mentioned users
      await Promise.all(
        mentionedUsers.map(async (username) => {
          const userId = await getIDfromUsername(username); // Helper function to get the user ID from the username
          if (userId) {
            await sendNotification({
              title: "You've been tagged!",
              content: `tagged you in an edited `,
              uid: userId,
              from: user.id,
              type: 'postTagging',
              time: Date.now(),
              postId: post.id,
            });
          }
        })
      );

      setIsEditing(false); // Exit editing mode
    } catch (error) {
      console.error("Error saving edit:", error);
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue.length <= postCharacterLimit) {
      setEditedText(newValue);
    }
  };

  const displayedText = isExpanded || text.length <= seeMoreCharacterLimit
  ? text
  : text.slice(0, seeMoreCharacterLimit) + "...";

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };


  const maxAttachments = 5;
  const displayedMediaFiles = allMediaFiles.slice(0, maxAttachments); // Limit to 5

  // Display message if there are more than 5 attachments
  const showLimitExceededMessage = allMediaFiles.length > maxAttachments;

  const isMobile = useBreakpointValue({ base: true, md: false });
  // const maxMediaHeight = {isMobile ? "125px" : "250px"};
  

  return (
    <Box
      p="0"
      mb={8}
      maxW={{ base: "auto", md: "600px" }}
      minW={{ base: "auto", md: "600px" }}
      textAlign="left"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      borderColor="rgba(128, 128, 128, 0.1)"
      boxShadow="sm"
    >
      <Box>
        <Header
          post={post}
          onEdit={handleEdit}
          onSave={handleSaveEdit}
          onCancel={handleCancelEdit}
          isEditing={isEditing}
          editedText={editedText}
          setEditedText={setEditedText}
        />
        {isRepost && (
          <>
            {reposts && reposts.length > 0 ? (
              reposts.map((repost, index) => (
                <RepostHeader
                  key={index}
                  originalPost={repost.originalPost}
                  reposts={repost.reposts}
                />
              ))
            ) : (
              <RepostHeader originalPost={originalPost} reposts={[]} />
            )}
          </>
        )}

        <Box p={2} pb={"2"} mt={{ base: 0, md: 1 }}>
          {isEditing ? (
            <>
              <MentionsInput
                value={editedText}
                onChange={handleChange}
                placeholder="Edit your post..."
                style={{
                  control: {
                    backgroundColor: '#fff',
                    fontSize: '14px',
                    padding: '12px',
                    borderRadius: '6px',
                    border: '1px solid #ddd',
                    width: '100%',
                    overflowY: 'auto',
                    resize: "vertical",
                  },
                  highlighter: {
                    overflow: 'hidden',
                  },
                  input: {
                    height: '100%',
                    padding: '8px',
                    boxSizing: 'border-box',
                  },
                  suggestions: {
                    list: {
                      maxHeight: '150px',
                      overflowY: 'auto',
                      backgroundColor: '#f1f1f1',
                      border: '1px solid #ddd',
                      borderRadius: '6px',
                      padding: '5px',
                      zIndex: 999,
                    },
                    item: {
                      padding: '10px 12px',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    },
                  },
                }}
              >
              <Mention
                trigger="@"
                data={async (search, callback) => {
                  await fetchUsers(search);  // Fetch users based on search
                  callback(users);           // Provide suggestions to callback
                }}
                displayTransform={(id, display) => `@${display}`}  // Display as @username
                markup="@[__display__]"  // How to store the mentions
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <Box display="flex" alignItems="center">
                    <Avatar size="sm" src={suggestion.avatar} />
                    <Text ml={2}>{highlightedDisplay}</Text>
                  </Box>
                )}
              />
              </MentionsInput>
            <VStack align="start" spacing={2}>
              <Text
                fontSize="sm"
                color={
                  editedText.length === postCharacterLimit
                    ? "red.500"
                    : "gray.500"
                }
              >
                {postCharacterLimit - editedText.length} characters remaining
              </Text>
              <HStack>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={handleSaveEdit}
                  isLoading={editLoading}
                  fontSize="xs"
                  px={2}
                  py={1}
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  colorScheme="gray"
                  onClick={handleCancelEdit}
                  fontSize="xs"
                  px={2}
                  py={1}
                >
                  Cancel
                </Button>
              </HStack>
            </VStack>
            </>
          ) : (
          <Text fontSize={{ base: "xs", md: "sm" }} mb={4} whiteSpace="pre-wrap">
            {renderTextWithMentions(displayedText)}
            {isTruncated && (
              <Text
                as="span"
                color="blue.500"
                cursor="pointer"
                onClick={toggleExpansion}
              >
                {isExpanded ? " See less" : " See more"}
              </Text>
            )}
          </Text>   
          )}

          {audioFiles.length > 0 && (
            <VStack align="start" spacing={2} mb={4}>
              {audioFiles.map((file, index) => (
                <Box key={index} width="100%">
                  <Text fontSize="xs" fontWeight="bold" mb={1} color="teal.500">
                    {parseArtistAndSong(extractFileName(file))}
                  </Text>
                  <audio controls>
                    <source src={file} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              ))}
            </VStack>
          )}

          {allMediaFiles.length > 0 && (
            <Center>
              {allMediaFiles.length === 1 ? (
                <Box
                  width="100%"
                  height={isMobile ? "125px" : "250px"}
                  onClick={() => handleMediaClick(0)}
                  cursor="pointer"
                >
                  {isImage(extractFileName(allMediaFiles[0])) ? (
                    <Image
                      src={allMediaFiles[0]}
                      alt={extractFileName(allMediaFiles[0])}
                      objectFit="cover"
                      width="100%"
                      height={"100%"}
                    />
                  ) : (
                    <Box position="relative" width="100%" height="100%">
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        zIndex="1"
                        color="white"
                      >
                        <FiPlay size={48} color={"revert"} />
                      </Box>
                      <video
                        src={allMediaFiles[0]}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <SimpleGrid
      columns={2}
      spacing="10px"
      width="100%"
    >
      {imageFiles.length > 0 && videoFiles.length > 0 ? (
        // When both images and videos are posted, show one image and one video
        <>
          {/* First Image */}
          <Box
            height={isMobile ? "125px" : "250px"}
            width="100%"
            position="relative"
            onClick={() => handleMediaClick(0)} // Opens carousel on click
            cursor="pointer"
          >
            <Image
              src={imageFiles[0]}
              alt={extractFileName(imageFiles[0])}
              objectFit="cover"
              height="100%"
              width="100%"
            />
            {/* If more than 1 image, add small hollow text in bottom right corner */}
            {imageFiles.length > 1 && (
              <Box
                position="absolute"
                bottom="10px"
                right="10px"
                zIndex="2"
                fontSize="2xl"
                fontWeight="bold"
                color="transparent"
                style={{
                  WebkitTextStroke: "1px white", // Smaller stroke for the corner text
                }}
              >
                +{imageFiles.length - 1}
              </Box>
            )}
          </Box>

          {/* First Video */}
          <Box
            height={isMobile ? "125px" : "250px"}
            width="100%"
            position="relative"
            onClick={() => handleMediaClick(imageFiles.length)} // Opens carousel on click
            cursor="pointer"
          >
            <Box position="relative" width="100%" height="100%">
              <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex="1"
                color="white"
              >
                <FiPlay size={48} color={"revert"} />
              </Box>
              <video
                src={videoFiles[0]}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
            {/* If more than 1 video, add small hollow text in bottom right corner */}
            {videoFiles.length > 1 && (
              <Box
                position="absolute"
                bottom="10px"
                right="10px"
                zIndex="2"
                fontSize="2xl"
                fontWeight="bold"
                color="transparent"
                style={{
                  WebkitTextStroke: "1px white", // Smaller stroke for the corner text
                }}
              >
                +{videoFiles.length - 1}
              </Box>
            )}
          </Box>
        </>
      ) : (
        // Default case, showing all media as normal
        allMediaFiles.slice(0, 2).map((media, i) => (
          <Box
            key={i}
            height={isMobile ? "125px" : "250px"}
            width="100%"
            position="relative"
            onClick={() => handleMediaClick(i)} // Opens carousel on click
            cursor="pointer"
          >
            {isImage(extractFileName(media)) ? (
              <Image
                src={media}
                alt={extractFileName(media)}
                objectFit="cover"
                height="100%"
                width="100%"
              />
            ) : (
              <Box position="relative" width="100%" height="100%">
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  zIndex="1"
                  color="white"
                >
                  <FiPlay size={48} color={"revert"} />
                </Box>
                <video
                  src={media}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            )}
            {/* If more than 2 media items, show "+1" or "+2" hollow text in center */}
            {i === 1 && allMediaFiles.length > 2 && (
              <Box
                position="absolute"
                bottom="10px"
                right="10px"
                zIndex="2"
                fontSize="2xl"
                fontWeight="bold"
                color="transparent"
                style={{
                  WebkitTextStroke: "1px white",
                }}
              >
                +{allMediaFiles.length - 2}
              </Box>
            )}
          </Box>
        ))
      )}
    </SimpleGrid>
              )}
            </Center>
          )}

          {otherFiles.length > 0 && (
            <VStack align="start" spacing={2}>
              <Button
                variant="ghost"
                size="sm"
                leftIcon={<FiFile boxSize={4} />}
                onClick={() => {
                  setExpanded(!expanded);
                }}
                rightIcon={expanded ? <FiChevronUp /> : <FiChevronDown />}
              >
                Files
              </Button>
              <Collapse in={expanded} animateOpacity>
                <List>
                  {otherFiles.map((file, index) => (
                    <ListItem key={index}>
                      <RouterLink to={file} target="_blank" download>
                        {extractFileName(file)}
                      </RouterLink>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </VStack>
          )}
        </Box>
        <Actions post={post} reposts={reposts} />
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick
        size="xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
        <ModalContent
          p={0}
          m={0}
          bg="transparent"
          boxShadow="none"
          maxW="800px"
        >
          <ModalBody p={0} m={0} position="relative">
            <HStack justifyContent="center" alignItems="center">
              {allMediaFiles.length > 1 && (
                <Button
                  backgroundColor="#FFFFFF54"
                  padding="20px 7.5px"
                  borderRadius="lg"
                  onClick={handlePreviousMedia}
                >
                  <Image src={picBack} height="15px" width="15px" />
                </Button>
              )}
              <Box ref={mediaRef} position="relative">
                {allMediaFiles[currentMediaIndex] &&
                  (isImage(
                    extractFileName(allMediaFiles[currentMediaIndex])
                  ) ? (
                    <Image
                      src={allMediaFiles[currentMediaIndex]}
                      alt="Selected Image"
                      objectFit="contain"
                      maxHeight="600px"
                      maxWidth="800px"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      onLoad={handleMediaLoad}
                    />
                  ) : (
                    <video
                      src={allMediaFiles[currentMediaIndex]}
                      width="800px"
                      height="600px"
                      controls
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      onLoadedMetadata={handleMediaLoad}
                    />
                  ))}
                {mediaLoaded && (
                  <Box position="absolute" top={2} right={2} zIndex={10}>
                    <ModalCloseButton
                      color="white"
                      bg="rgba(0, 0, 0, 0.5)"
                      borderRadius="full"
                      size="sm"
                    />
                  </Box>
                )}
              </Box>
              {allMediaFiles.length > 1 && (
                <Button
                  backgroundColor="#FFFFFF54"
                  padding="20px 7.5px"
                  borderRadius="lg"
                  onClick={handleNextMedia}
                >
                  <Image src={picFwd} height="15px" width="15px" />
                </Button>
              )}
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAudioOpen}
        onClose={onAudioClose}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
        <ModalContent p={0} m={0} bg="transparent" boxShadow="none">
          <ModalCloseButton
            color="white"
            position="absolute"
            top="10px"
            right="10px"
            zIndex="10"
          />
          <ModalBody
            p={0}
            m={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            {audioFiles[currentMediaIndex] && (
              <AudioPlayer src={audioFiles[currentMediaIndex]} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
