import React, { useEffect, useState, useRef } from 'react';
import { Box, ListItem, Stack, Text, Avatar, useBreakpointValue, Flex } from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import { formatDistanceToNow } from 'date-fns'; // Import date-fns function

const DealItem = ({ deal, onSelect }) => {
  const { user } = useAuth();
  const [usernames, setUsernames] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetchedData = useRef(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    const fetchUsernames = async () => {
      if (!hasFetchedData.current) {
        try {
          const userQuery = doc(db, 'users', deal.from);
          const userData = await getDoc(userQuery);
          const fetchedUser = userData.data();
          setUsernames([{ user: fetchedUser }]);
          setLoading(false);
          hasFetchedData.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUsernames();
  }, [deal.from]);

  // Check if the timestamp exists and is valid
  const formattedTime = deal.timestamp
    ? formatDistanceToNow(deal.timestamp.toDate(), { addSuffix: true })
    : 'Timestamp not available';

  return (
    <ListItem key={deal.id} mb={4} width="100%">
      {loading
        ? 'Loading...'
        : usernames.map(({ user }) => (
          <>
          {isMobile ? (
            <Box
            key={deal.id}
            position="relative"
            width="100vw"
            borderTop="1px solid #E2E8F0"
            backgroundColor="white"
            p={4}
            onClick={() => onSelect(deal)}
          >
            <Stack direction="row" spacing={4} align="center">
              <Avatar size="md" src={user?.avatar || ''} />
              <Box flex={1}>
                <Text as="b" fontSize="sm">
                  @{user?.username}
                </Text>
                <Text mt={2} fontSize="sm" noOfLines={3}>
                  {deal.message.length > 10
                    ? `${deal.message.substring(0, 10)}...`
                    : deal.message}
                </Text>
              </Box>
            </Stack>
            <Box
              position="absolute"
              bottom={2}
              right={2}
              fontSize="10px"
              color="gray.500"
            >
              {formattedTime}
            </Box>
          </Box>
          ) : (
            <Box
              key={deal.id}
              position="relative"
              width="100%"
              borderRadius="lg"
              boxShadow="xl"
              borderWidth="1px"
              borderColor="rgba(72, 50, 133, 0.1)"
              backgroundColor="white"
              p={4}
              onClick={() => onSelect(deal)}
            >
              <Stack direction="row" spacing={4} align="center">
                <Avatar size="md" src={user?.avatar || ''} />
                <Box flex={1}>
                  <Text as="b" fontSize="sm">
                    @{user?.username}
                  </Text>
                  <Text mt={2} fontSize="sm" noOfLines={3}>
                    {deal.message.length > 10
                      ? `${deal.message.substring(0, 10)}...`
                      : deal.message}
                  </Text>
                </Box>
              </Stack>
              <Box
                position="absolute"
                bottom={2}
                right={2}
                fontSize="10px"
                color="gray.500"
              >
                {formattedTime}
              </Box>
            </Box>
          )}
          </>
          ))}
          
    </ListItem>
  );
};

export default DealItem;
