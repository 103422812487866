import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth";
import { FooterIcons } from "components/navbar";
import Navbar from "components/navbar";
import {
  Box,
  Button,
  PinInput,
  PinInputField,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  HStack,
  VStack,
  useBreakpointValue,
  useColorMode,
  Flex,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import logo from "Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png";
import {
  LOGIN,
  DASHBOARD,
  REGISTER,
  VERIFICATION,
  RESET,
  ROOT,
  PROTECTED,
} from "lib/routes";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [isColorModeInitialized, setIsColorModeInitialized] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [isVerified, setIsVerified] = useState(false);
  const [pinInput, setPinInput] = useState("");
  const [pinError, setPinError] = useState(false);
  const [showPinInput, setShowPinInput] = useState(false);

  // Fetch the PIN from Firestore ("security" collection)
  const fetchPinFromFirestore = async () => {
    const securityDoc = doc(db, "security", "yI4JoxOjrnfPE13kADxP");
    const securitySnapshot = await getDoc(securityDoc);

    if (securitySnapshot.exists()) {
      return securitySnapshot.data().pin;
    } else {
      console.error("Security PIN document does not exist.");
      return null;
    }
  };

  // Check user verification status if logged in
  useEffect(() => {
    const checkVerification = async () => {
      if (user) {
        const userDoc = doc(db, "users", user.id);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists() && userSnapshot.data().verified) {
          setIsVerified(true);
        } else {
          setShowPinInput(true); // Show PIN input if not verified
        }
      } else {
        setShowPinInput(true); // Show PIN input if user is not logged in
      }
    };

    checkVerification();
  }, [user]);

  // Handle PIN submission
  const handlePinSubmit = async () => {
    const storedPin = await fetchPinFromFirestore();

    if (storedPin && pinInput.match(storedPin.toString())) {
      // Only store verification if user is logged in
      if (user) {
        const userDoc = doc(db, "users", user.id);
        await setDoc(userDoc, { verified: true }, { merge: true });
      }
      setIsVerified(true);
    } else {
      setPinError(true);
    }
  };

  // Handle redirection based on verification and authentication status
  useEffect(() => {
    if (!isLoading) {
      if (isVerified && user) {
        if (pathname === ROOT && user) {
          navigate(DASHBOARD);
        } else if (pathname === ROOT && !user) {
          navigate(LOGIN);
        } else if (pathname.startsWith(LOGIN) && !user) {
          navigate(LOGIN);
        } else if (pathname.startsWith(LOGIN) && user) {
          navigate(DASHBOARD);
        }
      } else if (showPinInput) {
        if (!user) {
          navigate(LOGIN);
        } else {
          navigate(pathname);
        }
      } else if (!isVerified && pathname.startsWith(PROTECTED)) {
        navigate(LOGIN);
      } else if (!user && pathname.startsWith(PROTECTED)) {
        navigate(LOGIN);
      }
    }
  }, [isVerified, isLoading, user, pathname, navigate, showPinInput]);

  if (showPinInput && !isVerified) {
    return (
      <VStack spacing={4} p={8} align="center">
        <Box mb={4}>
          <img src={logo} alt="Logo" width="150" />
        </Box>
        <HStack>
          <PinInput
            otp
            size="lg"
            value={pinInput}
            onChange={setPinInput}
            isInvalid={pinError}
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
        {pinError && (
          <Box color="red.500">Incorrect PIN. Please try again.</Box>
        )}
        <Button onClick={handlePinSubmit}>Submit</Button>
      </VStack>
    );
  }

  // Determine whether to show Navbar based on the path
  const showNavbar =
    !pathname.includes(LOGIN) &&
    !pathname.includes(REGISTER) &&
    !pathname.includes(VERIFICATION) &&
    !pathname.includes(RESET);

  if ((isLoading || !isColorModeInitialized) && user && isLoading) {
    return (
      <Box maxW="full" align="center">
        <Box pb="20">
          <Navbar colorMode={colorMode} toggleColorMode={toggleColorMode} />
        </Box>
        {pathname.startsWith("/protected/dashboard") && (
          <>
            <Box
              maxW="600px"
              mx={{ base: "auto", md: "auto" }}
              mt="20"
              p={4}
              boxShadow="md"
              rounded="md"
              bg="white"
            >
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
              />
              <HStack spacing={4} pt={5} align="start">
                <SkeletonCircle size={5} />
                <SkeletonCircle size={5} />
                <SkeletonCircle size={5} />
              </HStack>
            </Box>
            <Box
              maxW="400px"
              p={4}
              boxShadow="md"
              rounded="md"
              bg="white"
              mt={20}
            >
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
              />
              <HStack spacing={4} pt={5} align="start">
                <SkeletonCircle size={5} />
                <SkeletonCircle size={5} />
              </HStack>
            </Box>
            <Box
              maxW="400px"
              p={4}
              boxShadow="md"
              rounded="md"
              bg="white"
              mt={5}
            >
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
              />
              <HStack spacing={4} pt={5} align="start">
                <SkeletonCircle size={5} />
                <SkeletonCircle size={5} />
              </HStack>
            </Box>
          </>
        )}
        {pathname.startsWith("/protected/profile") && (
          <>
            <Box pt={20} pl={{ base: "", md: "60" }} width="100%">
              <VStack spacing={6} align="" maxW="container.lg">
                <Flex direction={["column", "row"]} align="start">
                  <SkeletonCircle size="20" />
                  <VStack spacing={2} align="start" ml={[0, 4]}>
                    <Skeleton height="16px" width="120px" />
                    <Skeleton height="20px" width="200px" />
                    <Skeleton height="16px" width="180px" />
                    <Skeleton height="16px" width="150px" />
                    <HStack>
                      <Skeleton height="20px" width="80px" />
                      <Skeleton height="20px" width="80px" />
                    </HStack>
                  </VStack>
                </Flex>
                <Divider />
                <Box
                  maxW="400px"
                  p={4}
                  boxShadow="md"
                  rounded="md"
                  bg="white"
                  mt={20}
                >
                  <SkeletonCircle size="10" />
                  <SkeletonText
                    mt="4"
                    noOfLines={4}
                    spacing="4"
                    skeletonHeight="2"
                  />
                  <HStack spacing={4} pt={5} align="start">
                    <SkeletonCircle size={5} />
                    <SkeletonCircle size={5} />
                  </HStack>
                </Box>
                <Box
                  maxW="400px"
                  p={4}
                  boxShadow="md"
                  rounded="md"
                  bg="white"
                  mt={5}
                >
                  <SkeletonCircle size="10" />
                  <SkeletonText
                    mt="4"
                    noOfLines={4}
                    spacing="4"
                    skeletonHeight="2"
                  />
                  <HStack spacing={4} pt={5} align="start">
                    <SkeletonCircle size={5} />
                    <SkeletonCircle size={5} />
                  </HStack>
                </Box>
              </VStack>
            </Box>
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      {showNavbar && (
        <Navbar colorMode={colorMode} toggleColorMode={toggleColorMode} />
      )}

      <Box
        pt={"5"}
        pb={{ base: "10", md: "0" }}
        mx="auto"
        w={{
          base: pathname === "/protected/dashboard" ? "fill" : "sm",
          md: "auto",
        }}
        flexDirection="column"
        minHeight="100vh"
      >
        <Outlet />
        <FooterIcons />
      </Box>
    </>
  );
}
