import React, { useEffect, useState } from 'react';
import { Box, Spinner, Center } from "@chakra-ui/react";
import Comment from './Comment';
import { collection, query, where, onSnapshot, doc, deleteDoc } from 'firebase/firestore'; // Firestore imports
import { db } from 'lib/firebase'; // Firestore instance

export default function CommentList({ post, addReply }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const postID = post?.id;

  useEffect(() => {
    if (!postID) return;

    // Firestore query to get live comments for the post
    const commentsRef = collection(db, 'comments');
    const q = query(commentsRef, where('postID', '==', postID));

    // Listen for changes in real-time
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(fetchedComments); // Update state with real-time data
      setLoading(false);
    });

    // Clean up listener when component unmounts
    return () => unsubscribe();
  }, [postID]);

  const handleDelete = async (commentId) => {
    try {
      const commentRef = doc(db, 'comments', commentId); // Reference to the comment document
      await deleteDoc(commentRef); // Delete comment in Firestore
      // The onSnapshot will automatically handle removing the comment from the UI
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleUpdate = (updatedComment) => {
    // Logic to handle comment updates, if needed
  };

  if (loading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
          addReply={addReply}
        />
      ))}
    </Box>
  );
}