import {
  Divider,
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
  IconButton,
  defineStyle,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  InputRightElement,
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepSeparator,
  StepDescription,
  StepTitle,
  Image,
  useDisclosure,
  Textarea,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import React, { useEffect, useRef, useState } from "react";
import {
  FaDollarSign,
  FaFileAudio,
  FaFileImport,
  FaMoneyCheck,
} from "react-icons/fa";
import {
  FiCheck,
  FiFacebook,
  FiFile,
  FiFileText,
  FiTriangle,
} from "react-icons/fi";
import {
  ConnectButton as ConnectWallet,
  useActiveAccount,
  useContractEvents,
  useNetworkSwitcherModal as useNetworkMismatch,
  useSwitchActiveWalletChain as useSwitchChain,
  useReadContract as useContract,
  useSendTransaction as useContractWrite,
  useActiveWallet as useWallet,
  useWalletBalance as useBalance,
  Web3Button,
  useConnectModal,
} from "thirdweb/react"; // Updated import
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from "@chakra-ui/icons";
import { ethers, utils } from "ethers";
// import { Polygon } from '@thirdweb-dev/chains';
import logoM from "Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png";
import { PAYMU, PROTECTED } from "lib/routes";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  doc,
  getDoc,
  where,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "lib/firebase";
import { SearchIcon } from "@chakra-ui/icons";
import { getIDfromUsername, useUsername } from "hooks/users";
import { useNotifications } from "hooks/notifications";
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  updateMetadata,
  uploadBytes,
} from "firebase/storage";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
//import { AudioPlayer } from "musicom/src/components/messageMu/player.js";
import AudioPlayer from "components/messageMu/player.js";
import session from "redux-persist/lib/storage/session";
import { m } from "framer-motion";
import { RiFileInfoLine, RiMoneyDollarCircleLine } from "react-icons/ri";
import { TbPolygon } from "react-icons/tb";
import { MdOutlineFilePresent } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import {
  createThirdwebClient,
  getContract,
  prepareContractCall,
} from "thirdweb";
import "./connectB.css";
import { submitReport } from "components/post/Header";

const StepperMobile = ({ activeStep, steps, sessionFetched, authUsername }) => {
  const totalSteps = steps.length;
  const progressPercentage = (activeStep / (totalSteps - 1)) * 100;

  return (
    <Box width="100%" mt={3}>
      <Flex alignItems="center">
        {/* Progress line before the circle */}
        <Box
          height="10px"
          width={`calc(${progressPercentage}% - 15px)`}
          backgroundColor="#6899FE"
          borderRadius="5px"
        />

        {/* Circle indicator with text */}
        <Flex alignItems="center" position="relative">
          <Box
            height="30px"
            width="30px"
            backgroundColor={activeStep === 0 ? "gray" : "#6899FE"}
            borderRadius="50%"
            border="2px solid white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            zIndex="1"
          />

          {/* Description of the current step */}
          <Text
            fontSize="xs"
            fontWeight="bold"
            marginLeft="10px"
            whiteSpace="nowrap"
            position="relative"
            zIndex="1"
          >
            {steps[activeStep].short.split("\n").map((line, index) => {
              const frusername = sessionFetched?.frusername;
              const authUser = sessionFetched?.authUser;
              const usernameRegex = new RegExp(
                `(${frusername}|${authUser})`,
                "g"
              );

              const parts = line.split(usernameRegex).map((part, i) =>
                part === frusername || part === authUser ? (
                  <Text key={i} as="span" fontWeight="bold">
                    {part}
                  </Text>
                ) : (
                  part
                )
              );

              return (
                <React.Fragment key={index}>
                  {parts}
                  <br />
                </React.Fragment>
              );
            })}
          </Text>
        </Flex>

        {/* Remaining progress line after the text */}
        <Box
          height="10px"
          flex="1"
          backgroundColor="gray"
          borderRadius="5px"
          marginLeft="10px"
        />
      </Flex>
    </Box>
  );
};

// Function to format the timestamp into a human-readable date and time
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
  const formattedDate = date.toLocaleDateString(undefined, options);
  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
  return { formattedDate, formattedTime };
};

const FileDownloadComponent = ({ session, onClose }) => {
  // Function to extract filename from URL
  const getFileNameFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const parts = decodedUrl.split("/");
    const lastPart = parts.pop();
    const [fileName] = lastPart.split("?");
    return fileName || "downloaded-file";
  };

  const handleDownload = async () => {
    try {
      // Fetch the file from the URL
      const response = await fetch(session);
      if (!response.ok) throw new Error("Failed to fetch file");

      // Convert response to a blob
      const blob = await response.blob();

      // Create a temporary link element to initiate the download
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;

      // Use the extracted filename or provide a default one
      link.download = getFileNameFromUrl(session);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download

      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
      onClose();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Text
      onClick={handleDownload}
      color={"#6899FE"}
      textDecor={"underline"}
      _hover={{ cursor: "pointer" }}
    >
      {getFileNameFromUrl(session)}
    </Text>
  );
};

const SessionDetailsModal = ({ isOpen, onClose, session }) => {
  const { formattedDate, formattedTime } = formatDate(session.createdAt);
  const { user } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader alignSelf={"center"} textDecoration={"underline"}>
          Session
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mt={5}>
            <VStack style={{ alignItems: "start" }}>
              <Text fontWeight={"bold"} marginBottom={-2}>
                Usernames
              </Text>
              <Text color={"#E21919"} marginBottom={-3} fontSize={14}>
                {session?.type === "sell file"
                  ? session?.frusername === user?.username
                    ? session?.authUser
                    : session?.type === "buy file" &&
                      session?.authUser === user?.username
                    ? session?.frusername
                    : session?.authUser
                  : session?.frusername}
              </Text>
              <Text color={"#066E0A"} fontSize={14}>
                {session?.type === "sell file"
                  ? session?.frusername === user?.username
                    ? session?.frusername
                    : session?.type === "buy file" &&
                      session?.authUser === user?.username
                    ? session?.authUser
                    : session?.frusername
                  : session?.authUser}
              </Text>
            </VStack>
            <Flex flex={1} />
            <VStack style={{ alignItems: "start", marginTop: -7 }}>
              <HStack>
                <Text fontWeight={"bold"}>Date</Text>
                <Text>{formattedDate}</Text>
              </HStack>
              <HStack>
                <Text fontWeight={"bold"}>Time</Text>
                <Text>{formattedTime}</Text>
              </HStack>
            </VStack>
          </HStack>
          <VStack style={{ alignItems: "start" }} mt={10}>
            <Text fontWeight={"bold"}>Wallet Addresses</Text>
            <HStack wrap={"wrap"}>
              <Text fontSize={14} color={"#E21919"}>
                To:
              </Text>
              <Text fontSize={14}>{session.buyer}</Text>
            </HStack>
            <HStack wrap={"wrap"}>
              <Text fontSize={14} color={"#066E0A"}>
                From:
              </Text>
              <Text fontSize={14}>{session.seller}</Text>
            </HStack>
          </VStack>
          <Box
            display="flex"
            justifyContent="center"
            marginTop={10}
            width="100%"
          >
            <HStack
              justifyContent="center"
              borderWidth={1}
              borderColor="black"
              width="70%"
              borderRadius="5px"
              height="30"
              p={2} // Optional padding for better spacing
            >
              <Text
                fontWeight="bold"
                color="black"
                fontSize={isMobile ? 12 : 14}
              >
                Amount{" "}
                {session.type === "sell file"
                  ? session.authUser === user?.username
                    ? "Received"
                    : "Sent"
                  : session.authUser === user?.username
                  ? "Sent"
                  : "Received"}{" "}
                (USDC):
              </Text>
              <Text color="#066E0A" fontSize={14} ml={1}>
                {session.priceExchanged} USDC
              </Text>
            </HStack>
          </Box>

          <VStack style={{ alignItems: "start" }} mt={10} mb={15}>
            <Text fontWeight={"bold"} marginBottom={-2}>
              File{" "}
              {session.type === "sell file"
                ? session.authUser === user?.username
                  ? "Sent"
                  : "Received"
                : session.authUser === user?.username
                ? "Received"
                : "Sent"}
            </Text>
            <FileDownloadComponent
              session={session.fileURL}
              onClose={onClose}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SessionButton = ({ session, isMobile, authUsername }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    if (session.status === "completed") {
      setIsModalOpen(true);
    } else {
      window.location.href = `${PROTECTED}/paymu/${session.frusername}/${session.id}`;
    }
  };

  return (
    <>
      <Button
        size={isMobile ? "xs" : "sm"}
        fontSize={isMobile && 10}
        onClick={handleButtonClick}
      >
        {session.status === "completed" &&
          (session.type === "sell file"
            ? session.authUser === authUsername
              ? "View Session"
              : "View File"
            : session.authUser === authUsername
            ? "View File"
            : "View Session")}

        {session.status !== "completed" && session.status}
      </Button>

      {/* Render the Modal */}
      <SessionDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        session={session}
      />
    </>
  );
};

const AudioPreviewPlayer = ({ fileUrl, state }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <ReactAudioPlayer
        src={fileUrl}
        autoPlay={false} // Auto-play is set to false to control play/pause manually
        controls // Show audio controls (play, pause, volume)
        onContextMenu={(e) => {
          if (state) e.preventDefault();
        }}
        controlsList={state ? "nodownload" : ""} // Disable download option
        onEnded={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </div>
  );
};

const ConnectUserModal = ({
  isOpen,
  onClose,
  onConnect,
  authUsername,
  userId,
  authUserId,
}) => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null); // Add user state
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userResults, setUserResults] = useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  const { user: authUser } = useAuth();
  const fetchUsers = async (searchQuery) => {
    const search = searchQuery.toLowerCase();
    try {
      const q = query(
        collection(db, "users"),
        where("username", ">=", search),
        where("username", "<=", search + "\uf8ff"),
        where("username", "!=", authUser.username)
      );
      const busQ = query(
        collection(db, "businesses"),
        where("username", ">=", search),
        where("username", "<=", search + "\uf8ff"),
        where("username", "!=", authUser.username)
      );
      const querySnapshot = await getDocs(q);
      const businessQuerySnapshot = await getDocs(busQ);
      const users = [
        ...querySnapshot.docs.map((doc) => doc.data()),
        ...businessQuerySnapshot.docs.map((doc) => doc.data()),
      ];
      setUserResults(users);
      setSearchResults(users);

      // Set the user state to the first result (if available)
      if (users.length > 0) {
        setUser(users[0]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
    if (inputValue) {
      fetchUsers(inputValue);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchResultClick = (result, index) => {
    setSearchQuery("");
    setSearchResults([]);
    setSelectedResultIndex(index);
    setUser(result);
    setSearchQuery(result.username);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (selectedResultIndex !== -1 && searchResults.length > 0) {
        const username = searchResults[selectedResultIndex];
        setSearchQuery("");
        setSearchResults([]);
        setSelectedResultIndex(-1);
        setUser(username);
        setSearchQuery(username.username);
      } else {
        const username = searchQuery.trim();
        setSearchQuery(username);
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) => {
        if (prevIndex === -1) {
          return searchResults.length - 1;
        } else {
          return Math.max(prevIndex - 1, -1);
        }
      });
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) => {
        if (prevIndex === searchResults.length - 1) {
          return -1;
        } else {
          return Math.min(prevIndex + 1, searchResults.length - 1);
        }
      });
    } else if (event.key === "Escape") {
      setSearchResults([]);
      setSelectedResultIndex(-1);
    } else {
      setSelectedResultIndex(-1); // Reset selected result index when a new key is pressed
    }
  };
  const { sendNotification } = useNotifications();

  if (!customElements.get("audio-player")) {
    customElements.define("audio-player", AudioPlayer);
  }

  const [connectErrorMessage, setErrorMessageConnect] = useState("");
  const handleConnect = async () => {
    const userExist = await getIDfromUsername(searchQuery.trim());

    if (userExist) {
      try {
        // Create a new session document in Firestore
        const sessionsCollection = collection(db, "sessions");
        const sessionsQuery = query(sessionsCollection);
        const sessionsSnapshot = await getDocs(sessionsQuery);
        const sessionsCount = sessionsSnapshot.size;
        const newSession = {
          listId: sessionsCount + 1,
          frusername: searchQuery.trim(), // The selected user's username
          authUser: authUsername, // The authenticated user's username
          fileURL: "", // Add the file URL here
          priceExchanged: 0, // Add the price exchanged here
          status: `awaiting ${searchQuery.trim()} approval`,
          createdAt: Date.now(),
          buyer: "",
          seller: "",
          type: "",
          confirmedExchange: false,
        };
        // Add the new session to Firestore
        const sessionDocRef = await addDoc(sessionsCollection, newSession);
        await sendNotification({
          title: "New PayMu Request",
          content: `@${authUsername} sent you a paymu request.`,
          uid: await getIDfromUsername(searchQuery.trim()),
          from: authUser?.id,
          type: "paymu",
          time: Date.now(),
        });
        // Show success message
        toast({
          title: "Connection Request Sent",
          description: `You have sent a connection request to ${searchQuery.trim()}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        window.location.href = `${PROTECTED}/paymu/${searchQuery.trim()}/${
          sessionDocRef.id
        }`;
        // const notificationSnapshot = await getDocs(
        //   query(
        //     collection(db, 'notifications'),
        //     where('uid', '==', userId),
        //     where('type', '==', 'paymu'),
        //     where('from', '==', authUserId)
        //   )
        // );

        // if (notificationSnapshot.size > 0) {
        //   notificationSnapshot.docs.forEach((docSnapshot) => {
        //     deleteDoc(doc(db, 'notifications', docSnapshot.id));
        //   });
        // }
        // window.location.href = `${PROTECTED}/paymu/${searchQuery.trim()}/${
        //   sessionDocRef.id
        // }`;
        // Close the modal
        onClose();
      } catch (error) {
        console.error("Error sending connection request:", error);
        toast({
          title: "Error",
          description: "Failed to send connection request.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      setErrorMessageConnect("No username selected");
    }
  };

  const { colorMode, toggleColorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Session</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <Avatar
                  src={user?.avatar || logoM}
                  size={isMobile ? "xs" : "sm"}
                  mb={isMobile && 2}
                />
              </InputLeftElement>
              <Input
                flex="1"
                type="text"
                placeholder="Search the users..."
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                size={{ base: "sm", md: "md" }}
                fontSize="md"
                borderWidth={1}
                borderColor={"gray.300"}
                borderStyle={"solid"}
                _focus={{ outline: "none" }}
                maxWidth={"auto"}
                //placeholder={"@" + (user?.username || "alelentini")}
              />
            </InputGroup>

            {searchResults.length > 0 && (
              <Box
                mt={2}
                p={2}
                borderRadius="md"
                boxShadow="md"
                backgroundColor={colorMode === "light" ? "white" : "gray.700"}
                color={colorMode === "light" ? "black" : "white"}
                zIndex={1}
                width="auto"
              >
                {searchResults.map((result, index) => (
                  <Box
                    key={result.id}
                    onClick={() => {
                      handleSearchResultClick(result, index);
                      setErrorMessageConnect("");
                    }}
                    cursor="pointer"
                    py={1}
                    px={2}
                    bg={
                      selectedResultIndex === index ? "gray.200" : "transparent"
                    }
                    _hover={{ background: "gray.200" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Avatar
                      size="sm"
                      border="2px solid #1041B2"
                      src={result.avatar}
                    />
                    <Text ml="2">{result.username}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <VStack>
            <HStack>
              <Button
                backgroundColor="#6899FE"
                color="white"
                size="sm"
                fontSize={"10px"}
                mr={3}
                onClick={handleConnect}
              >
                Request
              </Button>
              <Button
                backgroundColor="#6899FE"
                color="white"
                size="sm"
                fontSize={"10px"}
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
            {connectErrorMessage && (
              <Text color={"red"} fontSize={"14px"}>
                Select a valid user
              </Text>
            )}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// async function getSessionById(sessionId) {
//   console.log("sessionId", sessionId);
//   try {
//     const sessionRef = doc(db, "sessions", sessionId);
//     const sessionSnapshot = await getDoc(sessionRef);

//     if (sessionSnapshot.exists()) {
//       const sessionData = sessionSnapshot.data();
//       // Do something with the session data
//       console.log("Session Data:", sessionData);
//       return sessionData;
//     } else {
//       console.log("No such session document!");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error getting session:", error);
//     return null;
//   }
// }

const Avatarr = ({ username, isMobile }) => {
  const { user } = useUsername(username);

  return (
    <Avatar
      src={user?.avatar ? user?.avatar : logoM}
      size={isMobile ? "sm" : "sm"}
    />
  );
};

const DisputeModal = ({
  isReportModalOpen,
  setReportModalOpen,
  handleSubmitReport,
}) => {
  const [reportReason, setReportReason] = useState("dispute"); // Default to "Other"
  const [comments, setComments] = useState(""); // Handle additional comments
  const [fileUpload, setFileUpload] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track if the form is submitting
  const toast = useToast();

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (fileUpload.length + selectedFiles.length > 3) {
      // Show a toast notification if more than 3 files are selected
      toast({
        title: "File limit exceeded",
        description: "You can only attach up to 3 files.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // Add selected files to the existing list
    setFileUpload((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const removeFile = (indexToRemove) => {
    setFileUpload((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const onSubmit = async () => {
    if (fileUpload.length > 3) {
      toast({
        title: "File limit exceeded",
        description: "You can only attach up to 3 files.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    await handleSubmitReport(
      reportReason,
      comments,
      fileUpload,
      setIsSubmitting
    );
    setComments(""); // Reset comments
    setFileUpload([]); // Clear file uploads
    setReportModalOpen(false); // Close the modal
    setIsSubmitting(false); // Reset the loading state
  };

  return (
    <Modal isOpen={isReportModalOpen} onClose={() => setReportModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dispute a problem</ModalHeader>
        <ModalBody>
          <Textarea
            placeholder="Describe the issue with this session..."
            value={comments}
            onChange={(e) => {
              if (e.target.value.length <= 500) {
                setComments(e.target.value);
              }
            }}
            mt={3}
            maxLength={500}
          />
          <Text
            fontSize="sm"
            color={comments.length === 500 ? "red.500" : "gray.500"}
          >
            {comments.length}/{500 - comments.length}
          </Text>

          <Button
            variant="ghost"
            size="sm"
            leftIcon={<FiFile />}
            onClick={() => document.getElementById("fileInput").click()}
            bg="#6899fe"
            color="white"
            _hover={{ background: "#9bbafa" }}
            mt={3}
            isDisabled={fileUpload.length >= 3} // Disable button if 3 files are already selected
          >
            Attach Files (Max 3)
          </Button>

          <Input
            id="fileInput"
            type="file"
            accept="image/*, video/*" // Allow images and videos
            onChange={handleFileChange}
            multiple
            display="none"
          />

          {/* Display selected files */}
          {fileUpload.length > 0 && (
            <VStack align="start" mt={3}>
              {fileUpload.map((file, index) => (
                <HStack key={index} justifyContent="space-between" width="100%">
                  <Text>{file.name}</Text>
                  <IconButton
                    ml={2}
                    size="xs"
                    aria-label="Delete File"
                    icon={<DeleteIcon />}
                    onClick={() => removeFile(index)}
                  />
                </HStack>
              ))}
            </VStack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onSubmit}
            bg="#6899fe"
            _hover={{ background: "#9bbafa" }}
            rounded="md"
            size="xs"
            textColor="white"
            fontWeight="bold"
            isLoading={isSubmitting}
            loadingText="Submitting"
            isDisabled={comments.trim() === "" || fileUpload.length === 0} // Disable if no comments or files
          >
            Submit Report
          </Button>
          <Button
            border={"2px #6899fe solid"}
            variant="ghost"
            onClick={() => setReportModalOpen(false)}
            bg="white"
            _hover={{ background: "lightgrey" }}
            rounded="md"
            size="xs"
            textColor="#6899fe"
            fontWeight="bold"
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const PayMu = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const client = createThirdwebClient({
    clientId: "d294cd30551655013dfff9988795e8dd",
  });

  const { user, isLoading: isLoadinggg } = useAuth();

  useEffect(() => {
    if (!user) console.log("User not found");
  }, [user]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLink, setFileLink] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleUpload(file, user?.id);
  };
  const [amount, setAmount] = useState("");
  const handleAmountChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    setAmount(inputValue);
  };

  const handleUpload = async (file, uid) => {
    const fileRef = ref(storage, "paymufiles/" + uid + "/" + file.name);

    try {
      await uploadBytes(fileRef, file);
      const metadata = { contentType: file.type, name: file.name };
      await updateMetadata(fileRef, metadata);

      const fileUrl = await getDownloadURL(fileRef);
      setFileLink(fileUrl);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const toast = useToast();

  const [isRequestSessionsVisible, setIsRequestSessionsVisible] =
    useState(true);
  const [isPreviousSessionsVisible, setIsPreviousSessionsVisible] =
    useState(true);

  const inputWidth = useBreakpointValue({ base: "100%", md: "200px" });
  const [showAdditionalInputGroup, setShowAdditionalInputGroup] =
    useState(false);

  const handleSwitchChange = () => {
    setShowAdditionalInputGroup(!showAdditionalInputGroup);
  };

  const [showAdditionalInputGroup2, setShowAdditionalInputGroup2] =
    useState(false);

  const handleSwitchChange2 = () => {
    setShowAdditionalInputGroup2(!showAdditionalInputGroup2);
  };

  const { colorMode, toggleColorMode } = useColorMode();

  const activeAccount = useActiveAccount();
  const address = activeAccount?.address || null;

  const wallet = useWallet();

  const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
  const { data: balanceData, isLoading: balanceLoading } =
    useBalance(tokenAddress);

  const [authUsername, setAuthUsername] = useState("");
  const [loadingg, setLoadingg] = useState(false);
  useEffect(() => {
    if (!isLoadinggg && user && user.username) {
      setAuthUsername(user.username);
    }
  }, [isLoadinggg, user]);

  const isMismatched = useNetworkMismatch();
  const switchChain = useSwitchChain();

  // useEffect(() => {
  //   if (isMismatched && address) {
  //     switchChain('137');
  //   }
  // }, [isMismatched, switchChain, address]);

  // const {
  //   data: contract,
  //   isLoading: contractLoading,
  //   error: contractError,
  // } = useContract({
  //   address: '0x30d1DEA4383EA8D932Eabc423dF4e18A445DcA10',
  // });
  const contract = getContract({
    client,
    chain: "polygon",
    address: "0x30d1DEA4383EA8D932Eabc423dF4e18A445DcA10",
  });

  // useEffect(() => {
  //   if (!contract) {
  //     console.log(contract);
  //     console.error('Contract is not initialized or failed to load.');
  //   }
  // }, [contract]);

  // const addFile = useContractWrite({
  //   contract,
  //   functionName: 'addFile',
  // });
  const { mutateAsync: addFile, data: addFileResult } = useContractWrite();

  const [loading, setLoading] = useState(false);
  const [errorConfirm, setErrorConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const addFileButton = async (_id, price, buyer) => {
    console.log("TEST");
    // console.log(addFile.isLoading);
    if (!addFile && !contract) {
      console.log("ERRORRR");
      throw new Error("addFile function is not defined");
    }
    try {
      setLoading(true);
      const _price = ethers.utils.parseUnits(price, 6);
      const _buyer = ethers.utils.getAddress(buyer);

      console.log("ID:", _id);
      console.log("Price:", _price);
      console.log("Buyer:", _buyer);
      console.log("Formatted Buyer Address:", ethers.utils.getAddress(_buyer));

      const transaction = prepareContractCall({
        contract,
        method: "function addFile(string _id, uint256 _price, address _buyer)",
        params: [_id, _price, _buyer],
      });
      const receipt = await addFile(transaction);

      // const { receipt } = await addFile.mutateAsync({
      //   args: [_id, _price, _buyer],
      // });

      setLoading(false);
      console.log("Contract call success", receipt);

      handleApprove({
        session: sessionFetched,
        sessionId: sessionFetched?.id,
        sessionType: sessionFetched?.type,
        fileLink: sessionFetched?.fileURL,
        seller: sessionFetched?.seller,
        buyer: sessionFetched?.buyer,
        fileOnBlock: true,
      });
    } catch (err) {
      setLoading(false);
      setErrorConfirm(true);
      setErrorMessage(err.message || "An unknown error occurred");
      console.error("Contract call failure", err);
    }
  };

  // const buyFile = useContractWrite({
  //   contract,
  //   functionName: 'buyFile',
  // });
  const { mutateAsync: buyFile, data: buyFileResult } = useContractWrite();

  // const confirm = useContractWrite({
  //   contract,
  //   functionName: 'confirm',
  // });
  const { mutateAsync: confirm, data: confirmResult } = useContractWrite();

  // const withdraw = useContractWrite({
  //   contract,
  //   functionName: 'withdraw',
  // });
  const { mutateAsync: withdraw, data: withdrawResult } = useContractWrite();

  // const { data: usdcContract, isLoading: usdcContractLoading } = useContract({
  //   address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
  // });

  const usdcContract = getContract({
    client,
    chain: "polygon",
    address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
  });

  const [usdcLoading, setUsdcLoading] = useState(false);

  const usdcApprove = async (spender, value) => {
    setUsdcLoading(true);
    const abi = [
      "function approve(address spender, uint256 value) external returns (bool)",
      "function allowance(address owner, address spender) external view returns (uint256)",
    ];

    // Create a provider using ethers
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Get the signer from the provider
    const signer = provider.getSigner();
    const conttt = new ethers.Contract(
      await usdcContract.getAddress(),
      abi,
      signer
    );

    try {
      const requiredValue = ethers.utils.parseUnits(value, 6);
      const approveTx = await conttt.approve(
        await contract.getAddress(),
        requiredValue,
        {
          gasLimit: 1000000,
        }
      );
      await approveTx.wait();
      console.info("Approval transaction successful", approveTx);
      setUsdcLoading(false);
    } catch (err) {
      console.error("Contract call failure", err);
      setErrorMessage(
        "An error occurred during the approval process. Please try again."
      );
      setUsdcLoading(false);
    }
  };

  const buyButtonCall = async (_id) => {
    try {
      setLoading(true);
      await usdcApprove(address, sessionFetched?.priceExchanged);

      // const { receipt } = await buyFile.mutateAsync({
      //   args: [sessionFetched?.id],
      // });
      const transaction = prepareContractCall({
        contract,
        method: "function buyFile(string _id)",
        params: [sessionFetched?.id],
      });
      const receipt = await buyFile(transaction);

      setLoading(false);
      console.log(receipt);
      console.info("Contract call success", receipt);

      handleApprove({
        session: sessionFetched,
        sessionId: sessionFetched?.id,
        sessionType: sessionFetched?.type,
        fileLink: sessionFetched?.fileURL,
        seller: sessionFetched?.seller,
        buyer: sessionFetched?.buyer,
        fileOnBlock: true,
        payed: true,
      });
    } catch (err) {
      setLoading(false);
      setErrorMessage("Try Again");
      console.error("Contract call failure", err);
    }
  };

  const confirmButtonCall = async (_id) => {
    try {
      setLoading(true);

      // const { receipt } = await confirm.mutateAsync({
      //   args: [_id],
      // });

      const transaction = await prepareContractCall({
        contract,
        method: "function confirm(string _id)",
        params: [_id],
      });
      const receipt = await confirm(transaction);

      setLoading(false);
      console.log(receipt);
      console.info("Contract call success", receipt);
      handleApprove({
        session: sessionFetched,
        sessionId: sessionFetched?.id,
        sessionType: sessionFetched?.type,
        fileLink: sessionFetched?.fileURL,
        seller: sessionFetched?.seller,
        buyer: sessionFetched?.buyer,
        fileOnBlock: true,
        payed: true,
        confirmed: true,
      });
    } catch (err) {
      setLoading(false);
      console.error("Contract call failure", err);
    }
  };

  const withdrawButtonCall = async () => {
    try {
      setLoading(true);

      // const { receipt } = await withdraw.mutateAsync({
      //   args: [],
      // });

      const transaction = prepareContractCall({
        contract,
        method: "function withdraw()",
        params: [],
      });
      const receipt = await withdraw(transaction);

      setLoading(false);
      console.log(receipt);
      console.info("Contract call success", receipt);
      handleApprove({
        session: sessionFetched,
        sessionId: sessionFetched?.id,
        sessionType: sessionFetched?.type,
        fileLink: sessionFetched?.fileURL,
        seller: sessionFetched?.seller,
        buyer: sessionFetched?.buyer,
        fileOnBlock: true,
        payed: true,
        confirmed: true,
        sellerWithdraw: true,
      });
    } catch (err) {
      setLoading(false);
      setErrorMessage("Error");
      console.error("Contract call failure", err);
    }
  };

  const [isConnectModalOpen, setConnectModalOpen] = useState(false);
  const {
    isOpen: disputeOpen,
    onOpen: disputeOnOpen,
    onClose: disputeClose,
  } = useDisclosure();

  const [isReportModalOpen, setReportModalOpen] = useState(false);

  const handleSubmitDispute = async (
    reason,
    additionalComments,
    evidenceFiles,
    setIsSubmitting
  ) => {
    try {
      const result = await submitReport({
        id: sessionFetched?.id,
        reporterId: user.id,
        reportedId:
          user?.id === sessionFetched?.buyer
            ? sessionFetched?.seller
            : sessionFetched?.buyer,
        reason: reason,
        additionalComments: additionalComments,
        evidenceFiles: evidenceFiles,
        reportType: "payMu",
      });

      toast({
        title: result.success
          ? "Dispute submitted successfully."
          : "Failed to submit dispute.",
        description: result.success
          ? "Dispute sent successfully"
          : result.message || "Failed to submit dispute.",
        status: result.success ? "success" : "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });

      if (result.success) {
        disputeClose();
      }
    } catch (error) {
      console.error("Error handling report submission:", error);
      toast({
        title: "Failed to submit report.",
        description: "An unexpected error occurred. Please try again.",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false); // Make sure loading stops even in case of an error
    }
  };

  const SessionButton = ({ session, isMobile, authUsername }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonClick = () => {
      if (session.status === "completed") {
        setIsModalOpen(true);
      } else {
        window.location.href = `${PROTECTED}/paymu/${session.frusername}/${session.id}`;
      }
    };

    return (
      <>
        {session.status !== "completed" &&
        session.status !== `awaiting ${authUsername} approval` ? (
          <Button
            size={isMobile ? "xs" : "sm"}
            fontSize={isMobile && 10}
            onClick={handleButtonClick}
            style={{
              fontSize: "14px", // Adjust font size
              backgroundColor: "transparent", // Make the background transparent
              border: "none", // Remove the border to make it fully transparent
              padding: "5px 10px", // Padding inside the button
              lineHeight: "1.2", // Adjust line-height to fit text neatly
              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
              overflow: "hidden", // Hide any overflow to prevent text from spilling outside
              textOverflow: "ellipsis", // Add ellipsis if text is too long
              maxWidth: "100%", // Ensure the button width doesn't exceed the container
              display: "inline-block", // Ensures the button adjusts based on its content
              transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
            }}
            _hover={{
              backgroundColor: "#E3F2FD", // Light blue background on hover
              color: "#1E88E5", // Darker blue text color on hover
              transform: "scale(1.05)", // Slightly increase the size for a "pop" effect
              borderRadius: "5px", // Add a slight border radius for a rounded effect
              padding: "5px 10px", // Ensure padding remains consistent during hover
            }}
          >
            {session.status}
          </Button>
        ) : session.status !== "completed" &&
          session.status === `awaiting ${authUsername} approval` ? (
          <HStack height={"100%"}>
            <Button
              onClick={() => {
                try {
                  handleApprove({
                    session: session,
                    sessionId: session?.id,
                    authUser: authUsername,
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              fontSize="12.5px"
              fontWeight={"200"}
              backgroundColor={"#6899fe"}
              color={"white"}
              border={"1px solid #B1C3DA"}
              borderRadius={"6px"}
              width={"fit-content"}
              paddingX={2}
              paddingY={2}
              height={"100%"}
              _hover={{
                backgroundColor: "#527bd1",
              }}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                handleDeny(session?.id, session?.authUser);
              }}
              fontSize="12.5px"
              fontWeight={"200"}
              mr={"10%"}
              backgroundColor={"#6899fe"}
              color={"white"}
              border={"1px solid #B1C3DA"}
              borderRadius={"6px"}
              width={"fit-content"}
              paddingX={2}
              paddingY={2}
              height={"100%"}
              _hover={{
                backgroundColor: "#527bd1",
              }}
            >
              Decline
            </Button>
          </HStack>
        ) : (
          <Button
            size={isMobile ? "xs" : "sm"}
            fontSize={isMobile && 10}
            onClick={handleButtonClick}
            style={{
              color: "#6899FE", // Set the text color
              fontSize: "14px", // Adjust font size
              textDecoration: "underline", // Apply underline
              backgroundColor: "transparent", // Make the background transparent
              border: "none", // Remove the border to make it fully transparent
              padding: "5px 10px", // Padding inside the button
              lineHeight: "1.2", // Adjust line-height to fit text neatly
              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
              overflow: "hidden", // Hide any overflow to prevent text from spilling outside
              textOverflow: "ellipsis", // Add ellipsis if text is too long
              maxWidth: "100%", // Ensure the button width doesn't exceed the container
              display: "inline-block", // Ensures the button adjusts based on its content
              transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
            }}
            _hover={{
              backgroundColor: "#E3F2FD", // Light blue background on hover
              color: "#1E88E5", // Darker blue text color on hover
              transform: "scale(1.05)", // Slightly increase the size for a "pop" effect
              borderRadius: "5px", // Add a slight border radius for a rounded effect
              padding: "5px 10px", // Ensure padding remains consistent during hover
            }}
          >
            {session.status === "completed" &&
              (session.type === "sell file"
                ? session.authUser === authUsername
                  ? "View Session"
                  : "View File"
                : session.authUser === authUsername
                ? "View File"
                : "View Session")}
          </Button>
        )}
        {/* Render the Modal */}
        <SessionDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          session={session}
        />
      </>
    );
  };

  const handleConnectClick = () => {
    if (currentSessions.length >= 4) {
      toast({
        title: "Limit Reached",
        description:
          "You have reached the maximum limit of 4 active sessions. Please delete an existing session to create a new one.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setConnectModalOpen(true);
    }
  };

  const handleConnectModalClose = () => {
    setConnectModalOpen(false);
  };

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    // First query: authUser is equal to authUsername
    const query1 = query(
      collection(db, "sessions"),
      where("authUser", "==", authUsername)
    );

    // Second query: frusername is equal to authUsername
    const query2 = query(
      collection(db, "sessions"),
      where("frusername", "==", authUsername)
    );

    // Combine results from both queries and update state
    const unsubscribe1 = onSnapshot(query1, (snapshot) => {
      const sessionsData = [];
      snapshot.forEach((doc) => {
        sessionsData.push({ id: doc.id, ...doc.data() });
      });

      // Replace the sessions state with the new data
      setSessions((prevSessions) => {
        // Keep the sessions from query2 and replace the ones from query1
        const updatedSessions = prevSessions.filter(
          (session) => session.frusername === authUsername
        );
        return [...updatedSessions, ...sessionsData];
      });
    });

    const unsubscribe2 = onSnapshot(query2, (snapshot) => {
      const sessionsData = [];
      snapshot.forEach((doc) => {
        sessionsData.push({ id: doc.id, ...doc.data() });
      });

      // Replace the sessions state with the new data
      setSessions((prevSessions) => {
        // Keep the sessions from query1 and replace the ones from query2
        const updatedSessions = prevSessions.filter(
          (session) => session.authUser === authUsername
        );
        return [...updatedSessions, ...sessionsData];
      });
    });

    // Clean up subscriptions on unmount
    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, [authUsername]);

  const urlParts = window.location.toString().split("/");
  const sessionId =
    urlParts[urlParts.length - 1] !== "paymu"
      ? urlParts[urlParts.length - 1]
      : 0;

  const frusername =
    urlParts[urlParts.length - 2] !== "protected"
      ? urlParts[urlParts.length - 2]
      : 0;

  const [sessionFetched, setSessionFetched] = useState(null);

  useEffect(() => {
    if (sessionId) {
      const sessionDocRef = doc(db, "sessions", sessionId);

      const unsubscribe = onSnapshot(sessionDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const sessionData = docSnapshot.data();
          setSessionFetched({ ...sessionData, id: docSnapshot.id });
        } else {
          console.log("Session does not exist");
          setSessionFetched(null);
          if (sessionId !== 0 && frusername !== 0) {
            setTimeout(() => {
              window.location.href = `${PROTECTED}/paymu`;
            }, 2000);
          }
        }
      });

      // Cleanup function to unsubscribe from the snapshot listener
      return () => unsubscribe();
    }
  }, [sessionId]);

  const [fileType, setFileType] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const getFileType = async (url) => {
    try {
      const storage = getStorage(); // Initialize Firebase Storage

      const fileRef = ref(storage, url);
      const metadata = await getMetadata(fileRef);
      return metadata.contentType || metadata.type;
    } catch (error) {
      console.error("Error getting file type:", error);
      return null;
    }
  };

  useEffect(() => {
    if (sessionFetched?.fileURL) {
      getFileType(sessionFetched.fileURL)
        .then((contentType) => {
          setFileType(contentType);
          // Set the file URL
          setFileUrl(sessionFetched.fileURL);
        })
        .catch((error) => {
          console.error("Error getting file type:", error);
        });
    }
  }, [sessionFetched]);

  const removeDuplicates = (array, key) => {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  };

  const currentSessions = removeDuplicates(
    sessions.filter(
      (session) =>
        session.status !== "completed" &&
        session.status !== "awaiting ${authUsername} approval"
    ),
    "id"
  );

  const requestSessions = removeDuplicates(
    sessions.filter(
      (session) => session.status === `awaiting ${authUsername} approval`
    ),
    "id"
  );
  const previousSessions = removeDuplicates(
    sessions.filter((session) => session.status === "completed"),
    "id"
  );

  const renderFilePreview = (state, isMobile) => {
    // console.log(
    //   fileType + ' - ' + fileUrl,
    //   +' - ' +
    //     fileUrl
    //       ?.split('%2F')[2]
    //       .split('?')[0]
    //       .replace('%20', ' ')
    //       .replace('NaN', '')
    // );
    if (fileType && fileUrl) {
      if (fileType.startsWith("image/")) {
        // Display image preview
        return (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              height: "20%",
              width: isMobile ? "200px" : "300px",
            }}
          >
            <img
              src={fileUrl}
              alt={fileUrl?.split("%2F")[2].replace("%20", "")}
              style={{ width: "100%", height: "100%" }}
              onContextMenu={(e) => {
                if (state) e.preventDefault();
              }}
            />
            {state && (
              <>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "38%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "7px" : "12px",
                  }}
                >
                  MUSICOM™
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "5%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "7px" : "12px",
                  }}
                >
                  MUSICOM™
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "70%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "7px" : "12px",
                  }}
                >
                  MUSICOM™
                </div>
              </>
            )}
          </div>
        );
      } else if (fileType.startsWith("video/")) {
        return (
          <div style={{ position: "relative" }}>
            <video
              controls
              width={isMobile ? "250" : "640"}
              height="360"
              onContextMenu={(e) => {
                if (state) e.preventDefault();
              }}
            >
              <source src={fileUrl} type="video/mp4" />
            </video>
            {state && (
              <>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "40%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "10px" : "20px",
                  }}
                >
                  MUSICOM™
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "10%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "10px" : "20px",
                  }}
                >
                  MUSICOM™
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50%",
                    right: "70%",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    fontSize: isMobile ? "10px" : "20px",
                  }}
                >
                  MUSICOM™
                </div>
              </>
            )}
          </div>
        );
      } else if (fileType.startsWith("audio/")) {
        return <AudioPreviewPlayer fileUrl={fileUrl} state={state} />;
      } else if (fileType === "application/pdf") {
        // Display PDF preview in an iframe
        return (
          <iframe
            src={fileUrl + "#toolbar=0"}
            width="fit-content"
            height={isMobile ? "200px" : "400px"}
            frameBorder="0"
            title="PDF Preview"
          ></iframe>
        );
      } else if (fileType === "application/vnd.openxmlformats-officedocument") {
        // Display PPT presentation in an iframe
        return (
          <>
            <DocViewer
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                csvDelimiter: ",", // "," as default,
                pdfZoom: {
                  defaultZoom: 1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: false, // false as default
              }}
              documents={[{ uri: fileUrl + "#toolbar=0" }]}
              style={{ height: "20%", width: isMobile ? "200px" : "400px" }}
              initialActiveDocument={0} // Set the initialActiveDocument to 0 or the appropriate index
              pluginRenderers={DocViewerRenderers}
            />
          </>
        );
      } else {
        const regex = /\/([^/]+)\?/;

        let fille = "";
        const matches = fileUrl.match(regex);
        if (matches && matches.length > 1) {
          const filePath = matches[1];
          const fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
          fille = decodeURIComponent(fileName.split("%2F").pop());
        }

        // Handle other file types or show a message
        return (
          <VStack mt={"5%"} mb={isMobile ? "10%" : "5%"}>
            <HStack
              wrap={"wrap"}
              as="span"
              justifyContent={"space-between"}
              gap={"5px"}
              backgroundColor={"rgba(184, 167, 167, 0.12)"}
              borderRadius={15}
              maxWidth={isMobile ? "70vw" : "30vw"}
              p={2}
              display="inline-flex"
            >
              <RiFileInfoLine size={isMobile ? 18 : 24} />
              <Text
                fontWeight={"bold"}
                fontSize={isMobile ? "12px" : "16px"}
                isTruncated
                width={"90%"}
              >
                {fille}{" "}
              </Text>
            </HStack>
            <Text fontSize={isMobile ? "12px" : "16px"}>
              {" File Type: "}
              {fille.split(".")[fille.split(".").length - 1].split("_")[0]}{" "}
            </Text>
            <Text fontSize={isMobile ? "12px" : "16px"}>
              File Preview not available...{" "}
            </Text>
          </VStack>
        );
      }
    } else {
      return null;
    }
  };

  const deleteSession = async (sessionId) => {
    try {
      // Reference to the session document
      const sessionRef = doc(db, "sessions", sessionId);

      // Delete the document
      await deleteDoc(sessionRef);

      console.log(`Document with ID ${sessionId} successfully deleted.`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const { sendNotification } = useNotifications();

  const handleApprove = async ({
    session = null,
    authUser = "",
    sessionId = "",
    frusername = "",
    sessionType = "",
    fileLink = "",
    buyer = "",
    amount = "",
    seller = "",
    approveAmount = false,
    fileOnBlock = false,
    payed = false,
    confirmed = false,
    sellerWithdraw = false,
  }) => {
    try {
      // Reference to the session document
      const sessionRef = doc(db, "sessions", session?.id || sessionId);
      let status = "";

      if (session?.status === `awaiting ${authUsername} approval`) {
        status = "choose type of exchange";
      } else if (session?.status === "choose type of exchange") {
        status = `approve exchange type: ${sessionType}`;
      } else if (
        session.status === `approve exchange type: ${session?.type}` &&
        session?.type
      ) {
        status = `approve ${
          session?.type === "buy file"
            ? `buying file from ${session.frusername}`
            : `selling file to ${session.frusername}`
        }`;
      } else if (fileLink && !buyer && !amount) {
        status = `approve file from ${
          session?.type === "buy file" ? session?.frusername : session?.authUser
        }`;
      } else if (
        fileLink &&
        buyer &&
        !amount &&
        !approveAmount &&
        !fileOnBlock
      ) {
        status = "file approved";
      } else if (
        fileLink &&
        buyer &&
        amount &&
        seller &&
        !approveAmount &&
        !fileOnBlock &&
        !payed
      ) {
        status = "approve amount";
      } else if (approveAmount && !fileOnBlock && !payed && !confirmed) {
        status = "confirm file on blockchain";
      } else if (fileOnBlock && !payed && !confirmed && !sellerWithdraw) {
        status = "pay on blockchain";
      } else if (payed && !confirmed && !sellerWithdraw) {
        status = "confirm file received";
      } else if (confirmed && !sellerWithdraw) {
        status = "withdraw your balance";
      } else if (sellerWithdraw) {
        status = "completed";
      } else {
        status = "accept payment";
      }

      // Update the session document
      await updateDoc(sessionRef, {
        confirmedExchange: sellerWithdraw || "",
        type: sessionType
          ? sessionType
          : sessionFetched?.type
          ? sessionFetched?.type
          : "",
        status: status,
        fileURL: fileLink
          ? fileLink
          : sessionFetched?.fileURL
          ? sessionFetched?.fileURL
          : "",
        buyer: buyer ? buyer : session?.buyer ? session?.buyer : "",
        priceExchanged: amount
          ? amount
          : sessionFetched?.priceExchanged
          ? sessionFetched?.priceExchanged
          : "",
        seller: seller ? seller : session?.seller ? session?.seller : "",
      });

      if (session.status === `awaiting ${authUser} approval`)
        window.location.href = `${PROTECTED}/paymu/${session.frusername}/${session.id}`;

      // Send notification to the other user
      await sendNotification({
        title: "Your exchange has been approved",
        content: `@${authUsername} accepted your exchange.`,
        uid: await getIDfromUsername(frusername),
        from: user?.id,
        type: "paymu",
        time: Date.now(),
      });
    } catch (error) {
      console.error("Error approving session:", error);
    }
  };

  const handleDeny = async (sessionId, frusername) => {
    console.log(sessionId, frusername);
    try {
      // Reference to the session document
      const sessionRef = doc(db, "sessions", sessionId);

      // Delete the session document
      await deleteDoc(sessionRef);

      // Send notification to the other user
      await sendNotification({
        title: "Your exchange has been denied",
        content: `@${authUsername} denied your exchange.`,
        uid: await getIDfromUsername(frusername),
        from: user?.id,
        type: "paymu",
        time: Date.now(),
      });

      //window.location.href = `${PROTECTED}/paymu`;
    } catch (error) {
      console.error("Error denying session:", error);
    }
  };

  const getFileNameFromUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const parts = decodedUrl.split("/");
    const lastPart = parts.pop();
    const [fileName] = lastPart.split("?");
    return fileName || "downloaded-file";
  };

  const handleDownloadClick = async (event) => {
    // event.preventDefault(); // Prevent default link behavior

    // if (sessionFetched?.fileURL) {
    //   // Create a new window or tab
    //   const newWindow = window.open(sessionFetched.fileURL, '_blank');
    // } else {
    //   // Handle the case where popups are blocked
    //   console.log('Unable to open a new window. Popups might be blocked.');
    // }
    try {
      // Fetch the file from the URL
      const response = await fetch(sessionFetched?.fileURL);
      if (!response.ok) throw new Error("Failed to fetch file");

      // Convert response to a blob
      const blob = await response.blob();

      // Create a temporary link element to initiate the download
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;

      // Use the extracted filename or provide a default one
      link.download = getFileNameFromUrl(sessionFetched?.fileURL);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download

      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [sessionType, setSessionType] = useState("");

  const steps = [
    {
      title: "First",
      description: `awaiting ${sessionFetched?.frusername} approval`,
      short: "Awaiting\nApproval",
      loading: sessionFetched?.authUser === authUsername ? true : false,
    },
    {
      title: "Second",
      description: "choose type of exchange",
      short:
        sessionFetched?.authUser === authUsername
          ? `${sessionFetched?.frusername}\nis waiting`
          : `Awaiting\n${sessionFetched?.authUser}`,
      loading: sessionFetched?.authUser !== authUsername ? true : false,
    },

    {
      title: "Third",
      description: `approve exchange type: ${sessionFetched?.type}`,
      short:
        sessionFetched?.authUser === authUsername
          ? `Awaiting\n${sessionFetched?.frusername}`
          : "Approve\nExchange",
      loading: sessionFetched?.authUser !== authUsername ? true : false,
    },
    {
      title: "Fourth",
      description: `approve ${
        sessionFetched?.type === "sell file"
          ? "selling file to"
          : "buying file from"
      } ${sessionFetched?.frusername}`,
      short:
        sessionFetched?.type === "sell file" &&
        sessionFetched?.authUser === authUsername
          ? `Send\nFile`
          : sessionFetched?.type === "buy file" &&
            sessionFetched?.authUser === authUsername
          ? `Awaiting ${sessionFetched?.frusername}'s\nfile`
          : sessionFetched?.type === "sell file" &&
            sessionFetched?.authUser !== authUsername
          ? `Awaiting\n${sessionFetched?.authUser}'s\nfile`
          : "Send\nFile",
      loading: sessionFetched?.authUser !== authUsername ? true : false,
    },
    // {
    //   title: 'Fifth',
    //   description: `approve selling file to ${sessionFetched?.frusername}`,
    //   short: 'Approve\nSelling File',
    // },
    {
      title: "Sixth",
      description: `approve file from ${
        sessionFetched?.type === "sell file"
          ? sessionFetched?.authUser
          : sessionFetched?.type === "buy file" && sessionFetched?.frusername
      }`,
      short:
        sessionFetched?.authUser === authUsername &&
        sessionFetched?.type === "buy file"
          ? "Approve\nFile"
          : sessionFetched?.authUser !== authUsername &&
            sessionFetched?.type === "sell file"
          ? "Approve\nFile"
          : `Awaiting\n${
              sessionFetched?.type === "buy file"
                ? sessionFetched?.authUser
                : sessionFetched?.frusername
            }`,
      loading:
        sessionFetched?.authUser !== authUsername &&
        sessionFetched?.type === "sell file"
          ? false
          : sessionFetched?.authUser !== authUsername &&
            sessionFetched?.type === "buy file"
          ? false
          : true,
    },
    {
      title: "Seventh",
      description: "file approved",
      short:
        sessionFetched?.buyer === address
          ? `Awaiting\n${
              sessionFetched?.type === "buy file"
                ? sessionFetched?.frusername
                : sessionFetched?.authUser
            }'s\nprice`
          : "Set\nPrice",
      loading:
        sessionFetched?.authUser !== authUsername &&
        sessionFetched?.type === "sell file"
          ? true
          : sessionFetched?.authUser !== authUsername &&
            sessionFetched?.type === "buy file"
          ? true
          : false,
    },
    {
      title: "Eigth",
      description: "approve amount",
      short:
        sessionFetched?.seller === address
          ? `Awaiting\n${
              sessionFetched?.type === "buy file"
                ? sessionFetched?.authUser
                : sessionFetched?.type === "sell file"
                ? sessionFetched?.frusername
                : sessionFetched?.frusername
            }'s\napproval`
          : "Approve\nAmount",
      loading:
        sessionFetched?.authUser !== authUsername &&
        sessionFetched?.type === "sell file"
          ? false
          : sessionFetched?.authUser !== authUsername &&
            sessionFetched?.type === "buy file"
          ? false
          : true,
    },
    {
      title: "Nineth",
      description: "confirm file on blockchain",
      short:
        sessionFetched?.type === "buy file" &&
        sessionFetched?.authUser === authUsername
          ? "Awaiting\nFile\nUploading"
          : "Confirm\non\nBlockchain",
    },
    {
      title: "Tenth",
      description: "pay on blockchain",
      short:
        sessionFetched?.buyer === address
          ? "Sending\nPayment"
          : "Awaiting\nPayment",
    },
    {
      title: "Eleventh",
      description: "confirm file received",
      short:
        sessionFetched?.buyer === address
          ? "Confirm\nReceipt"
          : "Awaiting\nConfirmation",
    },
    {
      title: "Twelfth",
      description: "withdraw your balance",
      short:
        sessionFetched?.seller === address
          ? "Withdraw\nBalance"
          : "Awaiting\nWithdrawal",
    },
    { title: "Thirteenth", description: "completed", short: "Completed" },
  ];

  const statusToStepIndex = {
    awaitingApproval: 1,
    chooseTypeOfExchange: 2,
    approveExchangeType: 3,
    approveBuyingFile: 4,
    approveSellingFile: 5,
    approveFile: 6,
    fileApproved: 7,
    approveAmount: 8,
    confirmFileOnBlockchain: 9,
    payOnBlockchain: 10,
    confirmFileReceived: 11,
    withdrawBalance: 12,
    completed: 13,
  };

  const { activeStep, setActiveStep } = useSteps({
    initialStep: 0,
    count: steps.length,
  });

  useEffect(() => {
    if (sessionFetched?.status) {
      const stepIndex = steps.findIndex((step) =>
        step.description.match(sessionFetched?.status)
      );
      if (stepIndex !== -1) {
        setActiveStep(stepIndex);
      }
    }
  }, [sessionFetched?.status, setActiveStep]);

  const displayedSteps =
    activeStep < 4
      ? steps.slice(0, 4)
      : steps.slice(activeStep - 3, activeStep + 1);

  const { connect, isConnecting } = useConnectModal();

  async function handleConnect() {
    const wallet = await connect({ client }); // opens the connect modal
    console.log("connected to", wallet);
  }

  return (
    <Center pt={20} width={isMobile ? "100%" : "100vw"}>
      <VStack justifyContent="center" spacing={0}>
        <VStack justifyContent={"space-between"} spacing={0}>
          <Text
            mt={-10}
            mb={"20%"}
            fontWeight={"bold"}
            fontSize={"22px"}
            px={"10px"}
            color={"#6899FE"}
            backgroundColor={"#D9D9D9AB"}
            borderRadius={"4px"}
            borderColor={"#FFA500"}
            borderWidth={1}
          >
            PayMu
          </Text>
          {address && !isMobile && (
            <Box
              style={{
                marginBottom: "25px",
              }}
            >
              <ConnectWallet
                theme={colorMode}
                detailsModal={{
                  payOptions: {
                    buyWithFiat: {
                      prefillSource: {
                        currency: "USD",
                      },
                    },
                  },
                }}
                btnTitle={"Connect to PayMu"}
                detailsButton={{
                  displayBalanceToken: {
                    ["137"]: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
                  },
                }}
                dropdownPosition={{
                  side: "bottom", //  "top" | "bottom" | "left" | "right";
                  align: "end", // "start" | "center" | "end";
                }}
                client={client}
              />
            </Box>
          )}
          <VStack
            spacing={1}
            align="center"
            mr={
              address
                ? sessions.length > 0 && frusername === 0 && sessionId === 0
                  ? isMobile
                    ? 0
                    : 20
                  : isMobile
                  ? 0
                  : 60
                : 0
            }
            ml={
              address
                ? sessions.length > 0 &&
                  !isMobile &&
                  frusername === 0 &&
                  sessionId === 0 &&
                  20
                : 0
            }
          >
            {/* {!address && (
              <>
                <Text
                  fontSize={isMobile ? 22 : '30px'}
                  textAlign="center"
                  mt={'100px'}
                >
                  Pay safely and securely. Pay with PayMu.
                </Text>
                <Text
                  fontSize={isMobile ? 22 : '30px'}
                  textAlign="center"
                  color={'#6899FE'}
                  mb={'100px'}
                >
                  Pay safely and securely. Pay with PayMu.
                </Text>
              </>
            )} */}
            {address && isMobile && (
              <Box
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <ConnectWallet
                  theme={colorMode}
                  btnTitle={"Connect to PayMu"}
                  detailsButton={{
                    displayBalanceToken: {
                      ["137"]: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
                    },
                  }}
                  dropdownPosition={{
                    side: "bottom", //  "top" | "bottom" | "left" | "right";
                    align: "end", // "start" | "center" | "end";
                  }}
                  client={client}
                />
              </Box>
            )}
          </VStack>
          {address && !isMobile && frusername === 0 && sessionId === 0 && (
            <Button
              onClick={handleConnectClick}
              backgroundColor="#6899FE"
              color="white"
              fontSize={"16px"}
              fontWeight={"400"}
              padding={"20px 20px"}
            >
              Start New Session
            </Button>
          )}
        </VStack>
        {address &&
          isMobile &&
          !isLoadinggg &&
          frusername === 0 &&
          sessionId === 0 && (
            <Button onClick={handleConnectClick}>New Session</Button>
          )}
        {address && !isLoadinggg && frusername === 0 && sessionId === 0 && (
          <Box mt={"50px"}>
            <VStack>
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                textDecor={"underline"}
                mb={"20px"}
              >
                Current Sessions
              </Text>
              {currentSessions.length > 0 ? (
                currentSessions.map(
                  (session) =>
                    session.status !== `awaiting ${authUsername} approval` && (
                      <HStack
                        key={session.id}
                        width="100%"
                        justifyContent={"center"}
                      >
                        <Box
                          border="1px"
                          borderRadius={isMobile ? 10 : "6px"}
                          borderColor={"#C0BDBD"}
                          width={"100%"}
                          mb={2}
                          backgroundColor={
                            session.status === "completed"
                              ? "gray.300"
                              : "#B8A7A71F"
                          }
                        >
                          <HStack justifyContent={"space-evenly"}>
                            <HStack
                              justifyContent={"space-between"}
                              gap={"10px"}
                              padding={4}
                            >
                              <Avatarr
                                username={
                                  session.authUser === authUsername
                                    ? session.frusername
                                    : session.authUser
                                }
                                isMobile={isMobile}
                              />
                              <Text
                                fontWeight="500"
                                fontSize={isMobile ? 14 : "15px"}
                              >
                                {session.authUser === authUsername
                                  ? session.frusername
                                  : session.authUser}
                              </Text>
                            </HStack>
                            <Center height="50px">
                              <Divider
                                orientation="vertical"
                                borderColor={"#C0BDBD"}
                              />
                            </Center>
                            <HStack>
                              {/* <Text fontSize={isMobile && 8}>
                            {session.type === 'buy file' &&
                            session.frusername === authUsername
                              ? 'sell File'
                              : session.type === 'sell file' &&
                                session.frusername !== authUsername
                              ? 'buy File'
                              : session.type}
                          </Text> */}
                              <SessionButton
                                session={session}
                                isMobile={false}
                                authUsername={authUsername}
                              />
                            </HStack>
                          </HStack>
                        </Box>
                      </HStack>
                    )
                )
              ) : (
                <Text>No Current Sessions</Text>
              )}

              {/* Requests */}
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                textDecor={"underline"}
                mt={"30px"}
                mb={"20px"}
              >
                Requests
                <IconButton
                  aria-label="Toggle Requests"
                  icon={
                    isRequestSessionsVisible ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )
                  }
                  onClick={() =>
                    setIsRequestSessionsVisible(!isRequestSessionsVisible)
                  }
                  variant="ghost"
                  _hover={{ bg: "none", cursor: "default" }}
                />
              </Text>

              {isRequestSessionsVisible && (
                <>
                  {requestSessions.length > 0 ? (
                    requestSessions.map((session) => (
                      <HStack
                        key={session.id}
                        width="100%"
                        justifyContent={"center"}
                      >
                        <Box
                          border="1px"
                          borderRadius={isMobile ? 10 : "6px"}
                          borderColor={"#C0BDBD"}
                          width={"100%"} // Set the width to 1486px
                          // height={'228px'} // Set the height to 228px
                          mb={2}
                          backgroundColor={
                            session.status === "completed"
                              ? "gray.300"
                              : "#B8A7A71F"
                          }
                        >
                          <HStack justifyContent={"space-evenly"}>
                            <HStack
                              justifyContent={"space-between"}
                              gap={"10px"}
                              padding={4}
                            >
                              <Avatarr
                                username={
                                  session.authUser === authUsername
                                    ? session.frusername
                                    : session.authUser
                                }
                                isMobile={isMobile}
                              />
                              <Text
                                fontWeight="500"
                                fontSize={isMobile ? 10 : "15px"}
                              >
                                {session.authUser === authUsername
                                  ? session.frusername
                                  : session.authUser}
                              </Text>
                            </HStack>
                            <Center
                              height="50px"
                              width="100%"
                              justifyContent="center"
                            >
                              <Divider
                                orientation="vertical"
                                borderColor={"#C0BDBD"}
                              />
                            </Center>

                            <HStack>
                              <SessionButton
                                session={session}
                                isMobile={false}
                                authUsername={authUsername}
                              />
                            </HStack>
                          </HStack>
                        </Box>
                      </HStack>
                    ))
                  ) : (
                    <Text>No Requests</Text>
                  )}
                </>
              )}

              {/* Previous Sessions */}
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                textDecor={"underline"}
                mt={"30px"}
                mb={"20px"}
              >
                Previous Sessions
                <IconButton
                  aria-label="Toggle Previous Sessions"
                  icon={
                    isPreviousSessionsVisible ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )
                  }
                  onClick={() =>
                    setIsPreviousSessionsVisible(!isPreviousSessionsVisible)
                  }
                  variant="ghost"
                  _hover={{ bg: "none", cursor: "default" }}
                />
              </Text>

              {isPreviousSessionsVisible && (
                <>
                  {previousSessions.length > 0 ? (
                    previousSessions.map((session) => (
                      <HStack
                        key={session.id}
                        width="100%"
                        justifyContent={"center"}
                      >
                        <Box
                          border="1px"
                          borderRadius={isMobile ? 10 : "6px"}
                          borderColor={"#C0BDBD"}
                          width={"100%"}
                          mb={2}
                          backgroundColor={"#D3D3D3"} // Update this color to match the one in your image
                        >
                          <HStack justifyContent={"space-evenly"}>
                            <HStack
                              justifyContent={"space-between"}
                              gap={"10px"}
                              padding={4}
                            >
                              <Avatarr
                                username={
                                  session.frusername === authUsername
                                    ? session.authUser
                                    : session.frusername
                                }
                                isMobile={isMobile}
                              />
                              <Text
                                fontWeight="500"
                                fontSize={isMobile ? 10 : "15px"}
                              >
                                {session.frusername === authUsername
                                  ? session.authUser
                                  : session.frusername}
                              </Text>
                            </HStack>
                            <Center height="50px">
                              <Divider
                                orientation="vertical"
                                borderColor={"#C0BDBD"}
                              />
                            </Center>
                            <HStack>
                              {/* <Text fontSize={isMobile && 10}>
                                {session.price && '$'}
                                {session.price}
                              </Text>
                              <Text fontSize={isMobile && 8}>
                                {session.type}
                              </Text> */}
                              <SessionButton
                                session={session}
                                isMobile={false}
                                authUsername={authUsername}
                              />
                            </HStack>
                          </HStack>
                        </Box>
                      </HStack>
                    ))
                  ) : (
                    <Text>No Previous Sessions</Text>
                  )}
                </>
              )}
            </VStack>
          </Box>
        )}
        {/* Render ConnectUserModal */}
        <ConnectUserModal
          isOpen={isConnectModalOpen}
          onClose={handleConnectModalClose}
          onConnect={() => {
            // Handle connection logic
            handleConnectModalClose();
          }}
          authUsername={authUsername}
        />
        {address && sessionFetched?.id !== 0 && sessionFetched?.frusername && (
          <VStack justifyContent="center" ml={"auto"} right={0}>
            <Box width="100vw" overflowX="hidden" p={0}>
              {!isMobile ? (
                <Stepper
                  index={activeStep % 4} // Active step within the current set of 4 steps
                  orientation="horizontal"
                  width="100%"
                >
                  {displayedSteps.map((step, index) => (
                    <React.Fragment key={index}>
                      {/* Progress bar before the first step */}
                      {index === 0 && (
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                            marginRight: -10,
                            backgroundColor:
                              activeStep >=
                              parseInt(activeStep - (activeStep % 4))
                                ? "#6899FE"
                                : "gray",
                            borderRadius: 10,
                          }}
                        />
                      )}

                      {/* Step Indicator */}
                      <Step flex="1">
                        <StepIndicator
                          sx={{
                            "[data-status=complete] &": {
                              backgroundColor: "#6899FE",
                              borderColor: "transparent",
                              height: "50px",
                              width: "50px",
                            },
                            "[data-status=active] &": {
                              backgroundColor:
                                index === 0 && activeStep === 0
                                  ? "gray"
                                  : "#6899FE",
                              borderColor: "transparent",
                              height: "50px",
                              width: "50px",
                            },
                            "[data-status=incomplete] &": {
                              backgroundColor: "gray",
                              borderColor: "transparent",
                              height: "50px",
                              width: "50px",
                            },
                          }}
                        >
                          <StepStatus
                            complete={
                              <Text color={"white"} fontSize={20}>
                                {/* {parseInt(
                                activeStep - (activeStep % 4) + index + 1
                              )} */}
                              </Text>
                            }
                            incomplete={
                              <Text color={"white"} fontSize={20}>
                                {/* {parseInt(
                                activeStep - (activeStep % 4) + index + 1
                              )} */}
                              </Text>
                            }
                            active={
                              <Text color={"white"} fontSize={20}>
                                {/* {parseInt(
                                activeStep - (activeStep % 4) + index + 1
                              )} */}
                              </Text>
                            }
                          />
                        </StepIndicator>

                        {/* Show the description if the step is active */}
                        {index === activeStep % 4 && (
                          <Box
                            flexShrink="0"
                            textAlign="start"
                            width="100%"
                            flexWrap={"wrap"}
                          >
                            <StepDescription
                              style={{ marginRight: 10, flexWrap: "wrap" }}
                            >
                              {steps[activeStep].short
                                .split("\n")
                                .map((line, index) => {
                                  // Extract the usernames
                                  const frusername = sessionFetched?.frusername;
                                  const authUser = sessionFetched?.authUser;

                                  // Regular expression to match either username
                                  const usernameRegex = new RegExp(
                                    `(${frusername}|${authUser})`,
                                    "g"
                                  );

                                  // Split the line by the username and wrap the matches in bold
                                  const parts = line
                                    .split(usernameRegex)
                                    .map((part, i) =>
                                      part === frusername ||
                                      part === authUser ? (
                                        <Text
                                          key={i}
                                          as="span"
                                          fontWeight="bold"
                                        >
                                          {part}
                                        </Text>
                                      ) : (
                                        part
                                      )
                                    );

                                  return (
                                    <React.Fragment key={index}>
                                      {parts}
                                      <br />
                                    </React.Fragment>
                                  );
                                })}
                            </StepDescription>
                          </Box>
                        )}
                      </Step>

                      {/* Progress bar between steps */}
                      {index <= 3 && (
                        <div
                          style={{
                            width: index === 3 ? "10%" : "40%",
                            height: "20px",
                            marginLeft: -10,
                            marginRight: -10,
                            display:
                              activeStep === steps.length - 1 &&
                              parseInt(activeStep % 4) === index &&
                              "none",
                            backgroundColor:
                              activeStep === steps.length - 1 &&
                              parseInt(activeStep % 4) === index
                                ? "#6899FE"
                                : activeStep >=
                                  parseInt(
                                    activeStep - (activeStep % 4) + index + 1
                                  )
                                ? "#6899FE"
                                : "gray",
                            borderRadius: 10,
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Stepper>
              ) : (
                <StepperMobile
                  activeStep={activeStep}
                  steps={steps}
                  sessionFetched={sessionFetched}
                  authUsername={authUsername}
                />
              )}
            </Box>
            {/*<Text fontWeight={'bold'} fontSize={'medium'}>
                {sessionFetched?.status ===
                  'approve exchange type: sell file' &&
                sessionFetched?.authUser === authUsername
                  ? `Waiting for @${sessionFetched?.frusername} approval`
                  : sessionFetched?.status ===
                      'approve exchange type: sell file' &&
                    sessionFetched?.authUser !== authUsername
                  ? `Approve Selling file to @${sessionFetched?.authUser}`
                  : sessionFetched?.status === 'approve exchange type: buy file'
                  ? `Approve Buying file from @${sessionFetched?.authUser}`
                  : sessionFetched?.status ===
                    `approve buying file from ${frusername}`
                  ? `waiting ${
                      sessionFetched?.frusername === authUsername
                        ? 'for your'
                        : '@' + sessionFetched?.authUser + "'s"
                    } selection`
                  : sessionFetched?.status === 'approve amount' &&
                    sessionFetched?.buyer === address
                  ? `Do You want to approve the amount for the file of $${sessionFetched?.priceExchanged}`
                  : sessionFetched?.status === 'approve amount' &&
                    sessionFetched?.seller === address
                  ? 'Awaiting approving amount'
                  : sessionFetched?.status ===
                    `approve selling file to ${sessionFetched?.frusername}`
                  ? sessionFetched?.frusername === authUsername
                    ? `Awaiting for file uploading from @${sessionFetched?.authUser}`
                    : `waiting ${
                        sessionFetched?.authUser === authUsername
                          ? 'for your'
                          : '@' + sessionFetched?.authUser + "'s"
                      } selection`
                  : sessionFetched?.status === `choose type of exchange` &&
                    sessionFetched?.frusername !== authUsername
                  ? `Select the type of exchange`
                  : sessionFetched?.status === `choose type of exchange` &&
                    sessionFetched?.frusername === authUsername
                  ? `@${sessionFetched?.authUser} is choosing the type of exchange`
                  : sessionFetched?.frusername !== authUsername &&
                    sessionFetched?.status ===
                      `approve file from ${sessionFetched?.authUser}`
                  ? `@${frusername} is approving the file`
                  : sessionFetched?.frusername === authUsername &&
                    sessionFetched?.status ===
                      `approve file from ${sessionFetched?.authUser}`
                  ? `Approve file from @${sessionFetched.authUser}`
                  : sessionFetched?.status === 'file approved' &&
                    sessionFetched?.frusername !== authUsername
                  ? 'Insert the amount'
                  : sessionFetched?.status === 'file approved' &&
                    sessionFetched?.frusername === authUsername
                  ? 'File approved, awaiting for Amount'
                  : sessionFetched?.status === 'withdraw your balance'
                  ? sessionFetched?.buyer === address
                    ? 'Download your file'
                    : `${sessionFetched?.status}`
                  : `${sessionFetched?.status}`}
              </Text>
                    */}
            {(sessionFetched?.status ===
            `approve buying file from ${sessionFetched?.frusername}`
              ? true
              : sessionFetched?.status ===
                `approve selling file to ${sessionFetched?.frusername}`) &&
              steps[activeStep].short.match("Send\nFile") && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      {`Upload the file you want to sell. Please\nnote that once you confirm, you won't\nbe able to make any changes.`}
                    </Text>
                  </HStack>

                  <HStack mt={5} spacing={2}>
                    <FiFileText
                      style={{ pointerEvents: "none" }}
                      size={isMobile ? 25 : 45}
                    />
                    <InputGroup>
                      <Input
                        type="text"
                        htmlFor="file-input"
                        onClick={() => {
                          document.getElementById("file-input").click();
                        }}
                        isReadOnly
                        border={"1px solid black"}
                        fontSize={"14px"}
                        borderColor={"black"}
                        _focus={{ borderColor: "black" }}
                        _hover={{ borderColor: "#6899FE" }}
                        ml={0}
                        backgroundColor={"white"}
                        placeholder="No file selected"
                        value={selectedFile && selectedFile.name}
                        borderRadius="10px 10px 10px 10px"
                        // bg="#f0f0f0"
                      />
                      <Input
                        id="file-input"
                        type="file"
                        display="none" // Hide the original file input
                        onChange={handleFileChange}
                      />
                    </InputGroup>
                  </HStack>
                </VStack>
                // <HStack mt={16} spacing={4}>
                //   <FaFileImport style={{ pointerEvents: 'none' }} size={25} />
                //   <InputGroup>
                //     <InputLeftElement width="auto" pr={2}>
                //       <Button
                //         as="label"
                //         htmlFor="file-input"
                //         bg="white"
                //         fontSize={'14px'}
                //         border={'0.5px solid'}
                //         borderColor={'ButtonShadow'}
                //         color="black"
                //         _hover={{ bg: '#6899FE', color: 'white' }}
                //         borderRadius="0 10px 10px 0"
                //         px={4}
                //         cursor="pointer"
                //       >
                //         {selectedFile ? 'Change File' : 'Select a file'}
                //       </Button>
                //       <Input
                //         id="file-input"
                //         type="file"
                //         display="none" // Hide the original file input
                //         onChange={handleFileChange}
                //       />
                //     </InputLeftElement>
                //     <Input
                //       type="text"
                //       border={'0.5px solid'}
                //       fontSize={'14px'}
                //       borderColor={'ButtonShadow'}
                //       _hover={{ borderColor: 'ButtonShadow' }}
                //       borderLeftWidth={0}
                //       ml={10}
                //       backgroundColor={'white'}
                //       placeholder="No file selected"
                //       value={selectedFile && selectedFile.name}
                //       readOnly
                //       borderRadius="0 10px 10px 0"
                //       // bg="#f0f0f0"
                //       pl={20} // Add padding to make space for the button
                //     />
                //   </InputGroup>
                // </HStack>
              )}
            {/* {sessionFetched?.status ===
              `approve buying file from ${sessionFetched?.authUser}` ||
            (sessionFetched?.status ===
              `approve selling file to ${
                sessionFetched?.frusername !== authUsername &&
                sessionFetched?.frusername
              }` &&
              sessionFetched?.type === 'buy file')
              ? sessionFetched?.authUser !== authUsername
              : sessionFetched?.type === 'sell file'
              ? sessionFetched?.frusername === authUsername
              : sessionFetched?.frusername === authUsername && (
                  <HStack mt={16} spacing={4}>
                    <FaFileImport style={{ pointerEvents: 'none' }} size={25} />
                    <InputGroup>
                      <InputLeftElement width="auto" pr={2}>
                        <Button
                          as="label"
                          htmlFor="file-input"
                          bg="white"
                          fontSize={'14px'}
                          border={'0.5px solid'}
                          borderColor={'ButtonShadow'}
                          color="black"
                          _hover={{ bg: '#6899FE', color: 'white' }}
                          borderRadius="0 10px 10px 0"
                          px={4}
                          cursor="pointer"
                        >
                          {selectedFile ? 'Change File' : 'Select a file'}
                        </Button>
                        <Input
                          id="file-input"
                          type="file"
                          display="none" // Hide the original file input
                          onChange={handleFileChange}
                        />
                      </InputLeftElement>
                      <Input
                        type="text"
                        border={'0.5px solid'}
                        fontSize={'14px'}
                        borderColor={'ButtonShadow'}
                        _hover={{ borderColor: 'ButtonShadow' }}
                        borderLeftWidth={0}
                        ml={10}
                        backgroundColor={'white'}
                        placeholder="No file selected"
                        value={selectedFile && selectedFile.name}
                        readOnly
                        borderRadius="0 10px 10px 0"
                        // bg="#f0f0f0"
                        pl={20} // Add padding to make space for the button
                      />
                    </InputGroup>
                  </HStack>
                )} */}
            {sessionFetched?.fileURL &&
              (sessionFetched ===
                `approve buying file from ${
                  sessionFetched?.frusername === authUsername
                    ? authUsername
                    : sessionFetched?.frusername
                }` ||
                sessionFetched?.status ===
                  `approve selling file to ${
                    sessionFetched?.frusername !== authUsername
                      ? authUsername
                      : sessionFetched?.frusername
                  }`) &&
              sessionFetched?.frusername === authUsername && (
                <HStack mt={16} spacing={4}>
                  <FaFileImport style={{ pointerEvents: "none" }} size={25} />
                  <InputGroup>
                    <InputLeftElement width="auto" pr={2}>
                      <Button
                        as="label"
                        htmlFor="file-input"
                        bg="white"
                        fontSize={"14px"}
                        border={"0.5px solid"}
                        borderColor={"ButtonShadow"}
                        color="black"
                        _hover={{ bg: "#6899FE", color: "white" }}
                        borderRadius="0 10px 10px 0"
                        px={4}
                        cursor="pointer"
                      >
                        {selectedFile ? "Change File" : "Select a file"}
                      </Button>
                      <Input
                        id="file-input"
                        type="file"
                        display="none" // Hide the original file input
                        onChange={handleFileChange}
                      />
                    </InputLeftElement>
                    <Input
                      type="text"
                      border={"0.5px solid"}
                      fontSize={"14px"}
                      borderColor={"ButtonShadow"}
                      _hover={{ borderColor: "ButtonShadow" }}
                      borderLeftWidth={0}
                      ml={10}
                      backgroundColor={"white"}
                      placeholder="No file selected"
                      value={selectedFile && selectedFile.name}
                      readOnly
                      borderRadius="0 10px 10px 0"
                      // bg="#f0f0f0"
                      pl={20} // Add padding to make space for the button
                    />
                  </InputGroup>
                </HStack>
              )}
            {sessionFetched?.status === `file approved` &&
              sessionFetched?.buyer !== address && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      ml={isMobile && "5vw"}
                      textAlign={"center"}
                      mb={3}
                    >
                      {`Enter a price for the file. Please\nnote that you can change the\nprice even after confirming.`}
                    </Text>
                  </HStack>
                  <InputGroup alignItems={"center"} mt={"5%"}>
                    <Text fontWeight={"bold"} fontSize={"14px"}>
                      Receiving
                    </Text>
                    <Text mr={2} ml={3} fontWeight={"bold"} fontSize={"14px"}>
                      $
                    </Text>
                    <Input
                      value={amount}
                      onChange={handleAmountChange}
                      width={isMobile ? "40vw" : "fit-content"}
                      height={isMobile && "5vh"}
                      border={"1px solid black"}
                      fontSize={isMobile ? "14px" : "14px"}
                      borderColor={"black"}
                      _focus={{ borderColor: "black" }}
                      _hover={{ borderColor: "ButtonShadow" }}
                      backgroundColor={"white"}
                      placeholder="($) 100"
                      borderRadius="10px 10px 10px 10px"
                    />
                  </InputGroup>
                </VStack>
              )}
            {/* {sessionFetched?.status === `file approved` &&
              sessionFetched?.buyer == address && (
                <VStack mt={'5%'}>
                  <InputGroup alignItems={'center'}>
                    <Text fontWeight={'bold'} fontSize={'20px'}>
                      Receiving
                    </Text>
                    <Text mr={2} ml={3} fontWeight={'bold'} fontSize={'20px'}>
                      $
                    </Text>
                    <Input
                      // flex="1"
                      // maxWidth={'100%'}
                      // textAlign={'center'}
                      value={amount}
                      onChange={handleAmountChange}
                      // type="text"
                      // placeholder="($)100"
                      border={'0.5px solid'}
                      fontSize={'14px'}
                      borderColor={'ButtonShadow'}
                      _hover={{ borderColor: 'ButtonShadow' }}
                      backgroundColor={'white'}
                      placeholder="($) 100"
                      borderRadius="10px 10px 10px 10px"
                    />
                  </InputGroup>
                </VStack>
              )} */}
            {sessionFetched?.status === "confirm file on blockchain" &&
              sessionFetched?.seller === address && (
                <VStack mt={0}>
                  {renderFilePreview(true, isMobile)}
                  <Button
                    mt={"10%"}
                    width={
                      isMobile
                        ? loading || usdcLoading
                          ? "40vw"
                          : "30vw"
                        : loading || usdcLoading
                        ? "16vw"
                        : "16vw"
                    }
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    fontSize={isMobile && 14}
                    textColor={"white"}
                    p={3}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                    onClick={() => {
                      addFileButton(
                        sessionFetched?.id,
                        sessionFetched?.priceExchanged,
                        sessionFetched?.buyer
                      );
                    }}
                    isLoading={loading}
                    loadingText={
                      loading ? "Loading Contract.." : "Sending File.."
                    }
                  >
                    {errorMessage ? "Try Again" : "Confirm File"}
                  </Button>
                  {errorConfirm && (
                    <Text fontSize={14} width={"100%"} color={"red.400"}>
                      {errorMessage && errorMessage.split("Reason:").length > 0
                        ? "Error: " + errorMessage &&
                          errorMessage.split("Reason:")[1]?.split("╔")[0] +
                            ". Please Try Again"
                        : "Error Try again"}
                    </Text>
                  )}
                </VStack>
              )}
            {sessionFetched?.status === "confirm file received" &&
              sessionFetched?.buyer === address && (
                <VStack justifyContent={"center"} mt={isMobile ? "5%" : "2%"}>
                  <HStack alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      ml={isMobile && "5vw"}
                      textAlign={"center"}
                      mb={3}
                    >
                      {`Confirm the file on the Blockchain\nto exchange it.`}
                    </Text>
                  </HStack>{" "}
                  {renderFilePreview(false, isMobile)}
                  <Button
                    mt={isMobile ? "10%" : 0}
                    width={
                      isMobile
                        ? loading
                          ? "40vw"
                          : "40vw"
                        : loading
                        ? "16vw"
                        : "16vw"
                    }
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    fontSize={isMobile && 14}
                    textColor={"white"}
                    p={3}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                    onClick={() => {
                      confirmButtonCall(sessionFetched?.id);
                    }}
                    isLoading={loading}
                    loadingText={
                      loading ? "Loading Contract.." : "Approving File.."
                    }
                  >
                    <Text>Approve File</Text>
                    <MdOutlineFilePresent size={18} />
                  </Button>
                </VStack>
              )}
            {sessionFetched?.status === "withdraw your balance" &&
              sessionFetched?.seller === address && (
                <VStack justifyContent={"center"} mt={isMobile ? "15%" : "5%"}>
                  <HStack alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      ml={isMobile && "5vw"}
                      textAlign={"center"}
                      mb={3}
                    >
                      {`Withdraw the money from the Blockchain.`}
                    </Text>
                  </HStack>{" "}
                  <Button
                    mt={isMobile ? "10%" : "10%"}
                    width={
                      isMobile
                        ? loading
                          ? "55vw"
                          : "40vw"
                        : loading
                        ? "18vw"
                        : "16vw"
                    }
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    fontSize={isMobile && 14}
                    textColor={"white"}
                    p={3}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                    onClick={() => {
                      withdrawButtonCall();
                    }}
                    isLoading={loading}
                    loadingText={
                      loading
                        ? "Withdrawing Balance.."
                        : "Withdrawing Balance.."
                    }
                  >
                    <Text>
                      {errorMessage ? "Try Again " : "Withdraw Balance"}
                    </Text>
                    {!isMobile && <FaDollarSign size={isMobile ? 0 : 18} />}
                  </Button>
                </VStack>
              )}
            {sessionFetched?.status === "completed" && (
              <VStack justifyContent={"center"} mt={isMobile ? "15%" : "5%"}>
                <HStack alignItems="center" spacing={0}>
                  <Text
                    fontWeight={"bold"}
                    color="black"
                    fontSize={isMobile ? 14 : "x-large"}
                    overflowWrap="anywhere"
                    whiteSpace={"pre-wrap"}
                    ml={isMobile && "5vw"}
                    textAlign={"center"}
                    mb={3}
                  >
                    {`Transfer Complete. Thank you\nfor using PayMu.`}
                  </Text>
                </HStack>{" "}
                <Button
                  mt={isMobile ? "10%" : "10%"}
                  width={isMobile ? "40vw" : "16vw"}
                  height={"fit-content"}
                  backgroundColor={"#6899FE"}
                  borderRadius={50}
                  fontSize={isMobile && 14}
                  textColor={"white"}
                  p={3}
                  flexDirection={"row"}
                  gap={2}
                  _hover={{ backgroundColor: "blue.200" }}
                  onClick={() => {
                    window.location.href = `${PROTECTED}/paymu`;
                  }}
                >
                  <Text>Close Session</Text>
                </Button>
              </VStack>
            )}
            {sessionFetched?.status === "pay on blockchain" &&
              sessionFetched?.buyer === address && (
                <VStack mt={"1%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                      mb={3}
                    >
                      {`Here is a preview of the file. Do you approve this file\nand wish to continue with the exchange? Please\nnote that selecting 'Cancel' will end the session.`}
                    </Text>
                  </HStack>
                  {renderFilePreview(true, isMobile)}

                  <HStack gap={isMobile ? 5 : 10} mb={isMobile && "5%"}>
                    <Button
                      mt={"5%"}
                      width={
                        isMobile
                          ? loading
                            ? "45vw"
                            : "30vw"
                          : loading
                          ? "15vw"
                          : "13vw"
                      }
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      isLoading={loading}
                      loadingText={"Sending Payment.."}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        buyButtonCall(sessionFetched?.id);
                      }}
                    >
                      {errorMessage ? "Try Again" : "Send Payment"}
                    </Button>
                    {!loading && (
                      <Button
                        mt={"5%"}
                        width={
                          isMobile
                            ? loading
                              ? "30vw"
                              : "30vw"
                            : loading
                            ? "15vw"
                            : "13vw"
                        }
                        height={"fit-content"}
                        backgroundColor={"#6899FE"}
                        borderRadius={50}
                        textColor={"white"}
                        fontSize={isMobile && 14}
                        p={3}
                        flexDirection={"row"}
                        gap={2}
                        _hover={{ backgroundColor: "blue.200" }}
                        onClick={() => {
                          handleDeny(
                            sessionFetched?.id,
                            sessionFetched?.authUser
                          );
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </HStack>
                </VStack>
                // <>
                //   {renderFilePreview(true, isMobile)}
                //   <Button
                //     onClick={() => {
                //       buyButtonCall(sessionFetched?.id);
                //     }}
                //     isLoading={buyFileLoading || usdcLoading}
                //   >
                //     Buy File
                //   </Button>
                // </>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername !== authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` &&
              sessionFetched?.status === `awaiting ${frusername} approval` && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.authUser === authUsername
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.authUser === authUsername
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {
                        "has not yet accepted the\nexchange session. Waiting to\nconfirm to begin."
                      }
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status === `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername !== authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={sessionFetched?.authUser}
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {" is choosing the type of exchange"}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {address &&
              !sessionFetched?.confirmedExchange &&
              // sessionFetched?.status ===
              //   `approve file from ${
              //     sessionFetched?.type === 'buy file'
              //       ? sessionFetched?.authUser
              //       : sessionFetched?.type === 'sell file' &&
              //         sessionFetched?.frusername
              //   }` &&
              steps[activeStep].short.match("Approve\nFile") && (
                <VStack mt={"3%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                      mb={3}
                    >
                      {`Here is a preview of the file. Do you approve this file\nand wish to continue with the exchange? Please\nnote that selecting 'Cancel' will end the session.`}
                    </Text>
                  </HStack>
                  {renderFilePreview(true, isMobile)}

                  <HStack gap={isMobile ? 5 : 10}>
                    <Button
                      mt={isMobile ? "0%" : "15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                          fileLink: sessionFetched?.fileURL,
                          buyer: address,
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      mt={isMobile ? "0%" : "15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      textColor={"white"}
                      fontSize={isMobile && 14}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status ===
                `approve file from ${
                  sessionFetched?.type === "buy file"
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }` &&
              steps[activeStep].short.match(
                `Awaiting\n${
                  sessionFetched?.type !== "buy file"
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }`
              ) && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.type === "buy file"
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.type === "buy file"
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {"is currently previewing your uploaded file."}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status ===
                `approve file from ${
                  sessionFetched?.type === "buy file"
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }` &&
              steps[activeStep].short.match(
                `Awaiting\n${
                  sessionFetched?.type === "buy file"
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }`
              ) &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` && (
                <Spinner
                  mt={16}
                  thickness={"4px"}
                  speed="0.65s"
                  emptyColor="transparent"
                  color="blue.500"
                  size="xl"
                />
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername !== authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status ===
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` && (
                <Spinner
                  mt={16}
                  thickness={"4px"}
                  speed="0.65s"
                  emptyColor="transparent"
                  color="blue.500"
                  size="xl"
                />
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status ===
                `approve ${
                  sessionFetched?.type === "buy file" ? "buying" : "selling"
                } file ${sessionFetched?.type === "buy file" ? "from" : "to"} ${
                  sessionFetched?.frusername
                }` &&
              !steps[activeStep].short.match("Send\nFile") && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      {"Waiting for "}
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.type === "buy file"
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.type === "buy file"
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {"to upload file"}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {sessionFetched?.status === `confirm file received` &&
              sessionFetched?.seller === address && (
                <VStack justifyContent={"center"} mt={"10%"}>
                  <Text
                    fontWeight={"bold"}
                    color="black"
                    overflowWrap="anywhere"
                    fontSize={isMobile ? 14 : "x-large"}
                    whiteSpace={"pre-wrap"}
                    textAlign={"center"}
                  >
                    {`Confirming the file on blockchain. Do not\nleave this page`}
                  </Text>
                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.seller === address
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername !== authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status ===
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` && (
                <Spinner
                  mt={16}
                  thickness={"4px"}
                  speed="0.65s"
                  emptyColor="transparent"
                  color="blue.500"
                  size="xl"
                />
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername !== authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status ===
                `approve exchange type: ${sessionFetched?.type}` && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      {"Awaiting "}
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={sessionFetched?.frusername}
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.frusername}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {"approval."}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.authUser === authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` &&
              sessionFetched?.status !== "file approved" &&
              sessionFetched?.status === "approve amount" &&
              !steps[activeStep].short.match("Approve\nAmount") && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.type === "sell file"
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.type === "sell file"
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {" is currently reviewing the price you entered."}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.authUser === authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` &&
              sessionFetched?.status !== "file approved" &&
              sessionFetched?.status === "approve amount" &&
              sessionFetched?.seller === address &&
              sessionFetched?.type === "buy file" && (
                <Spinner
                  mt={16}
                  thickness={"4px"}
                  speed="0.65s"
                  emptyColor="transparent"
                  color="blue.500"
                  size="xl"
                />
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.authUser === authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` &&
              sessionFetched?.status !== "file approved" &&
              sessionFetched?.status !== "approve amount" &&
              sessionFetched?.status === "confirm file on blockchain" &&
              sessionFetched?.buyer === address && (
                <VStack justifyContent={"center"} mt={"10%"}>
                  <Text
                    fontWeight={"bold"}
                    color="black"
                    overflowWrap="anywhere"
                    fontSize={isMobile ? 14 : "x-large"}
                    whiteSpace={"pre-wrap"}
                    textAlign={"center"}
                  >
                    {`Confirming on blockchain. Do not\nleave this page`}
                  </Text>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.authUser === authUsername
                    ? frusername
                    : authUsername
                }` &&
              sessionFetched?.status !==
                `approve selling file to ${
                  sessionFetched?.frusername !== authUsername
                    ? authUsername
                    : frusername
                }` &&
              sessionFetched?.status !== "file approved" &&
              sessionFetched?.status !== "approve amount" &&
              sessionFetched?.status !== "confirm file on blockchain" &&
              sessionFetched?.status === "pay on blockchain" &&
              sessionFetched?.seller === address && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      fontSize={isMobile ? 14 : "x-large"}
                      overflowWrap="anywhere"
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.authUser === authUsername
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.authUser === authUsername
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {
                        "has not yet payed for\nthe file. Waiting for\ntransaction on blockchain."
                      }
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.authUser === authUsername
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {(!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status === `choose type of exchange`) ||
              sessionFetched?.status ===
                `approve selling file to ${
                  sessionFetched?.frusername === authUsername
                    ? frusername
                    : authUsername
                }` ||
              (sessionFetched?.status === "file approved" &&
                sessionFetched?.seller === address &&
                sessionFetched?.type === "sell file" && (
                  <Spinner
                    mt={16}
                    thickness={"4px"}
                    speed="0.65s"
                    emptyColor="transparent"
                    color="blue.500"
                    size="xl"
                  />
                ))}
            {sessionFetched?.status === "file approved" &&
              sessionFetched?.buyer === address && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <HStack width={"100%"} alignItems="center" spacing={0}>
                    <Text
                      fontWeight={"bold"}
                      color="black"
                      overflowWrap="anywhere"
                      fontSize={isMobile ? 14 : "x-large"}
                      whiteSpace={"pre-wrap"}
                      textAlign={"center"}
                    >
                      <Box as="span">
                        <HStack
                          as="span"
                          justifyContent={"space-between"}
                          gap={"10px"}
                          backgroundColor={"rgba(184, 167, 167, 0.12)"}
                          borderRadius={15}
                          p={2}
                          display="inline-flex"
                        >
                          <Avatarr
                            username={
                              sessionFetched?.type === "buy file"
                                ? sessionFetched?.frusername
                                : sessionFetched?.authUser
                            }
                            isMobile={isMobile}
                          />
                          <Text
                            fontWeight="500"
                            fontSize={isMobile ? 10 : "15px"}
                            p={1}
                            backgroundColor={"white"}
                            borderRadius={15}
                            width={"fit-content"}
                            display="inline"
                          >
                            {sessionFetched?.type === "buy file"
                              ? sessionFetched?.frusername
                              : sessionFetched?.authUser}
                          </Text>
                        </HStack>
                      </Box>{" "}
                      {"is entering a price for the file"}
                    </Text>
                  </HStack>

                  <Button
                    mt={"15%"}
                    width={"fit-content"}
                    height={"fit-content"}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    p={5}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                  >
                    <Text
                      fontWeight={200}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      Nudge
                    </Text>
                    <Text
                      fontWeight={"bold"}
                      color="white"
                      fontSize={isMobile && 12}
                      width={"fit-content"}
                      flexDirection={"row"}
                    >
                      @
                      {sessionFetched?.type === "buy file"
                        ? sessionFetched?.frusername
                        : sessionFetched?.authUser}
                    </Text>
                  </Button>
                </VStack>
              )}
            {sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status === `choose type of exchange` && (
                <VStack mt={"5%"} fontSize={14} fontWeight={"bold"}>
                  <Text>Select the type of exchange</Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      style={{
                        width: isMobile ? "50vw" : "24vw",
                        marginTop: "5%",
                        border: "0.5px solid black",
                        fontWeight: 200,
                        height: "fit-content",
                        padding: 10,
                        textAlign: "left",
                        backgroundColor: "transparent",
                        fontSize: "14px",
                      }}
                    >
                      {sessionType === "buy file"
                        ? `Buy file from @${sessionFetched?.frusername}`
                        : (sessionType === "sell file" &&
                            `Sell file to @${sessionFetched?.frusername}`) ||
                          "Choose exchange..."}
                    </MenuButton>
                    <MenuList
                      py={1}
                      style={{
                        width: "24vw",
                        height: "fit-content",
                        border: "0.5px solid black",
                        fontSize: "14px",
                      }}
                    >
                      <MenuItem
                        onClick={() => setSessionType(null)}
                        _focus={!sessionType && { bg: "#D9D9D99E" }}
                        px={"3px"}
                        _hover={{ bg: "#D9D9D99E" }}
                        py={0}
                        style={{
                          width: "100%",
                          fontWeight: 200,
                          backgroundColor:
                            sessionType !== "sell file" &&
                            sessionType !== "buy file" &&
                            "#D9D9D99E",
                        }}
                      >
                        Choose Exchange
                      </MenuItem>
                      <MenuItem
                        onClick={() => setSessionType("buy file")}
                        _hover={{ bg: "#D9D9D99E" }}
                        px={"3px"}
                        py={0}
                        style={{
                          width: "100%",
                          fontWeight: 200,
                          backgroundColor:
                            sessionType === "buy file" && "#D9D9D99E",
                        }}
                      >
                        Buy File from{" @"}
                        {sessionFetched?.frusername === authUsername
                          ? authUsername
                          : sessionFetched?.frusername}
                      </MenuItem>
                      <MenuItem
                        onClick={() => setSessionType("sell file")}
                        _hover={{ bg: "#D9D9D99E" }}
                        px={"3px"}
                        py={0}
                        style={{
                          width: "100%",
                          fontWeight: 200,
                          backgroundColor:
                            sessionType === "sell file" && "#D9D9D99E",
                        }}
                      >
                        Sell File to{" @"}
                        {sessionFetched?.frusername === authUsername
                          ? authUsername
                          : sessionFetched?.frusername}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </VStack>
              )}
            {/* {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              selectedFile !== undefined &&
              sessionFetched?.status ===
                `approve file from ${
                  sessionFetched?.type === 'sell file'
                    ? sessionFetched?.authUser
                    : sessionFetched?.type === 'buy file'
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }` &&
              !selectedFile && (
                <VStack>
                  <Text>
                    {sessionFetched?.status ===
                    `awaiting ${authUsername} approval`
                      ? 'Do you want to approve the connection with the user?'
                      : 'Do You want to approve the exchange ?'}
                  </Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        handleApprove(
                          sessionFetched?.id,
                          sessionFetched?.authUser,
                          sessionFetched?.type
                        );
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      Cancel Session
                    </Button>
                  </HStack>
                </VStack>
              )} */}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status ===
                `awaiting ${authUsername} approval` && (
                <VStack>
                  <Text fontSize={isMobile ? 14 : "x-large"}>
                    {sessionFetched?.status ===
                    `awaiting ${authUsername} approval`
                      ? "Do you want to approve the connection with the user?"
                      : "Do You want to approve the exchange ?"}
                  </Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      Cancel Session
                    </Button>
                  </HStack>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername === authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status ===
                `approve exchange type: ${sessionFetched?.type}` && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <Text
                    fontWeight={"bold"}
                    color="black"
                    fontSize={isMobile ? 14 : "x-large"}
                    overflowWrap="anywhere"
                    whiteSpace={"pre-wrap"}
                    textAlign={"center"}
                  >
                    {`Do you approve to be a\nseller? Please note that\nselecting 'No' will end the\nsession.`}
                  </Text>

                  <HStack gap={10}>
                    <Button
                      mt={"15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                        });
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      mt={"15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      No
                    </Button>
                  </HStack>
                </VStack>
              )}
            {sessionFetched && (
              <HStack
                position={isMobile ? "fixed" : "fixed"}
                bottom={isMobile ? 20 : 0}
                right={0}
                m={isMobile ? 0 : 4} // Adjust margin for mobile and desktop
                mt={"0%"}
                spacing={isMobile ? 2 : 4} // Adjust spacing for mobile and desktop
                width={isMobile ? "100%" : "auto"} // Full width for mobile, auto for desktop
                justifyContent={isMobile ? "center" : "flex-end"} // Center buttons on mobile
                pr={isMobile ? 2 : 0}
                backgroundColor={isMobile ? "transparent" : "transparent"} // Background color for mobile
              >
                {sessionFetched?.status !== "withdraw your balance" &&
                  sessionFetched.status !== "completed" && (
                    <Button
                      backgroundColor={"gray"}
                      color={"white"}
                      fontSize={isMobile ? 12 : 16}
                      onClick={() => setReportModalOpen(true)}
                      _hover={{ backgroundColor: "orange.300" }}
                      width={isMobile ? "35%" : "auto"} // Button width for mobile
                    >
                      Dispute a Problem
                    </Button>
                  )}
                {/* Report Modal */}
                <DisputeModal
                  isReportModalOpen={isReportModalOpen}
                  setReportModalOpen={setReportModalOpen}
                  handleSubmitReport={handleSubmitDispute}
                />
                {sessionFetched?.status !== "completed" &&
                  sessionFetched?.status !== "confirm file received" &&
                  sessionFetched?.status !== "withdraw your balance" && (
                    <Button
                      backgroundColor={"gray"}
                      color={"white"}
                      fontSize={isMobile ? 12 : 16}
                      _hover={{ backgroundColor: "red.300" }}
                      width={isMobile ? "35%" : "auto"} // Button width for mobile
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      Cancel Session
                    </Button>
                  )}
              </HStack>
            )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              // amount !== '' &&
              sessionFetched?.status === `file approved` &&
              sessionFetched?.buyer !== address && (
                <VStack mt={"5%"}>
                  <HStack gap={10}>
                    <Button
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      isDisabled={amount ? false : true}
                      onClick={() => {
                        if (amount) {
                          handleApprove({
                            session: sessionFetched,
                            sessionId: sessionFetched?.id,
                            sessionType: sessionFetched?.type,
                            fileLink: sessionFetched?.fileURL,
                            buyer: sessionFetched?.buyer,
                            amount: amount,
                            seller: address,
                          });
                        } else {
                          console.log("Dispute");
                        }
                      }}
                    >
                      Approve
                    </Button>
                  </HStack>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              selectedFile &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername === authUsername
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }` &&
              sessionFetched?.status !== "file approved" &&
              sessionFetched?.status !== "approve amount" &&
              sessionFetched?.status !== "confirm file on blockchain" &&
              sessionFetched?.status !== "pay on blockchain" &&
              sessionFetched?.status !== "confirm file received" &&
              sessionFetched?.status !== "withdraw your balance" && (
                <VStack mt={"5%"}>
                  <Button
                    height={"fit-content"}
                    py={2}
                    backgroundColor={"#6899FE"}
                    borderRadius={50}
                    width={isMobile ? "20vw" : "10vw"}
                    flexDirection={"row"}
                    gap={2}
                    _hover={{ backgroundColor: "blue.200" }}
                    textColor={"white"}
                    fontSize={isMobile && 14}
                    isLoading={fileLink ? false : true}
                    onClick={() => {
                      if (fileLink) {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                          fileLink: fileLink,
                        });
                      }
                    }}
                  >
                    Confirm
                  </Button>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status !==
                `approve file from ${
                  sessionFetched?.frusername === authUsername
                    ? sessionFetched?.frusername
                    : sessionFetched?.authUser
                }` &&
              sessionFetched?.status === "approve amount" &&
              steps[activeStep].short.match("Approve\nAmount") && (
                <VStack justifyContent={"center"} mt={"5%"}>
                  <Text
                    fontWeight={"bold"}
                    color="black"
                    overflowWrap="anywhere"
                    fontSize={isMobile ? 14 : "x-large"}
                    whiteSpace={"pre-wrap"}
                    textAlign={"center"}
                  >
                    {`Do you want to approve the $${sessionFetched?.priceExchanged}\namount for the file? Please note\nthat this cannot be changed here.`}
                  </Text>

                  <HStack gap={isMobile ? 5 : 10}>
                    <Button
                      mt={"15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      fontSize={isMobile && 14}
                      textColor={"white"}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                          fileLink: sessionFetched?.fileURL,
                          buyer: sessionFetched?.buyer,
                          amount: sessionFetched?.priceExchanged,
                          seller: sessionFetched?.seller,
                          approveAmount: true,
                        });
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      mt={"15%"}
                      width={isMobile ? "20vw" : "10vw"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      textColor={"white"}
                      fontSize={isMobile && 14}
                      p={3}
                      flexDirection={"row"}
                      gap={2}
                      _hover={{ backgroundColor: "blue.200" }}
                      onClick={() => {
                        handleDeny(
                          sessionFetched?.id,
                          sessionFetched?.authUser
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
                // <VStack mt={'5%'}>
                //   <Text>{`Do You want to approve the amount for the file of $${sessionFetched?.priceExchanged}?`}</Text>

                //   <HStack gap={10}>
                //     <Button
                //       height={'fit-content'}
                //       width={'fit-content'}
                //       backgroundColor="#6899FE"
                //       color="white"
                //       fontSize={'16px'}
                //       fontWeight={'400'}
                //       padding={'7px 7px'}
                //       onClick={() => {
                //         handleApprove({
                //           session: sessionFetched,
                //           sessionId: sessionFetched?.id,
                //           sessionType: sessionFetched?.type,
                //           fileLink: sessionFetched?.fileURL,
                //           buyer: sessionFetched?.buyer,
                //           amount: sessionFetched?.priceExchanged,
                //           seller: sessionFetched?.seller,
                //           approveAmount: true,
                //         });
                //       }}
                //     >
                //       Approve
                //     </Button>
                //   </HStack>
                // </VStack>
              )}
            {/* {!sessionFetched?.confirmedExchange &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status !== `approve file` &&
              sessionFetched?.status === 'approve amount' &&
              sessionFetched?.buyer === address &&
              steps[activeStep].short.match('Approve\nAmount') && (
                <VStack mt={'5%'}>
                  <Text>
                    {sessionFetched?.status ===
                    `awaiting ${authUsername} approval`
                      ? 'Do you want to approve the connection with the user?'
                      : `Do You want to approve the amount for the file of $${sessionFetched?.priceExchanged}?`}
                  </Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        handleApprove({
                          session: sessionFetched,
                          sessionId: sessionFetched?.id,
                          sessionType: sessionFetched?.type,
                          fileLink: sessionFetched?.fileURL,
                          buyer: sessionFetched?.buyer,
                          amount: sessionFetched?.priceExchanged,
                          seller: sessionFetched?.seller,
                          approveAmount: true,
                        });
                      }}
                    >
                      Approve
                    </Button>
                  </HStack>
                </VStack>
              )} */}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status === `choose type of exchange` &&
              // sessionType &&
              !selectedFile &&
              sessionFetched?.status !== `approve file` && (
                <VStack mt={"5%"}>
                  <HStack gap={10}>
                    <Button
                      mt={"15%"}
                      width={"fit-content"}
                      height={"fit-content"}
                      backgroundColor={"#6899FE"}
                      borderRadius={50}
                      p={3}
                      pr={10}
                      pl={10}
                      flexDirection={"row"}
                      gap={2}
                      color={"white"}
                      _hover={{ backgroundColor: "blue.200" }}
                      disabled={!sessionType ? true : false}
                      isDisabled={!sessionType ? true : false}
                      onClick={() => {
                        if (sessionType) {
                          console.log("Approve");
                          handleApprove({
                            session: sessionFetched,
                            sessionId: sessionFetched?.id,
                            sessionType: sessionType,
                          });
                        }
                      }}
                    >
                      {sessionType ? "Confirm" : "Select a type"}
                    </Button>
                  </HStack>
                </VStack>
              )}
            {!sessionFetched?.confirmedExchange &&
              sessionFetched?.frusername !== authUsername &&
              sessionFetched?.status !== `choose type of exchange` &&
              sessionFetched?.status ===
                `approve selling file to ${authUsername}` && (
                <Text mt={5}>Awaiting user confirmation</Text>
              )}
          </VStack>
        )}

        {/*<VStack spacing={1} align="center">*/}
        {/* <Flex justifyContent="center" width="100%">
          <HStack spacing={isMobile ? 0 : "200px"} mt={20} align="center">
            <Box>
              <InputGroup>
                <InputLeftElement>
                  <Avatar src={user?.avatar || ""} size="sm" />
                </InputLeftElement>
                <Input
                  flex="1"
                  maxWidth={inputWidth}
                  placeholder={"@" + (user?.username || "alelentini")}
                />
                <Checkbox colorScheme="green" disabled defaultChecked ml={2} />
              </InputGroup>
              
              <Stack direction={"column"} align={"center"}>
                <Stack direction={"row"} mt={10} mb={3}>
                  <Text>File</Text>
                  <Switch size="lg" onChange={handleSwitchChange} />
                  <Text>Pay</Text>
                  <Checkbox colorScheme="green" disabled defaultChecked />
                  <Checkbox
                    colorScheme="green"
                    disabled
                    defaultChecked
                    ml={2}
                  />
                </Stack>
                {!showAdditionalInputGroup ? (
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaFileAudio />}
                    />
                    <input
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Text
                      ml={8}
                      style={{
                        border: "1px solid #ccc",
                        padding: "5px 10px",
                        flex: 1,
                        marginRight: "4px",
                      }}
                    >
                      {selectedFile ? selectedFile.name : "Musicom Bit"}
                    </Text>
                    <Checkbox colorScheme="green" disabled defaultChecked />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Input
                      flex="1"
                      maxWidth={inputWidth}
                      type="text"
                      placeholder="(£)250.00"
                      mb={!showAdditionalInputGroup2 ? "3" : ""}
                    />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                  </InputGroup>
                )}
              </Stack>
              {!showAdditionalInputGroup ? (
                <Button ml={8}>Play Trailer</Button>
              ) : (
                <Box h={19} />
              )}
              <Text mt={8}>Recieved</Text>
              {!showAdditionalInputGroup2 ? (
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaFileAudio />}
                  />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Text
                    ml={8}
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      flex: 1,
                      marginRight: "4px",
                    }}
                  >
                    {selectedFile ? selectedFile.name : "Musicom Bit"}
                  </Text>
                  <Button color="#1041B2">Save/Download</Button>
                </InputGroup>
              ) : (
                <InputGroup>
                  <Input
                    flex="1"
                    maxWidth={inputWidth}
                    type="text"
                    placeholder="(£)237.50"
                  />
                </InputGroup>
              )}
            </Box>
            <Box>
              <InputGroup>
                <InputLeftElement>
                  <Avatar src={user?.avatar || ""} size="sm" />
                </InputLeftElement>
                <Input
                  flex="1"
                  maxWidth={inputWidth}
                  placeholder={"@musicom"}
                />
                <Checkbox colorScheme="green" disabled defaultChecked ml={2} />
              </InputGroup>
              
              <Stack direction={"column"} align={"center"}>
                <Stack direction={"row"} mt={10} mb={3}>
                  <Text>File</Text>
                  <Switch size="lg" onChange={handleSwitchChange2} />
                  <Text>Pay</Text>
                  <Checkbox colorScheme="green" disabled defaultChecked />
                  <Checkbox
                    colorScheme="green"
                    disabled
                    defaultChecked
                    ml={2}
                  />
                </Stack>
                {!showAdditionalInputGroup2 ? (
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FaFileAudio />}
                    />
                    <input
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Text
                      ml={8}
                      style={{
                        border: "1px solid #ccc",
                        padding: "5px 10px",
                        flex: 1,
                        marginRight: "4px",
                      }}
                    >
                      {selectedFile ? selectedFile.name : "Musicom Bit"}
                    </Text>
                    <Checkbox colorScheme="green" disabled defaultChecked />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Input
                      flex="1"
                      maxWidth={inputWidth}
                      type="text"
                      placeholder="(£)250.00"
                      mb={!showAdditionalInputGroup ? "3" : ""}
                    />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                    <Checkbox
                      colorScheme="green"
                      disabled
                      defaultChecked
                      ml={2}
                    />
                  </InputGroup>
                )}
              </Stack>
              {!showAdditionalInputGroup2 ? (
                <Button ml={8}>Play Trailer</Button>
              ) : (
                <Box h={19} />
              )}
              <Text mt={8}>Recieved</Text>
              {!showAdditionalInputGroup ? (
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaFileAudio />}
                  />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Text
                    ml={8}
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      flex: 1,
                      marginRight: "4px",
                    }}
                  >
                    {selectedFile ? selectedFile.name : "Musicom Bit"}
                  </Text>
                  <Button color="#1041B2">Save/Download</Button>
                </InputGroup>
              ) : (
                <InputGroup>
                  <Input
                    flex="1"
                    maxWidth={inputWidth}
                    type="text"
                    placeholder="(£)237.50"
                  />
                </InputGroup>
              )}
            </Box>
          </HStack>
        </Flex>
        <Box mt={10} align="center">
          <Button mt={8} variant="outline">
            Dispute
          </Button>
          <Text>or</Text>
          <Button variant="outline">Close</Button>
        </Box> */}
        {!address && (
          // <ConnectWallet
          //   theme={colorMode}
          //   btnTitle={'Connect to PayMu'}
          //   welcomeScreen={{
          //     img: {
          //       src: logoM,
          //       width: 150,
          //       height: 150,
          //     },
          //   }}
          //   detailsButton={{
          //     displayBalanceToken: {
          //       ['137']: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
          //     },
          //   }}
          //   termsOfServiceUrl={`${PROTECTED}/termsandconditions`}
          //   privacyPolicyUrl={`${PROTECTED}/privatypolicy`}
          //   client={client}
          // />
          <>
            <Button
              className="btn"
              onClick={handleConnect}
              style={{
                background: colorMode === "light" ? "#1c1a1c" : "white",
                marginTop: 10,
              }}
            >
              <svg
                class="sparkle"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                fill={colorMode === "light" ? "white" : "black"}
                width="24"
                height="24"
              >
                <path
                  clip-rule="evenodd"
                  d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                  fill-rule="evenodd"
                ></path>
                <path
                  clip-rule="evenodd"
                  d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                  fill-rule="evenodd"
                ></path>
              </svg>

              <Text
                className="text"
                style={{ color: colorMode === "light" ? "white" : "black" }}
              >
                Connect
              </Text>
            </Button>
            <Box mt={"50px"} width={"100%"}>
              <VStack>
                {/* Header for Current Sessions */}
                <SkeletonText
                  noOfLines={1}
                  width="30%"
                  startColor="gray.800"
                  endColor="gray.300"
                  skeletonHeight="10px"
                  mb={"20px"}
                />

                {/* Skeleton placeholders for 1 sessions */}
                {[0].map((_, index) => (
                  // <Skeleton key={index} height="50px" width="100%" mb={2} />
                  <Box
                    padding="4"
                    boxShadow="lg"
                    bg="white"
                    width={"30vw"}
                    mb={"15%"}
                  >
                    <HStack justifyContent={"space-evenly"}>
                      <HStack
                        justifyContent={"space-between"}
                        gap={"10px"}
                        width={"100%"}
                      >
                        <SkeletonCircle size="8" />
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                      <Center height="50px">
                        <Divider
                          orientation="vertical"
                          borderColor={"#C0BDBD"}
                        />
                      </Center>
                      <HStack width={"100%"}>
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                    </HStack>
                  </Box>
                ))}

                {/* Requests */}
                {/* <Text
                  fontSize={'16px'}
                  fontWeight={'600'}
                  textDecor={'underline'}
                  mt={'30px'}
                  mb={'20px'}
                >
                  Requests
                </Text> */}
                <SkeletonText
                  noOfLines={1}
                  width="30%"
                  startColor="gray.800"
                  endColor="gray.300"
                  skeletonHeight="10px"
                  mb={"20px"}
                />

                {/* Skeleton placeholders for 1 sessions */}
                {[0].map((_, index) => (
                  // <Skeleton key={index} height="50px" width="100%" mb={2} />
                  <Box
                    padding="4"
                    boxShadow="lg"
                    bg="white"
                    width={"30vw"}
                    mb={"15%"}
                  >
                    <HStack justifyContent={"space-evenly"}>
                      <HStack
                        justifyContent={"space-between"}
                        gap={"10px"}
                        width={"100%"}
                      >
                        <SkeletonCircle size="8" />
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                      <Center height="50px">
                        <Divider
                          orientation="vertical"
                          borderColor={"#C0BDBD"}
                        />
                      </Center>
                      <HStack width={"100%"}>
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                    </HStack>
                  </Box>
                ))}

                {/* Previous Sessions */}
                {/* <Text
                  fontSize={'16px'}
                  fontWeight={'600'}
                  textDecor={'underline'}
                  mt={'30px'}
                  mb={'20px'}
                >
                  Previous Sessions
                </Text> */}
                <SkeletonText
                  noOfLines={1}
                  width="30%"
                  startColor="gray.800"
                  endColor="gray.300"
                  skeletonHeight="10px"
                  mb={"20px"}
                />
                {/* Skeleton placeholders for 1 sessions */}
                {[0].map((_, index) => (
                  // <Skeleton key={index} height="50px" width="100%" mb={2} />
                  <Box padding="4" boxShadow="lg" bg="white" width={"30vw"}>
                    <HStack justifyContent={"space-evenly"}>
                      <HStack
                        justifyContent={"space-between"}
                        gap={"10px"}
                        width={"100%"}
                      >
                        <SkeletonCircle size="8" />
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                      <Center height="50px">
                        <Divider
                          orientation="vertical"
                          borderColor={"#C0BDBD"}
                        />
                      </Center>
                      <HStack width={"100%"}>
                        <SkeletonText
                          noOfLines={1}
                          width="100%"
                          skeletonHeight="10px"
                        />
                      </HStack>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Box>
          </>
        )}
        {/*
          {!balanceLoading && (
            <Text>
              {data.name} - {data.displayValue} - {data.decimals} -{" "}
              {data.value.toString()} -{" "}
              {data.value.toString() === utils.parseUnits("200", 6).toString()
                ? "true"
                : "false"}
            </Text>
          )}
          {address && !isLoadinggg && (
            <>
              <Button
                onClick={() => {
                  addFileButton(
                    "10",
                    "0xb7c81e08952F2790D1052E3E1f9cCC0d8f83C9d9"
                  );
                }}
                isLoading={isLoading}
              >
                addFile
              </Button>
              <Button
                onClick={() => {
                  buyButtonCall("3");
                }}
                isLoading={buyFileLoading || usdcLoading}
              >
                buyFile
              </Button>
              <Button
                onClick={() => {
                  confirmButtonCall("3");
                }}
                isLoading={confirmLoading}
              >
                condirm
              </Button>
              
              <Button
                onClick={() => {
                  withdrawButtonCall();
                }}
                isLoading={withdrawLoading}
              >
                Withdraw
              </Button>
              
            </>
          )}
        </VStack> */}
      </VStack>
    </Center>
  );
};

export default PayMu;
