import { useToast } from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "lib/firebase";
import { useEffect, useState } from "react";

export default function useAddComment({ postID, uid }) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addComment(text) {
    setLoading(true);
    const id = uuidv4();
    const date = new Date().toISOString();
    const docRef = doc(db, "comments", id);
    await setDoc(docRef, { text, id, postID, date, uid, likes: [] });

    toast({
      title: "Comment added!",
      status: "success",
      isClosable: true,
      position: "top",
      duration: 5000,
    });
    setLoading(false);
  }

  return { addComment, isLoading };
}

export function useComments(postID) {
  const [comments, setComments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (postID) {
      const q = query(
        collection(db, "comments"),
        where("postID", "==", postID),
        orderBy("date", "desc")
      );

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const commentsData = await Promise.all(snapshot.docs.map(async (doc) => {
          const data = doc.data();
          if (!data.likes) {
            data.likes = []; // Ensure likes is an array
          }

          // Convert date to JavaScript Date object
          data.date = data.date ? new Date(data.date) : null;

          // Fetch replies
          const repliesSnapshot = await getDocs(collection(db, 'comments', doc.id, 'replies'));
          const replies = repliesSnapshot.docs.map(replyDoc => {
            const replyData = replyDoc.data();
            replyData.date = replyData.date ? new Date(replyData.date) : null; // Convert date to JavaScript Date object
            return { id: replyDoc.id, ...replyData };
          });

          return { id: doc.id, ...data, replies };
        }));

        setComments(commentsData);
        setLoading(false);
      }, (error) => {
        setError(error);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [postID]);

  return { comments, isLoading, error };
}

export function useDeleteComment(id) {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function deleteComment() {
    const res = window.confirm("Are you sure you want to delete this comment?");

    if (res) {
      setLoading(true);
      const docRef = doc(db, "comments", id);
      await deleteDoc(docRef);
      toast({
        title: "Comment deleted!",
        status: "info",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      setLoading(false);
    }
  }

  return { deleteComment, isLoading };
}

