import React, { useState, useEffect } from 'react';
import { Box, Spinner, Center } from "@chakra-ui/react";
import Post from "components/post";
import { usePost } from "hooks/posts";
import { useParams } from "react-router-dom";
import NewComment from "./NewComment";
import CommentList from "./CommentList";
import { useAuth } from "hooks/auth"; // Import useAuth to get the user

export default function Comments() {
  const { id } = useParams();
  const { post, isLoading: postLoading } = usePost(id);
  const { user } = useAuth(); // Get the user object from useAuth hook
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (post?.comments) {
      setComments(post.comments);
    }
  }, [post]);

  if (postLoading) return <Center><Spinner size="xl" /></Center>;

  function addReply(commentId, replyText) {
    const newReply = {
      id: Date.now(),
      text: replyText,
      user: user, // Use the user object here
      replies: [],
    };

    const updateComments = (comments) =>
      comments.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, replies: [...comment.replies, newReply] };
        } else if (comment.replies.length > 0) {
          return { ...comment, replies: updateComments(comment.replies) };
        }
        return comment;
      });

    setComments(updateComments(comments));
  }

  return (
    <Box align="center" pt="70">
      {post && <Post post={post} />}
      <NewComment post={post} addReply={addReply} comments={comments} setComments={setComments} />
      <CommentList post={post} addReply={addReply} comments={comments} />
    </Box>
  );
}
