import {
  Flex,
  IconButton,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "hooks/auth";
import { useDeletePost, useToggleLike, useRepostPost } from "hooks/posts";
import { useComments } from "hooks/comments";
import {
  FaRegHeart,
  FaHeart,
  FaComment,
  FaRegComment,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiRepeat } from "react-icons/fi";
import { formatDistanceToNow } from "date-fns";
import { useNotifications } from "hooks/notifications";


export default function Actions({ post }) {
  const { id, likes, uid, reposts, date } = post;
  const { user, isLoading: userLoading } = useAuth();
  const { sendNotification } = useNotifications();

  // Check if the current user has liked the post
  const isLiked = likes.some((like) => like.userId === user?.id);

  const { colorMode } = useColorMode();
  const color = colorMode === "light" ? "#9F9F9F" : "gray.400";

  const config = {
    id,
    uid: user?.id,
  };
  const { toggleLike, isLoading: likeLoading } = useToggleLike(config);
  const { deletePost, isLoading: deletePostLoading } = useDeletePost(id);
  const { comments, isLoading: commentsLoading } = useComments(id);
  const { repostPost, isLoading: repostLoading } = useRepostPost();
  const toast = useToast();

  const handleRepost = async () => {
    try {
      const repostUser = user || {};
      await repostPost(post, repostUser);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async () => {
    try {
      await toggleLike(); // Toggle the like
  
      if (!isLiked) { // Only send a notification if the post was liked (not unliked)

  
        await sendNotification({
          title: "New Like",
          content: `liked your `,
          uid: post.uid,
          from: user.id,
          type: "postLike",
          time: Date.now(),
          postId: id, // Ensure postId is correctly passed
        });
      }
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };
  
  const isOwnPost = user && user.id === uid;

  const totalReposts = reposts?.length || 0;

  return (
    <Flex p="2">
      <Flex alignItems="center">
        <IconButton
          onClick={handleLike}
          isLoading={likeLoading || userLoading}
          size="m"
          colorScheme="blue"
          color="blue.500"
          variant="ghost"
          icon={isLiked ? <FaHeart /> : <FaRegHeart />}
          isRound
        />
        <Text size={"m"} color={color} fontSize={"m"} ml={"1"}>
          {likes.length}
        </Text>
      </Flex>
      {comments && (
        <Flex alignItems="center" ml="3">
          <IconButton
            as={Link}
            to={`/protected/comments/${id}`}
            size="m"
            color="blue.500"
            variant="ghost"
            icon={comments.length === 0 ? <FaRegComment /> : <FaComment />}
            isRound
          />
          <Text size={"m"} color={color} fontSize={"m"} ml={1}>
            {comments.length}
          </Text>
        </Flex>
      )}
      <IconButton
        onClick={handleRepost}
        ml="3"
        isLoading={repostLoading || userLoading}
        size="m"
        colorScheme="blue"
        color="blue.500"
        variant="ghost"
        icon={<FiRepeat />}
        isRound
      />
      {totalReposts >= 0 && (
        <Flex alignItems="center" ml="1">
          <Text size={"m"} fontSize={"m"} color={color}>
            {totalReposts}
          </Text>
        </Flex>
      )}
      <Text fontSize="xs" color={color} mt={1} ml={"auto"}>
        {formatDistanceToNow(date)} ago
      </Text>
    </Flex>
  );
}
