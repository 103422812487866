import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  VStack,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Divider,
  Image,
  InputGroup,
  Input,
  Textarea,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { app, auth, storage, firestore } from "lib/firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  runTransaction,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "lib/firebase";
import BgImage from "./assets/forums-bg.svg";
import logoM from "Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png";
import UpvoteImg from "./assets/upvote.svg";
import DownvoteImg from "./assets/downvote.svg";
import CommentImg from "./assets/comment.svg";
import ShareImg from "./assets/share.svg";
import OptionsImg from "./assets/options.svg";
import { useUser } from "hooks/users";
import { useAuth } from "hooks/auth";
import { PROTECTED } from "lib/routes";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { formatDistanceToNow } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaEllipsisH } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import RenderReplies from "./RenderReplies";
import SortIcon from "./assets/sort.png";
import { subWeeks, subMonths, subYears } from "date-fns";

const ForumPost = () => {
  const { forumId, postId, id } = useParams();
  const { user: authUser } = useAuth();
  const [post, setPost] = useState(null);
  const [forum, setForum] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAvatar, setUserAvatar] = useState(null);
  const [commentAvatars, setCommentAvatars] = useState({});
  const [postUserId, setPostUserId] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [votes, setVotes] = useState(0);
  const [userVote, setUserVote] = useState(null); // Track user's current vote
  const [commentText, setCommentText] = useState("");
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState({});
  const [replyText, setReplyText] = useState({});
  const [showReplyToReplyInput, setShowReplyToReplyInput] = useState({});
  const [replyToReplyText, setReplyToReplyText] = useState({});
  const [showReplies, setShowReplies] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingContent, setEditingContent] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editingType, setEditingType] = useState(null); // 'post', 'comment', or 'reply'
  const [imageUrls, setImageUrls] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPostHidden, setIsPostHidden] = useState(false);
  const [commentsSortBy, setCommentsSortBy] = useState("highest-of-the-week");
  const [sortedComments, setSortedComments] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSortOptionChange = (option) => {
    setCommentsSortBy(option);
  };

  const sortComments = useCallback(
    (commentsToSort) => {
      const now = new Date();
      const oneWeekAgo = subWeeks(now, 1);
      const oneMonthAgo = subMonths(now, 1);
      const oneYearAgo = subYears(now, 1);

      return [...commentsToSort].sort((a, b) => {
        const dateA = a.createdAt.toDate();
        const dateB = b.createdAt.toDate();

        switch (commentsSortBy) {
          case "highest-of-all-time":
            return (b.upvotes || 0) - (a.upvotes || 0);
          case "highest-of-the-year":
            if (dateA >= oneYearAgo && dateB >= oneYearAgo) {
              return (b.upvotes || 0) - (a.upvotes || 0);
            }
            return dateA >= oneYearAgo ? -1 : 1;
          case "highest-of-the-month":
            if (dateA >= oneMonthAgo && dateB >= oneMonthAgo) {
              return (b.upvotes || 0) - (a.upvotes || 0);
            }
            return dateA >= oneMonthAgo ? -1 : 1;
          case "highest-of-the-week":
            if (dateA >= oneWeekAgo && dateB >= oneWeekAgo) {
              return (b.upvotes || 0) - (a.upvotes || 0);
            }
            return dateA >= oneWeekAgo ? -1 : 1;
          case "latest":
          default:
            return dateB.getTime() - dateA.getTime();
        }
      });
    },
    [commentsSortBy]
  );

  useEffect(() => {
    if (comments.length > 0) {
      const sorted = sortComments(comments);
      setSortedComments(sorted);
    }
  }, [comments, commentsSortBy, sortComments]);

  const SortMenu = ({ sortBy, handleSortOptionChange }) => {
    const sortOptions = [
      { value: "latest", label: "Latest" },
      { value: "highest-of-the-week", label: "Highest of the Week" },
      { value: "highest-of-the-month", label: "Highest of the Month" },
      { value: "highest-of-the-year", label: "Highest of the Year" },
      { value: "highest-of-all-time", label: "Highest of All Time" },
    ];

    const getSortByText = () => {
      const option = sortOptions.find((option) => option.value === sortBy);
      return option ? option.label : "Sort By";
    };

    return (
      <Menu>
        <MenuButton
          as={Button}
          aria-label="Options"
          backgroundColor={"white"}
          _hover={{
            transform: "translateY(1px)",
          }}
          _active={{
            background: SortIcon,
          }}
        >
          <HStack>
            <Text
              fontWeight={"400"}
              fontSize={"12.5px"}
              textDecor={"underline"}
              _hover={{ color: "#706d63" }}
              cursor={"pointer"}
            >
              Sort By: {getSortByText()}
            </Text>
            <Image src={SortIcon} />
          </HStack>
        </MenuButton>
        <MenuList
          padding={0}
          border={"0.5px solid black"}
          borderTop={"0px"}
          borderBottom={"0px"}
        >
          {sortOptions.map((option, index) => (
            <MenuItem
              key={option.value}
              justifyContent={"center"}
              border={"0.5px solid black"}
              borderLeft={"0px"}
              borderRight={"0px"}
              borderTopRadius={index === 0 ? "5px" : ""}
              borderBottomRadius={index === sortOptions.length - 1 ? "5px" : ""}
              padding={0}
              fontWeight={"500"}
              onClick={() => handleSortOptionChange(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };

  const togglePostVisibility = () => {
    setIsPostHidden(!isPostHidden);
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postRef = doc(db, "forums", forumId, "posts", postId);
        const postSnap = await getDoc(postRef);
        const forumRef = doc(db, "forums", forumId);
        const forumSnap = await getDoc(forumRef);

        if (postSnap.exists()) {
          setForum(forumSnap.data());
          setPost(postSnap.data());
          setVotes(postSnap.data().upvotes || 0); // Initialize votes
          setImageUrls(postSnap.data().imageUrls || []);

          const userQuery = query(
            collection(db, "users"),
            where("username", "==", postSnap.data().user)
          );
          const userSnap = await getDocs(userQuery);

          if (!userSnap.empty) {
            const userData = userSnap.docs[0].data();
            setUserAvatar(userData.avatar);
            setPostUserId(userData.id);
          }

          const commentsRef = collection(
            db,
            "forums",
            forumId,
            "posts",
            postId,
            "comments"
          );
          const commentsSnap = await getDocs(commentsRef);
          const commentsList = commentsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            replies: [],
          }));

          for (const comment of commentsList) {
            const repliesRef = collection(
              db,
              "forums",
              forumId,
              "posts",
              postId,
              "comments",
              comment.id,
              "replies"
            );

            const repliesSnap = await getDocs(repliesRef);
            comment.replies = repliesSnap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
            }));
          }

          setComments(commentsList);

          const avatars = {};
          for (const comment of commentsList) {
            const userQuery = query(
              collection(db, "users"),
              where("username", "==", comment.user)
            );
            const userSnap = await getDocs(userQuery);

            if (!userSnap.empty) {
              const userData = userSnap.docs[0].data();
              avatars[comment.id] = userData.avatar; // Store the avatar by comment ID
            }
          }
          setCommentAvatars(avatars);
        } else {
          console.log("No such post!");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [forumId, postId]);

  useEffect(() => {
    if (authUser && postUserId) {
      setIsFollowing(authUser.following.includes(postUserId));
    }
  }, [authUser, postUserId]);

  const handleEdit = (content, id, type) => {
    setEditingContent(content);
    setEditingId(id);
    setEditingType(type);
    setIsEditing(true);
    onOpen();
  };

  const handleDelete = async (id, type) => {
    if (!window.confirm("Are you sure you want to delete this?")) return;

    try {
      if (type === "post") {
        await deleteDoc(doc(db, "forums", forumId, "posts", id));
        navigate(`/forums/${forumId}`); // Navigate back to forum page
      } else if (type === "comment") {
        await deleteDoc(
          doc(db, "forums", forumId, "posts", postId, "comments", id)
        );
        setComments(comments.filter((comment) => comment.id !== id));
      } else if (type === "reply") {
        const [commentId, replyId] = id.split("-");
        await deleteDoc(
          doc(
            db,
            "forums",
            forumId,
            "posts",
            postId,
            "comments",
            commentId,
            "replies",
            replyId
          )
        );
        setComments(
          comments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: comment.replies.filter(
                    (reply) => reply.id !== replyId
                  ),
                }
              : comment
          )
        );
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      if (editingType === "post") {
        await updateDoc(doc(db, "forums", forumId, "posts", postId), {
          post: editingContent,
        });
        setPost({ ...post, post: editingContent });
      } else if (editingType === "comment") {
        await updateDoc(
          doc(db, "forums", forumId, "posts", postId, "comments", editingId),
          {
            text: editingContent,
          }
        );
        setComments(
          comments.map((comment) =>
            comment.id === editingId
              ? { ...comment, text: editingContent }
              : comment
          )
        );
      } else if (editingType === "reply") {
        const [commentId, replyId] = editingId.split("-");
        await updateDoc(
          doc(
            db,
            "forums",
            forumId,
            "posts",
            postId,
            "comments",
            commentId,
            "replies",
            replyId
          ),
          {
            text: editingContent,
          }
        );
        setComments(
          comments.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  replies: comment.replies.map((reply) =>
                    reply.id === replyId
                      ? { ...reply, text: editingContent }
                      : reply
                  ),
                }
              : comment
          )
        );
      }
      setIsEditing(false);
      onClose();
    } catch (error) {
      console.error("Error saving edit:", error);
    }
  };

  const handleDownvote = async () => {
    if (!authUser) return; // Ensure user is logged in

    const postDocRef = doc(db, "forums", forumId, "posts", postId);
    const userVoteDocRef = doc(db, "userVotes", `${authUser.id}_${postId}`);

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!postDoc.exists()) {
          throw new Error("Post does not exist!");
        }

        const currentVotes = postDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "downvote";

        if (!userVoteDoc.exists()) {
          // New downvote
          newVotes = currentVotes - 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "downvote") {
            // Remove downvote
            newVotes = currentVotes + 1;
            newUserVote = null;
          } else if (currentUserVote === "upvote") {
            // Change from upvote to downvote
            newVotes = currentVotes - 2;
          }
        }

        transaction.update(postDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setVotes(newVoteData.newVotes);
      setUserVote(newVoteData.newUserVote);
    } catch (error) {
      console.error("Error updating vote: ", error);
    }
  };

  const handleUpvote = async () => {
    if (!authUser) return; // Ensure user is logged in

    const postDocRef = doc(db, "forums", forumId, "posts", postId);
    const userVoteDocRef = doc(db, "userVotes", `${authUser.id}_${postId}`);

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!postDoc.exists()) {
          throw new Error("Post does not exist!");
        }

        const currentVotes = postDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "upvote";

        if (!userVoteDoc.exists()) {
          // New upvote
          newVotes = currentVotes + 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "upvote") {
            // Remove upvote
            newVotes = currentVotes - 1;
            newUserVote = null;
          } else if (currentUserVote === "downvote") {
            // Change from downvote to upvote
            newVotes = currentVotes + 2;
          }
        }

        transaction.update(postDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setVotes(newVoteData.newVotes);
      setUserVote(newVoteData.newUserVote);
    } catch (error) {
      console.error("Error updating vote: ", error);
    }
  };

  const handleCommentSubmit = async () => {
    if (commentText.trim() === "") return;

    try {
      const commentsCollectionRef = collection(
        db,
        "forums",
        forumId,
        "posts",
        postId,
        "comments"
      );

      const newCommentRef = await addDoc(commentsCollectionRef, {
        user: authUser?.username,
        text: commentText,
        createdAt: new Date(),
        upvotes: 0, // Initialize with 0 upvotes
      });

      setComments((prevComments) => [
        ...prevComments,
        {
          id: newCommentRef.id,
          user: authUser?.username,
          avatar: authUser?.avatar,
          text: commentText,
          createdAt: { toDate: () => new Date() },
          upvotes: 0,
        },
      ]);

      setCommentText("");
      setShowCommentInput(false); // Hide comment input after submitting
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  const handleUpvoteComment = async (commentId) => {
    if (!authUser) return; // Ensure user is logged in

    const commentDocRef = doc(
      db,
      "forums",
      forumId,
      "posts",
      postId,
      "comments",
      commentId
    );
    const userVoteDocRef = doc(
      db,
      "userCommentVotes",
      `${authUser.id}_${commentId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const commentDoc = await transaction.get(commentDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!commentDoc.exists()) {
          throw new Error("Comment does not exist!");
        }

        const currentVotes = commentDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "upvote";

        if (!userVoteDoc.exists()) {
          // New upvote
          newVotes = currentVotes + 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "upvote") {
            // Remove upvote
            newVotes = currentVotes - 1;
            newUserVote = null;
          } else if (currentUserVote === "downvote") {
            // Change from downvote to upvote
            newVotes = currentVotes + 2;
          }
        }

        transaction.update(commentDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, upvotes: newVoteData.newVotes }
            : comment
        )
      );
    } catch (error) {
      console.error("Error updating comment vote: ", error);
    }
  };

  const handleDownvoteComment = async (commentId) => {
    if (!authUser) return; // Ensure user is logged in

    const commentDocRef = doc(
      db,
      "forums",
      forumId,
      "posts",
      postId,
      "comments",
      commentId
    );
    const userVoteDocRef = doc(
      db,
      "userCommentVotes",
      `${authUser.id}_${commentId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const commentDoc = await transaction.get(commentDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!commentDoc.exists()) {
          throw new Error("Comment does not exist!");
        }

        const currentVotes = commentDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "downvote";

        if (!userVoteDoc.exists()) {
          // New downvote
          newVotes = currentVotes - 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "downvote") {
            // Remove downvote
            newVotes = currentVotes + 1;
            newUserVote = null;
          } else if (currentUserVote === "upvote") {
            // Change from upvote to downvote
            newVotes = currentVotes - 2;
          }
        }

        transaction.update(commentDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, upvotes: newVoteData.newVotes }
            : comment
        )
      );
    } catch (error) {
      console.error("Error updating comment vote: ", error);
    }
  };

  const joinForum = async () => {
    try {
      const forumDocRef = doc(db, "forums", id);
      const forumDoc = await getDoc(forumDocRef);

      if (forumDoc.exists()) {
        const forumData = forumDoc.data();
        const currentMembers = forumData.members || [];

        // Check if the user is already a member
        if (!currentMembers.includes(auth?.currentUser?.uid)) {
          // Add user's UID to the members array
          currentMembers.push(auth?.currentUser?.uid);

          // Update the members array in the database
          await updateDoc(forumDocRef, { members: currentMembers });

          // Update the local state to reflect the change
          setForum({ ...forumData, members: currentMembers });
        }
      }
    } catch (error) {
      console.error("Error joining forum: ", error);
    }
  };

  const leaveForum = async () => {
    try {
      const forumDocRef = doc(db, "forums", id);
      const forumDoc = await getDoc(forumDocRef);

      if (forumDoc.exists()) {
        const forumData = forumDoc.data();
        const currentMembers = forumData.members || [];

        // Check if the user is a member
        if (currentMembers.includes(auth?.currentUser?.uid)) {
          // Remove user's UID from the members array
          const updatedMembers = currentMembers.filter(
            (memberId) => memberId !== auth?.currentUser?.uid
          );

          // Update the members array in the database
          await updateDoc(forumDocRef, { members: updatedMembers });

          // Update the local state to reflect the change
          setForum({ ...forumData, members: updatedMembers });
        }
      }
    } catch (error) {
      console.error("Error leaving forum: ", error);
    }
  };

  const fetchReplies = async (commentId) => {
    try {
      const repliesCollectionRef = collection(
        db,
        "forums",
        forumId,
        "posts",
        postId,
        "comments",
        commentId,
        "replies"
      );
      const repliesSnap = await getDocs(repliesCollectionRef);
      const repliesList = repliesSnap.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: { toDate: () => data.createdAt.toDate() },
        };
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, replies: repliesList }
            : comment
        )
      );
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  };

  const handleUpvoteReply = async (commentId, replyId) => {
    if (!authUser) return; // Ensure user is logged in

    const replyDocRef = doc(
      db,
      "forums",
      forumId,
      "posts",
      postId,
      "comments",
      commentId,
      "replies",
      replyId
    );
    const userVoteDocRef = doc(
      db,
      "userReplyVotes",
      `${authUser.id}_${replyId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const replyDoc = await transaction.get(replyDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!replyDoc.exists()) {
          throw new Error("Reply does not exist!");
        }

        const currentVotes = replyDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "upvote";

        if (!userVoteDoc.exists()) {
          // New upvote
          newVotes = currentVotes + 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "upvote") {
            // Remove upvote
            newVotes = currentVotes - 1;
            newUserVote = null;
          } else if (currentUserVote === "downvote") {
            // Change from downvote to upvote
            newVotes = currentVotes + 2;
          }
        }

        transaction.update(replyDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === replyId
                  ? { ...reply, upvotes: newVoteData.newVotes }
                  : reply
              ),
            };
          }
          return comment;
        })
      );
    } catch (error) {
      console.error("Error updating reply vote: ", error);
    }
  };

  const handleDownvoteReply = async (commentId, replyId) => {
    if (!authUser) return; // Ensure user is logged in

    const replyDocRef = doc(
      db,
      "forums",
      forumId,
      "posts",
      postId,
      "comments",
      commentId,
      "replies",
      replyId
    );
    const userVoteDocRef = doc(
      db,
      "userReplyVotes",
      `${authUser.id}_${replyId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const replyDoc = await transaction.get(replyDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!replyDoc.exists()) {
          throw new Error("Reply does not exist!");
        }

        const currentVotes = replyDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = "downvote";

        if (!userVoteDoc.exists()) {
          // New downvote
          newVotes = currentVotes - 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === "downvote") {
            // Remove downvote
            newVotes = currentVotes + 1;
            newUserVote = null;
          } else if (currentUserVote === "upvote") {
            // Change from upvote to downvote
            newVotes = currentVotes - 2;
          }
        }

        transaction.update(replyDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === replyId
                  ? { ...reply, upvotes: newVoteData.newVotes }
                  : reply
              ),
            };
          }
          return comment;
        })
      );
    } catch (error) {
      console.error("Error updating reply vote: ", error);
    }
  };

  const handleReplySubmit = async (commentId) => {
    if (replyText[commentId]?.trim() === "") return;

    try {
      const repliesCollectionRef = collection(
        db,
        "forums",
        forumId,
        "posts",
        postId,
        "comments",
        commentId,
        "replies"
      );

      const newReplyData = {
        user: authUser?.username,
        text: replyText[commentId],
        createdAt: new Date(),
        upvotes: 0,
        avatar: authUser?.avatar,
      };

      const newReplyRef = await addDoc(repliesCollectionRef, newReplyData);

      const newReply = {
        id: newReplyRef.id,
        ...newReplyData,
        createdAt: newReplyData.createdAt, // Keep as Date object
      };

      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replies: [...(comment.replies || []), newReply].sort(
                (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
              ),
            };
          }
          return comment;
        })
      );

      setReplyText((prev) => ({ ...prev, [commentId]: "" }));
      setShowReplyInput((prev) => ({ ...prev, [commentId]: false }));

      // Automatically show replies for this comment
      setShowReplies((prev) => ({ ...prev, [commentId]: true }));
    } catch (error) {
      console.error("Error adding reply: ", error);
    }
  };

  const handleReplyToReplySubmit = async (commentId, replyId) => {
    if (replyToReplyText[`${commentId}-${replyId}`]?.trim() === "") return;

    try {
      const repliesCollectionRef = collection(
        db,
        "forums",
        forumId,
        "posts",
        postId,
        "comments",
        commentId,
        "replies"
      );

      const newReplyData = {
        user: authUser?.username,
        text: replyToReplyText[`${commentId}-${replyId}`],
        createdAt: new Date(),
        upvotes: 0,
        avatar: authUser?.avatar,
        parentReplyId: replyId,
      };

      const newReplyRef = await addDoc(repliesCollectionRef, newReplyData);

      const newReply = {
        id: newReplyRef.id,
        ...newReplyData,
        createdAt: newReplyData.createdAt,
      };

      setComments((prevComments) =>
        prevComments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replies: [...comment.replies, newReply].sort(
                (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
              ),
            };
          }
          return comment;
        })
      );

      setReplyToReplyText((prev) => ({
        ...prev,
        [`${commentId}-${replyId}`]: "",
      }));
      setShowReplyToReplyInput((prev) => ({
        ...prev,
        [`${commentId}-${replyId}`]: false,
      }));

      // Ensure the parent comment's replies are shown
      setShowReplies((prev) => ({ ...prev, [commentId]: true }));
    } catch (error) {
      console.error("Error adding reply to reply: ", error);
    }
  };

  // Helper function to add a reply to the nested structure
  const addReplyToNestedStructure = (replies, targetReplyId, newReply) => {
    return replies.map((reply) => {
      if (reply.id === targetReplyId) {
        return {
          ...reply,
          replies: [...(reply.replies || []), newReply],
        };
      } else if (reply.replies && reply.replies.length > 0) {
        return {
          ...reply,
          replies: addReplyToNestedStructure(
            reply.replies,
            targetReplyId,
            newReply
          ),
        };
      }
      return reply;
    });
  };

  const toggleReplies = (commentId) => {
    setShowReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  }, [imageUrls.length]);

  const prevImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  }, [imageUrls.length]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowRight") {
        nextImage();
      } else if (event.key === "ArrowLeft") {
        prevImage();
      }
    },
    [nextImage, prevImage]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  if (loading) {
    return <Center>Loading...</Center>;
  }

  if (!post) {
    return <Center>No post found.</Center>;
  }

  return (
    <Box
      backgroundColor={isMobile ? "none" : `#F1F9FF`}
      minHeight={"100vh"}
      height="fit-content"
      display={"flex"}
      justifyContent={"center"}
      mb={5}
    >
      <VStack
        mt={isMobile ? "5%" : "0"}
        backgroundColor="white"
        width={isMobile ? "100vw" : "750px"}
        minH={"100vh"}
        border={isMobile ? "none" : "1px solid #E2E8F0"}
        borderBottom={"none"}
        borderRadius="md"
      >
        <Box
          minH={"85vh"}
          mt={"25px"}
          borderRadius="md"
          width={isMobile ? "100vw" : "100%"}
          px={isMobile ? "0px" : "5px"}
          mb={5}
        >
          <HStack
            justifyContent={"space-between"}
            padding={"10px"}
            mt={isMobile ? "5px" : "10px"}
          >
            <Text
              color={"#6899FE"}
              fontSize={"20px"}
              fontWeight={"500"}
              as={RouterLink}
              to={`${PROTECTED}/forums/${forum.title}/${forumId}`}
              variant="link"
              _hover={{ textDecoration: "underline" }}
            >
              {forum?.title}
            </Text>
            {forum?.members?.includes(authUser?.id) && (
              <Button
                colorScheme={
                  forum?.members?.includes(auth?.currentUser?.uid)
                    ? "red"
                    : "blue"
                }
                size={isMobile ? "xs" : "sm"}
                mt={0}
                fontWeight={"300"}
                onClick={
                  forum?.members?.includes(auth?.currentUser?.uid)
                    ? leaveForum
                    : joinForum
                }
              >
                {forum?.members?.includes(auth?.currentUser?.uid)
                  ? "Leave Forum"
                  : "Join Forum"}
              </Button>
            )}
          </HStack>
          <Divider mt="2" mb="2" />
          <HStack justifyContent="space-between" padding={"10px"}>
            <HStack mb={isMobile ? "10px" : "5px"}>
              <Avatar
                size={isMobile ? "md" : "lg"}
                src={userAvatar || logoM}
                ml={"5px"}
              />
              <VStack alignItems={"flex-start"} gap={0}>
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  fontWeight="600"
                  color="black"
                  as={RouterLink}
                  to={`${PROTECTED}/profile/${post.user}`}
                  variant="link"
                  _hover={{ textDecoration: "underline" }}
                >
                  {post.user}
                </Text>
                <Text fontSize="10px" color="#9F9F9F" whiteSpace={"nowrap"}>
                  {post.createdAt.toDate() &&
                  !isNaN(new Date(post.createdAt.toDate()).getTime())
                    ? formatDistanceToNow(new Date(post.createdAt.toDate())) +
                      " ago"
                    : "Invalid date"}
                </Text>
              </VStack>
            </HStack>
            <HStack mt={0} justifyContent={"space-around"} gap={0}>
              <Tooltip
                label={isPostHidden ? "Show Post" : "Hide Post"}
                placement="top"
                backgroundColor={"#6899FE"}
                borderRadius={"6px"}
              >
                <IconButton
                  icon={isPostHidden ? <FaEye /> : <FaEyeSlash />}
                  onClick={togglePostVisibility}
                  aria-label={isPostHidden ? "Show post" : "Hide post"}
                  fontSize={"20px"}
                  background="transparent"
                  _hover={{ background: "transparent" }}
                />
              </Tooltip>
              <Menu>
                <MenuButton
                  as={IconButton}
                  background={"white"}
                  backgroundImage={OptionsImg}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={isMobile ? "16px" : "20px"}
                  _hover={{
                    background: "white",
                    backgroundImage: `${OptionsImg}`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  _active={{
                    background: "white",
                    backgroundImage: `${OptionsImg}`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <MenuList
                  border="1px"
                  borderColor="#6899FE87"
                  width="100px"
                  sx={{ minWidth: "100px !important" }}
                  padding={"1px"}
                >
                  {authUser?.username === post?.user && (
                    <MenuItem
                      pl={"35%"}
                      borderBottom={"0.5px solid #EDF7FE"}
                      fontSize={"xs"}
                      onClick={() => handleEdit(post.post, postId, "post")}
                    >
                      Edit
                      <Box as="span" ml="1">
                        <CiEdit />
                      </Box>
                    </MenuItem>
                  )}
                  {authUser?.username === post?.user && (
                    <MenuItem
                      borderBottom={"0.5px solid #EDF7FE"}
                      fontSize={"xs"}
                      pl={"25%"}
                      onClick={() => handleDelete(postId, "post")}
                    >
                      Delete
                      <Box as="span" ml="1">
                        <RiDeleteBin6Line />
                      </Box>
                    </MenuItem>
                  )}

                  {authUser?.username !== post?.user && (
                    <MenuItem pl="25%" fontSize={"xs"}>
                      Report
                      <Box as="span" ml="1">
                        <HiOutlineDocumentReport />
                      </Box>
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </HStack>
          <Text
            m={"5px 0"}
            ml={isMobile ? "15px" : "20px"}
            mt={-2}
            fontSize={isMobile ? "18px" : "22px"}
            fontWeight={"700"}
          >
            {post.postTitle}
          </Text>
          <VStack alignItems={"flex-start"}>
            <Text
              textAlign={"justify"}
              fontSize={isMobile ? "12px" : "14px"}
              fontWeight={"400"}
              mx={isMobile ? "15px" : "20px"}
              lineHeight={"1.6"}
              mb={isMobile ? 2.5 : 5}
            >
              {isPostHidden ? "..." : post.post}
            </Text>
          </VStack>
          <Center>
            {imageUrls.length > 0 && (
              <Box
                position="relative"
                mb={isMobile ? 0 : 3}
                width="100%"
                height={isMobile ? "25vh" : "50vh"}
              >
                <Image
                  src={imageUrls[currentImageIndex]}
                  alt={`Post image ${currentImageIndex + 1}`}
                  objectFit="contain"
                  width="100%"
                  height="100%"
                  backgroundColor={"black"}
                  borderRadius={"6px"}
                />
                {imageUrls.length > 1 && (
                  <Flex
                    justify="space-between"
                    position="absolute"
                    top="50%"
                    width="100%"
                    px={isMobile ? 1 : 1}
                  >
                    <IconButton
                      icon={<ChevronLeftIcon />}
                      backgroundColor={"white"}
                      opacity={0.5}
                      onClick={prevImage}
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Previous image"
                      fontSize={"20px"}
                      size={isMobile ? "xs" : "md"}
                      _hover={{ backgroundColor: "white", opacity: "0.8" }}
                      _active={{
                        backgroundColor: "white",
                        opacity: "0.5",
                      }}
                    />

                    <IconButton
                      icon={<ChevronRightIcon />}
                      backgroundColor={"white"}
                      opacity={0.5}
                      onClick={nextImage}
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Next image"
                      fontSize={"20px"}
                      size={isMobile ? "xs" : "md"}
                      _hover={{ backgroundColor: "white", opacity: "0.8" }}
                      _active={{
                        backgroundColor: "white",
                        opacity: "0.5",
                      }}
                    />
                  </Flex>
                )}
                <Text
                  position="absolute"
                  bottom="1%"
                  left="50%"
                  transform="translateX(-50%)"
                  color="white"
                  bg="rgba(0,0,0,0.5)"
                  px={2}
                  py={1}
                  borderRadius="md"
                  fontSize={isMobile ? "12px" : "16px"}
                >
                  {currentImageIndex + 1} / {imageUrls.length}
                </Text>
              </Box>
            )}
          </Center>
          <Modal isOpen={isImageModalOpen} onClose={closeImageModal} size="3xl">
            <ModalOverlay />
            <ModalContent background={"none"}>
              <ModalCloseButton />
              <ModalBody p={0}>
                <Image
                  src={imageUrls[currentImageIndex]}
                  alt={`Post image ${currentImageIndex + 1}`}
                  objectFit="contain"
                  width="100%"
                  height="500px"
                />
                {imageUrls.length > 1 && (
                  <Flex
                    justify="space-between"
                    position="absolute"
                    top="50%"
                    width="100%"
                    px={4}
                  >
                    <IconButton
                      icon={<ChevronLeftIcon />}
                      onClick={prevImage}
                      variant="ghost"
                      colorScheme="whiteAlpha"
                      aria-label="Previous image"
                      fontSize={"50px"}
                      position={"relative"}
                      right={"75px"}
                    />
                    <IconButton
                      icon={<ChevronRightIcon />}
                      onClick={nextImage}
                      variant="ghost"
                      colorScheme="whiteAlpha"
                      aria-label="Next image"
                      fontSize={"50px"}
                      position={"relative"}
                      left={"75px"}
                    />
                  </Flex>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <HStack spacing="1" ml={"5px"} mb={0}>
            <IconButton
              background={"white"}
              backgroundImage={UpvoteImg}
              backgroundRepeat={"no-repeat"}
              backgroundPosition={"center"}
              backgroundSize={isMobile ? "12px" : "15px"}
              onClick={handleUpvote}
              _hover={{
                backgroundImage: `${UpvoteImg}`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
            <Text fontSize="sm" fontWeight="bold" textAlign="center">
              {votes}
            </Text>
            <IconButton
              background={"white"}
              backgroundImage={DownvoteImg}
              backgroundRepeat={"no-repeat"}
              backgroundPosition={"center"}
              backgroundSize={isMobile ? "12px" : "15px"}
              onClick={handleDownvote}
              _hover={{
                backgroundImage: `${DownvoteImg}`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
            <VStack>
              <Button
                ml={"10px"}
                background={"white"}
                backgroundImage={CommentImg}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={isMobile ? "12px" : "15px"}
                onClick={() => setShowCommentInput((prev) => !prev)}
                _hover={{
                  backgroundImage: `${CommentImg}`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </VStack>
            <Text fontSize={"sm"} cursor="pointer">
              {comments.length}
            </Text>

            <Flex
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              mr={"10px"}
            >
              <IconButton
                background={"white"}
                backgroundImage={ShareImg}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={isMobile ? "16px" : "20px"}
                _hover={{
                  backgroundImage: `${ShareImg}`,
                }}
              />
            </Flex>
          </HStack>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit {editingType}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Textarea
                  value={editingContent}
                  onChange={(e) => setEditingContent(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSaveEdit}>
                  Save
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Divider mt={isMobile ? "0" : "2"} mb="3" />
          <Box ml={isMobile ? "2.5%" : "20px"} mb={3}>
            <SortMenu
              sortBy={commentsSortBy}
              handleSortOptionChange={handleSortOptionChange}
            />
          </Box>
          {showCommentInput && (
            <InputGroup
              mt="0"
              mb="4"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Textarea
                variant="flushed"
                placeholder="Write comment..."
                autoComplete="off"
                resize="vertical"
                size={isMobile ? "xs" : "sm"}
                onChange={(e) => setCommentText(e.target.value)}
                minH="30px"
                width={isMobile ? "65%" : "84%"}
                overflow="hidden"
                transition="height 0.5s"
              />
              <Button
                mt="1"
                ml="3"
                colorScheme="blue"
                size={isMobile ? "xs" : "sm"}
                fontSize={"10px"}
                onClick={handleCommentSubmit}
              >
                Comment
              </Button>
            </InputGroup>
          )}
          <VStack
            alignItems="flex-start"
            spacing="4"
            width="95%"
            ml={"2.5%"}
            mb={1}
          >
            {sortedComments.map((comment) => (
              <Box key={comment.id} p={isMobile ? "0" : "1"} width="100%">
                <Box
                  border={"1px solid #6899FE"}
                  boxShadow={"0px 2px 5px #9F9F9F"}
                  borderRadius={"lg"}
                  p="2"
                  width="100%"
                >
                  <HStack>
                    <Avatar
                      size="sm"
                      src={
                        comment.avatar
                          ? comment.avatar
                          : commentAvatars[comment.id] || logoM
                      }
                    />
                    <VStack alignItems={"start"}>
                      <Button
                        color="black"
                        fontSize={isMobile ? "11px" : "13px"}
                        fontWeight="600"
                        variant="link"
                        as={RouterLink}
                        to={`${PROTECTED}/profile/${comment.user}`}
                      >
                        {comment.user}
                      </Button>
                      <Text
                        fontSize="8px"
                        color="gray.500"
                        mt={-1}
                        whiteSpace={"nowrap"}
                      >
                        {comment.createdAt.toDate() &&
                        !isNaN(new Date(comment.createdAt.toDate()).getTime())
                          ? formatDistanceToNow(
                              new Date(comment.createdAt.toDate())
                            ) + " ago"
                          : "Invalid date"}
                      </Text>
                    </VStack>
                    <Flex justifyContent="flex-end" width="100%">
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          background={"white"}
                          backgroundImage={OptionsImg}
                          backgroundRepeat={"no-repeat"}
                          backgroundPosition={"center"}
                          backgroundSize={isMobile ? "12px" : "16px"}
                          _hover={{
                            background: "white",
                            backgroundImage: `${OptionsImg}`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                          _active={{
                            background: "white",
                            backgroundImage: `${OptionsImg}`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                        <MenuList
                          border="1px"
                          borderColor="#6899FE87"
                          width="100px"
                          sx={{ minWidth: "100px !important" }}
                          padding={"1px"}
                        >
                          {authUser?.username === post?.user && (
                            <MenuItem
                              pl={"35%"}
                              borderBottom={"0.5px solid #EDF7FE"}
                              fontSize={"xs"}
                              onClick={() =>
                                handleEdit(comment.text, comment.id, "comment")
                              }
                            >
                              Edit
                              <Box as="span" ml="1">
                                <CiEdit />
                              </Box>
                            </MenuItem>
                          )}
                          {authUser?.username === post?.user && (
                            <MenuItem
                              borderBottom={"0.5px solid #EDF7FE"}
                              fontSize={"xs"}
                              pl={"25%"}
                              onClick={() =>
                                handleDelete(comment.id, "comment")
                              }
                            >
                              Delete
                              <Box as="span" ml="1">
                                <RiDeleteBin6Line />
                              </Box>
                            </MenuItem>
                          )}

                          {authUser?.username !== post?.user && (
                            <MenuItem pl="25%" fontSize={"xs"}>
                              Report
                              <Box as="span" ml="1">
                                <HiOutlineDocumentReport />
                              </Box>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Flex>
                  </HStack>
                  <Text
                    mt={isMobile ? "0" : "2"}
                    ml={isMobile ? "10px" : "5px"}
                    fontSize={isMobile ? "12px" : "12px"}
                    textAlign={"justify"}
                  >
                    {comment.text}
                  </Text>
                  <HStack spacing="1" ml={"5px"}>
                    <IconButton
                      background={"white"}
                      backgroundImage={UpvoteImg}
                      backgroundRepeat={"no-repeat"}
                      backgroundPosition={"center"}
                      backgroundSize={isMobile ? "12px" : "15px"}
                      onClick={() => handleUpvoteComment(comment.id)}
                      _hover={{
                        backgroundImage: `${UpvoteImg}`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />
                    <Text fontSize="sm" fontWeight="bold" textAlign="center">
                      {comment.upvotes ? comment.upvotes : 0}
                    </Text>
                    <IconButton
                      background={"white"}
                      backgroundImage={DownvoteImg}
                      backgroundRepeat={"no-repeat"}
                      backgroundPosition={"center"}
                      backgroundSize={isMobile ? "12px" : "15px"}
                      onClick={() => handleDownvoteComment(comment.id)}
                      _hover={{
                        backgroundImage: `${DownvoteImg}`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />
                    <VStack>
                      <Button
                        ml={"10px"}
                        background={"white"}
                        backgroundImage={CommentImg}
                        backgroundRepeat={"no-repeat"}
                        backgroundPosition={"center"}
                        backgroundSize={isMobile ? "12px" : "15px"}
                        onClick={() =>
                          setShowReplyInput((prev) => ({
                            ...prev,
                            [comment.id]: !prev[comment.id],
                          }))
                        }
                        _hover={{
                          backgroundImage: `${CommentImg}`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                      />
                    </VStack>
                    <Text fontSize={"sm"} cursor="pointer">
                      {/* {comment.replies?.length || 0} */}
                    </Text>
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      width="100%"
                    >
                      <IconButton
                        background={"white"}
                        backgroundImage={ShareImg}
                        backgroundRepeat={"no-repeat"}
                        backgroundPosition={"center"}
                        backgroundSize={isMobile ? "12px" : "16px"}
                        _hover={{
                          backgroundImage: `${ShareImg}`,
                        }}
                      />
                    </Flex>
                  </HStack>
                </Box>

                {showReplyInput[comment.id] && (
                  <InputGroup
                    mt="2"
                    mb="2"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Textarea
                      variant="flushed"
                      placeholder={`Reply to @${comment.user}...`}
                      autoComplete="off"
                      resize="vertical"
                      value={replyText[comment.id] || ""}
                      onChange={(e) =>
                        setReplyText((prev) => ({
                          ...prev,
                          [comment.id]: e.target.value,
                        }))
                      }
                      size={isMobile ? "xs" : "sm"}
                      minH="30px"
                      width={isMobile ? "65%" : "84%"}
                      overflow="hidden"
                      transition="height 0.5s"
                    />
                    <Button
                      mt="1"
                      ml="3"
                      colorScheme="blue"
                      size={isMobile ? "xs" : "sm"}
                      fontSize={"10px"}
                      onClick={() => handleReplySubmit(comment.id)}
                    >
                      Reply
                    </Button>
                  </InputGroup>
                )}

                {comment.replies && comment.replies.length > 0 && (
                  <>
                    <Button
                      onClick={() => toggleReplies(comment.id)}
                      size="sm"
                      fontSize={isMobile ? "12px" : "12px"}
                      variant="link"
                      mt="2"
                      ml={isMobile ? "4" : "6"}
                      color={"#6899FE"}
                      _active={{
                        color: "#6899FE",
                      }}
                    >
                      {showReplies[comment.id]
                        ? "Hide Replies"
                        : `Show Replies (${comment.replies.length})`}
                    </Button>

                    {showReplies[comment.id] && (
                      <VStack
                        alignItems="flex-start"
                        spacing="1"
                        mx={"auto"}
                        mt="2"
                        width="95%"
                      >
                        {comment.replies
                          .sort(
                            (a, b) =>
                              a.createdAt.getTime() - b.createdAt.getTime()
                          )
                          .map((reply) => (
                            <Box key={reply.id} width="100%">
                              <RenderReplies
                                replies={[reply]}
                                commentId={comment.id}
                                authUser={authUser}
                                isMobile={isMobile}
                                logoM={logoM}
                                PROTECTED={PROTECTED}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleUpvoteReply={handleUpvoteReply}
                                handleDownvoteReply={handleDownvoteReply}
                                showReplyToReplyInput={showReplyToReplyInput}
                                setShowReplyToReplyInput={
                                  setShowReplyToReplyInput
                                }
                                replyToReplyText={replyToReplyText}
                                setReplyToReplyText={setReplyToReplyText}
                                handleReplyToReplySubmit={
                                  handleReplyToReplySubmit
                                }
                                UpvoteImg={UpvoteImg}
                                DownvoteImg={DownvoteImg}
                                CommentImg={CommentImg}
                                ShareImg={ShareImg}
                                OptionsImg={OptionsImg}
                              />
                            </Box>
                          ))}
                      </VStack>
                    )}
                  </>
                )}
              </Box>
            ))}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default ForumPost;
