import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Button,
  Center,
  useToast,
  Checkbox,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAuth } from 'hooks/auth';
import { db } from 'lib/firebase';
import { doc, getDoc, collection, addDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { FiChevronLeft } from 'react-icons/fi';

const CreateGroup = ({ handleClose }) => {
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { user: authUser } = useAuth();
  const [groupName, setGroupName] = useState('');
  const [isError, setIsError] = useState(false);
  const [mutualFollowers, setMutualFollowers] = useState([]);
  const [followerData, setFollowerData] = useState({});
  const [step, setStep] = useState(1); // Step 1: Select friends, Step 2: Enter group name
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const maxGroupMembers = 8;
  const maxGroupNameLength = 20; // Set max group name length to 20
  const isCreateButtonDisabled = selectedFollowers.length === 0 || selectedFollowers.length > maxGroupMembers;
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchMutualFollowers = async () => {
      const authUserDoc = await getDoc(doc(db, 'users', authUser.id));
      const authUserData = authUserDoc.data();

      const mutualFollowersList = authUserData.followers.filter((follower) =>
        authUserData.following.includes(follower)
      );

      setMutualFollowers(mutualFollowersList);
      
      // Fetch data for each follower
      const followersData = {};
      await Promise.all(mutualFollowersList.map(async (followerId) => {
        const followerDoc = await getDoc(doc(db, 'users', followerId));
        if (followerDoc.exists()) {
          followersData[followerId] = followerDoc.data();
        }
      }));
      setFollowerData(followersData);
    };

    if (authUser) {
      fetchMutualFollowers();
    }
  }, [authUser]);

  const handleCreateGroup = async () => {
    if (!isCreateButtonDisabled && groupName.length <= maxGroupNameLength) {
      try {
        setIsLoading(true);

        const groupsCollection = collection(db, 'groups');
        const newGroupDocRef = await addDoc(groupsCollection, {
          groupName: groupName,
          members: [authUser.id, ...selectedFollowers],
          roles: {
            [authUser.id]: 'admin',
          },
          createdAt: Timestamp.now(),
          createdBy: authUser.id,
        });
        const groupID = newGroupDocRef.id;

        await updateDoc(newGroupDocRef, { groupID: groupID });

        const messagesSubcollectionRef = collection(newGroupDocRef, 'messages');
        await addDoc(messagesSubcollectionRef, {
          sentBy: authUser.id,
          message: `${authUser.username} added to '${groupName}' group chat`,
          createdAt: Timestamp.now(),
          type: 'msg',
        });

        const membersToUpdate = [authUser.id, ...selectedFollowers];
        await Promise.all(
          membersToUpdate.map(async (memberID) => {
            const memberDocRef = doc(db, 'users', memberID);
            const memberData = await getDoc(memberDocRef);
            const memberGroups = memberData.data()?.groups || [];
            await updateDoc(memberDocRef, {
              groups: [groupID, ...memberGroups],
            });
          })
        );

        setIsLoading(false);
        toast({
          title: 'Group Created',
          description: `Group '${groupName}' created successfully.`,
          status: 'success',
          isClosable: true,
          position: 'top',
          duration: 5000,
        });
        handleClose();
      } catch (error) {
        console.error('Error creating group:', error);
        setIsLoading(false);
        toast({
          title: 'Failed to create group',
          description: 'An error occurred while creating the group. Please try again.',
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: 5000,
        });
      }
    } else {
      if (!groupName) {
        setIsError(true);
      }
    }
  };

  const handleSelectFollower = (follower) => {
    const isFollowerSelected = selectedFollowers.includes(follower);
    if (isFollowerSelected) {
      setSelectedFollowers(selectedFollowers.filter((selectedFollower) => selectedFollower !== follower));
    } else {
      if (selectedFollowers.length < maxGroupMembers) {
        setSelectedFollowers([...selectedFollowers, follower]);
      } else {
        toast({
          title: 'Group member limit reached',
          description: `You can't add more than ${maxGroupMembers} members to a group.`,
          status: 'warning',
          duration: 1500,
          isClosable: true,
          position: 'top',
        });
      }
    }
  };

  const handleNextStep = () => {
    if (selectedFollowers.length < 2) {
      toast({
        title: 'No members selected',
        description: 'Please select at least 2 followera to create a group chat.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } else {
      setStep(2);
    }
  };

  const handleGroupNameChange = (e) => {
    const value = e.target.value;
    setGroupName(value);
    if (value.length > maxGroupNameLength) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const filteredFollowers = mutualFollowers.filter((follower) => {
    const followerInfo = followerData[follower];
    return followerInfo?.username.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Center>
      <Box width="300px" p={4} boxShadow="md" borderRadius="md" bg="white" border="2px solid #6899FE">
        {isMobile ? (
          <Button
            onClick={handleClose}
            leftIcon={<FiChevronLeft />}
            mb={4}
            variant="outline"
            colorScheme="blue"
          />
        ) : (
          <></>
        )}
        <Text fontSize="xl" mb={4} textAlign="center" color="#6899FE" fontWeight="bold">
          New Group
        </Text>
        {step === 1 ? (
          <>
            <Text color="#6899FE" fontSize="sm" mb={2} textAlign="left">
              Add 8 or fewer friends
            </Text>
            <FormControl mb={4}>
              <Input
                type="text"
                placeholder="Search for a friend.."
                color="black"
                fontSize={12}
                ml={-1}
                mb={2}
                height={"6"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel color="#6899FE">All Friends</FormLabel>
              <Box
                style={{
                  maxHeight: '300px',
                  overflowY: 'scroll',
                  borderRadius: '5px',
                  padding: '8px',
                }}
              >
                {filteredFollowers.map((follower) => (
                  <VStack key={follower} spacing={0}>
                    <Flex align="center" justify="space-between" p={2} width={'100%'}>
                      <Box ml={2} display="flex" alignItems="center">
                        {followerData[follower] && (
                          <>
                            <Avatar src={followerData[follower].avatar} size="sm" mr={2} />
                            <Text>{followerData[follower].username}</Text>
                          </>
                        )}
                      </Box>
                      <Checkbox
                        onChange={() => handleSelectFollower(follower)}
                        isChecked={selectedFollowers.includes(follower)}
                        colorScheme="blue"
                        size="lg"
                      />
                    </Flex>
                  </VStack>
                ))}
              </Box>
            </FormControl>
            <Button
              width="30%"
              onClick={handleNextStep}
              colorScheme="blue"
            >
              Next
            </Button>
          </>
        ) : (
          <>
            <FormControl mb={4}>
              <FormLabel>Selected Contacts</FormLabel>
              <Flex>
                {selectedFollowers.map((follower) => (
                  <Avatar key={follower} src={followerData[follower]?.avatar} name={followerData[follower]?.username} mr={2} />
                ))}
              </Flex>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Group name:</FormLabel>
              <Input
                type="text"
                placeholder="Enter group name.."
                color="black"
                fontSize={12}
                ml={-1}
                mb={2}
                height={"6"}
                value={groupName}
                onChange={handleGroupNameChange}
              />
              {isError && (
                <Text color="red" fontSize="sm">Group name cannot exceed {maxGroupNameLength} characters</Text>
              )}
            </FormControl>
            <Button
              width="30%"
              onClick={handleCreateGroup}
              disabled={isCreateButtonDisabled}
              colorScheme="blue"
              isLoading={isLoading}
              mr={1}
            >
              Create
            </Button>
            <Button
              onClick={() => setStep(1)}
              leftIcon={<FiChevronLeft />}
              variant="outline"
              colorScheme="blue"
            >
              Back
            </Button>
          </>
        )}
      </Box>
    </Center>
  );
};

export default CreateGroup;
