// msgTypes.js
import {
  Avatar as ChakraAvatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
  Image as ChakraImage,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useUser } from 'hooks/users';
import { PROTECTED } from 'lib/routes';
import { Message_Options } from './buttons';

const DocMsg = ({ el, menu, backgroundColor, user }) => {
  const { colorMode } = useColorMode();
  const [downloadLink, setDownloadLink] = useState(null); // State to store the download link
  const timestampMilliseconds = el.date
    ? el.date?.seconds * 1000 + el.date?.nanoseconds / 1e6
    : el.createdAt?.seconds * 1000 + el.createdAt?.nanoseconds / 1e6;
  const dateObject = new Date(timestampMilliseconds);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - dateObject.getTime();
  let displayText;

  if (timeDifference < 6000) {
    displayText = 'Now';
  } else if (timeDifference < 86400000) {
    displayText = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    displayText = dateObject.toLocaleString([], {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const { user: userr } = useUser(el?.sentBy ? el.sentBy : 'nope');

  const isCurrentUserMessage = el.outgoing || el.sentBy === user?.id;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDownloadClick = () => {
    setDownloadLink(el.message);
    window.open(el.message);
  };

  function extractFileName(url) {
    const regex = /\/([^/]+)\?/;
    const matches = url.match(regex);
    if (matches && matches.length > 1) {
      const filePath = matches[1];
      const fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
      return decodeURIComponent(fileName.split('%2F').pop());
    }
    return null;
  }

  return (
    <Stack
      direction={'row'}
      justifyContent={isCurrentUserMessage ? 'end' : 'start'}
    >
      <VStack alignItems={isCurrentUserMessage ? 'end' : 'start'}>
        {!isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        {!isCurrentUserMessage && el?.sentBy && userr && (
          <ChakraAvatar
            as={Link}
            to={`${PROTECTED}/profile/${userr?.username}`}
            size={isMobile ? 'sm' : 'md'}
            src={userr?.avatar || ''}
            _hover={{ cursor: 'pointer', opacity: '0.8' }}
          />
        )}
        {isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        <Box
          p={1}
          backgroundColor={
            isCurrentUserMessage ? 'blue.500' : backgroundColor || 'gray.50'
          }
          borderRadius={'md'}
          width={'max-content'}
          wordBreak="keep-all"
        >
          <Stack spacing={2}>
            <Stack
              p={2}
              direction={'row'}
              spacing={3}
              alignItems={'center'}
              backgroundColor={
                colorMode === 'dark'
                  ? isCurrentUserMessage
                    ? 'blue.700'
                    : 'gray.700'
                  : 'white'
              }
            >
              <Text
                fontSize={isMobile ? 'xs' : 'md'}
                color={
                  colorMode === 'dark'
                    ? isCurrentUserMessage
                      ? 'white'
                      : 'black'
                    : 'black'
                }
                variant={'caption'}
              >
                {extractFileName(el.message)}
              </Text>
              <IconButton
                fontSize={'sm'}
                color={
                  colorMode === 'dark'
                    ? isCurrentUserMessage
                      ? 'white'
                      : 'black'
                    : 'black'
                }
                icon={<FiDownload />}
                onClick={handleDownloadClick}
              />
            </Stack>
          </Stack>
        </Box>
      </VStack>
    </Stack>
  );
};

const LinkMsg = ({ el, menu, backgroundColor, user }) => {
  const timestampMilliseconds = el.date
    ? el.date?.seconds * 1000 + el.date?.nanoseconds / 1e6
    : el.createdAt?.seconds * 1000 + el.createdAt?.nanoseconds / 1e6;
  const dateObject = new Date(timestampMilliseconds);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - dateObject.getTime();
  let displayText;

  if (timeDifference < 6000) {
    displayText = 'Now';
  } else if (timeDifference < 86400000) {
    displayText = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    displayText = dateObject.toLocaleString([], {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const { user: userr } = useUser(el?.sentBy ? el.sentBy : 'nope');

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isCurrentUserMessage = el.outgoing || el.sentBy === user?.id;

  return (
    <Stack
      direction={'row'}
      justifyContent={
        el.incoming ? 'start' : el?.sentBy !== user?.id ? 'end' : 'start'
      }
    >
      <VStack
        alignItems={
          el.incoming ? 'start' : el?.sentBy !== user?.id ? 'end' : 'start'
        }
      >
        {el.incoming ? (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        ) : (
          el?.sentBy &&
          el?.sentBy !== user?.id && (
            <Box position="relative" mt={{ base: 3, md: 2.5 }}>
              <Text fontSize={'8px'}>{displayText}</Text>
            </Box>
          )
        )}
        <Box
          p={1.5}
          backgroundColor={
            isCurrentUserMessage ? 'blue.500' : backgroundColor || 'gray.50'
          }
          borderRadius={'md'}
          width={'max-content'}
        >
          <Stack
            p={2}
            spacing={3}
            alignItems={'center'}
            backgroundColor={'white'}
            borderRadius={'md'}
          >
            <Stack spacing={2}>
              <Text
                fontSize={isMobile ? 'sm' : 'lg'}
                variant={'subtitle2'}
                as={Link}
                to={el.message}
                color={'blue'}
                _hover={{ textDecoration: 'underline' }}
              >
                {el.message}
              </Text>
            </Stack>
          </Stack>
        </Box>
      </VStack>
    </Stack>
  );
};

const ReplyMsg = ({ el, menu, backgroundColor }) => {
  return (
    <Stack direction={'row'} justifyContent={el.incoming ? 'start' : 'end'}>
      <Box
        p={1.5}
        backgroundColor={backgroundColor}
        borderRadius={'md'}
        width={'max-content'}
      >
        <Stack spacing={2}>
          <Stack
            p={2}
            direction={'column'}
            spacing={3}
            alignItems={'center'}
            backgroundColor={el.incoming ? 'blue.500' : 'gray.100'}
            borderRadius={'md'}
          >
            <Text variant={'body2'} color={el.incoming ? 'white' : 'black'}>
              {el.reply}
            </Text>
          </Stack>
          <Text variant={'body2'} color={el.incoming ? 'black' : 'white'}>
            {el.message}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

const MediaDisplay = ({ el }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const mediaRef = useRef(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleMediaClick = () => {
    if (mediaType === 'image') {
      setSelectedMedia(el.message);
      onOpen();
    }
  };

  const getMediaType = (url) => {
    const extension = url.split('.').pop().toLowerCase().split('?')[0];
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
      return 'video';
    } else if (['mp3', 'wav', 'ogg'].includes(extension)) {
      return 'audio';
    } else {
      return 'unknown';
    }
  };

  const mediaType = getMediaType(el.message);

  const handleMediaLoad = () => {
    setMediaLoaded(true);
  };

  const renderMedia = () => {
    switch (mediaType) {
      case 'image':
        return (
          <ChakraImage
            src={el.message}
            alt="Media content"
            maxH="210px"
            maxW={isMobile ? '60vw' : '30vw'}
            borderRadius="10px"
            width="auto"
            cursor="pointer"
            onClick={handleMediaClick}
          />
        );
      case 'video':
        return (
          <video
            src={el.message}
            controls
            maxW={isMobile ? '60vw' : '30vw'}
            style={{
              maxHeight: 210,
              borderRadius: '10px',
              width: 'auto',
              cursor: 'pointer',
            }}
            onClick={handleMediaClick}
          />
        );
      case 'audio':
        return <audio src={el.message} title="Audio File" controls />;
      default:
        return (
          <p>
            Unsupported media type{' '}
            {el.message.split('.').pop().toLowerCase().split('?')[0]}
          </p>
        );
    }
  };

  return (
    <>
      {renderMedia()}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick
        size="xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
        <ModalContent
          p={0}
          m={0}
          bg="transparent"
          boxShadow="none"
          maxW="800px"
        >
          <ModalBody
            p={0}
            m={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              position="relative"
              display="inline-block"
              maxH={isMobile ? '80vh' : '80vh'}
              maxW={isMobile ? '80vw' : '80vw'}
            >
              {mediaType === 'image' ? (
                <ChakraImage
                  src={selectedMedia}
                  alt="Selected Image"
                  objectFit="contain"
                  maxH={isMobile ? '80vh' : '80vh'}
                  maxW={isMobile ? '80vw' : '80vw'}
                  onLoad={handleMediaLoad}
                  borderRadius={'6px'}
                />
              ) : (
                <video
                  src={selectedMedia}
                  maxH={isMobile ? '80vh' : '80vh'}
                  maxW={isMobile ? '80vw' : '80vw'}
                  controls
                  onLoadedMetadata={handleMediaLoad}
                />
              )}
              <ModalCloseButton
                position="absolute"
                top="8px"
                right="8px"
                zIndex={10}
                color="white"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius="full"
                size="sm"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const MediaMsg = ({ el, menu, user, backgroundColor }) => {
  const timestampMilliseconds = el.date
    ? el.date?.seconds * 1000 + el.date?.nanoseconds / 1e6
    : el.createdAt?.seconds * 1000 + el.createdAt?.nanoseconds / 1e6;
  const dateObject = new Date(timestampMilliseconds);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - dateObject.getTime();
  let displayText;

  if (timeDifference < 6000) {
    displayText = 'Now';
  } else if (timeDifference < 86400000) {
    displayText = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    displayText = dateObject.toLocaleString([], {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const { user: userr } = useUser(el?.sentBy ? el.sentBy : 'nope');

  const isCurrentUserMessage = el.outgoing || el.sentBy === user?.id;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack
      direction={'row'}
      justifyContent={isCurrentUserMessage ? 'end' : 'start'}
    >
      <VStack alignItems={isCurrentUserMessage ? 'end' : 'start'}>
        {!isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        {!isCurrentUserMessage && el?.sentBy && userr && (
          <ChakraAvatar
            as={Link}
            to={`${PROTECTED}/profile/${userr?.username}`}
            size={isMobile ? 'sm' : 'md'}
            src={userr?.avatar || ''}
            _hover={{ cursor: 'pointer', opacity: '0.8' }}
          />
        )}
        {isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        <Box
          p={1}
          backgroundColor={
            isCurrentUserMessage ? 'blue.500' : backgroundColor || 'gray.50'
          }
          borderRadius={'md'}
          width={'max-content'}
          wordBreak="break-all"
        >
          <Stack spacing={1}>
            <MediaDisplay el={el} />
          </Stack>
        </Box>
      </VStack>
    </Stack>
  );
};

const TextMsg = ({ el, menu, user, searchQuery, backgroundColor }) => {
  const timestampMilliseconds = el.date
    ? el.date?.seconds * 1000 + el.date?.nanoseconds / 1e6
    : el.createdAt?.seconds * 1000 + el.createdAt?.nanoseconds / 1e6;
  const dateObject = new Date(timestampMilliseconds);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - dateObject.getTime();
  let displayText;

  if (timeDifference < 6000) {
    displayText = 'Now';
  } else if (timeDifference < 86400000) {
    displayText = dateObject.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    displayText = dateObject.toLocaleString([], {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const isCurrentUserMessage = el.outgoing || el.sentBy === user?.id;

  const { user: userr } = useUser(el?.sentBy ? el.sentBy : 'nope');

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack
      direction={'row'}
      justifyContent={isCurrentUserMessage ? 'end' : 'start'}
    >
      <VStack alignItems={isCurrentUserMessage ? 'end' : 'start'}>
        {!isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        {!isCurrentUserMessage && el?.sentBy && userr && (
          <ChakraAvatar
            as={Link}
            to={`${PROTECTED}/profile/${userr?.username}`}
            size={isMobile ? 'sm' : 'md'}
            src={userr?.avatar || ''}
            _hover={{ cursor: 'pointer', opacity: '0.8' }}
          />
        )}
        {isCurrentUserMessage && (
          <Box position="relative" mt={{ base: 3, md: 2.5 }}>
            <Text fontSize={'8px'}>{displayText}</Text>
          </Box>
        )}
        <Box
          p={1.5}
          backgroundColor={
            el?.message.toLowerCase().includes(searchQuery?.toLowerCase())
              ? 'yellow'
              : isCurrentUserMessage
              ? 'blue.500'
              : backgroundColor || 'gray.50'
          }
          borderRadius={'md'}
          width={'max-content'}
          wordBreak={'keep-all'}
        >
          <Text
            fontSize={isMobile ? 'sm' : 'lg'}
            color={isCurrentUserMessage ? 'white' : 'white'}
            maxW={isMobile ? '60vw' : '30vw'}
          >
            {el.message}
          </Text>
        </Box>
      </VStack>
    </Stack>
  );
};

const Timeline = ({ el }) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Divider width={'46%'} />
      <Text variant={'caption'}>{el.text}</Text>
      <Divider width={'46%'} />
    </Stack>
  );
};

const MessageOptions = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box position="relative">
      <Menu>
        <MenuButton
          isActive={isOpen}
          id="basic-button"
          aria-controls={isOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          onClick={() => setIsOpen((prev) => !prev)}
          rightIcon={<ChevronDownIcon />}
        >
          <BsThreeDotsVertical size={15} />
        </MenuButton>
        <MenuList placement="bottom-end" position="absolute" top="0" mt="2">
          {Message_Options.map((el) => (
            <MenuItem key={el.title} onClick={() => {}}>
              {el.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export { Timeline, TextMsg, MediaMsg, ReplyMsg, LinkMsg, DocMsg, MediaDisplay };
