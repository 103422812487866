import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Text,
  useColorMode,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Flex,
  useToast,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure, // Add useDisclosure import
 
} from "@chakra-ui/react";
import { FiX, FiChevronLeft, FiPlus, FiShare2 } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { collection, query, where, getDocs, doc, getDoc, updateDoc, arrayRemove } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db } from "lib/firebase";
import { useAuth } from "hooks/auth";
import GroupMediaContent from "./groupMediaContent";
import { useNavigate } from 'react-router-dom'; 

export const PROTECTED = "/protected";

const GroupInfo = ({ groupId, setShowGroupInfo }) => {
  const [groupData, setGroupData] = useState(null);
  const [memberData, setMemberData] = useState({});
  const [mediaMessages, setMediaMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoteMemberId, setPromoteMemberId] = useState(null);
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const toast = useToast();
  const { user: authUser, isLoading } = useAuth();
  const { colorMode } = useColorMode();
  const [value, setValue] = useState(0);
  const [isAdminChangeModalOpen, setIsAdminChangeModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();



  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const confirmRemoveMember = async () => {
    await removeFromGroup(memberToRemove);
    setIsConfirmationModalOpen(false);
    setMemberToRemove(null);
  };

  const leaveGroupChat = async () => {
    if (!authUser || !groupData) return;
  
    // Check if the user is the last admin
    const isLastAdmin = groupData.roles[authUser.id] === 'admin' &&
      Object.values(groupData.roles).filter(role => role === 'admin').length === 1;
  
    if (isLastAdmin) {
      setIsAdminChangeModalOpen(true); // Show the modal to select a new admin
      return;
    }
  
    // Proceed with leaving the group
    try {
      const groupRef = doc(db, 'groups', groupId);
      await updateDoc(groupRef, { members: arrayRemove(authUser.id) });
  
      const userRef = doc(db, 'users', authUser.id);
      await updateDoc(userRef, { groups: arrayRemove(groupId) });
  
      toast({
        title: 'Left Group Chat',
        description: `You have successfully left ${groupData.groupName}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
  
      navigate('/protected/dashboard');
      onClose();
    } catch (error) {
      console.error('Error leaving group chat:', error);
      toast({
        title: 'Error',
        description: 'There was a problem leaving the group chat. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  

  const removeFromGroup = async (memberId) => {
    if (!groupData || !groupId) return;

    const isRemovingSelf = memberId === authUser.id;
    const remainingAdmins = Object.values(groupData.roles).filter(role => role === 'admin').length;

    if (isRemovingSelf && remainingAdmins <= 1) {
      toast({
        title: "Cannot remove yourself",
        description: "You cannot remove yourself as the only admin. Please assign another admin first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      const newMembers = groupData.members.filter(id => id !== memberId);
      const { [memberId]: _, ...newRoles } = groupData.roles; // Remove member from roles

      const groupDocRef = doc(db, "groups", groupId);
      await updateDoc(groupDocRef, { members: newMembers, roles: newRoles });

      const memberDocRef = doc(db, "users", memberId);
      await updateDoc(memberDocRef, { groups: arrayRemove(groupId) });

      setGroupData((prev) => ({ ...prev, members: newMembers, roles: newRoles }));
      setMemberData((prev) => {
        const newMemberData = { ...prev };
        delete newMemberData[memberId];
        return newMemberData;
      });

      toast({
        title: "Member removed",
        description: `${memberData[memberId]?.username} has been removed from the group.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error removing from group:", error);
      toast({
        title: "Error removing member",
        description: "An error occurred while removing the member. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleRemoveMember = (memberId) => {
    setMemberToRemove(memberId);
    setIsConfirmationModalOpen(true);
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      if (!groupId) return;
      try {
        const groupDoc = await getDoc(doc(db, "groups", groupId));
        if (groupDoc.exists()) {
          setGroupData(groupDoc.data());
        }
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchGroupData();
  }, [groupId]);

  useEffect(() => {
    const fetchMemberData = async () => {
      if (!groupData) return;

      const memberDataTemp = {};
      await Promise.all(
        groupData.members.map(async (memberId) => {
          const memberDoc = await getDoc(doc(db, "users", memberId));
          if (memberDoc.exists()) {
            memberDataTemp[memberId] = memberDoc.data();
          }
        })
      );
      setMemberData(memberDataTemp);
      setLoading(false);
    };

    fetchMemberData();
  }, [groupData]);

  useEffect(() => {
    const fetchMediaMessages = async () => {
      if (!groupId) return;
      try {
        const messagesRef = collection(db, "groups", groupId, "messages");
        const mediaQuery = query(messagesRef, where("subtype", "in", ["doc", "img", "link"]));
        const mediaDocs = await getDocs(mediaQuery);
        const mediaMessagesTemp = mediaDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMediaMessages(mediaMessagesTemp);
      } catch (error) {
        console.error("Error fetching media messages:", error);
      }
    };

    fetchMediaMessages();
  }, [groupId]);

  if (isLoading || !authUser || !groupId) {
    return <Center>Loading...</Center>;
  }

  if (!groupData || !authUser) {
    return <Text>Loading group information...</Text>;
  }

  const isAdmin = groupData.roles[authUser?.id] === 'admin';

  return (
    <Box
      width={isMobile ? "100vw" : "100%"}
      height={"100%"}
    >
      {/* Header */}
      <Box
        backgroundColor={'#transparent'}
        height={'7.5%'}
      >
        <Stack
          height={"100%"}
          direction={"row"}
          alignItems={"center"}
        >
          <IconButton
            onClick={() => setShowGroupInfo(false)}
            icon={<FiChevronLeft color="#6899FE" />}
            backgroundColor={"transparent"}
            size="lg"
            _hover={{ backgroundColor: "transparent" }}
          />
          <Text
            fontSize="xl"
            as="b"
          >
            Group Information
          </Text>
        </Stack>
      </Box>
      <Box
        width={"100%"}
        borderTop="1px #E2E8F0 solid"
        backgroundColor={'#transparent'}
        p={2}
        height={'10%'}
      >
        <Heading size="md">{groupData.groupName} </Heading>
      </Box>

      {/* Body */}
      <Stack
        height={"90%"}
        position={"relative"}
        flexGrow={1}
        overflowY={"scroll"}
      >
        {/* Add Starred messages Dropdown */}
        <Stack spacing={0} alignItems="stretch" width="100%" mb={0} p={0}>
        {/*  <Menu>
            <MenuButton as={Button} width="100%" variant="outline" rightIcon={<ChevronDownIcon />} borderRadius="0" _hover={{ bg: "#6899FE", color: "white" }}>
              Starred messages
            </MenuButton>
            <MenuList>
              <MenuItem>Option 1</MenuItem>
              <MenuItem>Option 2</MenuItem>
              <MenuItem>Option 3</MenuItem>
            </MenuList>
          </Menu> */}
        </Stack>

        {/* Media, Links, Docs, LeaveGroupButton */}
        <Box p={0} mb={0}>
  <Button
    onClick={onOpen}
    width="100%"
    backgroundColor="white"  // Set initial background to white
    color="black"  // Set the text color to black
    border="1px solid #E2E8F0"  // Match the border style to the tabs
    borderRight="none"  // Remove the right border
    _hover={{ backgroundColor: "red.600", color: "white", border: "1px solid red.600", borderRight: "none",height: "35px" }}  // On hover: change background to red, text to white, keep no right border
    _active={{ backgroundColor: "red.600", border: "1px solid red.600", borderRight: "none" }}   // On click: darker red, keep no right border
    
    height="35px"
    borderRadius="0"  // Squared edges
    fontWeight="bold"  // Match the font weight of the tabs
    fontSize="md"  // Match the font size of the tabs
    mb={0}  // Remove bottom margin
    borderBottom="none"
    borderLeft="none"
  >
    Leave Group Chat
  </Button>
</Box>


<Stack direction={"column"} height={"45%"} width={"100%"} borderTop="1px #E2E8F0 solid" borderBottom="0px #E2E8F0 solid" spacing={0}>
  <Tabs onChange={(value) => handleChange(value)} variant="unstyled" overflowY={"scroll"}>
    <TabList borderBottom="1px solid #E2E8F0" mb={0} mt={0} p={0}>
      <Tab _selected={{ color: "white", bg: "#6899FE" }} _focus={{ boxShadow: "none" }} borderRight="1px solid #E2E8F0" width="33.33%" _hover={{ bg: "#6899FE", color: "white" }} fontWeight="bold" >
        Media
      </Tab>
      <Tab _selected={{ color: "white", bg: "#6899FE" }} _focus={{ boxShadow: "none" }} borderRight="1px solid #E2E8F0" width="33.33%" _hover={{ bg: "#6899FE", color: "white" }}fontWeight="bold" >
        Links
      </Tab>
      <Tab _selected={{ color: "white", bg: "#6899FE" }} _focus={{ boxShadow: "none" }} width="33.33%" _hover={{ bg: "#6899FE", color: "white" }}fontWeight="bold" >
        Docs
      </Tab>
    </TabList>


            {/* Tab Panels */}
            <TabPanels>
              <TabPanel>
                <SimpleGrid columns={3} spacing={2}>
                  {mediaMessages.filter(el => el.subtype === "img").length > 0 ? (
                    mediaMessages.filter(el => el.subtype === "img").map((el, index) => (
                      <GroupMediaContent key={index} message={el} />
                    ))
                  ) : (
                    <Text>No media found</Text>
                  )}
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={3} spacing={2}>
                  {mediaMessages.filter(el => el.subtype === "link").length > 0 ? (
                    mediaMessages.filter(el => el.subtype === "link").map((el, index) => (
                      <GroupMediaContent key={index} message={el} />
                    ))
                  ) : (
                    <Text>No links found</Text>
                  )}
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <SimpleGrid row={3} spacing={2}>
                  {mediaMessages.filter(el => el.subtype === "doc").length > 0 ? (
                    mediaMessages.filter(el => el.subtype === "doc").map((el, index) => (
                      <GroupMediaContent key={index} message={el} />
                    ))
                  ) : (
                    <Text>No documents found</Text>
                  )}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>

      <Stack spacing={3} maxHeight="300px" overflowY="auto" >
         <Heading p={2} height={'5%'} size="sm">Group Members</Heading>
         {loading ? (
           <Text>Loading members...</Text>
         ) : (
           groupData.members.map((memberId) => {
             const user = memberData[memberId];
             if (!user) return null;
             const isAdminMember = groupData.roles[memberId] === 'admin';
             return (
               <Flex key={memberId} align="center" justify="space-between" p={2}>
                 <Flex align="center">
                   <Avatar as={Link}
                     href={`${PROTECTED}/profile/${user.username}`} src={user.avatar} size="sm" mr={2} />
                   <Text as={Link}
                     href={`${PROTECTED}/profile/${user.username}`} fontWeight="bold">{user.username}</Text>
                   {isAdminMember && (
                     <Text ml={1} fontSize="sm" color="gray.500">(Admin)</Text>
                   )}
                 </Flex>
                 {isAdmin && (
                   <Button ml={2} onClick={() => handleRemoveMember(memberId)} size="sm" colorScheme="red" variant="ghost" _hover={{ bg: "#6899FE", color: "white" }}>
                     Remove
                   </Button>
                 )}
               </Flex>
             );
           })
         )}
       </Stack>
        <Modal isOpen={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Removal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Are you sure you want to remove this member from the group?</Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" onClick={confirmRemoveMember}>Remove</Button>
              <Button variant="ghost" onClick={() => setIsConfirmationModalOpen(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* Modal for Leave Group */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Leave Group Chat</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to leave the group chat?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" onClick={leaveGroupChat}>Yes</Button>
              <Button variant="ghost" onClick={onClose}>No</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isAdminChangeModalOpen} onClose={() => setIsAdminChangeModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select a new admin before leaving</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Select a member to assign as the new admin before you leave:</Text>
              <Stack spacing={3} mt={3}>
                {groupData.members.filter(id => id !== authUser.id).map((memberId) => {
                  const user = memberData[memberId];
                  if (!user) return null;
                  return (
                    <Button
                      key={memberId}
                      variant={selectedAdminId === memberId ? "solid" : "outline"}
                      onClick={() => setSelectedAdminId(memberId)}
                    >
                      {user.username}
                    </Button>
                  );
                })}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={async () => {
                  if (selectedAdminId) {
                    // Update the roles to assign the selected member as admin
                    const groupRef = doc(db, 'groups', groupId);
                    await updateDoc(groupRef, {
                      [`roles.${selectedAdminId}`]: 'admin',
                    });
                  
                    leaveGroupChat(); // Proceed with leaving the group
                    setIsAdminChangeModalOpen(false);
                  } else {
                    toast({
                      title: 'No admin selected',
                      description: 'Please select a member to promote to admin.',
                      status: 'warning',
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                }}
              >
                Confirm and Leave
              </Button>
              <Button variant="ghost" onClick={() => setIsAdminChangeModalOpen(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

      </Stack>
    </Box>
  );
};

export default GroupInfo;
