import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Text,
  Input,
  Button,
  HStack,
  Tooltip,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import Avatar from 'components/profile/Avatar';
import UsernameButton from 'components/profile/UsernameButton';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { useDeleteComment } from 'hooks/comments';
import { useToggleCommentLike } from 'hooks/commentLikes';
import { useToggleReplyLike } from 'hooks/replyLikes';
import { useUser } from 'hooks/users';
import { FaTrash, FaEdit, FaHeart, FaRegHeart } from 'react-icons/fa';
import { updateDoc, doc, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';

const CHARACTER_LIMIT = 200; // Set your character limit here

export default function Comment({ comment, onDelete, onUpdate, addReply, parentCommentId, isReply = false }) {
  const { text, uid, date, id, edited, replies = [], likes = [] } = comment;
  const { user, isLoading: userLoading } = useUser(uid);
  const { deleteComment, isLoading: deleteLoading } = useDeleteComment(id);
  const { user: authUser, isLoading: authLoading } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const [editLoading, setEditLoading] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [showReplies, setShowReplies] = useState(false);
  const [localLikes, setLocalLikes] = useState(likes);
  const [localReplies, setLocalReplies] = useState(replies);
  const [repliesShown, setRepliesShown] = useState(5); 
  const [showMore, setShowMore] = useState(false);

  const isLiked = Array.isArray(localLikes) && localLikes.includes(authUser?.id);
  const { toggleCommentLike, isLoading: likeLoading } = useToggleCommentLike({
    commentId: parentCommentId || id,
    userId: authUser?.id,
  });

  const { toggleReplyLike, isLoading: replyLikeLoading } = useToggleReplyLike({
    commentId: parentCommentId || id,
    replyId: parentCommentId ? id : null,
    userId: authUser?.id,
  });

  const handleToggleCommentLike = () => {
    toggleCommentLike(setLocalLikes);
  };

  const handleToggleReplyLike = () => {
    toggleReplyLike(setLocalLikes);
  };

  if (userLoading) return <Spinner size="sm" />;

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setEditedText(text);
  };
  const handleSave = async () => {
    setEditLoading(true);
    try {
      const commentRef = parentCommentId
        ? doc(db, `comments/${parentCommentId}/replies`, id)
        : doc(db, 'comments', id);
      await updateDoc(commentRef, { text: editedText, edited: true });
      setIsEditing(false);
      setEditLoading(false);
      onUpdate({ ...comment, text: editedText, edited: true });
    } catch (error) {
      console.error('Error editing comment: ', error);
      setEditLoading(false);
    }
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyText.trim()) return;

    const trimmedReplyText = replyText.trim().slice(0, CHARACTER_LIMIT);
    const finalReplyText = `${trimmedReplyText}`;

    try {
      const replyRef = collection(db, 'comments', parentCommentId || id, 'replies');
      const newReply = {
        text: finalReplyText,
        uid: authUser.id,
        date: new Date().toISOString(),
        likes: [],
      };
      const docRef = await addDoc(replyRef, newReply);
      const newReplyWithId = { ...newReply, id: docRef.id };
      setLocalReplies([...localReplies, newReplyWithId]);
      addReply(parentCommentId || id, newReplyWithId);
      setReplyText('');
      setShowReplyForm(false);
    } catch (error) {
      console.error('Error adding reply: ', error);
    }
  };

  const handleDeleteReply = async (replyId) => {
    try {
      const replyRef = doc(db, 'comments', parentCommentId || id, 'replies', replyId);
      await deleteDoc(replyRef);
      setLocalReplies(localReplies.filter(reply => reply.id !== replyId));
    } catch (error) {
      console.error('Error deleting reply: ', error);
    }
  };

  const handleEditReply = async (replyId, newText) => {
    try {
      const replyRef = doc(db, 'comments', parentCommentId || id, 'replies', replyId);
      await updateDoc(replyRef, { text: newText, edited: true });
      setLocalReplies(localReplies.map(reply => (reply.id === replyId ? { ...reply, text: newText, edited: true } : reply)));
    } catch (error) {
      console.error('Error editing reply: ', error);
    }
  };

  const toggleShowReplies = () => setShowReplies(!showReplies);
  const showMoreReplies = () => setRepliesShown(repliesShown + 5);

  const renderFormattedText = (text) => {
    const parts = text.split(/(@\w+)/g).map((part, index) => {
      if (part.startsWith('@')) {
        return (
          <span key={index} style={{ color: '#3182ce', fontWeight: 'bold' }}>
            {part}
          </span>
        );
      }
      return part;
    });

    return <span>{parts}</span>;
  };

  return (
    <Box
      px="4"
      py="2"
      maxW="600px"
      mx="auto"
      my="4"
      bg="#edf2f7"
      boxShadow="md"
      borderRadius="md"
      borderWidth="1px"
      borderColor="#E2E8F0"
      textAlign="left"
      position="relative"
      className="comment-box"
    >
      <Flex pb="2" alignItems="flex-start">
        <Avatar user={user} size="sm" post={true} />
        <Box flex={1} ml="6">
          <Flex
            borderBottom="1px solid"
            borderColor="#E2E8F0"
            pb="2"
            alignItems="center"
            position="relative"
          >
            <Box flex={1}>
              <UsernameButton user={user} />
              <Text fontSize="xs" color="gray.500">
                {date && !isNaN(new Date(date).getTime()) ? formatDistanceToNow(new Date(date)) + ' ago' : 'Invalid date'}
              </Text>
            </Box>
            {!authLoading && authUser?.id === uid && (
              <HStack spacing={2}>
                <IconButton
                  size="sm"
                  icon={<FaEdit />}
                  colorScheme="blue"
                  variant="ghost"
                  isRound
                  onClick={handleEdit}
                />
                <IconButton
                  size="sm"
                  icon={<FaTrash />}
                  colorScheme="red"
                  variant="ghost"
                  isRound
                  onClick={() => onDelete(id)}
                  isLoading={deleteLoading}
                />
              </HStack>
            )}
          </Flex>
          <Box pt="2" fontSize="sm" position="relative">
            {isEditing ? (
              <>
                <Input
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value.slice(0, CHARACTER_LIMIT))}
                  size="sm"
                />
                <HStack pt="2" spacing={2}>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={handleSave}
                    isLoading={editLoading}
                  >
                    Save
                  </Button>
                  <Button size="sm" onClick={handleCancel}>
                    Cancel
                  </Button>
                </HStack>
              </>
            ) : (
              <Text>
                {/* Render only the comment.text, not the entire comment object */}
                {renderFormattedText(showMore ? String(text.text) : String(text.text).slice(0, 200))}
                {String(text.text).length > 200 && (
                  <Button
                    size="xs"
                    variant="link"
                    color="blue.500"
                    onClick={() => setShowMore(!showMore)}
                    _hover={{ textDecoration: 'none', color: 'blue.700' }}
                  >
                    {showMore ? 'Show Less' : 'Read More'}
                  </Button>
                )}
                {comment.edited && (
                  <Tooltip
                    label="This comment has been edited"
                    aria-label="Edited tooltip"
                  >
                    <Text
                      as="span"
                      fontSize="xs"
                      color="gray.500"
                      ml={2}
                      fontStyle="italic"
                    >
                      (Edited)
                    </Text>
                  </Tooltip>
                )}
              </Text>
            )}
          </Box>
          <Flex alignItems="center" pt="2">
            <IconButton
              onClick={parentCommentId ? handleToggleReplyLike : handleToggleCommentLike}
              isLoading={likeLoading || replyLikeLoading || authLoading}
              size="sm"
              colorScheme="blue"
              variant="ghost"
              icon={isLiked ? <FaHeart /> : <FaRegHeart />}
              isRound
            />
            <Text
              size={'sm'}
              color="gray.500"
              fontSize={'sm'}
              ml={'1'}
              style={{ opacity: localLikes.length === 0 ? 0 : 1 }}
            >
              {localLikes.length}
            </Text>
          </Flex>
          {!isReply && (
            <>
              {showReplyForm ? (
                <form onSubmit={handleReplySubmit} style={{ width: '100%' }}>
                  <Flex alignItems="center" mt={5}>
                    <Avatar user={authUser} size="sm" post={true} />
                    <Input
                      ml="2"
                      size="sm"
                      variant="flushed"
                      placeholder="Write a reply..."
                      value={replyText}
                      onChange={(e) => setReplyText(e.target.value.slice(0, CHARACTER_LIMIT))}
                      flex={1}
                    />
                    <Button
                      size="xs"
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!replyText.trim()}
                      ml="2"
                    >
                      Add Reply
                    </Button>
                    <Button
                      size="xs"
                      colorScheme="gray"
                      ml="2"
                      onClick={() => setShowReplyForm(false)}
                    >
                      Cancel
                    </Button>
                  </Flex>
                  <Text fontSize="xs" color="gray.500">
                    {`${CHARACTER_LIMIT - replyText.length} characters remaining`}
                  </Text>
                </form>
              ) : (
                <Button
                  className="reply-button"
                  size="xs"
                  fontWeight="bold"
                  variant="link"
                  color="blue.500"
                  onClick={() => setShowReplyForm(!showReplyForm)}
                  _hover={{ textDecoration: 'none', color: 'blue.700' }}
                >
                  Reply
                </Button>
              )}
            </>
          )}
          {localReplies && localReplies.length > 0 && (
            <>
              <Flex justifyContent="center" mt="2">
                <Button
                  size="xs"
                  variant="link"
                  color="blue.500"
                  onClick={toggleShowReplies}
                  _hover={{ textDecoration: 'none', color: 'blue.700' }}
                >
                  {showReplies ? 'Hide Replies' : `Show Replies (${localReplies.length})`}
                </Button>
              </Flex>
              <VStack
                align="start"
                spacing="4"
                mt="4"
                pl="4"
                borderLeft="1px solid #E2E8F0"
                display={showReplies ? 'block' : 'none'}
              >
                {localReplies.slice(0, repliesShown).map((reply) => (
                  <Box
                    key={reply.id}
                    pl={4}
                    borderLeft="1px solid #E2E8F0"
                    className="reply-box"
                  >
                    <Comment
                      comment={reply}
                      addReply={addReply}
                      onDelete={handleDeleteReply}
                      onUpdate={(updatedReply) => handleEditReply(reply.id, updatedReply.text)}
                      parentCommentId={parentCommentId || id}
                      isReply={true}
                    />
                  </Box>
                ))}
                {localReplies.length > repliesShown && (
                  <Button
                    size="xs"
                    variant="link"
                    color="blue.500"
                    onClick={showMoreReplies}
                    _hover={{ textDecoration: 'none', color: 'blue.700' }}
                  >
                    Show More Replies
                  </Button>
                )}
              </VStack>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
}