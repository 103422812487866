import React, { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Center,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Switch,
  FormControl,
  InputGroup,
  InputRightElement,
  Input,
  Wrap,
  WrapItem,
  Badge,
  Button,
  useBreakpointValue,
  Flex,
  VStack,
  FormLabel,
} from '@chakra-ui/react';
import { MdLocationOn, MdOutlineRefresh } from 'react-icons/md';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import L from 'leaflet';
import { useAuth } from 'hooks/auth';
import { db } from 'lib/firebase';
import {
  collection,
  query,
  onSnapshot,
  where,
  updateDoc,
  doc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import logo from 'Musicom Resources/Blue Logo Design/No Background/4x/Blue-White Icon Logo copy@4x.png'; // Import your logo image
import pinMusicom from '../findr/PinMusicom.png';
import pinUser from '../findr/PinMusicomRed.png';
import UserCard from 'components/network/UserCard';
import axios from 'axios';
import { BiMap } from 'react-icons/bi';

const PlacesAutocomplete = ({ location, setLocation: setFormLocation }) => {
  const [suggestions, setSuggestions] = useState([]);

  const handlePlaceSearch = async (value) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`,
        {
          params: {
            access_token:
              'pk.eyJ1IjoiYWxlbGVudGluaSIsImEiOiJjbGk5ZWF5MnQwOHl2M25wcXBjamd3NjQ4In0.MpcjArF0h_rXY6O3LdqjwA',
            types: 'place',
          },
        }
      );

      const features = response.data.features;
      const citySuggestions = features.map((feature) => feature.place_name);

      setSuggestions(citySuggestions);
    } catch (error) {
      console.log('Error fetching location suggestions:', error);
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (value) => {
    setFormLocation(value);
    setSuggestions([]);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <FormControl>
      <InputGroup>
        <Input
          style={{ width: '100%' }}
          height={isMobile ? '7' : '10'}
          fontSize={{ base: 'xs', md: 'md' }}
          ml={{ base: 0, md: 0 }}
          placeholder="Enter your location (city)."
          value={location}
          onChange={(e) => {
            setFormLocation(e.target.value);
            handlePlaceSearch(e.target.value);
          }}
        />
        <InputRightElement pointerEvents="none">
          <Box
            color="gray.300"
            display="flex"
            alignItems="center"
            mb={isMobile ? '3' : '0'}
          >
            <BiMap color="#4480FC" h="6" w="6" />
          </Box>
        </InputRightElement>
      </InputGroup>
      {suggestions.length > 0 && (
        <Wrap mt={2} spacing={2}>
          {suggestions.map((suggestion) => (
            <WrapItem key={suggestion}>
              <Badge
                fontSize={isMobile ? '8px' : '10px'}
                colorScheme="blue"
                cursor="pointer"
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion}
              </Badge>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </FormControl>
  );
};

// Function to calculate distance between two coordinates using Haversine formula
const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Radius of the Earth in meters
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in meters
  return distance;
};

const InStudio = () => {
  const { user, isLoading, error } = useAuth(); // Use the useAuth hook to get the authenticated user
  const [userLocation, setUserLocation] = useState(null);
  const [radius, setRadius] = useState(1000); // radius in meters
  const [nearbyUsers, setNearbyUsers] = useState([]);
  const [inStudio, setInStudio] = useState(false);
  const [mapKey, setMapKey] = useState(Date.now()); // Unique key for MapContainer
  // const [location, setLocation] = useState('');
  const [subscribed, setSubscribed] = useState(null);

  const getActiveSubscription = async () => {
    try {
      const snapshot = await getDocs(
        query(
          collection(
            db,
            user?.businessName ? 'businesses' : 'users',
            user?.id,
            'subscriptions'
          ),
          where('status', 'in', ['trialing', 'active'])
        )
      );
      if (snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];
        return doc.data().status;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error getting active subscription:', error);
      throw error;
    }
  };
  useEffect(() => {
    if (user) {
      setInStudio(user?.inStudio);
    }
  }, [user]);

  const [showInStudio, setShowInStudio] = useState(false);
  useEffect(() => {
    if (user) {
      getActiveSubscription().then((status) => {
        setShowInStudio(status);
      });
    }
  }, [user]);

  const updateLocation = (latitude, longitude) => {
    setUserLocation([latitude, longitude]);
    setMapKey(Date.now());
    const userDocRef = doc(db, 'users', user?.id);

    updateDoc(userDocRef, {
      lat: latitude,
      lng: longitude,
      inStudio: inStudio,
    })
      .then(() => {
        console.log('User document updated successfully.');
      })
      .catch((error) => {
        console.error('Error updating user document:', error);
      });
  };

  useEffect(() => {
    if (user && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateLocation(latitude, longitude);

          const q = query(
            collection(db, 'users'),
            where('inStudio', '==', true),
            where('username', '!=', user?.username)
          );

          // Listen to updates from Firestore
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              users.push({ id: doc.id, ...data });
            });

            // Filter users by distance based on the current radius
            setNearbyUsers(
              users.filter(
                (u) =>
                  getDistanceFromLatLonInMeters(
                    latitude,
                    longitude,
                    u.lat,
                    u.lng
                  ) <= radius
              )
            );
          });

          // Clean up Firestore subscription on component unmount or when dependencies change
          return () => unsubscribe();
        },
        (error) => {
          console.error("Error getting user's location:", error);
          // Handle the error, possibly fall back to a default location
        }
      );
    }
  }, [user, inStudio, radius]); // Include 'radius' in the dependency array

  const handleInStudioChange = async (event) => {
    setInStudio(event.target.checked);
    if (user) {
      const userDocRef = doc(db, 'users', user?.id);
      await updateDoc(userDocRef, {
        inStudio: event.target.checked,
      });
    }
    setMapKey(Date.now());
  };

  // const handleLocationSubmit = () => {
  //   if (location) {
  //     axios
  //       .get(
  //         `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json`,
  //         {
  //           params: {
  //             access_token:
  //               'pk.eyJ1IjoiYWxlbGVudGluaSIsImEiOiJjbGk5ZWF5MnQwOHl2M25wcXBjamd3NjQ4In0.MpcjArF0h_rXY6O3LdqjwA',
  //             types: 'place',
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const { center } = response.data.features[0];
  //         const [longitude, latitude] = center;
  //         updateLocation(latitude, longitude);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching location:', error);
  //       });
  //   }
  // };

  // const handleRefreshLocation = () => {
  //   setLocation('');
  //   setRadius(1000);
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         updateLocation(latitude, longitude);
  //       },
  //       (error) => {
  //         console.error("Error getting user's location:", error);
  //       }
  //     );
  //   }
  // };
  const isMobile = useBreakpointValue({ base: true, md: false });

  const defaultIcon = L.icon({
    iconUrl: pinMusicom,
    iconSize: [15, 25],
  });

  const userIcon = L.icon({
    iconUrl: pinUser,
    iconSize: [38, 38],
  });

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error.message}</Text>;
  }

  if (!user) {
    return <Text>Please log in to see the map.</Text>;
  }
  const handleRadiusChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setRadius(Number(value) * 1000);
    }
  };

  return (
    <>
      {isMobile ? (
        <Center>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={20}
            minHeight="60vh"
            width={'100%'}
          >
            <Box className="App" align="center" width="100%" pt={6}>
              <div
                style={{
                  mt: '0px',
                  borderRadius: '8px',
                  padding: '16px 12px',
                  width: '100%',
                  marginBottom: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={0}
                >
                  <Text
                    border="1px solid white"
                    borderRadius={'10px'}
                    borderWidth={'1px'}
                    backgroundColor="gray.200"
                    color={'#6899fe'}
                    fontWeight={'bold'}
                    textAlign={'center'}
                    fontSize={'20px'}
                    mb={'15px'}
                    p={'4px 8px'}
                  >
                    InStudio
                  </Text>
                </Box>

                {/* {inStudio && (
                  <>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      width={'90vw'}
                    >
                      <FormLabel
                        color={'#6899fe'}
                        fontSize={'12px'}
                        alignSelf={'flex-start'}
                        mb={'2px'}
                      >
                        Location
                      </FormLabel>
                      <PlacesAutocomplete
                        location={location}
                        setLocation={setLocation}
                      />
                    </Box>

                    <Flex justifyContent={'center'}>
                      <Button
                        leftIcon={<MdLocationOn color="white" />}
                        fontSize={'12px'}
                        size={'xs'}
                        mt={4}
                        backgroundColor={'#6899FE'}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                        fontWeight={'normal'}
                        textColor={'white'}
                        onClick={handleLocationSubmit}
                      >
                        Set General Location
                      </Button>
                    </Flex>
                    <Flex justifyContent={'center'} mt={4} gap={3}>
                      <Button
                        onClick={handleLocationSubmit}
                        backgroundColor={'#6899FE'}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                        fontWeight={'normal'}
                        textColor={'white'}
                        fontSize={'12px'}
                        size={'xs'}
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={handleRefreshLocation}
                        backgroundColor={'#6899FE'}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                        fontWeight={'normal'}
                        textColor={'white'}
                        fontSize={'12px'}
                        size={'xs'}
                      >
                        Reset
                      </Button>
                    </Flex>
                  </>
                )} */}

                {inStudio && (
                  <Box mt={4} fontWeight="semibold" textAlign="center">
                    <Text flexDirection={'row'} fontSize={'12px'}>
                      Adjust Search Radius:{' '}
                      <Input
                        fontSize={'12px'}
                        width={10}
                        height={'fit-content'}
                        onChange={handleRadiusChange}
                        value={radius / 1000}
                      />{' '}
                      km
                    </Text>
                  </Box>
                )}

                {showInStudio && (
                  <Switch
                    isChecked={inStudio}
                    onChange={handleInStudioChange}
                    colorScheme="teal"
                    size="lg"
                    fontSize={'12px'}
                  >
                    {inStudio ? 'In Studio' : 'Out of Studio'}
                  </Switch>
                )}
              </div>
            </Box>
            <Box
              width={'90vw'}
              border={'1px solid black'}
              borderRadius={'2px'}
              mx={'auto'}
            >
              {userLocation ? (
                <Box
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <MapContainer
                    key={mapKey} // Use mapKey as key for MapContainer
                    center={userLocation}
                    zoom={12}
                    minZoom={5}
                    maxZoom={18}
                    style={{
                      minHeight: '57.5vh',
                      maxHeight: '100vh',
                      objectFit: 'contain',
                      filter: inStudio ? 'none' : 'blur(5px)',
                    }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={userLocation} icon={defaultIcon}>
                      <Popup>You are here</Popup>
                    </Marker>
                    {inStudio &&
                      nearbyUsers?.map((user, idx) => (
                        <Marker
                          key={idx}
                          position={[user?.lat, user?.lng]}
                          icon={userIcon}
                        >
                          <Popup
                            minWidth={isMobile ? 100 : 300} // Minimum width for the popup
                            maxWidth={isMobile ? 300 : 400} // Maximum width for the popup
                            maxHeight={400} // Maximum height for the popup (scrollable if content overflows)
                          >
                            <WrapItem
                              width={isMobile ? '300px' : '400px'}
                              height="auto"
                            >
                              <UserCard
                                user={user}
                                isNetwork={true}
                                followAndRequest={false}
                                inStudio={true}
                              />
                            </WrapItem>
                          </Popup>
                        </Marker>
                      ))}
                    <Circle center={userLocation} radius={radius} />
                  </MapContainer>
                  {!inStudio && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.8)',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={logo}
                        alt="Logo"
                        style={{ width: '150px', height: '150px' }}
                      />
                      {!showInStudio && (
                        <Text>Upgrate to Pro to use InStudio</Text>
                      )}
                    </div>
                  )}
                </Box>
              ) : (
                <Text>Loading map...</Text>
              )}
            </Box>
          </Box>
        </Center>
      ) : (
        // Desktop
        <Center>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="70vh"
            //mb={12}
            width={'80%'}
          >
            <Box className="App" align="center" pt={6}>
              <div
                style={{
                  // borderRadius: "8px",
                  // border: "1px solid white",
                  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: '8px',
                  width: '100%',
                  marginBottom: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={0}
                >
                  <Text
                    mb={'30px'}
                    mt={3}
                    fontWeight={'bold'}
                    color={'#6899FE'}
                    fontSize={'20px'}
                    px={4}
                    bgColor={'#E2E8F0'}
                    borderRadius={'10px'}
                    borderWidth={'1px'}
                  >
                    InStudio
                  </Text>
                </Box>

                {/* {inStudio && (
                  <>
                    <Box width={'40vw'}>
                      <FormLabel color={'#6899fe'} alignSelf={'flex-start'}>
                        Location
                      </FormLabel>
                      <PlacesAutocomplete
                        location={location}
                        setLocation={setLocation}
                      />
                    </Box>

                    <Flex justifyContent={'center'}>
                      <Button
                        leftIcon={<MdLocationOn color="white" />}
                        mt={4}
                        backgroundColor={'#6899FE'}
                        fontWeight={'normal'}
                        textColor={'white'}
                        onClick={handleLocationSubmit}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                      >
                        Set General Location
                      </Button>
                    </Flex>
                    <Flex justifyContent={'center'} mt={4} gap={3}>
                      <Button
                        onClick={handleLocationSubmit}
                        // bg="#C0BDBD"
                        backgroundColor={'#6899FE'}
                        fontWeight={'normal'}
                        // border="0.2px solid #9F9F9F"
                        textColor={'white'}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={handleRefreshLocation}
                        // bg="#C0BDBD"
                        backgroundColor={'#6899FE'}
                        fontWeight={'normal'}
                        // border="0.2px solid #9F9F9F"
                        textColor={'white'}
                        _hover={{ backgroundColor: '#8fb1f7' }}
                      >
                        Reset
                      </Button>
                    </Flex>
                  </>
                )} */}

                {inStudio && (
                  <Box mt={4} fontWeight="semibold" textAlign="center">
                    <Text flexDirection={'row'}>
                      Adjust Search Radius:{' '}
                      <Input
                        inputMode="numeric"
                        width={20}
                        height={'fit-content'}
                        onChange={handleRadiusChange}
                        value={radius / 1000}
                      />{' '}
                      km
                    </Text>
                  </Box>
                )}

                {showInStudio && (
                  <Switch
                    isChecked={inStudio}
                    onChange={handleInStudioChange}
                    colorScheme="teal"
                    size="lg"
                  >
                    {inStudio ? 'In Studio' : 'Out of Studio'}
                  </Switch>
                )}
              </div>
            </Box>
            <Box
              ml={'100'}
              minWidth={'85vw'}
              border={'0.5px solid #a3a19b'}
              borderRadius={'3px'}
              mb={'20px'}
            >
              {userLocation ? (
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '8px',
                  }}
                >
                  <MapContainer
                    key={mapKey} // Use mapKey as key for MapContainer
                    center={userLocation}
                    zoom={12}
                    minZoom={5}
                    maxZoom={18}
                    style={{
                      minHeight: '65vh',
                      maxHeight: '100vh',
                      objectFit: 'contain',
                      filter: inStudio ? 'none' : 'blur(5px)',
                    }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={userLocation} icon={defaultIcon}>
                      <Popup>You are here</Popup>
                    </Marker>
                    {inStudio &&
                      nearbyUsers?.map((user, idx) => (
                        <Marker
                          key={idx}
                          position={[user?.lat, user?.lng]}
                          icon={userIcon}
                          width={'500px'}
                          height="100px"
                        >
                          <Popup
                            minWidth={isMobile ? 150 : 300} // Minimum width for the popup
                            maxWidth={isMobile ? 200 : 400} // Maximum width for the popup
                            maxHeight={400} // Maximum height for the popup (scrollable if content overflows)
                          >
                            <WrapItem
                              width={isMobile ? '200px' : '400px'}
                              height="auto"
                            >
                              <UserCard
                                user={user}
                                isNetwork={true}
                                followAndRequest={false}
                                inStudio={true}
                              />
                            </WrapItem>
                          </Popup>
                        </Marker>
                      ))}
                    <Circle center={userLocation} radius={radius} />
                  </MapContainer>
                  {!inStudio && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.8)',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={logo}
                        alt="Logo"
                        style={{ width: '150px', height: '150px' }}
                      />
                      {!showInStudio && (
                        <Text>Upgrate to Pro to use InStudio</Text>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <Text>Loading map...</Text>
              )}
            </Box>
          </Box>
        </Center>
      )}
    </>
  );
};

export default InStudio;
