import { SearchIcon } from '@chakra-ui/icons';
import {
  AvatarBadge,
  Badge,
  Box,
  Divider,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  Avatar,
  AvatarGroup,
  Spacer,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
} from '@chakra-ui/react';
import { IoFilterCircleOutline } from 'react-icons/io5';
import { FiMoreVertical } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { db } from 'lib/firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';
import { useUser } from 'hooks/users';

const Search = ({ onChange }) => {
  return (
    <InputGroup borderRadius="xl" backgroundColor="transparent">
      <Input
        type="text"
        placeholder="Search for a group"
        color="black"
        fontSize={12}
        ml={-1}
        mb={2}
        height={'6'}
        onChange={onChange}
      />
      <InputRightElement pointerEvents="none">
        <SearchIcon color="gray.300" mb={4} />
      </InputRightElement>
    </InputGroup>
  );
};

const MutualAvatar = ({ mutualID }) => {
  const { user, isLoading } = useUser(mutualID);
  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!user) {
    return <div>User not found</div>; // Handle user not found scenario
  }

  return (
    <AvatarGroup>
      <Avatar size="sm" src={user.avatar} style={{ pointerEvents: 'none' }} />
    </AvatarGroup>
  );
};

const GroupList = ({ groups, onSelectGroup }) => {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      { isMobile ? (      
    <Stack spacing={3}>
      {groups.map((group) => (
        <Box
          key={group.id}
          borderTop="1px #E2E8F0 solid"
          backgroundColor="white"
          p={2}
          onClick={() => onSelectGroup(group.id)}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Stack direction="row" spacing={2} align="center">
                <AvatarGroup size="sm" max={8}>
                  {group.members.slice(0, 3).map((member, index) => (
                    <MutualAvatar key={index} mutualID={member} />
                  ))}
                  {group.members.length > 3 && (
                    <Text>+{group.members.length - 3}</Text>
                  )}
                </AvatarGroup>
                <Divider
                  orientation="vertical"
                  height="24px"
                  borderColor="#E2E8F0"
                />
                <Stack spacing={0.3} textAlign={'left'}>
                  <Text
                    variant="subtitle2"
                    as="b"
                    fontSize={isMobile ? 'xs' : 'sm'}
                  >
                    {group.groupName}
                  </Text>
                  <Text
                    variant="caption"
                    as="p"
                    fontSize={isMobile ? 'xs' : 'sm'}
                  >
                    <Text as="span" fontWeight="bold">
                      {group.username}
                    </Text>
                    :{' '}
                    {group.lastMessage
                      ? `${group.lastMessage.substring(0, 13)}${
                          group.lastMessage.length > 50 ? '...' : ''
                        }`
                      : 'No messages yet'}
                  </Text>
                </Stack>
              </Stack>
            </Box>
            <Stack spacing={1} alignItems="center">
              <Text
                fontWeight={600}
                fontSize={isMobile ? 'xs' : 'sm'}
                variant="caption"
              >
                {group.lastMessageTime || ''}
              </Text>
              {group.unreadCount > 0 && (
                <Badge
                  backgroundColor="blue"
                  color="white"
                  size={isMobile ? 'lg' : 'xl'}
                  rounded="xl"
                >
                  {group.unreadCount}
                </Badge>
              )}
            </Stack>
            <Flex mt={2}>
              <Text fontSize="2xs" color="gray.500">
                {group.lastMessageTimestamp}
              </Text>
            </Flex>
          </Stack>
        </Box>
      ))}
    </Stack>
    ):( 
    <Stack spacing={3}>
      {groups.map((group) => (
        <Box
          key={group.id}
          borderRadius={'lg'}
          boxShadow={'xl'}
          border="1px #E2E8F0 solid"
          backgroundColor="white"
          style={{
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)', // Custom CSS for shadow
          }}
          p={1}
          _hover={{ bg: '#6899FE', cursor: 'pointer' }}
          onClick={() => onSelectGroup(group.id)}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Stack direction="row" spacing={2} align="center">
                <AvatarGroup size="sm" max={8}>
                  {group.members.slice(0, 3).map((member, index) => (
                    <MutualAvatar key={index} mutualID={member} />
                  ))}
                  {group.members.length > 3 && (
                    <Text>+{group.members.length - 3}</Text>
                  )}
                </AvatarGroup>
                <Divider
                  orientation="vertical"
                  height="24px"
                  borderColor="#E2E8F0"
                />
                <Stack spacing={0.3} textAlign={'left'}>
                  <Text
                    variant="subtitle2"
                    as="b"
                    fontSize={isMobile ? 'xs' : 'sm'}
                  >
                    {group.groupName}
                  </Text>
                  <Text
                    variant="caption"
                    as="p"
                    fontSize={isMobile ? 'xs' : 'sm'}
                  >
                    <Text as="span" fontWeight="bold">
                      {group.username}
                    </Text>
                    :{' '}
                    {group.lastMessage
                      ? `${group.lastMessage.substring(0, 13)}${
                          group.lastMessage.length > 50 ? '...' : ''
                        }`
                      : 'No messages yet'}
                  </Text>
                </Stack>
              </Stack>
            </Box>
            <Stack spacing={1} alignItems="center">
              <Text
                fontWeight={600}
                fontSize={isMobile ? 'xs' : 'sm'}
                variant="caption"
              >
                {group.lastMessageTime || ''}
              </Text>
              {group.unreadCount > 0 && (
                <Badge
                  backgroundColor="blue"
                  color="white"
                  size={isMobile ? 'lg' : 'xl'}
                  rounded="xl"
                >
                  {group.unreadCount}
                </Badge>
              )}
            </Stack>
            <Flex mt={2}>
              <Text fontSize="2xs" color="gray.500">
                {group.lastMessageTimestamp}
              </Text>
            </Flex>
          </Stack>
        </Box>
      ))}
    </Stack>
    )}
    </>
  );
};

const Groups = ({ onGroupSelect, onOpenCreateGroup }) => {
  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const { user: authUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!authUser?.id) return;

    const handleClickOutside = () => setDropdownOpen(false);

    document.addEventListener('click', handleClickOutside);

    const userDocRef = doc(db, 'users', authUser.id);
    const unsubscribeFromUserGroups = onSnapshot(userDocRef, (userDoc) => {
      if (!userDoc.exists()) {
        console.log('No user document found');
        return;
      }

      const userGroups = userDoc.data().groups || [];
      const groupsPromises = userGroups.map(async (groupId) => {
        let lastMessageTimestamp;

        const groupDocRef = doc(db, 'groups', groupId);
        const groupSnap = await getDoc(groupDocRef);
        if (!groupSnap.exists()) return null;

        const groupData = { id: groupId, ...groupSnap.data() };

        const messagesRef = collection(db, 'groups', groupId, 'messages');
        const lastMessageQuery = query(
          messagesRef,
          orderBy('createdAt', 'desc'),
          limit(1)
        );
        const querySnapshot = await getDocs(lastMessageQuery);

        let lastMessageText = 'No messages yet';
        let username = 'Unknown';

        if (!querySnapshot.empty) {
          const lastMessageDoc = querySnapshot.docs[0];
          const lastMessageData = lastMessageDoc.data();

          lastMessageText =
            lastMessageData.subtype === 'doc'
              ? 'Media Content'
              : lastMessageData.message;

          const userDocRef = doc(db, 'users', lastMessageData.sentBy);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            username = userDocSnap.data().username;
          }

          if (
            lastMessageData.createdAt &&
            typeof lastMessageData.createdAt.toDate === 'function'
          ) {
            lastMessageTimestamp = lastMessageData.createdAt.toDate();
            lastMessageTimestamp = formatDate(lastMessageTimestamp);
          } else {
            lastMessageTimestamp = new Date();
            lastMessageTimestamp = formatDate(lastMessageTimestamp);
          }
        }

        return {
          ...groupData,
          lastMessage: lastMessageText,
          username,
          lastMessageTimestamp,
        };
      });

      Promise.all(groupsPromises).then((groupsWithLastMessageAndUsername) => {
        setGroups(groupsWithLastMessageAndUsername.filter(Boolean));
      });
    });

    return () => {
      unsubscribeFromUserGroups();
      document.removeEventListener('click', handleClickOutside);
    };
  }, [authUser?.id]);

  const formatDate = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const messageDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    if (messageDate.getTime() === today.getTime()) {
      return `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    } else {
      return `${date.getDate().toString().padStart(2, '0')}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}/${date.getFullYear().toString().substr(-2)}`;
    }
  };

  const handleSelectGroup = (groupId) => {
    onGroupSelect(groupId);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      width="100%"
      borderRight={isMobile ? 'none' : '1px solid #E2E8F0'}
      height={'80vh'}
    >
      <Box
        height={'100%'}
        width="100%"
        backgroundColor={colorMode === 'light' ? '#fff' : 'blackAlpha.300'}
        boxShadow={'sm'}
      >
        <Stack width="100%">
          <Search onChange={handleSearchChange} />
        </Stack>
        <Stack spacing={2} height={'100%'}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Text marginBottom={3} fontSize="14px" color="#6899FE">
              All Group Chats
            </Text>
            <Spacer />
            <IconButton
              backgroundColor={'transparent'}
              icon={<IoFilterCircleOutline color="#6899FE" />}
              aria-label="Filter"
              size="sm"
              _hover={{ backgroundColor: 'transparent' }}
              ml={1}
            />
            <Menu isOpen={dropdownOpen} onClose={() => setDropdownOpen(false)}>
              <MenuButton
                as={IconButton}
                backgroundColor={'transparent'}
                icon={<FiMoreVertical color="#6899FE" />}
                size="sm"
                _hover={{ backgroundColor: 'transparent' }}
                onClick={handleMenuClick}
              />
              <MenuList border="1px #6899FE solid" py={1}>
                <MenuItem
                  borderBottom="1px #6899FE solid"
                  fontSize="xs"
                  py={-10}
                  onClick={onOpenCreateGroup}
                >
                  New Group
                </MenuItem>
                <MenuItem
                  fontSize="xs"
                  py={-10}
                  onClick={() => console.log('Select Group clicked')}
                >
                  Select Group
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>

          <Stack
            direction={'column'}
            flexGrow={1}
            overflow="scroll"
            height={'100vh'}
            spacing={2}
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#6899fe',
                borderRadius: '24px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#6899fe',
              },
            }}
          >
            <Stack spacing={2.4}>
              <GroupList
                groups={filteredGroups}
                onSelectGroup={handleSelectGroup}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Groups;
