import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { ToggleSidebar } from "./redux/slices/app";
import { db } from "lib/firebase";
import { collection, query, where, getDocs, writeBatch, doc, deleteDoc } from "firebase/firestore";
import { useAuth } from "hooks/auth";
import { useUser } from "hooks/users";
import MediaContent from "./mediaContent";
import { useNavigate } from 'react-router-dom';

const Contact = ({ userPressed, setUserPressed }) => {
  const dispatch = useDispatch();
  const { user: authUser } = useAuth();
  const { user: userr, isLoading: isLoadingUserr } = useUser(userPressed);
  const [sharedMedia, setSharedMedia] = useState([]);
  const [sharedLinks, setSharedLinks] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [value, setValue] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchSharedMessages = async () => {
      if (!authUser?.id || !userPressed) return;

      try {
        const messagesRef = collection(db, "users", authUser.id, "chats", userPressed, "timestamp");

        // Fetch shared media
        const mediaQuery = query(messagesRef, where("subtype", "==", "img"));
        const mediaSnapshot = await getDocs(mediaQuery);
        setSharedMedia(mediaSnapshot.docs.map((doc) => doc.data()));

        // Fetch shared links
        const linksQuery = query(messagesRef, where("subtype", "==", "link"));
        const linksSnapshot = await getDocs(linksQuery);
        setSharedLinks(linksSnapshot.docs.map((doc) => doc.data()));

        // Fetch shared docs
        const docsQuery = query(messagesRef, where("subtype", "==", "doc"));
        const docsSnapshot = await getDocs(docsQuery);
        setSharedDocs(docsSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching shared messages:", error);
      }
    };

    fetchSharedMessages();
  }, [authUser, userPressed]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const deleteChat = async () => {
    if (!authUser?.id || !userPressed) return;

    // Close the modal instantly
    onConfirmClose();

    try {
      // Reference to the current user's chat document
      const currentUserChatDocRef = doc(db, "users", authUser.id, "chats", userPressed);

      // Reference to the other participant's chat document
      const otherUserChatDocRef = doc(db, "users", userPressed, "chats", authUser.id);

      // First, delete all the messages in the current user's chat
      const messagesRef = collection(currentUserChatDocRef, "timestamp");
      const messagesSnapshot = await getDocs(messagesRef);
      const batch = writeBatch(db);

      messagesSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Now delete the chat document for both users
      await deleteDoc(currentUserChatDocRef); // Delete for the current user
      await deleteDoc(otherUserChatDocRef); // Delete for the other participant

      console.log("Chat deleted successfully for both participants.");

      // Reset the chat state and navigate back to the dashboard
      setUserPressed(null);
      navigate('/protected/dashboard');
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  if (isLoadingUserr) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box width={isMobile ? "100vw" : "100%"} height={isMobile ? '100%' : '75vh'} borderLeft={isMobile ? "" : "1px #E2E8F0 solid"}>
      <Stack height={"100%"}>
        <Box 
          width={"100%"} 
          borderBottom="1px #E2E8F0 solid" 
          backgroundColor={'#transparent'}
          p={2}
          height={'10%'}
        >
          <Stack
            height={"100%"}
            direction={"row"}
            alignItems={"center"}
          >
            <IconButton
              onClick={() => {
                dispatch(ToggleSidebar());
              }}
              icon={<FiChevronLeft color="#6899FE"/>}
              backgroundColor={"transparent"}
              size="lg"
              _hover={{ backgroundColor: "transparent" }}
            />
            <Text fontSize="xl" as="b">
              Profile Information
            </Text>
          </Stack>
        </Box>
        <Stack
          height={"90%"}
          position={"relative"}
          flexGrow={1}
          overflowY={"scroll"}
          spacing={0}
        >
          <Stack alignItems={"center"} direction={"row"} ml={2}>
            <Avatar src={userr?.avatar} alt="Name" height={12} width={12} />
            <Stack spacing={0.5}>
              <Text variant={"article"} fontWeight={600}>
                {userr?.fullName ? userr.fullName : userr?.businessName ? userr.businessName : "Error"}
              </Text>
            </Stack>
          </Stack>

          <Stack spacing={0} alignItems="stretch" width="100%">
            <Stack direction="row" spacing={0} width="100%" borderTop="1px solid #E2E8F0">
              <Button width="50%" variant="outline" borderRadius="0" borderRight="1px solid #E2E8F0" borderTop="0" _hover={{ bg: "red.600", color: "white" }} onClick={onConfirmOpen}>
                Delete Chat
              </Button>
              <Button onClick={onOpen} width="50%" variant="outline" borderRadius="0" borderTop="0" _hover={{ bg: "#6899FE", color: "white" }}>
                Share Contact
              </Button>
            </Stack>
          </Stack>

          {/* Share Contact Modal */}
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Share Contact</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={4}>Share the profile information via:</Text>
                <Stack spacing={4}>
                  <Input placeholder="Email" />
                  <Button as={Link} href={`mailto:?subject=Profile Information&body=Check out this profile: ${userr?.fullName || userr?.businessName}`} variant="outline" width="100%" _hover={{ bg: "#6899FE", color: "white" }}>
                    Email
                  </Button>
                  <Button as={Link} href={`https://www.facebook.com/sharer/sharer.php?u=https://profile.url/${userr?.id}`} target="_blank" variant="outline" width="100%" _hover={{ bg: "#6899FE", color: "white" }}>
                    Facebook
                  </Button>
                  <Button as={Link} href={`https://twitter.com/intent/tweet?text=Check out this profile: https://profile.url/${userr?.id}`} target="_blank" variant="outline" width="100%" _hover={{ bg: "#6899FE", color: "white" }}>
                    Twitter
                  </Button>
                  <Button as={Link} href={`https://wa.me/?text=Check out this profile: https://profile.url/${userr?.id}`} target="_blank" variant="outline" width="100%" _hover={{ bg: "#6899FE", color: "white" }}>
                    WhatsApp
                  </Button>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose} variant="outline" _hover={{ bg: "#6899FE", color: "white" }}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Confirmation Modal for Deleting Chat */}
          <Modal isOpen={isConfirmOpen} onClose={onConfirmClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader></ModalHeader>
              
              <ModalBody>
                <Text>Are you sure you want to delete the chat? This action cannot be undone.</Text>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onConfirmClose} mr={3} variant="outline" _hover={{ bg: "#6899FE", color: "white" }}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deleteChat();
                    navigate('/protected/dashboard');  // Navigate back to dashboard after chat deletion
                  }}
                  colorScheme="red"
                >
                  Delete Chat
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Stack direction={"column"} width="100%" borderTop="1px solid #E2E8F0">
            <Tabs onChange={(value) => handleChange(value)} variant="unstyled">
              <TabList borderBottom="1px solid #E2E8F0" width="100%">
                <Tab
                  _selected={{ color: "white", bg: "#6899FE" }}
                  _focus={{ boxShadow: "none" }}
                  borderRight="1px solid #E2E8F0"
                  borderRadius="0"
                  width="33.33%"
                  _hover={{ bg: "#6899FE", color: "white" }}
                >
                  Media
                </Tab>
                <Tab
                  _selected={{ color: "white", bg: "#6899FE" }}
                  _focus={{ boxShadow: "none" }}
                  borderRight="1px solid #E2E8F0"
                  borderRadius="0"
                  width="33.33%"
                  _hover={{ bg: "#6899FE", color: "white" }}
                >
                  Links
                </Tab>
                <Tab
                  _selected={{ color: "white", bg: "#6899FE" }}
                  _focus={{ boxShadow: "none" }}
                  borderRadius="0"
                  width="33.33%"
                  _hover={{ bg: "#6899FE", color: "white" }}
                >
                  Docs
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={3} spacing={2}>
                    {sharedMedia.length > 0 ? (
                      sharedMedia.map((el, index) => (
                        <MediaContent key={index} message={el} />
                      ))
                    ) : (
                      <Text>No media found</Text>
                    )}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={3} spacing={2}>
                    {sharedLinks.length > 0 ? (
                      sharedLinks.map((el, index) => (
                        <MediaContent key={index} message={el} />
                      ))
                    ) : (
                      <Text>No links found</Text>
                    )}
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid row={3} spacing={2}>
                    {sharedDocs.length > 0 ? (
                      sharedDocs.map((el, index) => (
                        <MediaContent key={index} message={el} />
                      ))
                    ) : (
                      <Text>No documents found</Text>
                    )}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Contact;
