import React, { useState, useEffect, useRef } from 'react';
// import BgImage from "./forums-bg.svg";
import BgImage from './assets/forums-bg.svg';
import PlaceHolderOne from './assets/placeholder1.jpg';
import PlaceHolderTwo from './assets/placehoder2.jpg';
import NextIndicator from './assets/next-indicator.png';
import BackIndicator from './assets/back-indicator.png';
import SortIcon from './assets/sort.png';
import UpVoteImg from './assets/upvote.svg';
import DownVoteImg from './assets/downvote.svg';
import {
  Menu,
  useDisclosure,
  Link,
  Modal,
  FormControl,
  FormLabel,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  IconButton,
  Container,
  Flex,
  Box,
  Image,
  Divider,
  Text,
  Stack,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useBreakpointValue,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar,
  SimpleGrid,
  Grid,
} from '@chakra-ui/react';
import {
  FaCog,
  FaArrowUp,
  FaArrowDown,
  FaComment,
  FaSearch,
  FaShare,
} from 'react-icons/fa';
import { FiMoreVertical } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import { FORUMS } from 'lib/routes';
import { useAuth } from 'hooks/auth';
import { app, db, auth, storage, firestore } from 'lib/firebase';
import { runTransaction } from 'firebase/firestore';

import {
  collection,
  addDoc,
  getDoc,
  where,
  query,
  setDoc,
  doc,
  getDocs,
  updateDoc,
  serverTimestamp,
  deleteDoc,
} from '@firebase/firestore';
import { PROTECTED } from 'lib/routes';
import { Spinner } from '@chakra-ui/react';
import { transform } from 'typescript';
import logoM from 'Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png';
import UpvoteImg from './assets/upvote.svg';
import DownvoteImg from './assets/downvote.svg';
import CommentImg from './assets/comment.svg';
import ShareImg from './assets/share.svg';
import OptionsImg from './assets/options.svg';
import { Link as RouterLink } from 'react-router-dom';
import './loading.css';
import { formatDistanceToNow } from 'date-fns';

function Loading() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Stack>
      <div class="three-body">
        <div class="three-body__dot"></div>
        <div class="three-body__dot"></div>
        <div class="three-body__dot"></div>
      </div>
    </Stack>
  );
}

function FilteredPosts({
  key,
  forumId,
  postId,
  user,
  postTitle,
  post,
  upvotes,
  comments,
  createdAt,
  imageUrls, // Add imageUrl to the props
  forumTitle,
}) {
  const [showShare, setShowShare] = useState(false);
  const [votes, setVotes] = useState(upvotes);
  const [showComments, setShowComments] = useState(false);
  const [commentText, setCommentText] = useState('');
  const authUser = useAuth();

  const [userVote, setUserVote] = useState(null);
  const [postComments, setPostComments] = useState([]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedPost, setEditedPost] = useState(post);

  const isPostOwner = authUser?.user?.username === user;
  const [authorAvatar, setAuthorAvatar] = useState(null);

  const [isTruncated, setIsTruncated] = useState(false);
  const postRef = useRef(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetchAuthorAvatar = async (username) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setAuthorAvatar(userData.avatar || null);
      }
    } catch (error) {
      console.error('Error fetching author avatar:', error);
    }
  };

  const formatCreatedAt = (createdAt) => {
    let date;
    if (createdAt && typeof createdAt.toDate === 'function') {
      // It's a Firestore Timestamp
      date = createdAt.toDate();
    } else if (createdAt && createdAt.seconds) {
      // It's a Firestore Timestamp-like object
      date = new Date(createdAt.seconds * 1000);
    } else if (createdAt && createdAt instanceof Date) {
      // It's already a Date object
      date = createdAt;
    } else if (createdAt && !isNaN(new Date(createdAt).getTime())) {
      // It's a valid date string or timestamp
      date = new Date(createdAt);
    } else {
      // Invalid or missing date
      return 'Invalid date';
    }

    return formatDistanceToNow(date) + ' ago';
  };

  useEffect(() => {
    fetchAuthorAvatar(user);
  }, [user]);

  const handleEditPost = () => {
    setEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
      await updateDoc(postDocRef, { post: editedPost });
      setEditModalOpen(false);

      window.location.reload();
    } catch (error) {
      console.error('Error editing post: ', error);
    }
  };

  const handleCancelEdit = () => {
    setEditModalOpen(false);
  };

  const handleDeletePost = async () => {
    try {
      const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
      await deleteDoc(postDocRef);
      window.location.reload();
    } catch (error) {
      console.error('Error deleting post: ', error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [postId]);

  const fetchComments = async () => {
    try {
      const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
      const commentsCollectionRef = collection(postDocRef, 'comments');
      const commentsSnapshot = await getDocs(commentsCollectionRef);
      const commentsData = commentsSnapshot.docs.map((doc) => doc.data());
      setPostComments(commentsData);
    } catch (error) {
      console.error('Error fetching comments: ', error);
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const handleCommentSubmit = async () => {
    if (commentText.trim() === '') {
      return;
    }

    try {
      const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
      const commentsCollectionRef = collection(postDocRef, 'comments');

      await addDoc(commentsCollectionRef, {
        user: authUser?.user?.username,
        text: commentText,
        createdAt: serverTimestamp(),
      });

      setPostComments((prevComments) => [
        ...prevComments,
        { user: authUser?.user?.username, text: commentText },
      ]);

      setCommentText('');
    } catch (error) {
      console.error('Error adding comment: ', error);
    }
  };

  useEffect(() => {
    const fetchUserVote = async () => {
      if (!authUser?.user) return;

      const userVoteDocRef = doc(
        db,
        'userVotes',
        `${authUser?.user?.uid}_${postId}`
      );
      const userVoteDocSnap = await getDoc(userVoteDocRef);

      if (userVoteDocSnap.exists()) {
        setUserVote(userVoteDocSnap.data().voteType);
      } else {
        setUserVote(null);
      }
    };

    fetchUserVote();
  }, [postId, authUser?.user]);

  const handleUpvote = async () => {
    if (!authUser?.user) return; // Ensure user is logged in

    const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
    const userVoteDocRef = doc(
      db,
      'userVotes',
      `${authUser.user.uid}_${postId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!postDoc.exists()) {
          throw new Error('Post does not exist!');
        }

        const currentVotes = postDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = 'upvote';

        if (!userVoteDoc.exists()) {
          // New upvote
          newVotes = currentVotes + 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === 'upvote') {
            // Remove upvote
            newVotes = currentVotes - 1;
            newUserVote = null;
          } else if (currentUserVote === 'downvote') {
            // Change from downvote to upvote
            newVotes = currentVotes + 2;
          }
        }

        transaction.update(postDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setVotes(newVoteData.newVotes);
      setUserVote(newVoteData.newUserVote);
    } catch (error) {
      console.error('Error updating vote: ', error);
    }
  };

  const handleDownvote = async () => {
    if (!authUser?.user) return; // Ensure user is logged in

    const postDocRef = doc(db, 'forums', forumId, 'posts', postId);
    const userVoteDocRef = doc(
      db,
      'userVotes',
      `${authUser.user.uid}_${postId}`
    );

    try {
      const newVoteData = await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postDocRef);
        const userVoteDoc = await transaction.get(userVoteDocRef);

        if (!postDoc.exists()) {
          throw new Error('Post does not exist!');
        }

        const currentVotes = postDoc.data().upvotes || 0;
        let newVotes = currentVotes;
        let newUserVote = 'downvote';

        if (!userVoteDoc.exists()) {
          // New downvote
          newVotes = currentVotes - 1;
        } else {
          const currentUserVote = userVoteDoc.data().voteType;
          if (currentUserVote === 'downvote') {
            // Remove downvote
            newVotes = currentVotes + 1;
            newUserVote = null;
          } else if (currentUserVote === 'upvote') {
            // Change from upvote to downvote
            newVotes = currentVotes - 2;
          }
        }

        transaction.update(postDocRef, { upvotes: newVotes });
        if (newUserVote) {
          transaction.set(userVoteDocRef, { voteType: newUserVote });
        } else {
          transaction.delete(userVoteDocRef);
        }

        return { newVotes, newUserVote };
      });

      setVotes(newVoteData.newVotes);
      setUserVote(newVoteData.newUserVote);
    } catch (error) {
      console.error('Error updating vote: ', error);
    }
  };

  const renderImageGrid = () => {
    if (!imageUrls || imageUrls.length === 0) return null;

    const imagesToShow = imageUrls.slice(0, 4);
    const remainingCount = imageUrls.length - 4;

    if (imagesToShow.length === 1) {
      return (
        <Image
          src={imagesToShow[0]}
          height={isMobile ? '50px' : '100px'}
          width={isMobile ? '75px' : '150px'}
          objectFit="cover"
          borderRadius="md"
          mb={isMobile ? '0px' : '5px'}
          mt={isMobile ? '10px' : '0px'}
        />
      );
    }

    if (imagesToShow.length === 2) {
      return (
        <SimpleGrid
          columns={2}
          spacing={2}
          mb={isMobile ? '0px' : '5px'}
          mt={isMobile ? '10px' : '0px'}
        >
          {imagesToShow.map((url, index) => (
            <Image
              key={index}
              src={url}
              height={isMobile ? '50px' : '100px'}
              width={isMobile ? '75px' : '150px'}
              objectFit="cover"
              borderRadius="md"
            />
          ))}
        </SimpleGrid>
      );
    }

    if (imagesToShow.length === 3) {
      return (
        <SimpleGrid
          columns={3}
          spacing={2}
          mb={isMobile ? '0px' : '5px'}
          mt={isMobile ? '10px' : '0px'}
        >
          {imagesToShow.map((url, index) => (
            <Image
              key={index}
              src={url}
              height={isMobile ? '50px' : '100px'}
              width={isMobile ? '75px' : '150px'}
              objectFit="cover"
              borderRadius="md"
            />
          ))}
        </SimpleGrid>
      );
    }

    return (
      <SimpleGrid
        columns={4}
        spacing={1}
        mb={isMobile ? '0px' : '5px'}
        mt={isMobile ? '10px' : '0px'}
      >
        {imagesToShow.map((url, index) => (
          <Box key={index} position="relative">
            <Image
              src={url}
              height={isMobile ? '50px' : '100px'}
              width={isMobile ? '75px' : '150px'}
              objectFit="cover"
              borderRadius="md"
            />
            {index === 3 && remainingCount > 0 && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="rgba(0,0,0,0.6)"
                color="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
              >
                +{remainingCount}
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  useEffect(() => {
    const checkTextHeight = () => {
      if (postRef.current) {
        const lineHeight = parseInt(
          window.getComputedStyle(postRef.current).lineHeight
        );
        const height = postRef.current.scrollHeight;
        setIsTruncated(height > lineHeight * 2);
      }
    };

    checkTextHeight();
    window.addEventListener('resize', checkTextHeight);
    return () => window.removeEventListener('resize', checkTextHeight);
  }, [post]);

  return (
    <>
      {!isMobile && (
        <Box p="4" pb={'0'} ml={'20px'} mr={'20px'} mb={'30px'}>
          <Box
            p="4"
            pb={'0'}
            mt={-6}
            ml={'20px'}
            mr={'20px'}
            bg="white"
            borderRadius="lg"
            position="relative"
            border={'1px solid #6899FE'}
            boxShadow={'0px 2px 5px #9F9F9F'}
            _hover={{
              backgroundColor: '#f7faff',
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Box flex="1" ml="4">
                <HStack>
                  <Avatar
                    height={'40px'}
                    width={'40px'}
                    src={authorAvatar || logoM}
                  />
                  <Flex
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    flexDirection={'column'}
                    ml={2}
                  >
                    <Text
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#6899FE"
                      whiteSpace={'nowrap'}
                      as={RouterLink}
                      to={`${PROTECTED}/forums/${forumTitle}/${forumId}`}
                      variant="link"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {forumTitle}
                    </Text>
                    <Button
                      color="black"
                      fontSize={'12px'}
                      as={Link}
                      to={`${PROTECTED}/profile/${user}`}
                      variant="link"
                    >
                      {user}
                    </Button>

                    <Text fontSize="10px" color="#9F9F9F" whiteSpace={'nowrap'}>
                      {formatCreatedAt(createdAt)}
                    </Text>
                  </Flex>

                  {isPostOwner && (
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      width="100%"
                    >
                      <Menu>
                        <MenuButton
                          mr={'2px'}
                          as={IconButton}
                          background={'white'}
                          backgroundImage={OptionsImg}
                          backgroundRepeat={'no-repeat'}
                          backgroundPosition={'center'}
                          backgroundSize={'20px'}
                          _hover={{
                            background: 'white',
                            backgroundImage: `${OptionsImg}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                          }}
                        />
                        <MenuList
                          border="1px"
                          borderColor="#6899FE87"
                          width="100px"
                          sx={{ minWidth: '100px !important' }}
                          padding={'1px'}
                        >
                          <MenuItem
                            onClick={handleEditPost}
                            borderBottom="1px"
                            borderColor="#6899FE87"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={'0px'}
                          >
                            Edit Post
                          </MenuItem>
                          <MenuItem
                            onClick={handleDeletePost}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={'0px'}
                          >
                            Delete Post
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  )}
                </HStack>

                <Text
                  m={'5px 0'}
                  fontSize={'13px'}
                  fontWeight={'bold'}
                  as={RouterLink}
                  to={'/protected/forum/forumPosts/' + forumId + '/' + postId}
                  _hover={{
                    textDecor: 'underline',
                  }}
                >
                  {postTitle}
                </Text>

                <VStack alignItems={'flex-start'}>
                  <Text
                    as={RouterLink}
                    to={`/protected/forum/forumPosts/${forumId}/${postId}`}
                    ref={postRef}
                    fontSize="10px"
                    mb={0}
                    color={'#696969'}
                    noOfLines={2}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {post}
                    {isTruncated && '...'}
                  </Text>
                  <HStack justifyContent={'center'} alignItems={'center'}>
                    {renderImageGrid()}
                  </HStack>
                </VStack>
              </Box>
            </Flex>
            <Modal isOpen={editModalOpen} onClose={handleCancelEdit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Post</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    value={editedPost}
                    onChange={(e) => setEditedPost(e.target.value)}
                    placeholder="Edit your post..."
                  />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleSaveEdit}>
                    Save
                  </Button>
                  <Button variant="ghost" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <HStack spacing="1">
              <IconButton
                background={'white'}
                backgroundImage={UpvoteImg}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundSize={'15px'}
                onClick={handleUpvote}
                _hover={{
                  backgroundImage: `${UpvoteImg}`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <Text fontSize="sm" fontWeight="bold" textAlign="center">
                {votes}
              </Text>
              <IconButton
                background={'white'}
                backgroundImage={DownvoteImg}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundSize={'15px'}
                onClick={handleDownvote}
                _hover={{
                  backgroundImage: `${DownvoteImg}`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <VStack>
                <Button
                  ml={'20px'}
                  background={'white'}
                  backgroundImage={CommentImg}
                  backgroundRepeat={'no-repeat'}
                  backgroundPosition={'center'}
                  backgroundSize={'15px'}
                  onClick={toggleComments}
                  _hover={{
                    backgroundImage: `${CommentImg}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                ></Button>
              </VStack>
              <Text fontSize={'sm'} cursor="pointer">
                {postComments.length}
              </Text>
              <Flex justifyContent="flex-end" alignItems="center" width="100%">
                <IconButton
                  background={'white'}
                  backgroundImage={ShareImg}
                  backgroundRepeat={'no-repeat'}
                  backgroundPosition={'center'}
                  backgroundSize={'20px'}
                  _hover={{
                    backgroundImage: `${ShareImg}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '15px',
                  }}
                  onClick={() => setShowShare(!showShare)}
                />
              </Flex>
            </HStack>
          </Box>
          {showComments && (
            <Box mt="12px" ml={'20px'} mr={'20px'}>
              {postComments.map((comment, index) => (
                <Comment key={index} {...comment} />
              ))}
              <InputGroup mt="2">
                <Textarea
                  placeholder="Add a comment..."
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  width="100%"
                  variant="flushed"
                  autoComplete="off"
                  resize="vertical"
                  minH="5px"
                  overflow="hidden"
                  transition="height 0.2s"
                />
                <Button
                  mt="1"
                  ml="3"
                  colorScheme="blue"
                  size="sm"
                  onClick={handleCommentSubmit}
                >
                  Comment
                </Button>
              </InputGroup>
            </Box>
          )}
        </Box>
      )}

      {isMobile && (
        <Box pb={'0'} mb={'30px'}>
          <Box
            p="4"
            pb={'0'}
            mt={-6}
            bg="white"
            borderRadius="lg"
            position="relative"
            border={'1px solid #6899FE'}
            boxShadow={'0px 2px 5px #9F9F9F'}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Box flex="1" ml="2">
                <HStack>
                  <Avatar
                    height={'30px'}
                    width={'30px'}
                    src={authorAvatar || logoM}
                  />
                  <Flex
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    flexDirection={'column'}
                  >
                    <Text
                      fontSize="10px"
                      fontWeight={'600'}
                      color="#6899FE"
                      whiteSpace={'nowrap'}
                      as={RouterLink}
                      to={`${PROTECTED}/forums/${forumTitle}/${forumId}`}
                      variant="link"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {forumTitle}
                    </Text>
                    <Button
                      color="black"
                      fontSize={'10px'}
                      as={Link}
                      to={`${PROTECTED}/profile/${user}`}
                      variant="link"
                    >
                      {user}
                    </Button>
                    <Text fontSize="8px" color="#9F9F9F" whiteSpace={'nowrap'}>
                      {formatCreatedAt(createdAt)}
                    </Text>
                  </Flex>

                  {isPostOwner && (
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      width="100%"
                    >
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          background={'white'}
                          backgroundImage={OptionsImg}
                          backgroundRepeat={'no-repeat'}
                          backgroundPosition={'center'}
                          backgroundSize={'12px'}
                          _hover={{
                            background: 'white',
                            backgroundImage: `${OptionsImg}`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: '12px',
                          }}
                        />
                        <MenuList
                          border="1px"
                          borderColor="#6899FE87"
                          width="100px"
                          sx={{ minWidth: '100px !important' }}
                          padding={'1px'}
                        >
                          <MenuItem
                            onClick={handleEditPost}
                            borderBottom="1px"
                            borderColor="#6899FE87"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={'0px'}
                            fontSize={'10px'}
                          >
                            Edit Post
                          </MenuItem>
                          <MenuItem
                            onClick={handleDeletePost}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={'0px'}
                            fontSize={'10px'}
                          >
                            Delete Post
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                  )}
                </HStack>

                <Text
                  m={'5px 0'}
                  fontSize={'10px'}
                  fontWeight={'bold'}
                  as={RouterLink}
                  to={'/protected/forum/forumPosts/' + forumId + '/' + postId}
                  _hover={{
                    textDecor: 'underline',
                  }}
                >
                  {postTitle}
                </Text>

                <VStack alignItems={'flex-start'} gap={0}>
                  <Text
                    as={RouterLink}
                    to={`/protected/forum/forumPosts/${forumId}/${postId}`}
                    ref={postRef}
                    fontSize="10px"
                    mb={0}
                    color={'#696969'}
                    noOfLines={2}
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {post}
                    {isTruncated && '...'}
                  </Text>
                  <HStack justifyContent={'center'} alignItems={'center'}>
                    {renderImageGrid()}
                  </HStack>
                </VStack>
              </Box>
            </Flex>
            <Modal isOpen={editModalOpen} onClose={handleCancelEdit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Post</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    value={editedPost}
                    onChange={(e) => setEditedPost(e.target.value)}
                    placeholder="Edit your post..."
                  />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleSaveEdit}>
                    Save
                  </Button>
                  <Button variant="ghost" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <HStack spacing="0">
              <IconButton
                background={'white'}
                backgroundImage={UpvoteImg}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundSize={'12px'}
                onClick={handleUpvote}
                _hover={{
                  backgroundImage: `${UpvoteImg}`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '12px',
                }}
              />
              <Text fontSize="9px" fontWeight="bold" textAlign="center">
                {votes}
              </Text>
              <IconButton
                background={'white'}
                backgroundImage={DownvoteImg}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundSize={'12px'}
                onClick={handleDownvote}
                _hover={{
                  backgroundImage: `${DownvoteImg}`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '12px',
                }}
              />
              <VStack>
                <Button
                  ml={'0px'}
                  background={'white'}
                  backgroundImage={CommentImg}
                  backgroundRepeat={'no-repeat'}
                  backgroundPosition={'center'}
                  backgroundSize={'12px'}
                  onClick={toggleComments}
                  _hover={{
                    backgroundImage: `${CommentImg}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '12px',
                  }}
                ></Button>
              </VStack>
              <Text fontSize={'9'} cursor="pointer">
                {postComments.length}
              </Text>
              <Flex justifyContent="flex-end" alignItems="center" width="100%">
                <IconButton
                  background={'white'}
                  backgroundImage={ShareImg}
                  backgroundRepeat={'no-repeat'}
                  backgroundPosition={'center'}
                  backgroundSize={'12px'}
                  _hover={{
                    backgroundImage: `${ShareImg}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '12px',
                  }}
                  onClick={() => setShowShare(!showShare)}
                />
              </Flex>
            </HStack>
          </Box>
          {showComments && (
            <Box mt="10px">
              {postComments.map((comment, index) => (
                <Comment key={index} {...comment} />
              ))}
              <InputGroup mt="2">
                <Textarea
                  placeholder="Add a comment..."
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  width="100%"
                  variant="flushed"
                  autoComplete="off"
                  resize="vertical"
                  minH="5px"
                  overflow="hidden"
                  transition="height 0.2s"
                />
                <Button
                  mt="1"
                  ml="3"
                  colorScheme="blue"
                  size="sm"
                  onClick={handleCommentSubmit}
                >
                  Comment
                </Button>
              </InputGroup>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

function Comment({ user, text }) {
  return (
    <Box mt="2" p="2" bg="gray.100" borderRadius="md">
      <Button
        color="blue.500"
        as={Link}
        to={`${PROTECTED}/profile/${user}`}
        colorScheme={'#1041B2'}
        variant="link"
      >
        {user}
      </Button>
      <Text>{text}</Text>
    </Box>
  );
}

function Forums({
  id,
  title,
  owner,
  members,
  posts,
  postId,
  upvotes,
  totalVotes,
  forumId,
}) {
  const [votes, setVotes] = useState(upvotes);
  const [userVote, setUserVote] = useState(null);
  const authUser = useAuth();

  useEffect(() => {
    const fetchUserVote = async () => {
      if (!authUser?.user) return;

      const userVoteDocRef = doc(
        db,
        'userVotes',
        `${authUser?.user?.uid}_${postId}`
      );
      const userVoteDocSnap = await getDoc(userVoteDocRef);

      if (userVoteDocSnap.exists()) {
        setUserVote(userVoteDocSnap.data().voteType);
      } else {
        setUserVote(null);
      }
    };

    fetchUserVote();
  }, [postId, authUser?.user]);

  const joinForum = async () => {
    try {
      const forumDocRef = doc(db, 'forums', forumId);
      const forumDoc = await getDoc(forumDocRef);

      if (forumDoc.exists()) {
        const forumData = forumDoc.data();
        const currentMembers = forumData.members || [];

        // Check if the user is already a member
        if (!currentMembers.includes(auth?.currentUser?.uid)) {
          // Add user's UID to the members array
          currentMembers.push(auth?.currentUser?.uid);

          // Update the members array in the database
          await updateDoc(forumDocRef, { members: currentMembers });

          // Update the local state to reflect the change
        }
      }

      window.location.reload();
    } catch (error) {
      console.error('Error joining forum: ', error);
    }
  };

  const isMember = members?.includes(auth?.currentUser?.uid);
  const backgroundColor = '#6899FE';
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      p="12px 4px"
      pr={{ base: 0, md: 10 }}
      pl={{ base: 0, md: 10 }}
      mr={{ base: 0, md: 30 }}
      ml={{ base: 0, md: 30 }}
      bg={backgroundColor}
      border={{ base: '0.5px solid blue', md: '2px solid #6899FE' }}
      borderRadius="md"
      position="relative"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt={{ base: -2, md: 0 }}
        padding={isMobile ? '5px 10px' : ''}
      >
        <Flex>
          <VStack spacing="1">
            <Image src={UpVoteImg} />
            <Text color="white" fontSize={'12px'}>
              {totalVotes}
            </Text>
            <Image src={DownVoteImg} />
          </VStack>
        </Flex>
        <Box flex="1" ml="4">
          <Flex justifyContent="space-between"></Flex>
          <Flex alignItems="center" justifyContent="space-between" mt="1">
            <Text fontSize={'16px'} fontWeight="500" color={'white'} mt={3}>
              <Link href={`/protected/forums/${title}/${id}`}>{title}</Link>
            </Text>
            {isMember ? (
              <></>
            ) : (
              <Button
                backgroundColor={'green'}
                colorScheme={'green'}
                border={'1px solid white'}
                mt={3}
                size={{ base: 'xs', md: 'sm' }}
                onClick={() => joinForum(forumId)}
              >
                Join
              </Button>
            )}
          </Flex>
          <Text fontSize="md"></Text>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="2"
            color="white"
          ></Flex>
        </Box>
      </Flex>
    </Box>
  );
}

function ForumModal({ id, title, owner, members, posts }) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      p={'2.5px 0px'}
      bg="white"
      width={'100%'}
      boxShadow="sm"
      mb={isMobile ? '1' : '4'}
      borderRadius="md"
      position="relative"
    >
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
        justifyContent="space-between"
        gap={isMobile ? '0px' : '10px'}
        p={isMobile ? '6px 10px' : '0px'}
      >
        <Text>
          <Link
            href={`/protected/forums/${title}/${id}`}
            fontSize={isMobile ? '10px' : 'md'}
            fontWeight="bold"
            color="#6899FEDB"
          >
            {title}
          </Link>
        </Text>
        <Text color="grey" fontSize={isMobile ? '8px' : 'sm'}>
          {'(' + members?.length + ' members)'}
        </Text>
      </Flex>
    </Box>
  );
}

const SortMenu = ({ sortBy, handleSortOptionChange, SortIcon }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const sortOptions = [
    { value: 'latest', label: 'Latest' },
    { value: 'highest-of-the-week', label: 'Highest of the Week' },
    { value: 'highest-of-the-month', label: 'Highest of the Month' },
    { value: 'highest-of-the-year', label: 'Highest of the Year' },
    { value: 'highest-of-all-time', label: 'Highest of All Time' },
  ];

  const getSortByText = () => {
    const option = sortOptions.find((option) => option.value === sortBy);
    return option ? option.label : 'Sort By';
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        aria-label="Options"
        text={'Sort By'}
        _hover={{
          transform: 'translateY(1px)',
        }}
        _active={{
          background: SortIcon,
        }}
        backgroundColor={isMobile ? '#F1F9FF' : 'white'}
      >
        <HStack>
          <Text
            fontWeight={'400'}
            fontSize={'12.5px'}
            textDecor={'underline'}
            _hover={{ color: '#706d63' }}
            cursor={'pointer'}
          >
            Sort By: {getSortByText()}
          </Text>
          <Image src={SortIcon} />
        </HStack>
      </MenuButton>
      <MenuList
        padding={0}
        border={'0.5px solid black'}
        borderTop={'0px'}
        borderBottom={'0px'}
      >
        {sortOptions.map((option, index) => (
          <MenuItem
            key={option.value}
            justifyContent={'center'}
            border={'0.5px solid black'}
            borderLeft={'0px'}
            borderRight={'0px'}
            borderTopRadius={index === 0 ? '5px' : ''}
            borderBottomRadius={index === sortOptions.length - 1 ? '5px' : ''}
            padding={0}
            fontWeight={'500'}
            onClick={() => handleSortOptionChange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const FilterMenu = ({ sortBy, handleSortOptionChange, SortIcon }) => {
  const sortOptions = [
    { value: 'latest', label: 'Latest' },
    { value: 'highest-of-the-week', label: 'Highest of the Week' },
    { value: 'highest-of-the-month', label: 'Highest of the Month' },
    { value: 'highest-of-the-year', label: 'Highest of the Year' },
    { value: 'highest-of-all-time', label: 'Highest of All Time' },
  ];

  const getSortByText = () => {
    const option = sortOptions.find((option) => option.value === sortBy);
    return option ? option.label : 'Sort By';
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        aria-label="Options"
        text={'Sort By'}
        backgroundColor={'white'}
        _hover={{
          transform: 'translateY(1px)',
        }}
        _active={{
          background: SortIcon,
        }}
      >
        <HStack>
          <Text
            fontWeight={'400'}
            fontSize={'12.5px'}
            textDecor={'underline'}
            _hover={{ color: '#706d63' }}
            cursor={'pointer'}
          >
            Filter (TODO):{' '}
          </Text>
          <Image src={SortIcon} />
        </HStack>
      </MenuButton>
      <MenuList
        padding={0}
        border={'0.5px solid black'}
        borderTop={'0px'}
        borderBottom={'0px'}
      >
        {sortOptions.map((option, index) => (
          <MenuItem
            key={option.value}
            justifyContent={'center'}
            border={'0.5px solid black'}
            borderTop={index === 0 ? '0px' : ''}
            borderLeft={'0px'}
            borderRight={'0px'}
            borderTopRadius={index === 0 ? '5px' : ''}
            borderBottomRadius={index === sortOptions.length - 1 ? '5px' : ''}
            padding={0}
            fontWeight={'500'}
            onClick={() => handleSortOptionChange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

function ForumPage() {
  const authUser = useAuth();
  const [forumTitle, setForumTitle] = useState('');
  const [forums, setForums] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isMyForumsModalOpen, setIsMyForumsModalOpen] = useState(false);
  const [userJoinedForums, setUserJoinedForums] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [appliedSearchQuery, setAppliedSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('upvotes');
  const forumVotes = {};
  const [currentPage, setCurrentPage] = useState(0);
  const postsPerPage = 5; // Changed to 5 posts per page
  const [paginatedPosts, setPaginatedPosts] = useState([]);
  const [filterPost, setFilterPost] = useState('');
  // testing new
  const [paginatedForums, setPaginatedForums] = useState([]);
  const [paginatedMedia, setPaginatedMedia] = useState([]);
  const [currentForumPage, setCurrentForumPage] = useState(0);
  const [currentMediaPage, setCurrentMediaPage] = useState(0);
  const [postsSortBy, setPostsSortBy] = useState('latest');
  const [forumsSortBy, setForumsSortBy] = useState('highest-of-the-week');
  const [activeTab, setActiveTab] = useState('posts');
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [currentTrendingIndex, setCurrentTrendingIndex] = useState(0);
  const itemsPerPage = 5;
  const forumsPerPage = 5;

  posts.forEach((post) => {
    if (!forumVotes[post.forumId]) {
      forumVotes[post.forumId] = 0;
    }
    forumVotes[post.forumId] += post.upvotes;
  });

  const uid = auth?.currentUser?.uid;

  const [isCreateForumModalOpen, setIsCreateForumModalOpen] = useState(false);

  const openCreateForumModal = () => {
    setIsCreateForumModalOpen(true);
  };

  const closeCreateForumModal = () => {
    setIsCreateForumModalOpen(false);
  };

  const createForum = async () => {
    if (forumTitle.trim() === '') {
      return;
    }

    try {
      const forumRef = await addDoc(collection(db, 'forums'), {
        title: forumTitle,
        owner: uid,
        members: [uid],
      });

      // Use the ID from the newly created document
      const newForumId = forumRef.id;

      // You might want to update your local state here
      setForums((prevForums) => [
        ...prevForums,
        { id: newForumId, title: forumTitle, owner: uid, members: [uid] },
      ]);

      // Clear the forum title input and close the modal
      setForumTitle('');
      closeCreateForumModal();
    } catch (error) {
      console.error('Error creating forum: ', error);
    }
  };

  // Fetch forums from Firestore and update the state
  useEffect(() => {
    const fetchForums = async () => {
      try {
        const forumsCollectionRef = collection(db, 'forums');
        const forumsSnapshot = await getDocs(forumsCollectionRef);
        const forumsData = forumsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setForums(forumsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching forums: ', error);
        setIsLoading(false);
      }
    };

    fetchForums();
  }, []);

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const allPosts = [];

        for (const forum of forums) {
          const postsCollectionRef = collection(
            db,
            'forums',
            forum.id,
            'posts'
          );
          const postsSnapshot = await getDocs(postsCollectionRef);
          const postsData = postsSnapshot.docs.map((doc) => ({
            forumId: forum.id,
            forumTitle: forum.title,
            members: forum.members,
            postId: doc.id,
            ...doc.data(),
          }));
          allPosts.push(...postsData);
        }

        // Filter posts to include only those with at least one image
        const postsWithImages = allPosts.filter(
          (post) => post.imageUrls && post.imageUrls.length > 0
        );

        // Sort filtered posts by upvotes in descending order
        postsWithImages.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));

        // Take the top 10 posts
        const top10Posts = postsWithImages.slice(0, 10);

        // Set the top 10 posts as trending
        setTrendingPosts(top10Posts);

        // ... existing code for setting posts state and sorting
      } catch (error) {
        console.error('Error fetching posts: ', error);
      }
    };

    fetchAllPosts();
  }, [forums]);

  useEffect(() => {
    const fetchUserJoinedForums = async () => {
      try {
        const userJoinedForumsData = [];

        for (const forum of forums) {
          const forumDocRef = doc(db, 'forums', forum.id);
          const forumDoc = await getDoc(forumDocRef);

          if (forumDoc.exists()) {
            const forumData = forumDoc.data();
            const currentMembers = forumData.members || [];

            if (currentMembers.includes(uid)) {
              userJoinedForumsData.push(forum);
            }
          }
        }

        setUserJoinedForums(userJoinedForumsData);
      } catch (error) {
        console.error('Error fetching user joined forums: ', error);
      }
    };

    fetchUserJoinedForums();
  }, [uid, forums]);

  useEffect(() => {
    const fetchForums = async () => {
      try {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        console.error('Error fetching forums: ', error);
      }
    };

    fetchForums();
  }, []);

  const openMyForumsModal = () => {
    setIsMyForumsModalOpen(true);
  };

  // Function to close My Forums modal
  const closeMyForumsModal = () => {
    setIsMyForumsModalOpen(false);
  };
  // Filter forums to show only those owned by the authenticated user
  const userForums = forums.filter((forum) => forum.owner === uid);

  // Initialize filterPost as an empty string
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [searchForums, setSearchForums] = useState(true);
  const [searchPost, setSearchPost] = useState(false);
  const [searchMedia, setSearchMedia] = useState(false);

  // Use the filter method to filter posts based on the filterPost value
  const uniqueForumTitles = new Set();

  const filterForums = posts.filter((post) => {
    let lowerCaseTitle = '';

    if (searchForums) {
      // Convert forum title to lowercase
      lowerCaseTitle = post.forumTitle.toLowerCase();
    } else if (searchPost) {
      // Convert post title to lowercase
      lowerCaseTitle = post.postTitle.toLowerCase();
    } else if (searchMedia) {
      // Extract and manipulate image URL to create a searchable title
      lowerCaseTitle =
        post?.imageUrl &&
        post.imageUrl
          .toString()
          .split('forumPosts')[1]
          .split('?')[0]
          .replace(/%2F|%2f/g, ' ') // Replace all instances of %2F or %2f with a space
          .replace(/%20/g, ' ') // Replace all instances of %20 with a space
          .toLowerCase();
    }

    if (lowerCaseTitle) {
      // Check if the title is unique before including it in the filtered array
      if (!uniqueForumTitles.has(lowerCaseTitle)) {
        uniqueForumTitles.add(lowerCaseTitle);
        return lowerCaseTitle.includes(filterPost.toLowerCase());
      }
    }

    return false;
  });

  // Handle the search input change
  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setFilterPost(inputValue);
    setSearchQuery(inputValue);
    setCurrentPage(0); // Reset to first page when searching
    setCurrentForumPage(0); // Reset forum page when searching
    fetchSortItems(); // Trigger a re-fetch of sorted and filtered items
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      setAppliedSearchQuery(searchQuery);
      setCurrentPage(0);
      setCurrentForumPage(0);
      fetchSortItems();
    }
  };

  const [sortBy, setSortBy] = useState('latest'); //sort latest for now

  const handleSortOptionChange = (option) => {
    if (activeTab === 'posts') {
      setPostsSortBy(option);
    } else {
      setForumsSortBy(option);
    }
  };

  useEffect(() => {
    fetchSortItems();
  }, [postsSortBy, forumsSortBy, activeTab, appliedSearchQuery]);

  const fetchSortItems = async () => {
    try {
      if (activeTab === 'posts') {
        const postsCollectionRef = collection(db, 'forums');
        const postsSnapshot = await getDocs(postsCollectionRef);
        let allPosts = [];

        for (const forumDoc of postsSnapshot.docs) {
          const forumPostsRef = collection(db, 'forums', forumDoc.id, 'posts');
          const forumPostsSnapshot = await getDocs(forumPostsRef);
          const forumPosts = forumPostsSnapshot.docs.map((doc) => ({
            forumId: forumDoc.id,
            forumTitle: forumDoc.data().title,
            postId: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
          }));
          allPosts = [...allPosts, ...forumPosts];
        }

        // Filter posts based on search query
        allPosts = allPosts.filter(
          (post) =>
            post.postTitle
              .toLowerCase()
              .includes(appliedSearchQuery.toLowerCase()) ||
            post.post.toLowerCase().includes(appliedSearchQuery.toLowerCase())
        );

        const dateNow = new Date();
        const oneWeekAgo = new Date(
          dateNow.getTime() - 7 * 24 * 60 * 60 * 1000
        );
        const oneMonthAgo = new Date(
          dateNow.getFullYear(),
          dateNow.getMonth() - 1,
          dateNow.getDate()
        );
        const oneYearAgo = new Date(
          dateNow.getFullYear() - 1,
          dateNow.getMonth(),
          dateNow.getDate()
        );

        switch (postsSortBy) {
          case 'highest-of-all-time':
            allPosts.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
            break;
          case 'highest-of-the-year':
            allPosts = allPosts.filter((post) => post.createdAt >= oneYearAgo);
            allPosts.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
            break;
          case 'highest-of-the-month':
            allPosts = allPosts.filter((post) => post.createdAt >= oneMonthAgo);
            allPosts.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
            break;
          case 'highest-of-the-week':
            allPosts = allPosts.filter((post) => post.createdAt >= oneWeekAgo);
            allPosts.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));
            break;
          case 'latest':
          default:
            allPosts.sort((a, b) => b.createdAt - a.createdAt);
            break;
        }

        setPosts(allPosts);
      } else {
        const forumsCollectionRef = collection(db, 'forums');
        const forumsSnapshot = await getDocs(forumsCollectionRef);
        let allForums = await Promise.all(
          forumsSnapshot.docs.map(async (doc) => {
            const forumData = doc.data();
            const postsRef = collection(db, 'forums', doc.id, 'posts');
            const postsSnapshot = await getDocs(postsRef);

            let totalVotes = 0;
            let latestPostDate = new Date(0);

            postsSnapshot.docs.forEach((postDoc) => {
              const postData = postDoc.data();
              totalVotes += postData.upvotes || 0;
              if (
                postData.createdAt &&
                postData.createdAt.toDate() > latestPostDate
              ) {
                latestPostDate = postData.createdAt.toDate();
              }
            });

            return {
              id: doc.id,
              ...forumData,
              totalVotes: totalVotes,
              latestPostDate: latestPostDate,
            };
          })
        );

        // Filter forums based on search query
        allForums = allForums.filter((forum) =>
          forum.title.toLowerCase().includes(appliedSearchQuery.toLowerCase())
        );

        switch (forumsSortBy) {
          case 'highest-of-all-time':
          case 'highest-of-the-year':
          case 'highest-of-the-month':
          case 'highest-of-the-week':
            allForums.sort((a, b) => b.totalVotes - a.totalVotes);
            break;
          case 'latest':
          default:
            allForums.sort((a, b) => b.latestPostDate - a.latestPostDate);
            break;
        }

        setForums(allForums);
      }
    } catch (error) {
      console.error('Error fetching and sorting items: ', error);
    }
  };

  // Calculate the current posts to display
  const indexOfLastPost = (currentPage + 1) * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filterAndPaginateItems = () => {
      let filteredForums = forums;
      let filteredPosts = posts;
      let filteredMedia = posts.filter((post) => post.imageUrl);

      // Apply your existing filtering logic here for each tab
      if (filterPost) {
        filteredForums = filteredForums.filter((forum) =>
          forum.title.toLowerCase().includes(filterPost.toLowerCase())
        );
        filteredPosts = filteredPosts.filter((post) =>
          post.postTitle.toLowerCase().includes(filterPost.toLowerCase())
        );
        filteredMedia = filteredMedia.filter((post) =>
          post.imageUrl.toLowerCase().includes(filterPost.toLowerCase())
        );
      }

      if (sortBy === 'highest-of-all-time') {
        filteredForums.sort(
          (a, b) => (forumVotes[b.id] || 0) - (forumVotes[a.id] || 0)
        );
      }

      // Apply pagination for each tab
      const indexOfLastForum = (currentForumPage + 1) * itemsPerPage;
      const indexOfFirstForum = indexOfLastForum - itemsPerPage;
      setPaginatedForums(
        filteredForums.slice(indexOfFirstForum, indexOfLastForum)
      );

      const indexOfLastPost = (currentPage + 1) * itemsPerPage;
      const indexOfFirstPost = indexOfLastPost - itemsPerPage;
      setPaginatedPosts(filteredPosts.slice(indexOfFirstPost, indexOfLastPost));

      const indexOfLastMedia = (currentMediaPage + 1) * itemsPerPage;
      const indexOfFirstMedia = indexOfLastMedia - itemsPerPage;
      setPaginatedMedia(
        filteredMedia.slice(indexOfFirstMedia, indexOfLastMedia)
      );
    };

    filterAndPaginateItems();
  }, [
    forums,
    posts,
    filterPost,
    currentForumPage,
    currentPage,
    currentMediaPage,
  ]);

  const paginateForum = (pageNumber) => {
    setCurrentForumPage(pageNumber);
  };

  const paginateMedia = (pageNumber) => {
    setCurrentMediaPage(pageNumber);
  };

  const paginateForums = (pageNumber) => {
    setCurrentForumPage(pageNumber);
  };

  // Calculate paginated forums
  const indexOfLastForum = (currentForumPage + 1) * forumsPerPage;
  const indexOfFirstForum = indexOfLastForum - forumsPerPage;
  const currentForums = forums.slice(indexOfFirstForum, indexOfLastForum);

  useEffect(() => {
    const fetchForumsAndPosts = async () => {
      try {
        const forumsCollectionRef = collection(db, 'forums');
        const forumsSnapshot = await getDocs(forumsCollectionRef);
        const forumsData = forumsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const forumsWithTotalVotes = await Promise.all(
          forumsData.map(async (forum) => {
            const postsCollectionRef = collection(
              db,
              'forums',
              forum.id,
              'posts'
            );
            const postsSnapshot = await getDocs(postsCollectionRef);
            const postsData = postsSnapshot.docs.map((doc) => doc.data());

            // Sum upvotes for all posts in the forum
            const totalVotes = postsData.reduce(
              (acc, post) => acc + (post.upvotes || 0),
              0
            );

            return {
              ...forum,
              totalVotes,
            };
          })
        );

        setForums(forumsWithTotalVotes); // Update state with forums and totalVotes
      } catch (error) {
        console.error('Error fetching forums and posts: ', error);
      }
    };

    fetchForumsAndPosts();
  }, []);

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const allPosts = [];

        for (const forum of forums) {
          const postsCollectionRef = collection(
            db,
            'forums',
            forum.id,
            'posts'
          );
          const postsSnapshot = await getDocs(postsCollectionRef);
          const postsData = postsSnapshot.docs.map((doc) => ({
            forumId: forum.id,
            forumTitle: forum.title,
            members: forum.members,
            postId: doc.id,
            ...doc.data(),
          }));
          allPosts.push(...postsData);
        }

        // Sort all posts by upvotes (likes) in descending order
        allPosts.sort((a, b) => (b.upvotes || 0) - (a.upvotes || 0));

        // Set the top 3 posts as trending
        setTrendingPosts(allPosts.slice(0, 3));

        // ... existing code for setting posts state and sorting
      } catch (error) {
        console.error('Error fetching posts: ', error);
      }
    };

    fetchAllPosts();
  }, [forums]);

  const renderTrendingImage = (imageUrls) => {
    if (!imageUrls || imageUrls.length === 0) {
      return (
        <Image
          src={PlaceHolderOne} // Use your placeholder image here
          height={isMobile ? '100px' : '200px'}
          width={'100%'}
          objectFit="cover"
          borderRadius="md"
          mb={'5px'}
        />
      );
    }

    return (
      <Image
        src={imageUrls[0]}
        height={isMobile ? '100px' : '200px'}
        width={'100%'}
        objectFit="cover"
        borderRadius="md"
        mb={'5px'}
      />
    );
  };

  const handlePrevTrending = () => {
    setCurrentTrendingIndex((prevIndex) =>
      prevIndex === 0 ? trendingPosts.length - 1 : prevIndex - 1
    );
  };

  const handleNextTrending = () => {
    setCurrentTrendingIndex(
      (prevIndex) => (prevIndex + 1) % trendingPosts.length
    );
  };

  const renderTrendingTopics = () => {
    if (trendingPosts.length === 0) {
      return null;
    }

    const currentPost = trendingPosts[currentTrendingIndex];

    return (
      <>
        <Text fontWeight={'bold'} fontSize={'12.5px'} mt={'15px'} mb={'15px'}>
          Trending Topics
        </Text>
        <HStack gap={'20px'} alignItems="center">
          <Image
            src={BackIndicator}
            onClick={handlePrevTrending}
            aria-label="Previous trending topic"
            isDisabled={trendingPosts.length <= 1}
            cursor={'pointer'}
            opacity={0.8}
            _hover={{ opacity: 1 }}
          />
          <Box
            as={RouterLink}
            to={`/protected/forum/forumPosts/${currentPost.forumId}/${currentPost.postId}`}
            border={'1px solid white'}
            mb={'15px'}
            borderRadius={'10px'}
            backgroundColor={'#F3F1F1'}
            cursor={'pointer'}
            boxShadow={'0px 4px 4px 0px #00000040'}
            width={isMobile ? '275px' : '450px'}
          >
            {renderTrendingImage(currentPost.imageUrls)}
            <HStack m={'7.5px 5px'} justifyContent="center" alignItems="center">
              <Text
                fontWeight={'600'}
                noOfLines={2}
                flex={1}
                fontSize={isMobile ? '10px' : '12px'}
              >
                {currentPost.postTitle}
              </Text>
              <HStack>
                <Text fontSize={isMobile ? '10px' : '12px'} fontWeight="bold">
                  {currentPost.upvotes || 0}
                </Text>
                <Text
                  fontSize={isMobile ? '10px' : '12px'}
                  role="img"
                  aria-label="fire"
                >
                  🔥
                </Text>
              </HStack>
            </HStack>
          </Box>
          <Image
            src={NextIndicator}
            onClick={handleNextTrending}
            aria-label="Next trending topic"
            isDisabled={trendingPosts.length <= 1}
            cursor={'pointer'}
            opacity={0.8}
            _hover={{ opacity: 1 }}
          />
        </HStack>
      </>
    );
  };

  return (
    <>
      {!isMobile && (
        <HStack backgroundColor={'#F1F9FF'} minHeight={'100vh'} height={'auto'}>
          <Container
            width={'container.md'}
            maxW="container.md"
            mt="2%"
            // border={"1px solid #6899FE"}
            backgroundColor={'white'}
            minHeight={'100vh'}
            height={'auto'}
            padding={0}
          >
            <Flex
              flexDirection={['column', 'column', 'row']}
              justifyContent="space-between"
              alignItems={['center', 'center', 'flex-start']}
              mb="0"
              p="3"
              boxShadow="sm"
              borderRadius="md"
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  mt={0}
                  mb={'75px'}
                  fontWeight={'bold'}
                  fontSize={'22px'}
                  px={'10px'}
                  color={'#6899FE'}
                  backgroundColor={'#D9D9D9AB'}
                  borderRadius={'4px'}
                >
                  Communities
                </Text>

                <InputGroup w={'85%'} ml={'10px'}>
                  <Input
                    border={'1px solid #9F9F9F'}
                    borderRadius={'5px'}
                    placeholder="Search for a forum"
                    fontSize={'12px'}
                    value={searchQuery}
                    size={{ base: 'sm' }}
                    onChange={handleSearchInputChange}
                    onKeyPress={handleSearchKeyPress}
                  />
                  <InputRightElement>
                    <IconButton
                      backgroundColor={'white'}
                      icon={<FaSearch />}
                      color="#6899FE"
                      size={isMobile ? '0px' : '5px'}
                      mt={{ base: -2 }}
                      aria-label="Search"
                      onClick={() => {
                        setAppliedSearchQuery(searchQuery);
                        setCurrentPage(0);
                        setCurrentForumPage(0);
                        fetchSortItems();
                      }}
                    />
                  </InputRightElement>
                </InputGroup>

                {renderTrendingTopics()}

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  gap={'12.5px'}
                >
                  <Button
                    color={'#6899FE'}
                    backgroundColor={'white'}
                    border={'1px solid #6899FE'}
                    size="sm"
                    _hover={{ transform: 'translateY(1px)' }}
                    onClick={openCreateForumModal}
                  >
                    Create Forum
                  </Button>
                  <Button
                    backgroundColor={'#6899FE'}
                    color={'white'}
                    size="sm"
                    _hover={{ transform: 'translateY(1px)' }}
                    onClick={openMyForumsModal}
                    marginLeft="2"
                  >
                    My Forums
                  </Button>
                </Box>
              </Box>
              <Modal
                isOpen={isCreateForumModalOpen}
                onClose={closeCreateForumModal}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Create Forum</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl>
                      <FormLabel>Forum Name</FormLabel>
                      <Input
                        type="text"
                        value={forumTitle}
                        onChange={(e) => setForumTitle(e.target.value)}
                        placeholder="Enter the forum name"
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={createForum}>
                      Create
                    </Button>
                    <Button variant="ghost" onClick={closeCreateForumModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>

            <Flex>
              <Modal
                isOpen={isMyForumsModalOpen}
                onClose={closeMyForumsModal}
                size={'2xl'}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>My Forums</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Box>
                      <Grid
                        templateColumns={isMobile ? '1fr 1fr' : '1fr 1fr'} // Single column for mobile, two for desktop
                        gap={isMobile ? '10px' : '50px'} // Adjust gap between columns based on screen size
                        w="100%" // Ensure grid takes full width
                      >
                        <VStack
                          alignItems={'flex-start'}
                          w="full"
                          minHeight="150px" // Set a minimum height to keep both sections aligned
                        >
                          {/* Display user's owned forums */}
                          <Text fontWeight="bold" fontSize="lg">
                            My Owned Forums:
                          </Text>
                          {userForums.length > 0 ? (
                            userForums.map((forum) => (
                              <ForumModal
                                key={forum.id}
                                {...forum}
                                user={authUser.user}
                              />
                            ))
                          ) : (
                            <Text>No owned forums</Text>
                          )}
                        </VStack>

                        <VStack
                          alignItems={'flex-start'}
                          w="full"
                          minHeight="150px" // Ensure same minimum height for this section
                        >
                          {/* Display user's joined forums */}
                          <Text fontWeight="bold" fontSize="lg">
                            My Joined Forums:
                          </Text>
                          {userJoinedForums.length > 0 ? (
                            userJoinedForums.map((forum) => (
                              <ForumModal
                                key={forum.id}
                                {...forum}
                                user={authUser.user}
                              />
                            ))
                          ) : (
                            <Text>No joined forums</Text>
                          )}
                        </VStack>
                      </Grid>
                    </Box>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="blue" onClick={closeMyForumsModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
            <Box margin={'10px 0'}>
              <Tabs
                onChange={(index) =>
                  setActiveTab(index === 0 ? 'posts' : 'forums')
                }
              >
                <TabList borderBottom={'1px solid #6899FE'} gap={'1px'}>
                  <Tab
                    border={'1px solid #6899FE'}
                    borderBottom={'none'}
                    borderTopRadius={'8px'}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    onClick={() => {
                      setActiveTab('posts');
                      setSearchForums(true);
                      setSearchPost(false);
                      setSearchMedia(false);
                    }}
                    padding={'0px 20px'}
                    color={'#6899FE'}
                    _selected={{
                      color: 'white',
                      bg: '#6899FE',
                    }}
                    _hover={{ color: 'white', bg: '#acc6fa' }}
                  >
                    Posts
                  </Tab>
                  <Tab
                    border={'1px solid #6899FE'}
                    borderTopRadius={'8px'}
                    borderBottom={'none'}
                    fontSize={'16px'}
                    onClick={() => {
                      setActiveTab('forums');
                      setSearchForums(false);
                      setSearchPost(true);
                      setSearchMedia(false);
                    }}
                    fontWeight={'400'}
                    padding={'0px 20px'}
                    color={'#6899FE'}
                    _selected={{
                      color: 'white',
                      bg: '#6899FE',
                    }}
                    _hover={{ color: 'white', bg: '#acc6fa' }}
                  >
                    Forums
                  </Tab>
                </TabList>
                <TabPanels>
                  {/* Posts Tab */}
                  <TabPanel padding={'0px'}>
                    {/* Sort + Filter */}
                    <HStack
                      mt={'10px'}
                      mb={'10px'}
                      // justifyContent={"space-evenly"}
                      gap={'50%'}
                      // remove next line after filter is complete
                      ml={'50px'}
                    >
                      <SortMenu
                        sortBy={postsSortBy}
                        handleSortOptionChange={handleSortOptionChange}
                        SortIcon={SortIcon}
                      />
                      {/* <FilterMenu
                        sortBy={sortBy}
                        handleSortOptionChange={handleSortOptionChange}
                        SortIcon={SortIcon}
                      /> */}
                    </HStack>
                    {/* Posts */}
                    <Stack spacing="4" mb={'16px'} minHeight={'239px'}>
                      {currentPosts.map((post) => (
                        <FilteredPosts key={post.postId} {...post} />
                      ))}
                    </Stack>
                    {currentPosts && (
                      <HStack justifyContent="center" mt={4} mb={5}>
                        <Button
                          onClick={() => paginate(currentPage - 1)}
                          isDisabled={currentPage === 0}
                          backgroundColor={'#6899FE'}
                          color={'#fff'}
                          width={'100px'}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => paginate(currentPage + 1)}
                          isDisabled={paginatedPosts.length < itemsPerPage}
                          backgroundColor={'#6899FE'}
                          color={'#fff'}
                          width={'100px'}
                        >
                          Next
                        </Button>
                      </HStack>
                    )}
                  </TabPanel>
                  {/* Forums Tab */}
                  <TabPanel padding={'0px'}>
                    {/* Sort + Filter */}
                    <HStack
                      mt={'10px'}
                      mb={'10px'}
                      // justifyContent={"space-evenly"}
                      gap={'50%'}
                      // remove next line after filter is complete
                      ml={'25px'}
                    >
                      <SortMenu
                        sortBy={forumsSortBy}
                        handleSortOptionChange={handleSortOptionChange}
                        SortIcon={SortIcon}
                      />
                      {/* <FilterMenu
                        sortBy={sortBy}
                        handleSortOptionChange={handleSortOptionChange}
                        SortIcon={SortIcon}
                      /> */}
                    </HStack>
                    {/* Forums */}
                    <Stack spacing="4" mb={'16px'} minHeight={'239px'}>
                      {forums
                        .slice(
                          currentForumPage * forumsPerPage,
                          (currentForumPage + 1) * forumsPerPage
                        )
                        .map((forum) => (
                          <Forums
                            key={forum.id}
                            id={forum.id}
                            title={forum.title}
                            owner={forum.owner}
                            members={forum.members}
                            totalVotes={forum.totalVotes || 0}
                            forumId={forum.id}
                          />
                        ))}
                    </Stack>
                    <HStack justifyContent="center" mt={4} mb={5}>
                      <Button
                        onClick={() => paginateForums(currentForumPage - 1)}
                        isDisabled={currentForumPage === 0}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        width={'100px'}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => paginateForums(currentForumPage + 1)}
                        isDisabled={currentForums.length < forumsPerPage}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        width={'100px'}
                      >
                        Next
                      </Button>
                    </HStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Container>
        </HStack>
      )}

      {isMobile && (
        <Container
          width={'100%'}
          height={'100%'}
          backgroundColor={'#F1F9FF'}
          margin={0}
          padding={0}
          mt={'8.5%'}
          mx={0}
        >
          <Flex
            flexDirection={['column']}
            justifyContent="space-between"
            alignItems={['center', 'center', 'flex-start']}
            mb="0"
            p="3"
            mx={0}
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mx={0}
            >
              <Text
                mb={6}
                fontWeight={'bold'}
                color={'#6899FE'}
                fontSize={'20px'}
                px={4}
                bgColor={'#E2E8F0'}
                borderRadius={'10px'}
                borderWidth={'1px'}
              >
                Communities
              </Text>

              <InputGroup w={'90vw'} ml={'10px'}>
                <Input
                  border={'1px solid #9F9F9F'}
                  borderRadius={'5px'}
                  placeholder="Search for a forum"
                  fontSize={'12px'}
                  value={searchQuery}
                  size={{ base: 'sm' }}
                  onChange={handleSearchInputChange}
                  onKeyPress={handleSearchKeyPress}
                />
                <InputRightElement>
                  <IconButton
                    backgroundColor={'white'}
                    icon={<FaSearch />}
                    color="#6899FE"
                    size={isMobile ? '0px' : '5px'}
                    mt={{ base: -2 }}
                    aria-label="Search"
                    onClick={() => {
                      setAppliedSearchQuery(searchQuery);
                      setCurrentPage(0);
                      setCurrentForumPage(0);
                      fetchSortItems();
                    }}
                  />
                </InputRightElement>
              </InputGroup>

              {renderTrendingTopics()}

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={'6px'}
              >
                <Button
                  color={'#6899FE'}
                  backgroundColor={'white'}
                  border={'1px solid #6899FE'}
                  size="sm"
                  fontSize={'11px'}
                  fontWeight={'300'}
                  _hover={{ transform: 'translateY(1px)' }}
                  onClick={openCreateForumModal}
                >
                  Create Forum
                </Button>
                <Button
                  backgroundColor={'#6899FE'}
                  color={'white'}
                  size="sm"
                  fontSize={'11px'}
                  fontWeight={'300'}
                  _hover={{ transform: 'translateY(1px)' }}
                  onClick={openMyForumsModal}
                  marginLeft="2"
                >
                  My Forums
                </Button>
              </Box>
            </Box>

            <Divider
              orientation="horizontal"
              borderColor={'#9F9F9F'}
              borderWidth={'0.5px'}
              width={'90vw'}
              mt={'20px'}
              mb={'10px'}
            />

            <Modal
              isOpen={isCreateForumModalOpen}
              onClose={closeCreateForumModal}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Create Forum</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Forum Name</FormLabel>
                    <Input
                      type="text"
                      value={forumTitle}
                      onChange={(e) => setForumTitle(e.target.value)}
                      placeholder="Enter the forum name"
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={createForum}>
                    Create
                  </Button>
                  <Button variant="ghost" onClick={closeCreateForumModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>

          <Modal
            isOpen={isMyForumsModalOpen}
            onClose={closeMyForumsModal}
            size={'2xl'}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>My Forums</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <Grid
                    templateColumns={isMobile ? '1fr 1fr' : '1fr 1fr'} // Single column for mobile, two for desktop
                    gap={isMobile ? '10px' : '50px'} // Adjust gap between columns based on screen size
                    w="100%" // Ensure grid takes full width
                  >
                    <VStack
                      alignItems={'flex-start'}
                      w="full"
                      minHeight="150px" // Set a minimum height to keep both sections aligned
                    >
                      {/* Display user's owned forums */}
                      <Text fontWeight="bold" fontSize="12px">
                        My Owned Forums:
                      </Text>
                      {userForums.length > 0 ? (
                        userForums.map((forum) => (
                          <ForumModal
                            key={forum.id}
                            {...forum}
                            user={authUser.user}
                          />
                        ))
                      ) : (
                        <Text fontSize={'12px'}>No owned forums</Text>
                      )}
                    </VStack>

                    <VStack
                      alignItems={'flex-start'}
                      w="full"
                      minHeight="150px" // Ensure same minimum height for this section
                    >
                      {/* Display user's joined forums */}
                      <Text fontWeight="bold" fontSize="12px">
                        My Joined Forums:
                      </Text>
                      {userJoinedForums.length > 0 ? (
                        userJoinedForums.map((forum) => (
                          <ForumModal
                            key={forum.id}
                            {...forum}
                            user={authUser.user}
                          />
                        ))
                      ) : (
                        <Text fontSize={'12px'}>No joined forums</Text>
                      )}
                    </VStack>
                  </Grid>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={closeMyForumsModal}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Box margin={'10px 0'}>
            <Tabs
              onChange={(index) =>
                setActiveTab(index === 0 ? 'posts' : 'forums')
              }
            >
              <TabList borderBottom={'1px solid #6899FE'} gap={'1px'}>
                <Tab
                  border={'1px solid #6899FE'}
                  borderTopRadius={'8px'}
                  borderBottom={'none'}
                  fontSize={'16px'}
                  onClick={() => {
                    setActiveTab('posts');
                    setSearchForums(true);
                    setSearchPost(false);
                    setSearchMedia(false);
                  }}
                  fontWeight={'400'}
                  padding={'0px 20px'}
                  color={'#6899FE'}
                  _selected={{
                    color: 'white',
                    bg: '#6899FE',
                  }}
                  _hover={{ color: 'white', bg: '#acc6fa' }}
                >
                  Posts
                </Tab>
                <Tab
                  border={'1px solid #6899FE'}
                  borderBottom={'none'}
                  borderTopRadius={'8px'}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  onClick={() => {
                    setActiveTab('forums');
                    setSearchForums(false);
                    setSearchPost(true);
                    setSearchMedia(false);
                  }}
                  padding={'0px 20px'}
                  color={'#6899FE'}
                  _selected={{
                    color: 'white',
                    bg: '#6899FE',
                  }}
                  _hover={{ color: 'white', bg: '#acc6fa' }}
                >
                  Forums
                </Tab>
              </TabList>
              <TabPanels>
                {/* Posts Tab */}
                <TabPanel padding={'0px'}>
                  {/* Sort + Filter */}
                  <HStack
                    mt={'10px'}
                    mb={'30px'}
                    // justifyContent={"space-evenly"}
                    gap={'25%'}
                    // remove next lines after filter is complete
                    ml={'25px'}
                  >
                    <SortMenu
                      sortBy={postsSortBy}
                      handleSortOptionChange={handleSortOptionChange}
                      SortIcon={SortIcon}
                    />
                    {/* <FilterMenu
                      sortBy={sortBy}
                      handleSortOptionChange={handleSortOptionChange}
                      SortIcon={SortIcon}
                    /> */}
                  </HStack>
                  {/* Posts */}
                  <Stack
                    spacing="4"
                    mb={'0px'}
                    minHeight={'239px'}
                    ml={'2%'}
                    mr={'2%'}
                  >
                    {currentPosts.map((post) => (
                      <FilteredPosts key={post.postId} {...post} />
                    ))}
                  </Stack>
                  {currentPosts && (
                    <HStack justifyContent="center" mt={0} pb={5}>
                      <Button
                        onClick={() => paginate(currentPage - 1)}
                        isDisabled={currentPage === 0}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        size={'sm'}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => paginate(currentPage + 1)}
                        isDisabled={paginatedPosts.length < itemsPerPage}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        size={'sm'}
                      >
                        Next
                      </Button>
                    </HStack>
                  )}
                </TabPanel>
                {/* Forums Tab */}
                <TabPanel padding={'0px'}>
                  {/* Sort + Filter */}
                  <HStack
                    mt={'10px'}
                    // mb={"30px"}
                    // justifyContent={"space-evenly"}
                    gap={'25%'}
                    // remove next lines after filter is complete
                    ml={'25px'}
                    mb={'15px'}
                  >
                    <SortMenu
                      sortBy={forumsSortBy}
                      handleSortOptionChange={handleSortOptionChange}
                      SortIcon={SortIcon}
                    />
                    {/* <FilterMenu
                      sortBy={sortBy}
                      handleSortOptionChange={handleSortOptionChange}
                      SortIcon={SortIcon}
                    /> */}
                  </HStack>
                  {/* Forums */}
                  <Stack
                    spacing="4"
                    mb={'0px'}
                    minHeight={'239px'}
                    ml={'2%'}
                    mr={'2%'}
                  >
                    {forums
                      .slice(
                        currentForumPage * forumsPerPage,
                        (currentForumPage + 1) * forumsPerPage
                      )
                      .map((forum) => (
                        <Forums
                          key={forum.id}
                          id={forum.id}
                          title={forum.title}
                          owner={forum.owner}
                          members={forum.members}
                          totalVotes={forum.totalVotes || 0}
                          forumId={forum.id}
                        />
                      ))}
                  </Stack>
                  {forums && (
                    <HStack justifyContent="center" mt={6} pb={5}>
                      <Button
                        onClick={() => paginateForum(currentForumPage - 1)}
                        isDisabled={currentForumPage === 0}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        size={'sm'}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => paginateForum(currentForumPage + 1)}
                        isDisabled={paginatedForums.length < itemsPerPage}
                        backgroundColor={'#6899FE'}
                        color={'#fff'}
                        size={'sm'}
                      >
                        Next
                      </Button>
                    </HStack>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Container>
      )}
    </>
  );
}

export default ForumPage;
