import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Icon,
  Select,
  Stack,
  Wrap,
  WrapItem,
  Center,
  Text,
  HStack,
  Badge,
  InputGroup,
  Box,
  Switch,
  useDisclosure,
  useBreakpointValue,
  CloseButton,
  Flex,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Portal,
  Image,
} from "@chakra-ui/react";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "lib/firebase";
import UserCard from "./UserCard";
import { FiRefreshCcw, FiX } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
import axios from "axios";
import musicRoles from "lib/musicRoles.json";
import musicRolesSuggestions from "lib/musicRoleSuggestions.json";
import genresJSON from "lib/genres.json";
import "../tour.css";
import { useAuth } from "hooks/auth";
import logoM from "Musicom Resources/Blue Logo Design/No Background/0.75x/Blue-White Icon Logo copy@0.75x.png";

function isMobileDevice() {
  const mobileWidthThreshold = 768;
  return window.innerWidth < mobileWidthThreshold;
}

function getCurrentWeekRange() {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const daysSinceMonday = currentDay === 0 ? 6 : currentDay - 1;

  const lastMonday = new Date(
    currentDate.setDate(currentDate.getDate() - daysSinceMonday)
  );
  lastMonday.setHours(0, 0, 0, 0);

  const nextSunday = new Date(
    currentDate.setDate(currentDate.getDate() + (6 - daysSinceMonday))
  );
  nextSunday.setHours(23, 59, 59, 999);

  return { start: lastMonday, end: nextSunday };
}

const Autocomplete = ({
  location,
  setLocation,
  businessLocation,
  isTourActive,
  stepIndex,
  setBusinessLocation,
  isSwitchOn, // Pass the toggle state
  setIsSwitchOn, // Pass the setIsSwitchOn function
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLocationFinalized, setIsLocationFinalized] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [initialSwitchState, setInitialSwitchState] = useState(false); // Track the initial toggle state

  const handlePlaceSearch = async (value) => {
    setSearchQuery(value);

    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json`,
        {
          params: {
            access_token:
              "pk.eyJ1IjoiYWxlbGVudGluaSIsImEiOiJjbGk5ZWF5MnQwOHl2M25wcXBjamd3NjQ4In0.MpcjArF0h_rXY6O3LdqjwA",
            types: "place",
            limit: 5,
          },
        }
      );

      const features = response.data.features;
      const citySuggestions = features.map((feature) => ({
        address: feature.place_name,
        latitude: feature.center[1],
        longitude: feature.center[0],
      }));

      setSuggestions(citySuggestions);
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchQuery.trim() !== "") {
        handleFinalLocation(searchQuery.trim());
      }
    }
  };

  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    setInitialSwitchState(isSwitchOn); // Set the initial state before toggling
    setIsSwitchOn(newValue);
    if (newValue) {
      onOpen();
    } else {
      handleDeleteAddress();
    }
  };

  const handleSetLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await axios.get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
              {
                params: {
                  access_token:
                    "pk.eyJ1IjoiYWxlbGVudGluaSIsImEiOiJjbGk5ZWF5MnQwOHl2M25wcXBjamd3NjQ4In0.MpcjArF0h_rXY6O3LdqjwA",
                  types: "place",
                  limit: 1,
                },
              }
            );

            const placeName =
              response.data.features[0]?.place_name || "Unknown Location";

            // Reset location states before setting new one
            setLocation([]);
            setBusinessLocation([]);

            handleFinalLocation(placeName);
            onClose();
          } catch (error) {
            console.error("Error during reverse geocoding:", error);
            alert("Could not retrieve your city name.");
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleFinalLocation = (location) => {
    const selectedLocation = { address: location };
    setSearchQuery(location);
    setBusinessLocation([selectedLocation]);
    setLocation([selectedLocation]);
    setSuggestions([]);
    setIsLocationFinalized(true);
  };

  const handleSelectSuggestion = (selectedSuggestion) => {
    handleFinalLocation(selectedSuggestion.address);
  };

  const handleDeleteAddress = () => {
    setBusinessLocation([]);
    setLocation([]);
    setSearchQuery("");
    setIsLocationFinalized(false);
    setIsSwitchOn(false);
  };

  useEffect(() => {
    if (searchQuery && !isLocationFinalized) {
      handlePlaceSearch(searchQuery);
    }
  }, [searchQuery]);

  const handleModalClose = () => {
    setIsSwitchOn(initialSwitchState); // Revert to the initial toggle state if "No" is pressed
    onClose();
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <FormControl>
      <InputGroup>
        <Input
          fontSize={isMobile ? "12px" : "15px"}
          height={isMobile ? "7" : "8"}
          width={isMobile ? "40rem" : "20rem"}
          placeholder="Insert your location"
          id="set-location"
          _placeholder={{
            color: stepIndex === 6 ? "black" : stepIndex === 7 && "gray",
          }}
          backgroundColor={
            stepIndex === 6 ? "white" : stepIndex === 7 && "white"
          }
          fontWeight={isTourActive && stepIndex === 6 && "bold"}
          color={isTourActive && stepIndex === 6 && "black"}
          className={isTourActive && stepIndex === 6 ? "tour-highlight" : ""}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setIsLocationFinalized(false);
          }}
          onKeyDown={handleKeyDown}
        />
        <Box
          ml={2}
          mt={1}
          position="relative"
          id="set-location-toggle"
          className={isTourActive && stepIndex === 7 ? "tour-highlight" : ""}
        >
          <Switch
            isChecked={isSwitchOn}
            className={isTourActive && stepIndex === 7 ? "tour-highlight" : ""}
            zIndex={"1002"}
            onChange={handleToggleChange}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          />
          {isTooltipVisible && (
            <Box
              position="absolute"
              top="-30px"
              left="0"
              backgroundColor="#6899FE"
              color="#fff"
              padding="4px 8px"
              borderRadius="4px"
              fontSize="12px"
              zIndex="tooltip"
              whiteSpace="nowrap"
            >
              Toggle to set city by your location
            </Box>
          )}
        </Box>
      </InputGroup>
      {suggestions.length > 0 && searchQuery && (
        <Wrap mt={2} spacing={2}>
          {suggestions.map((suggestion, index) => (
            <WrapItem key={suggestion.address + index}>
              <Badge
                backgroundColor={"#6899FE"}
                textColor={"white"}
                fontWeight={500}
                fontSize={"x-small"}
                cursor="pointer"
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.address}
              </Badge>
            </WrapItem>
          ))}
        </Wrap>
      )}
      {businessLocation.length > 0 && isLocationFinalized && (
        <Wrap mt={2} spacing={2}>
          <WrapItem
            key={businessLocation[0].address}
            justifyContent={"center"}
            flexDirection={"row"}
          >
            <Badge
              backgroundColor={"#6899FE"}
              textColor={"white"}
              height={"20px"}
              justifyContent={"center"}
              alignContent={"center"}
              fontWeight={500}
              fontSize={"x-small"}
            >
              <Flex flexDirection={"row"}>
                {businessLocation[0].address}
                <CloseButton size="xs" ml={2} onClick={handleDeleteAddress} />
              </Flex>
            </Badge>
          </WrapItem>
        </Wrap>
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Your Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Would you like to set your current location?</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSetLocation}>
              Yes
            </Button>
            <Button variant="ghost" onClick={handleModalClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormControl>
  );
};

const NetworkPage = () => {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [instrument, setInstrument] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState([]);
  const [businessLocation, setBusinessLocation] = useState([]);
  const [signed, setSigned] = useState("");
  const [languages, setLanguages] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instrumentSuggestions, setInstrumentSuggestions] = useState([]);
  const [genreSuggestions, setGenreSuggestions] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);
  const [genre, setGenre] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [isSwitchOn, setIsSwitchOn] = useState(false); // Toggle state for location switch
  const [isFirstTime, setIsFirstTime] = useState(false);

  const [moreLikeThisUsers, setMoreLikeThisUsers] = useState([]);
  const dividerRef = useRef(null);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [isTourActive, setIsTourActive] = useState(false);
  const { user } = useAuth();

  const [tutorialSkipped, setTutorialSkipped] = useState(false);

  useEffect(() => {
    const checkFirstTimeUser = async () => {
      if (!user?.id) return; // User must be logged in

      try {
        const userRef = doc(db, "users", user.id);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Check if the field exists and determine if it's the first time
          if (userData.hasCompletedTutorialNetwork !== undefined) {
            setIsFirstTime(!userData.hasCompletedTutorialNetwork);
          }
        } else {
          // Document does not exist; create a new user entry
          await setDoc(
            userRef,
            {
              hasCompletedTutorialNetwork: false,
            },
            { merge: true }
          );
          setIsFirstTime(true);
        }
      } catch (error) {
        console.error("Error checking Firestore user data: ", error);
        // Handle the error accordingly
      }
    };

    if (user?.id) {
      checkFirstTimeUser();
    }
  }, [user?.id]);

  const steps = [
    {
      targetId: "set-username",
      content: "Insert an username to add it to the search query.",
    },
    {
      targetId: "set-role",
      content: "Select the role to search for it too.",
    },
    {
      targetId: "set-instruments",
      content: "Insert instruments to add them to the search query.",
    },
    {
      targetId: "set-gender",
      content: "Select the gender to search for it.",
    },
    {
      targetId: "set-signed",
      content: "Select the signed type to search for it too.",
    },
    {
      targetId: "set-genres",
      content: "Insert the genres to add them to the search query.",
    },
    {
      targetId: "set-location",
      content: "Insert the location that you want to look for it.",
    },
    {
      targetId: "set-location-toggle",
      content: "Toggle to set the location based on your current city.",
    },
    {
      targetId: "set-languages",
      content: "Select the languages to add them to the search query.",
    },
    // {
    //   targetId: 'start-tour-btn',
    //   content: 'This button starts the tour.',
    // },
  ];

  const isLastStep = stepIndex === steps.length - 1;

  // Function to move to the next step
  const handleNext = async () => {
    try {
      if (stepIndex === steps.length - 1) {
        handleSkip();
      } else {
        setStepIndex(stepIndex + 1);
      }
    } catch (error) {
      console.error("Error updating Firestore: ", error);
      // Handle the error accordingly
    }
  };

  const handlePrevious = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const handleSkip = async () => {
    if (!user?.id) return;

    try {
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        hasCompletedTutorialNetwork: true, // Mark tutorial as completed
      });
      setIsFirstTime(false); // Ensure the modal does not show again
      handleCloseTour(); // Close the tour modal
    } catch (error) {
      console.error("Error updating Firestore on skip: ", error);
      // Handle the error accordingly
    }
  };

  // Function to close the tour
  const handleCloseTour = () => {
    setIsTourActive(false);
    setStepIndex(0);
  };

  const handleStartTour = () => {
    setIsTourActive(true);
    setStepIndex(0);
  };

  useEffect(() => {
    if (isTourActive) {
      const element = document.getElementById(steps[stepIndex].targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [stepIndex, isTourActive, steps]);

  const resetForm = () => {
    setUsername("");
    setRole("");
    setInstrument("");
    setInstruments([]);
    setGenres([]);
    setGenre("");
    setGender("");
    if (!isSwitchOn) {
      // Only reset location if switch is off
      setLocation([]);
      setBusinessLocation([]);
    }
    setSigned("");
    setFilteredUsers([]);
    setSelectedLanguage([]);
    setSelectedLanguages([]);
    setIsSearchPerformed(false);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetchLanguages = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v2/all");
      const countries = response.data;
      const languages = countries.reduce((allLanguages, country) => {
        const countryLanguages = country.languages.map((language) => ({
          value: language.name,
          label: language.name,
        }));
        return [...allLanguages, ...countryLanguages];
      }, []);
      return languages;
    } catch (error) {
      console.error("Error fetching language data:", error);
      return [];
    }
  };

  const uniqueLanguages = new Set(
    languages.map((language) => {
      return language.value;
    })
  );

  const sortedLanguages = Array.from(uniqueLanguages).sort();

  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    const fetchLanguageOptions = async () => {
      const languagess = await fetchLanguages();
      setLanguageOptions(languagess);
      setLanguages(languagess);
    };

    fetchLanguageOptions();
  }, []);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleAddLanguage = () => {
    setSelectedLanguages((prevLanguages) => [
      ...prevLanguages,
      selectedLanguage,
    ]);
    setSelectedLanguage([]); // Clear the instrument input field
  };

  const handleRemoveLanguage = (language) => {
    const updatedLanguages = selectedLanguages.filter(
      (lang) => lang !== language
    );
    setSelectedLanguages(updatedLanguages);
  };

  const scrollToDivider = () => {
    if (dividerRef.current) {
      dividerRef.current.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "start", // Align to the top of the element
      });
    }
  };

  const handleFilter = async () => {
    setLoading(true);
  
    const usersRef = collection(db, "users");
    let filteredQuery = query(usersRef);
  
    // For username search
    if (username) {
      const search = username.toLowerCase();
      filteredQuery = query(
        filteredQuery,
        where('username', '>=', search),
        where('username', '<=', search + '\uf8ff')
      );
    }
  
    // Add broader search for other fields like roles, instruments, etc.
    if (role) {
      const roleSearch = role.toLowerCase();
      filteredQuery = query(
        filteredQuery,
        where('role', '>=', roleSearch),
        where('role', '<=', roleSearch + '\uf8ff')
      );
    }
  
    if (instruments.length > 0) {
      filteredQuery = query(
        filteredQuery,
        where('instruments', 'array-contains-any', instruments)
      );
    }
  
    if (genres.length > 0) {
      filteredQuery = query(
        filteredQuery,
        where('genres', 'array-contains-any', genres)
      );
    }
  
    try {
      const userSnapshot = await getDocs(filteredQuery);
      const users = userSnapshot.docs.map((doc) => doc.data());
      setFilteredUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const getActiveSubscription = async (user) => {
    try {
      const snapshot = await getDocs(
        query(
          collection(
            db,
            user?.businessName ? "businesses" : "users",
            user?.id,
            "subscriptions"
          ),
          where("status", "in", ["trialing", "active"])
        )
      );

      if (snapshot.docs.length > 0) {
        return snapshot.docs[0].data().status;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting active subscription:", error);
      return null;
    }
  };

  const fetchInstrumentSuggestions = async (value) => {
    try {
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(query(usersRef));
      const users = querySnapshot.docs.map((doc) => doc.data());
  
      const matchedInstruments = new Set();
  
      // Fetch instruments from Firestore
      users.map((user) => {
        user?.instrument.map((instrument) => {
          if (instrument.toLowerCase().includes(value.toLowerCase())) {
            matchedInstruments.add(instrument);
          }
        });
      });
  
      let combinedSuggestions = Array.from(matchedInstruments);
  
      // Prioritize exact matches first
      combinedSuggestions = combinedSuggestions.sort((a, b) => {
        const lowerValue = value.toLowerCase();
        const aExactMatch = a.toLowerCase() === lowerValue;
        const bExactMatch = b.toLowerCase() === lowerValue;
  
        // Exact matches first
        if (aExactMatch && !bExactMatch) return -1;
        if (bExactMatch && !aExactMatch) return 1;
  
        // Partial matches next
        const aIncludes = a.toLowerCase().includes(lowerValue);
        const bIncludes = b.toLowerCase().includes(lowerValue);
        if (aIncludes && !bIncludes) return -1;
        if (bIncludes && !aIncludes) return 1;
  
        return 0; // Default case, keep current order
      });
  
      setInstrumentSuggestions(combinedSuggestions);
    } catch (error) {
      console.log("Error fetching instrument suggestions:", error);
      setInstrumentSuggestions([]);
    }
  };
  

  const fetchGenreSuggestions = async (value) => {
  try {
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(query(usersRef));
    const users = querySnapshot.docs.map((doc) => doc.data());

    const matchedGenresFromFirestore = new Set();

    // Fetch genres from Firestore
    users.forEach((user) => {
      user.genres.forEach((genre) => {
        if (genre.toLowerCase().includes(value.toLowerCase())) {
          matchedGenresFromFirestore.add(genre);
        }
      });
    });

    const firestoreSuggestions = Array.from(matchedGenresFromFirestore);

    // Fetch from JSON
    const matchedGenresFromJSON = genresJSON.filter((g) =>
      g.toLowerCase().includes(value.toLowerCase())
    );

    // Combine both sources
    let combinedSuggestions = [
      ...firestoreSuggestions,
      ...matchedGenresFromJSON,
    ];

    // Prioritize exact matches first
    combinedSuggestions = combinedSuggestions.sort((a, b) => {
      const lowerValue = value.toLowerCase();
      const aExactMatch = a.toLowerCase() === lowerValue;
      const bExactMatch = b.toLowerCase() === lowerValue;

      // Exact matches first
      if (aExactMatch && !bExactMatch) return -1;
      if (bExactMatch && !aExactMatch) return 1;

      // Partial matches next
      const aIncludes = a.toLowerCase().includes(lowerValue);
      const bIncludes = b.toLowerCase().includes(lowerValue);
      if (aIncludes && !bIncludes) return -1;
      if (bIncludes && !aIncludes) return 1;

      return 0; // Default case, keep current order
    });

    setGenreSuggestions(combinedSuggestions);
  } catch (error) {
    const matchedGenres = genresJSON.filter((g) =>
      g.toLowerCase().includes(value.toLowerCase())
    );
    setGenreSuggestions(matchedGenres);
    console.log("Error fetching genres suggestions:", error);
  }
};


  const handleInstrumentChange = (e) => {
    const value = e.target.value;
    setInstrument(value);

    if (value.length >= 2) {
      fetchInstrumentSuggestions(value);
    } else {
      setInstrumentSuggestions([]);
    }
  };

  const handleGenreChange = (e) => {
    const value = e.target.value;
    setGenre(value);

    if (value.length >= 2) {
      fetchGenreSuggestions(value);
    } else {
      setGenreSuggestions([]);
    }
  };

  const handleInsertInstrument = (e) => {
    if (e.key === "Enter" && instrument.trim() !== "") {
      e.preventDefault();
      setInstruments((prevInstruments) => [
        ...prevInstruments,
        instrument.trim(),
      ]);
      setInstrument("");
      setInstrumentSuggestions([]);
    }
  };

  const handleInsertGenre = (e) => {
    if (e.key === "Enter" && genre.trim() !== "") {
      e.preventDefault();
      setGenres((prevGenres) => [...prevGenres, genre.trim()]);
      setGenre("");
      setGenreSuggestions([]);
    }
  };

  const handleSelectGenre = (genre) => {
    setGenres((prevGenres) => [...prevGenres, genre]);
    setGenre("");
    setGenreSuggestions([]);
  };

  const handleSelectInstrument = (instrument) => {
    setInstruments((prevInstruments) => [...prevInstruments, instrument]);
    setInstrument("");
    setInstrumentSuggestions([]);
  };

  const handleRemoveInstrument = (instrument) => {
    setInstruments((prevInstruments) =>
      prevInstruments.filter((item) => item !== instrument)
    );
  };

  const handleRemoveGenre = (genre) => {
    setGenres((prevGenres) => prevGenres.filter((item) => item !== genre));
  };

  const roles = musicRoles.categorymembers.map((item) => item.title);
  const suggestedRoles = musicRolesSuggestions.categorymembers.map(
    (item) => item.title
  );

  const [filteredRoles, setFilteredRoles] = useState(roles);

  const [searchValue, setSearchValue] = useState("");
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [otherRole, setOtherRole] = useState("");
  const [value, setValue] = useState("");

  const [otherRoleSuggestions, setOtherRoleSuggestions] = useState([]);
  const [selectedOtherRoles, setSelectedOtherRoles] = useState([]);

  const fetchOtherRoleSuggestions = (value) => {
    const matchedSuggestions = musicRolesSuggestions.categorymembers.filter(
      (role) => role.title.toLowerCase().includes(value.toLowerCase())
    );
    setOtherRoleSuggestions(matchedSuggestions);
  };

  const handleRoleSelectChange = (event) => {
    const { value } = event.target;
    if (value === "Other") {
      setShowOtherTextField(true);
    } else {
      setShowOtherTextField(false);
      setOtherRole("");
    }
    setRole(value);
  };

  const handleOtherRoleChange = (event) => {
    const { value } = event.target;
    setOtherRole(value);

    if (value.length >= 2) {
      fetchOtherRoleSuggestions(value);
    } else {
      setOtherRoleSuggestions([]);
    }
  };

  const handleRemoveRole = (role) => {
    setSelectedOtherRoles((prevRoles) =>
      prevRoles.filter((item) => item !== role)
    );
  };

  const handleInsertRole = (event) => {
    if (event.key === "Enter" && otherRole.trim() !== "") {
      event.preventDefault();
      setSelectedOtherRoles((prevRoles) => [...prevRoles, otherRole.trim()]);
      setOtherRole("");
    }
  };

  const displayedUsers = filteredUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  return (
    <Center bg="#EDF7FE">
      {/* Initial View as Modal */}
      {isFirstTime && !isTourActive && (
        <Modal
          isOpen={!isTourActive && isFirstTime}
          onClose={() => null} // Disable onClose
          isCentered // Centers the modal
          closeOnOverlayClick={false} // Disable closing by clicking outside
          closeOnEsc={false} // Disable closing by pressing 'Escape'
        >
          <ModalOverlay bg="rgba(0, 0, 0, 0.5)" />
          <ModalContent
            maxWidth={["90%", "80%", "md"]} // Responsive width
            height="auto"
            minHeight="50vh"
            maxHeight="80vh"
            overflowY="auto"
            p={[4, 6, 8]} // Responsive padding
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ModalHeader textAlign="center">
              <Image
                src={logoM}
                alt="App Logo"
                boxSize={["70px", "90px", "100px"]} // Responsive image size
                mx="auto"
                mb={2}
              />
              <Text fontSize={["lg", "xl"]} fontWeight="bold">
                Welcome to Network!
              </Text>
            </ModalHeader>
            {/* Remove the close button */}
            {/* <ModalCloseButton /> */}
            <ModalBody mt={["2%", "3%"]} textAlign="center">
              <Box
                p={[3, 4]} // Responsive padding for the content box
                border="1px solid #ccc"
                borderRadius="md"
                backgroundColor="white"
                boxShadow="md"
                textAlign="center"
              >
                <Text fontWeight="600" fontSize={["md", "lg"]}>
                  This tutorial will guide you through the main features.
                </Text>
                <Button
                  mt={6}
                  colorScheme="blue"
                  width={["100%", "auto"]} // Full-width button on mobile
                  onClick={handleStartTour}
                >
                  Start Tour
                </Button>
                <Button
                  mt={isMobile ? 4 : 6}
                  colorScheme="red"
                  width={["100%", "auto"]} // Full-width button on mobile
                  onClick={handleSkip}
                  ml={[0, 2]} // Margin left for non-mobile views
                >
                  Skip
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {!isMobile ? (
        <VStack
          bg="white"
          borderRightWidth={"1px"}
          borderLeftWidth={"1px"}
          borderColor="gray.300"
          p={0}
          rounded="md"
          spacing={4}
          width="1000px"
          minHeight={"100vh"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <HStack
              spacing={4}
              align="center"
              width={{ md: "60%" }}
              pt="10"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleFilter();
                }}
                style={{
                  borderRadius: "8px",
                  padding: "5px",
                  width: "100%",
                }}
              >
                <Flex direction={"row"}>
                  <Flex flex={1} />
                  <Text
                    mb={6}
                    mt={-0.5}
                    fontWeight={"bold"}
                    fontSize={"22px"}
                    px={"10px"}
                    color={"#6899FE"}
                    backgroundColor={"#D9D9D9AB"}
                    borderRadius={"4px"}
                  >
                    Network
                  </Text>
                  <Flex flex={1} />
                </Flex>
                <HStack
                  gap={"10rem"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  mb={"1rem"}
                >
                  <VStack>
                    <FormControl
                      flex={1}
                      id="set-username"
                      className={
                        isTourActive && stepIndex === 0 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 0
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Username
                      </FormLabel>
                      <Input
                        fontSize={"15px"}
                        value={username}
                        height={isMobile ? "7" : "8"}
                        fontWeight={isTourActive && stepIndex === 0 && "bold"}
                        color={isTourActive && stepIndex === 0 && "black"}
                        backgroundColor={
                          isTourActive && stepIndex === 0 && "white"
                        }
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter username"
                        width={"20rem"}
                      />
                    </FormControl>
                    <FormControl
                      flex={1}
                      id="set-role"
                      className={
                        isTourActive && stepIndex === 1 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 1
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Role
                      </FormLabel>
                      <Select
                        fontSize={"15px"}
                        value={role}
                        height={"8"}
                        width={"20rem"}
                        fontWeight={isTourActive && stepIndex === 1 && "bold"}
                        color={isTourActive && stepIndex === 1 && "black"}
                        backgroundColor={
                          isTourActive && stepIndex === 1 && "white"
                        }
                        onChange={(e) => {
                          handleRoleSelectChange(e);
                          setRole(e.target.value);
                        }}
                        placeholder="Select role"
                      >
                        {roles.map((rol) => (
                          <option
                            key={rol}
                            disabled={
                              rol === "PROFESSIONAL" ||
                              rol === "CREATIVE" ||
                              rol === "BUSINESS"
                            }
                          >
                            {rol}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <Stack flexDirection={"column"}>
                      {showOtherTextField && (
                        <FormControl flex={1}>
                          <FormLabel
                            fontSize={"15px"}
                            textAlign={"center"}
                            color={"#6899FE"}
                          >
                            Other Role
                          </FormLabel>
                          <Input
                            fontSize={"15px"}
                            value={otherRole}
                            onChange={handleOtherRoleChange}
                            onKeyDown={handleInsertRole}
                            placeholder="Enter other role"
                            width={"20rem"}
                          />
                        </FormControl>
                      )}
                      {otherRoleSuggestions.length > 0 && (
                        <Wrap mt={2} spacing={2}>
                          {otherRoleSuggestions.map((suggestion, index) => (
                            <WrapItem key={suggestion + index}>
                              <Badge
                                fontSize={isMobile ? "2xs" : "xs"}
                                colorScheme="blue"
                                cursor="pointer"
                                onClick={() => setOtherRole(suggestion.title)}
                              >
                                {suggestion.title}
                              </Badge>
                            </WrapItem>
                          ))}
                        </Wrap>
                      )}

                      <Wrap>
                        {selectedOtherRoles.map((role, index) => (
                          <WrapItem key={role + index}>
                            <Badge
                              variant="subtle"
                              colorScheme="blue"
                              fontSize="sm"
                            >
                              {role}
                              <IconButton
                                icon={<Icon as={FiRefreshCcw} />}
                                size={isMobile ? "2xs" : "xs"}
                                ml="1"
                                onClick={() => handleRemoveRole(role)}
                                aria-label="Remove Role"
                              />
                            </Badge>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </Stack>
                    <FormControl
                      flex={1}
                      id="set-instruments"
                      className={
                        isTourActive && stepIndex === 2 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 2
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Instruments
                      </FormLabel>
                      <InputGroup>
                        <Input
                          fontSize={"15px"}
                          value={instrument}
                          onChange={handleInstrumentChange}
                          onKeyDown={handleInsertInstrument}
                          fontWeight={isTourActive && stepIndex === 2 && "bold"}
                          color={isTourActive && stepIndex === 2 && "black"}
                          backgroundColor={
                            isTourActive && stepIndex === 2 && "white"
                          }
                          height={"8"}
                          placeholder="Enter instrument"
                          width={"20rem"}
                        />
                      </InputGroup>
                      {instrumentSuggestions.length > 0 && instrument && (
                        <Wrap mt={2} spacing={2}>
                          {instrumentSuggestions.map((suggestion) => (
                            <WrapItem key={suggestion}>
                              <Badge
                                variant="subtle"
                                backgroundColor="#6899FE"
                                textColor={"white"}
                                fontSize={"xs"}
                                onClick={() =>
                                  handleSelectInstrument(suggestion)
                                }
                              >
                                {suggestion}
                              </Badge>
                            </WrapItem>
                          ))}
                        </Wrap>
                      )}
                      <Wrap mt={2} mb={1}>
                        {instruments.map((instrument) => (
                          <WrapItem key={instrument}>
                            <Badge
                              variant="subtle"
                              backgroundColor="#6899FE"
                              textColor={"white"}
                              border={10}
                              fontWeight={500}
                              fontSize={isMobile ? "2xs" : "xs"}
                            >
                              {instrument}
                              <IconButton
                                icon={<Icon as={FiX} />}
                                size={isMobile ? "2xs" : "xs"}
                                ml="0.5"
                                backgroundColor={"transparent"}
                                color={"white"}
                                onClick={() =>
                                  handleRemoveInstrument(instrument)
                                }
                                aria-label="Remove Instrument"
                              />
                            </Badge>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </FormControl>
                    <FormControl
                      flex={1}
                      width="100%"
                      id="set-gender"
                      className={
                        isTourActive && stepIndex === 3 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={isMobile ? "12px" : "15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 3
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Gender
                      </FormLabel>
                      <Select
                        fontSize={isMobile ? "12px" : "15px"}
                        value={gender}
                        height={isMobile ? "7" : "8"}
                        fontWeight={isTourActive && stepIndex === 3 && "bold"}
                        color={isTourActive && stepIndex === 3 && "black"}
                        backgroundColor={
                          isTourActive && stepIndex === 3 && "white"
                        }
                        onChange={(e) => setGender(e.target.value)}
                        placeholder="Select gender"
                        width={"20rem"}
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Select>
                    </FormControl>
                  </VStack>
                  <VStack>
                    <FormControl
                      flex={1}
                      width="100%"
                      id="set-signed"
                      className={
                        isTourActive && stepIndex === 4 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={isMobile ? "12px" : "15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 4
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Signed
                      </FormLabel>
                      <Select
                        fontSize={isMobile ? "12px" : "15px"}
                        value={signed}
                        height={isMobile ? "7" : "8"}
                        fontWeight={isTourActive && stepIndex === 4 && "bold"}
                        color={isTourActive && stepIndex === 4 && "black"}
                        backgroundColor={
                          isTourActive && stepIndex === 4 && "white"
                        }
                        onChange={(e) => setSigned(e.target.value)}
                        placeholder="Select signed"
                        width={"20rem"}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Select>
                    </FormControl>
                    <FormControl
                      flex={1}
                      id="set-genres"
                      className={
                        isTourActive && stepIndex === 5 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 5
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Genres
                      </FormLabel>
                      <InputGroup>
                        <Input
                          fontSize={"15px"}
                          value={genre}
                          onChange={handleGenreChange}
                          onKeyDown={handleInsertGenre}
                          fontWeight={isTourActive && stepIndex === 5 && "bold"}
                          color={isTourActive && stepIndex === 5 && "black"}
                          backgroundColor={
                            isTourActive && stepIndex === 5 && "white"
                          }
                          height={"8"}
                          mb={1}
                          width={"20rem"}
                          placeholder="Enter genre"
                        />
                      </InputGroup>
                      {genreSuggestions.length > 0 && genre && (
                        <Wrap mt={2} spacing={2}>
                          {genreSuggestions.slice(0, 6).map((suggestion) => (
                            <WrapItem key={suggestion}>
                              <Badge
                                backgroundColor={"#6899FE"}
                                textColor={"white"}
                                fontWeight={500}
                                fontSize={"xs"}
                                cursor="pointer"
                                onClick={() => handleSelectGenre(suggestion)}
                              >
                                {suggestion}
                              </Badge>
                            </WrapItem>
                          ))}
                        </Wrap>
                      )}
                      <Wrap mt={1}>
                        {genres.map((genre) => (
                          <WrapItem key={genre}>
                            <Badge
                              variant="subtle"
                              backgroundColor={"#6899FE"}
                              textColor={"white"}
                              fontWeight={500}
                              fontSize={"xs"}
                            >
                              {genre}
                              <IconButton
                                icon={<Icon as={FiX} />}
                                size={"xs"}
                                backgroundColor={"transparent"}
                                color={"white"}
                                ml="1"
                                onClick={() => handleRemoveGenre(genre)}
                                aria-label="Remove Instrument"
                              />
                            </Badge>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </FormControl>
                    <FormControl
                      flex={1}
                      textAlign={"center"}
                      className={
                        (isTourActive && stepIndex === 6) ||
                        (isTourActive && stepIndex === 7)
                          ? "tour-highlight"
                          : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mb={1}
                        className={
                          (isTourActive && stepIndex === 6) ||
                          (isTourActive && stepIndex === 7)
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Location
                      </FormLabel>
                      <Autocomplete
                        location={location}
                        isTourActive={isTourActive}
                        stepIndex={stepIndex}
                        setLocation={setLocation}
                        businessLocation={businessLocation}
                        setBusinessLocation={setBusinessLocation}
                        isSwitchOn={isSwitchOn}
                        setIsSwitchOn={setIsSwitchOn}
                        
                        
                      />
                    </FormControl>
                    <FormControl
                      flex={1}
                      id="set-languages"
                      className={
                        isTourActive && stepIndex === 8 ? "tour-highlight" : ""
                      }
                    >
                      <FormLabel
                        fontSize={"15px"}
                        textAlign={"center"}
                        mt={3}
                        mb={1}
                        className={
                          isTourActive && stepIndex === 8
                            ? "text-tour-highlight"
                            : "normal-text-title"
                        }
                      >
                        Languages
                      </FormLabel>
                      <InputGroup>
                        <Select
                          placeholder="Select your language"
                          onChange={(e) => setSelectedLanguage(e.target.value)}
                          fontSize={"15px"}
                          fontWeight={isTourActive && stepIndex === 8 && "bold"}
                          color={isTourActive && stepIndex === 8 && "black"}
                          backgroundColor={
                            isTourActive && stepIndex === 8 && "white"
                          }
                          height={"8"}
                          width={"17.5rem"}
                        >
                          {sortedLanguages.map((language, index) => (
                            <option key={language + index} value={language}>
                              {language}
                            </option>
                          ))}
                        </Select>
                        <Button
                          size="sm"
                          backgroundColor={"#6899FE"}
                          color="#fff"
                          ml={1}
                          onClick={() => handleAddLanguage(selectedLanguage)}
                        >
                          +
                        </Button>
                      </InputGroup>
                      <Flex direction="row" mt="2" flexWrap="wrap">
                        {selectedLanguages.map((language, index) => (
                          <Box
                            key={language + index}
                            mr={2}
                            mt={2}
                            position="relative"
                          >
                            <Flex alignItems="center">
                              <Badge
                                variant="solid"
                                colorScheme={"white"}
                                textColor="black"
                                fontSize={12}
                                fontWeight={400}
                                border={"0.3px solid black"}
                                borderRadius="md"
                                px="2"
                                py="1"
                                display="inline-flex"
                                alignContent={"center"}
                                alignItems="center"
                              >
                                {language}
                                <CloseButton
                                  height={3}
                                  width={3}
                                  ml={2}
                                  onClick={() => handleRemoveLanguage(language)}
                                />
                              </Badge>
                            </Flex>
                          </Box>
                        ))}
                      </Flex>
                    </FormControl>
                  </VStack>
                </HStack>
                <HStack spacing={4} pt={2} direction={"row"} width="100%">
                  <Stack
                    direction="row"
                    flex={1}
                    justifyContent="center"
                    pt={8}
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      isLoading={loading}
                      size={"md"}
                      width={"100px"}
                      backgroundColor={"#6899FE"}
                      color={"#fff"}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                      }}
                      backgroundColor={"#6899FE"}
                      color={"#fff"}
                      size={"md"}
                      width={"100px"}
                      variant="outline"
                    >
                      Reset
                    </Button>
                    {displayedUsers.length > 0 && (
                      <Select
                        width={"100px"}
                        value={usersPerPage}
                        onChange={(e) =>
                          setUsersPerPage(Number(e.target.value))
                        }
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </Select>
                    )}
                  </Stack>
                </HStack>
              </form>
            </HStack>
            {/* Custom Tour Overlay */}
            {isTourActive && (
              <Portal>
                {/* Full-page background overlay with blur effect */}
                <Box
                  position="fixed"
                  top="0"
                  left="0"
                  width="100vw"
                  height="100vh"
                  bg="rgba(0, 0, 0, 0.5)"
                  zIndex="1000"
                  backdropFilter="blur(8px)"
                  onClick={handleCloseTour}
                />

                {/* Highlight the current step target */}
                {steps.map((step, index) => {
                  const element = document.getElementById(step.targetId);
                  if (!element || index !== stepIndex) return null;

                  const { top, left, width, height } =
                    element.getBoundingClientRect();

                  return (
                    <Box key={step.targetId}>
                      {/* Transparent highlight box around the target element */}
                      <Box
                        position="fixed"
                        top={`${top + window.scrollY - 4}px`}
                        left={`${left - 4}px`}
                        width={`${width + 8}px`}
                        height={`${height + 8}px`}
                        border="2px solid #6899fe"
                        borderRadius="8px"
                        zIndex={index === 7 ? "1001" : "1002"}
                        boxShadow="0 0 10px rgba(104, 153, 254, 0.8)"
                        pointerEvents="none"
                        backgroundColor="rgba(255, 255, 255, 0.8)" // Added to make the element stand out
                      />

                      {/* Tooltip box with content */}
                      <Box
                        position="fixed"
                        top={`${top + height + window.scrollY + 10}px`}
                        left={index === 7 ? "75vw" : `${left + width / 2}px`} // Use 'left' for most steps
                        transform="translateX(-50%)"
                        bg="white"
                        p={4}
                        borderRadius="md"
                        boxShadow="lg"
                        zIndex="1003"
                        width="fit-content" // Adjust width based on content
                      >
                        <Box mb={4}>{step.content}</Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Button
                              size="sm"
                              mt={2}
                              onClick={handlePrevious}
                              colorScheme="gray"
                              mr={2}
                            >
                              Previous
                            </Button>
                            <Button
                              size="sm"
                              mt={2}
                              onClick={handleNext}
                              colorScheme="blue"
                            >
                              {stepIndex === steps.length - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          </Box>
                          <Button
                            size="sm"
                            mt={2}
                            onClick={handleSkip}
                            colorScheme="red"
                          >
                            Skip
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Portal>
            )}
          </Box>

          <Stack width="100%" mt={"10px"} mb={"30px"}>
            {displayedUsers.length > 0 ? (
              <Wrap p={4}>
                <Divider
                  borderColor="gray.300"
                  width="90%"
                  m={3}
                  borderWidth="0.5px"
                />
                {displayedUsers.map((user) => (
                  <WrapItem key={user.id} width={"49%"}>
                    <UserCard user={user} isNetwork={true} />
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <Center>No results found.</Center>
            )}
          </Stack>

          {/* Display "More Like This" section if there are results in moreLikeThisUsers */}
          {moreLikeThisUsers.length > 0 && (
            <VStack mt={4} align="center" width="100%">
              <Divider
                borderColor="gray.300"
                width="90%"
                m={3}
                borderWidth="0.5px"
              />
              <Text color={"#6899FE"} fontSize="lg" fontWeight="bold">
                More Like This
              </Text>
              <Wrap p={4}>
                {moreLikeThisUsers.map((user) => (
                  <WrapItem key={user.id} width={"49%"}>
                    <UserCard user={user} isNetwork={true} />
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          )}
          <HStack mb={5}>
            {currentPage > 0 && displayedUsers.length > 0 && (
              <Button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  scrollToDivider();
                }}
                backgroundColor={"#6899FE"}
                color={"#fff"}
                width={"100px"}
              >
                Back
              </Button>
            )}
            {filteredUsers.length > usersPerPage * (currentPage + 1) && (
              <Button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  scrollToDivider();
                }}
                backgroundColor={"#6899FE"}
                color={"#fff"}
                width={"100px"}
              >
                Next
              </Button>
            )}
          </HStack>
        </VStack>
      ) : (
        <VStack
          spacing={4}
          align="center"
          width={{ base: "100%" }}
          maxWidth={{ base: "100vw", md: "500px" }} // Adjusts for smaller screens
          padding={{ base: "10px", md: "20px" }}
          bg="white"
          pt="6"
        >
          {/* Custom Tour Overlay */}
          {isTourActive && (
            <Portal>
              {/* Full-page background overlay with blur effect */}
              <Box
                position="fixed"
                top="0"
                left="0"
                width="100vw"
                height="100vh"
                bg="rgba(0, 0, 0, 0.5)"
                zIndex="1000"
                backdropFilter="blur(8px)"
                onClick={handleCloseTour}
              />

              {/* Highlight the current step target */}
              {steps.map((step, index) => {
                const element = document.getElementById(step.targetId);
                if (!element || index !== stepIndex) return null;

                const { top, left, width, height } =
                  element.getBoundingClientRect();

                return (
                  <Box key={step.targetId}>
                    {/* Transparent highlight box around the target element */}
                    <Box
                      position="fixed"
                      top={`${top + window.scrollY - 4}px`}
                      left={`${left - 4}px`}
                      width={`${width + 8}px`}
                      height={`${height + 8}px`}
                      border="2px solid #6899fe"
                      borderRadius="8px"
                      zIndex={index === 7 ? "1001" : "1002"}
                      boxShadow="0 0 10px rgba(104, 153, 254, 0.8)"
                      pointerEvents="none"
                      backgroundColor="rgba(255, 255, 255, 0.8)" // Added to make the element stand out
                    />

                    {/* Tooltip box with content */}
                    <Box
                      position="fixed"
                      top={`${top + height + window.scrollY + 10}px`}
                      left={
                        index === 7
                          ? `${50}%`
                          : `${
                              left +
                              width / 2 +
                              (index === 6 || index === 8 ? 15 : 0)
                            }px`
                      }
                      transform="translateX(-50%)"
                      bg="white"
                      p={4}
                      borderRadius="md"
                      boxShadow="lg"
                      zIndex="1003"
                      width="90%" // Adjust width based on content
                    >
                      <Box mb={4}>{step.content}</Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Button
                            size="sm"
                            mt={2}
                            onClick={handlePrevious}
                            colorScheme="gray"
                            mr={2}
                          >
                            Previous
                          </Button>
                          <Button
                            size="sm"
                            mt={2}
                            onClick={handleNext}
                            colorScheme="blue"
                          >
                            {stepIndex === steps.length - 1 ? "Finish" : "Next"}
                          </Button>
                        </Box>
                        <Button
                          size="sm"
                          mt={2}
                          onClick={handleSkip}
                          colorScheme="red"
                        >
                          Skip
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Portal>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFilter();
            }}
            style={{
              borderRadius: "8px",
              padding: "5px",
              width: "100%",
            }}
          >
            <Flex direction={"row"}>
              <Flex flex={1} />
              <Text
                mt={3}
                mb={"20px"}
                fontWeight={"bold"}
                color={"#6899FE"}
                fontSize={"20px"}
                px={4}
                bgColor={"#E2E8F0"}
                borderRadius={"10px"}
                borderWidth={"1px"}
              >
                Network
              </Text>
              <Flex flex={1} />
            </Flex>
            <Stack direction={{ base: "column" }} width="100%">
              <FormControl
                flex={1}
                id="set-username"
                className={
                  isTourActive && stepIndex === 0 ? "tour-highlight" : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  mb="2px"
                  mt={"10px"}
                  className={
                    isTourActive && stepIndex === 0
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                >
                  Username
                </FormLabel>
                <Input
                  fontSize={"12px"}
                  value={username}
                  height={"7"}
                  fontWeight={isTourActive && stepIndex === 0 && "bold"}
                  color={isTourActive && stepIndex === 0 && "black"}
                  backgroundColor={isTourActive && stepIndex === 0 && "white"}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter username"
                  mb="1px"
                  mt="none"
                />
              </FormControl>
              <FormControl
                flex={1}
                id="set-role"
                className={
                  isTourActive && stepIndex === 1 ? "tour-highlight" : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  className={
                    isTourActive && stepIndex === 1
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                  mb="2px"
                >
                  Role
                </FormLabel>
                <Select
                  fontSize={"12px"}
                  value={role}
                  height={"7"}
                  fontWeight={isTourActive && stepIndex === 1 && "bold"}
                  color={isTourActive && stepIndex === 1 && "black"}
                  backgroundColor={isTourActive && stepIndex === 1 && "white"}
                  mb="1px"
                  onChange={(e) => {
                    handleRoleSelectChange(e);
                    setRole(e.target.value);
                  }}
                  placeholder="Select role"
                >
                  {roles.map((rol) => (
                    <option
                      key={rol}
                      disabled={
                        rol === "PROFESSIONAL" ||
                        rol === "CREATIVE" ||
                        rol === "BUSINESS"
                      }
                    >
                      {rol}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack flexDirection={"column"}>
                {showOtherTextField && (
                  <FormControl flex={1}>
                    <FormLabel
                      fontSize={"12px"}
                      textAlign={"center"}
                      color={"#6899FE"}
                    >
                      Other Role
                    </FormLabel>
                    <Input
                      fontSize={"12px"}
                      value={otherRole}
                      onChange={handleOtherRoleChange}
                      onKeyDown={handleInsertRole}
                      placeholder="Enter other role"
                    />
                  </FormControl>
                )}
                {otherRoleSuggestions.length > 0 && (
                  <Wrap mt={2} spacing={2}>
                    {otherRoleSuggestions.map((suggestion, index) => (
                      <WrapItem key={suggestion + index}>
                        <Badge
                          fontSize={"2xs"}
                          colorScheme="blue"
                          cursor="pointer"
                          onClick={() => setOtherRole(suggestion.title)}
                        >
                          {suggestion.title}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                )}

                <Wrap>
                  {selectedOtherRoles.map((role, index) => (
                    <WrapItem key={role + index}>
                      <Badge variant="subtle" colorScheme="blue" fontSize="sm">
                        {role}
                        <IconButton
                          icon={<Icon as={FiRefreshCcw} />}
                          size={"2xs"}
                          ml="1"
                          onClick={() => handleRemoveRole(role)}
                          aria-label="Remove Role"
                        />
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              </Stack>
              <FormControl
                flex={1}
                id="set-instruments"
                className={
                  isTourActive && stepIndex === 2 ? "tour-highlight" : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  mb="2px"
                  className={
                    isTourActive && stepIndex === 2
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                >
                  Instruments
                </FormLabel>
                <InputGroup>
                  <Input
                    fontSize={"12px"}
                    value={instrument}
                    onChange={handleInstrumentChange}
                    onKeyDown={handleInsertInstrument}
                    fontWeight={isTourActive && stepIndex === 2 && "bold"}
                    color={isTourActive && stepIndex === 2 && "black"}
                    backgroundColor={isTourActive && stepIndex === 2 && "white"}
                    height={"7"}
                    mb="1px"
                    placeholder="Enter instrument"
                  />
                </InputGroup>
                {instrumentSuggestions.length > 0 && instrument && (
                  <Wrap mt={2} spacing={2}>
                    {instrumentSuggestions.slice(0, 6).map((suggestion) => (
                      <WrapItem key={suggestion}>
                        <Badge
                          variant="subtle"
                          backgroundColor="#6899FE"
                          textColor={"white"}
                          fontSize={"2xs"}
                          onClick={() => handleSelectInstrument(suggestion)}
                        >
                          {suggestion}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                )}
                <Wrap mt={2} mb={1}>
                  {instruments.map((instrument) => (
                    <WrapItem key={instrument}>
                      <Badge
                        variant="subtle"
                        backgroundColor="#6899FE"
                        textColor={"white"}
                        fontSize={"2xs"}
                      >
                        {instrument}
                        <IconButton
                          icon={<Icon as={FiRefreshCcw} />}
                          size={"2xs"}
                          ml="3"
                          onClick={() => handleRemoveInstrument(instrument)}
                          aria-label="Remove Instrument"
                        />
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              </FormControl>
            </Stack>
            <Stack
              spacing={4}
              pt="0"
              direction={{ base: "column" }}
              width="100%"
            >
              <VStack>
                <FormControl
                  flex={1}
                  width="100%"
                  id="set-gender"
                  className={
                    isTourActive && stepIndex === 3 ? "tour-highlight" : ""
                  }
                >
                  <FormLabel
                    fontSize={"12px"}
                    textAlign={"center"}
                    className={
                      isTourActive && stepIndex === 3
                        ? "text-tour-highlight"
                        : "normal-text-title"
                    }
                    mb="2px"
                  >
                    Gender
                  </FormLabel>
                  <Select
                    fontSize={"12px"}
                    value={gender}
                    height={"7"}
                    fontWeight={isTourActive && stepIndex === 3 && "bold"}
                    color={isTourActive && stepIndex === 3 && "black"}
                    backgroundColor={isTourActive && stepIndex === 3 && "white"}
                    mb="1px"
                    onChange={(e) => setGender(e.target.value)}
                    placeholder="Select gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                </FormControl>
                <FormControl
                  flex={1}
                  width="100%"
                  id="set-signed"
                  className={
                    isTourActive && stepIndex === 4 ? "tour-highlight" : ""
                  }
                >
                  <FormLabel
                    fontSize={"12px"}
                    textAlign={"center"}
                    mb="2px"
                    className={
                      isTourActive && stepIndex === 4
                        ? "text-tour-highlight"
                        : "normal-text-title"
                    }
                  >
                    Signed
                  </FormLabel>
                  <Select
                    fontSize={"12px"}
                    value={signed}
                    height={"7"}
                    fontWeight={isTourActive && stepIndex === 4 && "bold"}
                    color={isTourActive && stepIndex === 4 && "black"}
                    backgroundColor={isTourActive && stepIndex === 4 && "white"}
                    mb="1px"
                    onChange={(e) => setSigned(e.target.value)}
                    placeholder="Select signed"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Select>
                </FormControl>
              </VStack>
              <FormControl
                flex={1}
                id="set-genres"
                className={
                  isTourActive && stepIndex === 5 ? "tour-highlight" : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  className={
                    isTourActive && stepIndex === 5
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                  mb="2px"
                >
                  Genres
                </FormLabel>
                <InputGroup>
                  <Input
                    fontSize={"12px"}
                    value={genre}
                    onChange={handleGenreChange}
                    onKeyDown={handleInsertGenre}
                    fontWeight={isTourActive && stepIndex === 5 && "bold"}
                    color={isTourActive && stepIndex === 5 && "black"}
                    backgroundColor={isTourActive && stepIndex === 5 && "white"}
                    height={"7"}
                    mb="1px"
                    placeholder="Enter genre"
                  />
                </InputGroup>
                {genreSuggestions.length > 0 && genre && (
                  <Wrap>
                    {genreSuggestions.map((suggestion) => (
                      <WrapItem key={suggestion}>
                        <Badge
                          colorScheme="blue"
                          cursor="pointer"
                          fontSize={"2xs"}
                          onClick={() => handleSelectGenre(suggestion)}
                        >
                          {suggestion}
                        </Badge>
                      </WrapItem>
                    ))}
                  </Wrap>
                )}
                <Wrap>
                  {genres.map((genre) => (
                    <WrapItem key={genre}>
                      <Badge
                        variant="subtle"
                        colorScheme="blue"
                        fontSize={"2xs"}
                      >
                        {genre}
                        <IconButton
                          icon={<Icon as={FiX} />}
                          size={"2xs"}
                          ml="1"
                          onClick={() => handleRemoveGenre(genre)}
                          aria-label="Remove Instrument"
                        />
                      </Badge>
                    </WrapItem>
                  ))}
                </Wrap>
              </FormControl>
              <FormControl
                flex={1}
                textAlign={"center"}
                className={
                  (isTourActive && stepIndex === 6) ||
                  (isTourActive && stepIndex === 7)
                    ? "tour-highlight"
                    : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  mb="2px"
                  className={
                    (isTourActive && stepIndex === 6) ||
                    (isTourActive && stepIndex === 7)
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                  ref={dividerRef}
                >
                  Location
                </FormLabel>
                <Autocomplete
                  location={location}
                  setLocation={setLocation}
                  isTourActive={isTourActive}
                  stepIndex={stepIndex}
                  businessLocation={businessLocation}
                  setBusinessLocation={setBusinessLocation}
                  isSwitchOn={isSwitchOn}
                  setIsSwitchOn={setIsSwitchOn}
                />
              </FormControl>
            </Stack>
            <>
              <FormControl
                flex={1}
                mt={2}
                id="set-languages"
                className={
                  isTourActive && stepIndex === 8 ? "tour-highlight" : ""
                }
              >
                <FormLabel
                  fontSize={"12px"}
                  textAlign={"center"}
                  mt="15px"
                  className={
                    isTourActive && stepIndex === 8
                      ? "text-tour-highlight"
                      : "normal-text-title"
                  }
                  mb="2px"
                >
                  Languages
                </FormLabel>
                <InputGroup>
                  <Select
                    height={"7"}
                    placeholder="Select your language"
                    fontSize={"12px"}
                    fontWeight={isTourActive && stepIndex === 8 && "bold"}
                    color={isTourActive && stepIndex === 8 && "black"}
                    backgroundColor={isTourActive && stepIndex === 8 && "white"}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    ml={0}
                  >
                    {sortedLanguages.map((language, index) => (
                      <option key={language + index} value={language}>
                        {language}
                      </option>
                    ))}
                  </Select>
                  <Button
                    size="sm"
                    ml={2}
                    color="#fff"
                    backgroundColor={"#6899fe"}
                    onClick={() => handleAddLanguage(selectedLanguage)}
                  >
                    +
                  </Button>
                </InputGroup>
                <Flex direction="row" mt="-1" flexWrap="wrap">
                  {selectedLanguages.map((language, index) => (
                    <Box
                      key={language + index}
                      mr={2}
                      mt={2}
                      position="relative"
                    >
                      <Flex alignItems="center">
                        <Badge
                          variant="solid"
                          colorScheme="blue"
                          borderRadius="md"
                          px="2"
                          py="1"
                          fontSize={"2xs"}
                          display="inline-flex"
                          alignItems="center"
                        >
                          {language}
                        </Badge>
                        <CloseButton
                          size="sm"
                          onClick={() => handleRemoveLanguage(language)}
                        />
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              </FormControl>
              <Stack
                direction="row"
                flex={1}
                justifyContent="center"
                pt={3}
                alignItems="center"
              >
                <Button
                  type="submit"
                  isLoading={loading}
                  size={"sm"}
                  width={{ base: "auto" }}
                  backgroundColor={"#6899fe"}
                  color={"#fff"}
                  onClick={() => setIsSearchPerformed(true)}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    resetForm();
                    setIsSearchPerformed(false);
                  }}
                  size={"sm"}
                  backgroundColor={"#6899fe"}
                  color={"#fff"}
                  width={{ base: "auto" }}
                  variant="outline"
                >
                  Reset
                </Button>
                {isSearchPerformed && (
                  <Select
                    width={"100px"}
                    value={usersPerPage}
                    onChange={(e) => setUsersPerPage(Number(e.target.value))}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </Select>
                )}
              </Stack>
            </>
          </form>
          <Stack spacing={6} width="102%">
            {displayedUsers.length > 0 ? (
              <Wrap>
                <Divider
                  borderColor="gray.300"
                  width="90%"
                  m={2}
                  mt="0px"
                  mb="2px"
                  borderWidth="0.5px"
                />
                {displayedUsers.map((user) => (
                  <WrapItem key={user.id} width={"100%"} height="auto">
                    <UserCard
                      user={user}
                      isNetwork={true}
                      style={{
                        padding: "6px", // Reduced padding inside the card to make it tighter
                        lineHeight: "1.1", // Reduced line height for more compact text
                        fontSize: "13px", // Decreased font size to fit better in smaller height
                      }}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            ) : (
              <Center>No results found.</Center>
            )}
          </Stack>

          {moreLikeThisUsers.length > 0 && (
            <VStack mt={4} align="center" width="102%">
              <Divider
                borderColor="gray.300"
                width="90%"
                m={2}
                borderWidth="0.5px"
              />
              <Text color={"#6899FE"} fontSize="lg" fontWeight="bold">
                More Like This
              </Text>
              <Wrap p={4}>
                {moreLikeThisUsers.map((user) => (
                  <WrapItem key={user.id} width={"100%"}>
                    <UserCard user={user} isNetwork={true} />
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          )}
          <HStack>
            {isSearchPerformed &&
              currentPage > 0 &&
              displayedUsers.length > 0 && (
                <Button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    scrollToDivider();
                  }}
                  backgroundColor={"#6899FE"}
                  color={"#fff"}
                  width={"100px"}
                >
                  Back
                </Button>
              )}
            {filteredUsers.length > usersPerPage * (currentPage + 1) && (
              <Button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  scrollToDivider();
                }}
                backgroundColor={"#6899FE"}
                color={"#fff"}
                width={"100px"}
              >
                Next
              </Button>
            )}
          </HStack>
        </VStack>
      )}
    </Center>
  );
};

export default NetworkPage;
